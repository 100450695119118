import { createSelector } from 'reselect';
import _pick from 'lodash.pick';

export const basicInfo = createSelector(
  state => state.prescoreReport?.basicData,
  basicData => _pick(basicData, ['basicInformation', 'statistics', 'rating', 'averageSalaryDate'])
);

export const basicContact = createSelector(
  state => state.prescoreReport?.basicData,
  basicData => _pick(basicData, ['contacts'])
);

export const selectBasicInformation = createSelector(
  state => state.prescoreReport?.basicData,
  basicData => basicData?.basicInformation || {}
);
