import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, BackTop } from 'antd';

import useRedirect from 'hooks/navigation/useRedirect';
import ReportHeader from 'components/reusable/report/ReportHeader';
import {
  getChildOrderId,
  getOrderId,
  getChildNumberOfQueries,
  getChildVehiclesValue,
  getChildAutomatedCreditLimit,
  getChildBankruptcyRating,
  getChildDefaultRating,
  getChildOpenDebts,
  getChildPaymentHistory,
  getChildRegisteredArrests,
  getChildUnregisteredArrests,
} from 'store/entities/startReport/thunks';
import { resetStartReport } from 'store/entities/startReport';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import useStartReportState from 'hooks/report/useStartReportState';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import FullReport from 'views/StartReport/FullReport';
import OrderExpertReport from '../ProfessionalReport/CompanyRatings/OrderExpertReport';

const StartReport = () => {
  const { companyId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { orderId, orderDetails, childOrderId, productCode } = useSelector(state => state.startReport);
  const isUiHidden = useIsUiHidden();
  const redirect = useRedirect();
  const { isLoading, hasError } = useStartReportState();

  const orderReport = useCallback(async () => {
    const orderIdResult = await dispatch(getOrderId(companyId));

    if (!getOrderId.fulfilled.match(orderIdResult)) {
      redirect('/search/company');
    }
  }, [dispatch, redirect, getOrderId]);

  useEffect(() => {
    orderReport();
    return () => dispatch(resetStartReport());
  }, []);

  useEffect(() => {
    if (orderId) {
      const childIdRequest = {
        productCode: productCode,
        parentOrderId: orderId,
      };
      dispatch(
        getChildOrderId({
          companyCode: companyId,
          productCodeRequest: childIdRequest,
        })
      );
    }
  }, [productCode]);

  useEffect(() => {
    if (childOrderId) {
      if (productCode === 'BankruptcyRating') {
        dispatch(getChildBankruptcyRating(childOrderId));
      } else if (productCode === 'SubjectSearchesCount') {
        dispatch(getChildNumberOfQueries(childOrderId));
      } else if (productCode === 'ValueOfVehicles') {
        dispatch(getChildVehiclesValue(childOrderId));
      } else if (productCode === 'AutomatedCreditLimit') {
        dispatch(getChildAutomatedCreditLimit(childOrderId));
      } else if (productCode === 'DefaultRating') {
        dispatch(getChildDefaultRating(childOrderId));
      } else if (productCode === 'OpenDebts') {
        dispatch(getChildOpenDebts(childOrderId));
      } else if (productCode === 'PaymentHistory') {
        dispatch(getChildPaymentHistory(childOrderId));
      } else if (productCode === 'RegisteredArrests') {
        dispatch(getChildRegisteredArrests(childOrderId));
      } else if (productCode === 'RegisteredOutRealEstateArrests') {
        dispatch(getChildUnregisteredArrests(childOrderId));
      }
    }
  }, [childOrderId]);

  return (
    <LoadingSpinner size="large" spinning={isLoading}>
      {hasError && <Redirect to="/search/company" exact />}
      <div className="container">
        <Col span={24}>
          <ReportHeader title={orderDetails?.companyTitle} subTitle={t('startReport.title')} />
          {orderId && (
            <Col span={24}>
              <OrderExpertReport />
              <FullReport />
            </Col>
          )}
        </Col>
        {!isUiHidden && <BackTop />}
      </div>
    </LoadingSpinner>
  );
};

export default StartReport;
