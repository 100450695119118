import { fetchFromApi, optionsForGetRequest } from 'api';

const getOrderId = companyCode =>
  fetchFromApi(`/company/${companyCode}/report/main-info/order`, optionsForGetRequest());

const getBasicInfo = orderId => fetchFromApi(`/company/${orderId}/report/main-info/basic`, optionsForGetRequest());

const getOrderDetails = (companyCode, orderId) =>
  fetchFromApi(`/company/${companyCode}/report/main-info/order/details/${orderId}`, optionsForGetRequest());

const getMainInformationPdf = orderId => fetchFromApi(`/company/${orderId}/report/main-info/pdf/preview`);

export default {
  getOrderId,
  getBasicInfo,
  getOrderDetails,
  getMainInformationPdf,
};
