import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { languages } from 'i18n';

import { Col, Row, Menu, Popover, Drawer, Button } from 'antd';
import { MenuOutlined, CloseOutlined, GlobalOutlined } from '@ant-design/icons';
import { ReactComponent as FaceBook } from 'assets/img/icons/fb.svg';
import { ReactComponent as LinkedIn } from 'assets/img/icons/linkedin.svg';
import LoginOptions from './LoginOptions';
import RegisteredUsersForm from './RegisteredUsersForm';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import { setIsUiHidden } from 'store/entities/ui';
import ForgotPassword from './ForgotPassword';
import ContactUs from './ContactUs';
import { LogoLink } from 'components/reusable/LogoLink';
import useWindowSize from '../../hooks/dom/useWindowSize';
import ContactUsDiv from 'views/Search/UnauthenticatedSearch/ContactUsDiv';

const { SubMenu } = Menu;

const Login = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const { signInPending, sessionLoading } = useSelector(state => state.user);
  const [contactPopoverVisible, setContactPopoverVisible] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [loginOption, setLoginOption] = useState(null);
  const location = useLocation();
  const showLoginForm = location.pathname.includes('login');
  const { width: windowWidth } = useWindowSize();

  const contactRef = React.useRef();

  useEffect(() => {
    dispatch(setIsUiHidden(true));
    return () => {
      dispatch(setIsUiHidden(false));
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 991) {
      contactPopoverVisible && setContactPopoverVisible(false);
    } else {
      drawerVisible && setDrawerVisible(false);
      contactPopoverVisible && setContactPopoverVisible(false);
    }
  }, [windowWidth]);

  const getCompanyProfilePageByLang = lang =>
    lang === 'lt-LT' ? 'https://lt.creditinfo.com/apie-mus/' : 'https://lt.creditinfo.com/en/company-profile/';

  const getCompanyStrongestPageByLang = lang =>
    lang === 'lt-LT'
      ? 'https://lt.creditinfo.com/lt/stipriausi-lietuvoje/'
      : 'https://lt.creditinfo.com/en/strongest-in-lithuania/';

  const socials = [
    {
      link: 'https://www.linkedin.com/company/creditinfo-lietuva/',
      text: <LinkedIn />,
    },
    {
      link: 'https://www.facebook.com/StipriausiLietuvoje',
      text: <FaceBook />,
    },
  ];

  return (
    <LoadingSpinner size="large" spinning={signInPending || sessionLoading}>
      <>
        <Row className="login-wrapper">
          <Col xs={24} sm={24} md={24} lg={12} xl={10} className="login-container">
            <LogoLink
              className="small-login-logo"
              content={<div className="login-logo" style={{ minHeight: windowWidth > 991 && 80 }} />}
            />
            {!loginOption && !showLoginForm && <LoginOptions setLoginOption={setLoginOption} />}
            {(loginOption === 'registered' || showLoginForm) && <RegisteredUsersForm setLoginOption={setLoginOption} />}
            {loginOption === 'forgotPassword' && <ForgotPassword setLoginOption={setLoginOption} />}
            {windowWidth > 991 && (
              <Menu
                mode="horizontal"
                selectedKeys={false}
                overflowedIndicator={<MenuOutlined className="only-child" />}
              >
                <Menu.Item key="contact">
                  <Popover
                    overlayClassName="contact-popover"
                    content={<ContactUs ref={contactRef} />}
                    visible={contactPopoverVisible}
                    onVisibleChange={visible => setContactPopoverVisible(visible)}
                    trigger="click"
                  >
                    {t('login.contactUs')}
                  </Popover>
                </Menu.Item>
                <Menu.Item key="about">
                  <a href={getCompanyProfilePageByLang(i18n.language)} target="_blank" rel="noopener noreferrer">
                    {t('login.aboutUs')}
                  </a>
                </Menu.Item>
                <SubMenu title={t('global.language')} popupClassName="minimized-submenu" icon={<GlobalOutlined />}>
                  {languages.map(({ codeShort, codeLong }) => (
                    <Menu.Item
                      key={`lang${codeLong}`}
                      onClick={() => i18n.changeLanguage(codeShort)}
                      style={{ fontWeight: i18n.language === codeShort ? 600 : 'normal' }}
                      className="minimized-submenu-item"
                    >
                      {t(`global.${codeLong}`)}
                    </Menu.Item>
                  ))}
                </SubMenu>
              </Menu>
            )}
          </Col>
          <Col xs={0} sm={0} md={0} lg={12} xl={14} style={{ background: '#ffffff' }}>
            <a href={getCompanyStrongestPageByLang(i18n.language)} target="_blank" rel="noopener noreferrer">
              <div className={`login-image${loginOption ? ' login-image--backdrop' : ''}`}>
                <div className="top-text">{t('banner.top.text')}</div>
                <div className="bottom-text">{t('banner.bottom.text')}</div>
              </div>
            </a>
          </Col>
        </Row>
        {windowWidth < 992 && (
          <>
            <div className="login-small-screen-menu">
              <MenuOutlined onClick={() => setDrawerVisible(true)} />
            </div>
            <Drawer
              title={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span>Menu</span>
                  <Button type="text" icon={<CloseOutlined />} onClick={() => setDrawerVisible(false)} />
                </div>
              }
              placement="left"
              closable={false}
              onClose={() => setDrawerVisible(false)}
              visible={drawerVisible}
              width={windowWidth}
              className="border-free-drawer"
              getContainer={false}
            >
              <Menu
                mode="inline"
                onClick={() => setDrawerVisible(false)}
                selectedKeys={[]}
                className="unauthenticated-menu"
              >
                <Menu.Item key="about" style={{ paddingLeft: 16 }}>
                  <a href={getCompanyProfilePageByLang(i18n.language)} target="_blank" rel="noopener noreferrer">
                    {t('login.aboutUs')}
                  </a>
                </Menu.Item>
                <Menu.SubMenu key="contactSubMenu" title={t('login.contactUs')}>
                  <ContactUsDiv />
                </Menu.SubMenu>
                <Menu.SubMenu key="languageSubMenu" title={t('global.language')}>
                  <Menu.Item
                    className="selectable"
                    key="langEN"
                    onClick={() => {
                      i18n.changeLanguage('en-US');
                    }}
                  >
                    {t('global.ENG')}
                  </Menu.Item>
                  <Menu.Item
                    className="selectable"
                    key="langLT"
                    onClick={() => {
                      i18n.changeLanguage('lt-LT');
                    }}
                  >
                    {t('global.LTU')}
                  </Menu.Item>
                </Menu.SubMenu>
              </Menu>
              <Row style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', padding: '16px' }}>
                {socials.map((item, index) => (
                  <Col key={index} span={12} className="justify-content-center">
                    <a href={item.link} target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>
                      {item.text}
                    </a>
                  </Col>
                ))}
              </Row>
            </Drawer>
          </>
        )}
      </>
    </LoadingSpinner>
  );
};

export default Login;
