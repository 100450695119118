import React from 'react';
import { useTranslation } from 'react-i18next';

import useCurrentLanguage from 'hooks/user/useCurrentLanguage';

import { Button, Card, Col, Row, Typography } from 'antd';

import SL20112022EN from 'assets/img/strongest/SL_2011_2022_EN.png';
import SL20112022LT from 'assets/img/strongest/SL_2011_2022_LT.png';
import SL20122022EN from 'assets/img/strongest/SL_2012_2022_EN.png';
import SL20122022LT from 'assets/img/strongest/SL_2012_2022_LT.png';
import SL20132022EN from 'assets/img/strongest/SL_2013_2022_EN.png';
import SL20132022LT from 'assets/img/strongest/SL_2013_2022_LT.png';
import SL20142022EN from 'assets/img/strongest/SL_2014_2022_EN.png';
import SL20142022LT from 'assets/img/strongest/SL_2014_2022_LT.png';
import SL20152022EN from 'assets/img/strongest/SL_2015_2022_EN.png';
import SL20152022LT from 'assets/img/strongest/SL_2015_2022_LT.png';
import SL20162022EN from 'assets/img/strongest/SL_2016_2022_EN.png';
import SL20162022LT from 'assets/img/strongest/SL_2016_2022_LT.png';
import SL20172022EN from 'assets/img/strongest/SL_2017_2022_EN.png';
import SL20172022LT from 'assets/img/strongest/SL_2017_2022_LT.png';
import SL20182022EN from 'assets/img/strongest/SL_2018_2022_EN.png';
import SL20182022LT from 'assets/img/strongest/SL_2018_2022_LT.png';
import SL20192022EN from 'assets/img/strongest/SL_2019_2022_EN.png';
import SL20192022LT from 'assets/img/strongest/SL_2019_2022_LT.png';
import SL20202022EN from 'assets/img/strongest/SL_2020_2022_EN.png';
import SL20202022LT from 'assets/img/strongest/SL_2020_2022_LT.png';
import SL20212022EN from 'assets/img/strongest/SL_2021_2022_EN.png';
import SL20212022LT from 'assets/img/strongest/SL_2021_2022_LT.png';
import SL20112023EN from 'assets/img/strongest/SL_2011_2023_EN.png';
import SL20112023LT from 'assets/img/strongest/SL_2011_2023_LT.png';
import SL20122023EN from 'assets/img/strongest/SL_2012_2023_EN.png';
import SL20122023LT from 'assets/img/strongest/SL_2012_2023_LT.png';
import SL20132023EN from 'assets/img/strongest/SL_2013_2023_EN.png';
import SL20132023LT from 'assets/img/strongest/SL_2013_2023_LT.png';
import SL20142023EN from 'assets/img/strongest/SL_2014_2023_EN.png';
import SL20142023LT from 'assets/img/strongest/SL_2014_2023_LT.png';
import SL20152023EN from 'assets/img/strongest/SL_2015_2023_EN.png';
import SL20152023LT from 'assets/img/strongest/SL_2015_2023_LT.png';
import SL20162023EN from 'assets/img/strongest/SL_2016_2023_EN.png';
import SL20162023LT from 'assets/img/strongest/SL_2016_2023_LT.png';
import SL20172023EN from 'assets/img/strongest/SL_2017_2023_EN.png';
import SL20172023LT from 'assets/img/strongest/SL_2017_2023_LT.png';
import SL20182023EN from 'assets/img/strongest/SL_2018_2023_EN.png';
import SL20182023LT from 'assets/img/strongest/SL_2018_2023_LT.png';
import SL20192023EN from 'assets/img/strongest/SL_2019_2023_EN.png';
import SL20192023LT from 'assets/img/strongest/SL_2019_2023_LT.png';
import SL20202023EN from 'assets/img/strongest/SL_2020_2023_EN.png';
import SL20202023LT from 'assets/img/strongest/SL_2020_2023_LT.png';
import SL20212023EN from 'assets/img/strongest/SL_2021_2023_EN.png';
import SL20212023LT from 'assets/img/strongest/SL_2021_2023_LT.png';
import SL20222023EN from 'assets/img/strongest/SL_2022_2023_EN.png';
import SL20222023LT from 'assets/img/strongest/SL_2022_2023_LT.png';
import SL20112024EN from 'assets/img/strongest/SL_2011_2024_EN.png';
import SL20112024LT from 'assets/img/strongest/SL_2011_2024_LT.png';
import SL20122024EN from 'assets/img/strongest/SL_2012_2024_EN.png';
import SL20122024LT from 'assets/img/strongest/SL_2012_2024_LT.png';
import SL20132024EN from 'assets/img/strongest/SL_2013_2024_EN.png';
import SL20132024LT from 'assets/img/strongest/SL_2013_2024_LT.png';
import SL20142024EN from 'assets/img/strongest/SL_2014_2024_EN.png';
import SL20142024LT from 'assets/img/strongest/SL_2014_2024_LT.png';
import SL20152024EN from 'assets/img/strongest/SL_2015_2024_EN.png';
import SL20152024LT from 'assets/img/strongest/SL_2015_2024_LT.png';
import SL20162024EN from 'assets/img/strongest/SL_2016_2024_EN.png';
import SL20162024LT from 'assets/img/strongest/SL_2016_2024_LT.png';
import SL20172024EN from 'assets/img/strongest/SL_2017_2024_EN.png';
import SL20172024LT from 'assets/img/strongest/SL_2017_2024_LT.png';
import SL20182024EN from 'assets/img/strongest/SL_2018_2024_EN.png';
import SL20182024LT from 'assets/img/strongest/SL_2018_2024_LT.png';
import SL20192024EN from 'assets/img/strongest/SL_2019_2024_EN.png';
import SL20192024LT from 'assets/img/strongest/SL_2019_2024_LT.png';
import SL20202024EN from 'assets/img/strongest/SL_2020_2024_EN.png';
import SL20202024LT from 'assets/img/strongest/SL_2020_2024_LT.png';
import SL20212024EN from 'assets/img/strongest/SL_2021_2024_EN.png';
import SL20212024LT from 'assets/img/strongest/SL_2021_2024_LT.png';
import SL20222024EN from 'assets/img/strongest/SL_2022_2024_EN.png';
import SL20222024LT from 'assets/img/strongest/SL_2022_2024_LT.png';
import SL20232024EN from 'assets/img/strongest/SL_2023_2024_EN.png';
import SL20232024LT from 'assets/img/strongest/SL_2023_2024_LT.png';
import SL2022EN from 'assets/img/strongest/SL_2022_EN.png';
import SL2022LT from 'assets/img/strongest/SL_2022_LT.png';
import SL2023EN from 'assets/img/strongest/SL_2023_EN.png';
import SL2023LT from 'assets/img/strongest/SL_2023_LT.png';
import SL2024EN from 'assets/img/strongest/SL_2024_EN.png';
import SL2024LT from 'assets/img/strongest/SL_2024_LT.png';
import SLL20152022EN from 'assets/img/strongest/SLL_2015_2022_EN.png';
import SLL20152022LT from 'assets/img/strongest/SLL_2015_2022_LT.png';
import SLL20162022EN from 'assets/img/strongest/SLL_2016_2022_EN.png';
import SLL20162022LT from 'assets/img/strongest/SLL_2016_2022_LT.png';
import SLL20172022EN from 'assets/img/strongest/SLL_2017_2022_EN.png';
import SLL20172022LT from 'assets/img/strongest/SLL_2017_2022_LT.png';
import SLL20182022EN from 'assets/img/strongest/SLL_2018_2022_EN.png';
import SLL20182022LT from 'assets/img/strongest/SLL_2018_2022_LT.png';
import SLL20192022EN from 'assets/img/strongest/SLL_2019_2022_EN.png';
import SLL20192022LT from 'assets/img/strongest/SLL_2019_2022_LT.png';
import SLL20202022EN from 'assets/img/strongest/SLL_2020_2022_EN.png';
import SLL20202022LT from 'assets/img/strongest/SLL_2020_2022_LT.png';
import SLL20212022EN from 'assets/img/strongest/SLL_2021_2022_EN.png';
import SLL20212022LT from 'assets/img/strongest/SLL_2021_2022_LT.png';
import SLL20152023EN from 'assets/img/strongest/SLL_2015_2023_EN.png';
import SLL20152023LT from 'assets/img/strongest/SLL_2015_2023_LT.png';
import SLL20162023EN from 'assets/img/strongest/SLL_2016_2023_EN.png';
import SLL20162023LT from 'assets/img/strongest/SLL_2016_2023_LT.png';
import SLL20172023EN from 'assets/img/strongest/SLL_2017_2023_EN.png';
import SLL20172023LT from 'assets/img/strongest/SLL_2017_2023_LT.png';
import SLL20182023EN from 'assets/img/strongest/SLL_2018_2023_EN.png';
import SLL20182023LT from 'assets/img/strongest/SLL_2018_2023_LT.png';
import SLL20192023EN from 'assets/img/strongest/SLL_2019_2023_EN.png';
import SLL20192023LT from 'assets/img/strongest/SLL_2019_2023_LT.png';
import SLL20202023EN from 'assets/img/strongest/SLL_2020_2023_EN.png';
import SLL20202023LT from 'assets/img/strongest/SLL_2020_2023_LT.png';
import SLL20212023EN from 'assets/img/strongest/SLL_2021_2023_EN.png';
import SLL20212023LT from 'assets/img/strongest/SLL_2021_2023_LT.png';
import SLL20222023EN from 'assets/img/strongest/SLL_2022_2023_EN.png';
import SLL20222023LT from 'assets/img/strongest/SLL_2022_2023_LT.png';
import SLL20152024EN from 'assets/img/strongest/SLL_2015_2024_EN.png';
import SLL20152024LT from 'assets/img/strongest/SLL_2015_2024_LT.png';
import SLL20162024EN from 'assets/img/strongest/SLL_2016_2024_EN.png';
import SLL20162024LT from 'assets/img/strongest/SLL_2016_2024_LT.png';
import SLL20172024EN from 'assets/img/strongest/SLL_2017_2024_EN.png';
import SLL20172024LT from 'assets/img/strongest/SLL_2017_2024_LT.png';
import SLL20182024EN from 'assets/img/strongest/SLL_2018_2024_EN.png';
import SLL20182024LT from 'assets/img/strongest/SLL_2018_2024_LT.png';
import SLL20192024EN from 'assets/img/strongest/SLL_2019_2024_EN.png';
import SLL20192024LT from 'assets/img/strongest/SLL_2019_2024_LT.png';
import SLL20202024EN from 'assets/img/strongest/SLL_2020_2024_EN.png';
import SLL20202024LT from 'assets/img/strongest/SLL_2020_2024_LT.png';
import SLL20212024EN from 'assets/img/strongest/SLL_2021_2024_EN.png';
import SLL20212024LT from 'assets/img/strongest/SLL_2021_2024_LT.png';
import SLL20222024EN from 'assets/img/strongest/SLL_2022_2024_EN.png';
import SLL20222024LT from 'assets/img/strongest/SLL_2022_2024_LT.png';
import SLL20232024EN from 'assets/img/strongest/SLL_2023_2024_EN.png';
import SLL20232024LT from 'assets/img/strongest/SLL_2023_2024_LT.png';
import SLL2022EN from 'assets/img/strongest/SLL_2022_EN.png';
import SLL2022LT from 'assets/img/strongest/SLL_2022_LT.png';
import SLL2023EN from 'assets/img/strongest/SLL_2023_EN.png';
import SLL2023LT from 'assets/img/strongest/SLL_2023_LT.png';
import SLL2024EN from 'assets/img/strongest/SLL_2024_EN.png';
import SLL2024LT from 'assets/img/strongest/SLL_2024_LT.png';
import S102022EN from 'assets/img/strongest/S10_2022_EN.png';
import S102022LT from 'assets/img/strongest/S10_2022_LT.png';
import S102023EN from 'assets/img/strongest/S10_2023_EN.png';
import S102023LT from 'assets/img/strongest/S10_2023_LT.png';
// import S102024EN from 'assets/img/strongest/S10_2024_EN.png';
// import S102024LT from 'assets/img/strongest/S10_2024_LT.png';
import strongestOfLithuaniaEn from 'assets/img/strongestOfLithuaniaEn.png';
import strongestOfLithuaniaLt from 'assets/img/strongestOfLithuaniaLt.png';
import strongestOfDecadeEn from 'assets/img/strongestOfDecadeEn.png';
import strongestOfDecadeLt from 'assets/img/strongestOfDecadeLt.png';
import strongestOfLeadersEn from 'assets/img/strongestOfLeadersEn.png';
import strongestOfLeadersLt from 'assets/img/strongestOfLeadersLt.png';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import useWindowSize from '../../hooks/dom/useWindowSize';

const { Title, Paragraph } = Typography;

const images = {
  StrongestLithuania: {
    SL_2011_2022: {
      'en-US': SL20112022EN,
      'lt-LT': SL20112022LT,
      key: 'strongestOfLithuania',
    },
    SL_2012_2022: {
      'en-US': SL20122022EN,
      'lt-LT': SL20122022LT,
      key: 'strongestOfLithuania',
    },
    SL_2013_2022: {
      'en-US': SL20132022EN,
      'lt-LT': SL20132022LT,
      key: 'strongestOfLithuania',
    },
    SL_2014_2022: {
      'en-US': SL20142022EN,
      'lt-LT': SL20142022LT,
      key: 'strongestOfLithuania',
    },
    SL_2015_2022: {
      'en-US': SL20152022EN,
      'lt-LT': SL20152022LT,
      key: 'strongestOfLithuania',
    },
    SL_2016_2022: {
      'en-US': SL20162022EN,
      'lt-LT': SL20162022LT,
      key: 'strongestOfLithuania',
    },
    SL_2017_2022: {
      'en-US': SL20172022EN,
      'lt-LT': SL20172022LT,
      key: 'strongestOfLithuania',
    },
    SL_2018_2022: {
      'en-US': SL20182022EN,
      'lt-LT': SL20182022LT,
      key: 'strongestOfLithuania',
    },
    SL_2019_2022: {
      'en-US': SL20192022EN,
      'lt-LT': SL20192022LT,
      key: 'strongestOfLithuania',
    },
    SL_2020_2022: {
      'en-US': SL20202022EN,
      'lt-LT': SL20202022LT,
      key: 'strongestOfLithuania',
    },
    SL_2021_2022: {
      'en-US': SL20212022EN,
      'lt-LT': SL20212022LT,
      key: 'strongestOfLithuania',
    },
    SL_2022: {
      'en-US': SL2022EN,
      'lt-LT': SL2022LT,
      key: 'strongestOfLithuania',
    },
    SL_2011_2023: {
      'en-US': SL20112023EN,
      'lt-LT': SL20112023LT,
      key: 'strongestOfLithuania',
    },
    SL_2012_2023: {
      'en-US': SL20122023EN,
      'lt-LT': SL20122023LT,
      key: 'strongestOfLithuania',
    },
    SL_2013_2023: {
      'en-US': SL20132023EN,
      'lt-LT': SL20132023LT,
      key: 'strongestOfLithuania',
    },
    SL_2014_2023: {
      'en-US': SL20142023EN,
      'lt-LT': SL20142023LT,
      key: 'strongestOfLithuania',
    },
    SL_2015_2023: {
      'en-US': SL20152023EN,
      'lt-LT': SL20152023LT,
      key: 'strongestOfLithuania',
    },
    SL_2016_2023: {
      'en-US': SL20162023EN,
      'lt-LT': SL20162023LT,
      key: 'strongestOfLithuania',
    },
    SL_2017_2023: {
      'en-US': SL20172023EN,
      'lt-LT': SL20172023LT,
      key: 'strongestOfLithuania',
    },
    SL_2018_2023: {
      'en-US': SL20182023EN,
      'lt-LT': SL20182023LT,
      key: 'strongestOfLithuania',
    },
    SL_2019_2023: {
      'en-US': SL20192023EN,
      'lt-LT': SL20192023LT,
      key: 'strongestOfLithuania',
    },
    SL_2020_2023: {
      'en-US': SL20202023EN,
      'lt-LT': SL20202023LT,
      key: 'strongestOfLithuania',
    },
    SL_2021_2023: {
      'en-US': SL20212023EN,
      'lt-LT': SL20212023LT,
      key: 'strongestOfLithuania',
    },
    SL_2022_2023: {
      'en-US': SL20222023EN,
      'lt-LT': SL20222023LT,
      key: 'strongestOfLithuania',
    },
    SL_2023: {
      'en-US': SL2023EN,
      'lt-LT': SL2023LT,
      key: 'strongestOfLithuania',
    },
    SL_2011_2024: {
      'en-US': SL20112024EN,
      'lt-LT': SL20112024LT,
      key: 'strongestOfLithuania',
    },
    SL_2012_2024: {
      'en-US': SL20122024EN,
      'lt-LT': SL20122024LT,
      key: 'strongestOfLithuania',
    },
    SL_2013_2024: {
      'en-US': SL20132024EN,
      'lt-LT': SL20132024LT,
      key: 'strongestOfLithuania',
    },
    SL_2014_2024: {
      'en-US': SL20142024EN,
      'lt-LT': SL20142024LT,
      key: 'strongestOfLithuania',
    },
    SL_2015_2024: {
      'en-US': SL20152024EN,
      'lt-LT': SL20152024LT,
      key: 'strongestOfLithuania',
    },
    SL_2016_2024: {
      'en-US': SL20162024EN,
      'lt-LT': SL20162024LT,
      key: 'strongestOfLithuania',
    },
    SL_2017_2024: {
      'en-US': SL20172024EN,
      'lt-LT': SL20172024LT,
      key: 'strongestOfLithuania',
    },
    SL_2018_2024: {
      'en-US': SL20182024EN,
      'lt-LT': SL20182024LT,
      key: 'strongestOfLithuania',
    },
    SL_2019_2024: {
      'en-US': SL20192024EN,
      'lt-LT': SL20192024LT,
      key: 'strongestOfLithuania',
    },
    SL_2020_2024: {
      'en-US': SL20202024EN,
      'lt-LT': SL20202024LT,
      key: 'strongestOfLithuania',
    },
    SL_2021_2024: {
      'en-US': SL20212024EN,
      'lt-LT': SL20212024LT,
      key: 'strongestOfLithuania',
    },
    SL_2022_2024: {
      'en-US': SL20222024EN,
      'lt-LT': SL20222024LT,
      key: 'strongestOfLithuania',
    },
    SL_2023_2024: {
      'en-US': SL20232024EN,
      'lt-LT': SL20232024LT,
      key: 'strongestOfLithuania',
    },
    SL_2024: {
      'en-US': SL2024EN,
      'lt-LT': SL2024LT,
      key: 'strongestOfLithuania',
    },
  },
  StrongestLithuaniaLeaders: {
    SLL_2015_2022: {
      'en-US': SLL20152022EN,
      'lt-LT': SLL20152022LT,
      key: 'strongestOfLeaders',
    },
    SLL_2016_2022: {
      'en-US': SLL20162022EN,
      'lt-LT': SLL20162022LT,
      key: 'strongestOfLeaders',
    },
    SLL_2017_2022: {
      'en-US': SLL20172022EN,
      'lt-LT': SLL20172022LT,
      key: 'strongestOfLeaders',
    },
    SLL_2018_2022: {
      'en-US': SLL20182022EN,
      'lt-LT': SLL20182022LT,
      key: 'strongestOfLeaders',
    },
    SLL_2019_2022: {
      'en-US': SLL20192022EN,
      'lt-LT': SLL20192022LT,
      key: 'strongestOfLeaders',
    },
    SLL_2020_2022: {
      'en-US': SLL20202022EN,
      'lt-LT': SLL20202022LT,
      key: 'strongestOfLeaders',
    },
    SLL_2021_2022: {
      'en-US': SLL20212022EN,
      'lt-LT': SLL20212022LT,
      key: 'strongestOfLeaders',
    },
    SLL_2022: {
      'en-US': SLL2022EN,
      'lt-LT': SLL2022LT,
      key: 'strongestOfLeaders',
    },
    SLL_2015_2023: {
      'en-US': SLL20152023EN,
      'lt-LT': SLL20152023LT,
      key: 'strongestOfLeaders',
    },
    SLL_2016_2023: {
      'en-US': SLL20162023EN,
      'lt-LT': SLL20162023LT,
      key: 'strongestOfLeaders',
    },
    SLL_2017_2023: {
      'en-US': SLL20172023EN,
      'lt-LT': SLL20172023LT,
      key: 'strongestOfLeaders',
    },
    SLL_2018_2023: {
      'en-US': SLL20182023EN,
      'lt-LT': SLL20182023LT,
      key: 'strongestOfLeaders',
    },
    SLL_2019_2023: {
      'en-US': SLL20192023EN,
      'lt-LT': SLL20192023LT,
      key: 'strongestOfLeaders',
    },
    SLL_2020_2023: {
      'en-US': SLL20202023EN,
      'lt-LT': SLL20202023LT,
      key: 'strongestOfLeaders',
    },
    SLL_2021_2023: {
      'en-US': SLL20212023EN,
      'lt-LT': SLL20212023LT,
      key: 'strongestOfLeaders',
    },
    SLL_2022_2023: {
      'en-US': SLL20222023EN,
      'lt-LT': SLL20222023LT,
      key: 'strongestOfLeaders',
    },
    SLL_2023: {
      'en-US': SLL2023EN,
      'lt-LT': SLL2023LT,
      key: 'strongestOfLeaders',
    },
    SLL_2015_2024: {
      'en-US': SLL20152024EN,
      'lt-LT': SLL20152024LT,
      key: 'strongestOfLeaders',
    },
    SLL_2016_2024: {
      'en-US': SLL20162024EN,
      'lt-LT': SLL20162024LT,
      key: 'strongestOfLeaders',
    },
    SLL_2017_2024: {
      'en-US': SLL20172024EN,
      'lt-LT': SLL20172024LT,
      key: 'strongestOfLeaders',
    },
    SLL_2018_2024: {
      'en-US': SLL20182024EN,
      'lt-LT': SLL20182024LT,
      key: 'strongestOfLeaders',
    },
    SLL_2019_2024: {
      'en-US': SLL20192024EN,
      'lt-LT': SLL20192024LT,
      key: 'strongestOfLeaders',
    },
    SLL_2020_2024: {
      'en-US': SLL20202024EN,
      'lt-LT': SLL20202024LT,
      key: 'strongestOfLeaders',
    },
    SLL_2021_2024: {
      'en-US': SLL20212024EN,
      'lt-LT': SLL20212024LT,
      key: 'strongestOfLeaders',
    },
    SLL_2022_2024: {
      'en-US': SLL20222024EN,
      'lt-LT': SLL20222024LT,
      key: 'strongestOfLeaders',
    },
    SLL_2023_2024: {
      'en-US': SLL20232024EN,
      'lt-LT': SLL20232024LT,
      key: 'strongestOfLeaders',
    },
    SLL_2024: {
      'en-US': SLL2024EN,
      'lt-LT': SLL2024LT,
      key: 'strongestOfLeaders',
    },
  },
  StrongestLithuaniaLeader: {
    SLL_2015_2022: {
      'en-US': SLL20152022EN,
      'lt-LT': SLL20152022LT,
      key: 'strongestOfLeaders',
    },
    SLL_2016_2022: {
      'en-US': SLL20162022EN,
      'lt-LT': SLL20162022LT,
      key: 'strongestOfLeaders',
    },
    SLL_2017_2022: {
      'en-US': SLL20172022EN,
      'lt-LT': SLL20172022LT,
      key: 'strongestOfLeaders',
    },
    SLL_2018_2022: {
      'en-US': SLL20182022EN,
      'lt-LT': SLL20182022LT,
      key: 'strongestOfLeaders',
    },
    SLL_2019_2022: {
      'en-US': SLL20192022EN,
      'lt-LT': SLL20192022LT,
      key: 'strongestOfLeaders',
    },
    SLL_2020_2022: {
      'en-US': SLL20202022EN,
      'lt-LT': SLL20202022LT,
      key: 'strongestOfLeaders',
    },
    SLL_2021_2022: {
      'en-US': SLL20212022EN,
      'lt-LT': SLL20212022LT,
      key: 'strongestOfLeaders',
    },
    SLL_2022: {
      'en-US': SLL2022EN,
      'lt-LT': SLL2022LT,
      key: 'strongestOfLeaders',
    },
    SLL_2015_2023: {
      'en-US': SLL20152023EN,
      'lt-LT': SLL20152023LT,
      key: 'strongestOfLeaders',
    },
    SLL_2016_2023: {
      'en-US': SLL20162023EN,
      'lt-LT': SLL20162023LT,
      key: 'strongestOfLeaders',
    },
    SLL_2017_2023: {
      'en-US': SLL20172023EN,
      'lt-LT': SLL20172023LT,
      key: 'strongestOfLeaders',
    },
    SLL_2018_2023: {
      'en-US': SLL20182023EN,
      'lt-LT': SLL20182023LT,
      key: 'strongestOfLeaders',
    },
    SLL_2019_2023: {
      'en-US': SLL20192023EN,
      'lt-LT': SLL20192023LT,
      key: 'strongestOfLeaders',
    },
    SLL_2020_2023: {
      'en-US': SLL20202023EN,
      'lt-LT': SLL20202023LT,
      key: 'strongestOfLeaders',
    },
    SLL_2021_2023: {
      'en-US': SLL20212023EN,
      'lt-LT': SLL20212023LT,
      key: 'strongestOfLeaders',
    },
    SLL_2022_2023: {
      'en-US': SLL20222023EN,
      'lt-LT': SLL20222023LT,
      key: 'strongestOfLeaders',
    },
    SLL_2023: {
      'en-US': SLL2023EN,
      'lt-LT': SLL2023LT,
      key: 'strongestOfLeaders',
    },
    SLL_2015_2024: {
      'en-US': SLL20152024EN,
      'lt-LT': SLL20152024LT,
      key: 'strongestOfLeaders',
    },
    SLL_2016_2024: {
      'en-US': SLL20162024EN,
      'lt-LT': SLL20162024LT,
      key: 'strongestOfLeaders',
    },
    SLL_2017_2024: {
      'en-US': SLL20172024EN,
      'lt-LT': SLL20172024LT,
      key: 'strongestOfLeaders',
    },
    SLL_2018_2024: {
      'en-US': SLL20182024EN,
      'lt-LT': SLL20182024LT,
      key: 'strongestOfLeaders',
    },
    SLL_2019_2024: {
      'en-US': SLL20192024EN,
      'lt-LT': SLL20192024LT,
      key: 'strongestOfLeaders',
    },
    SLL_2020_2024: {
      'en-US': SLL20202024EN,
      'lt-LT': SLL20202024LT,
      key: 'strongestOfLeaders',
    },
    SLL_2021_2024: {
      'en-US': SLL20212024EN,
      'lt-LT': SLL20212024LT,
      key: 'strongestOfLeaders',
    },
    SLL_2022_2024: {
      'en-US': SLL20222024EN,
      'lt-LT': SLL20222024LT,
      key: 'strongestOfLeaders',
    },
    SLL_2023_2024: {
      'en-US': SLL20232024EN,
      'lt-LT': SLL20232024LT,
      key: 'strongestOfLeaders',
    },
    SLL_2024: {
      'en-US': SLL2024EN,
      'lt-LT': SLL2024LT,
      key: 'strongestOfLeaders',
    },
  },
  StrongestLithuaniaDecade: {
    S10_2022: {
      'en-US': S102022EN,
      'lt-LT': S102022LT,
      key: 'strongestOfDecade',
    },
    S10_2023: {
      'en-US': S102023EN,
      'lt-LT': S102023LT,
      key: 'strongestOfDecade',
    },
    // S10_2024: {
    //   'en-US': S102024EN,
    //   'lt-LT': S102024LT,
    //   key: 'strongestOfDecade',
    // },
  },
};

const strongest = {
  StrongestLithuania: {
    'en-US': strongestOfLithuaniaEn,
    'lt-LT': strongestOfLithuaniaLt,
    key: 'strongestOfLithuania',
  },
  StrongestLithuaniaLeaders: {
    'en-US': strongestOfLeadersEn,
    'lt-LT': strongestOfLeadersLt,
    key: 'strongestOfLeaders',
  },
  StrongestLithuaniaLeader: {
    'en-US': strongestOfLeadersEn,
    'lt-LT': strongestOfLeadersLt,
    key: 'strongestOfLeaders',
  },
  StrongestLithuaniaDecade: {
    'en-US': strongestOfDecadeEn,
    'lt-LT': strongestOfDecadeLt,
    key: 'strongestOfDecade',
  },
};

const getCurrentStrongestPageByLang = lang => {
  switch (lang) {
    case 'en-US':
      return 'https://lt.creditinfo.com/en/strongest-in-lithuania/';
    case 'lt-LT':
      return 'https://lt.creditinfo.com/lt/stipriausi-lietuvoje/#sertifikuotos-imones';
    default:
      return '#';
  }
};

const StrongestCompanyCard = ({ type, year }) => {
  const lang = useCurrentLanguage();
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowSize();

  const image = images[type]?.[year]?.[lang];

  return (
    <Card className="card-simple card-simple-padding-none space-vertical-sm has-shadow" style={{ width: '100%' }}>
      <Row justify="start" align="top" gutter={[32, 16]} className="padding-sm">
        <Col
          xs={{ span: 15, order: 1, flex: 'flex-start' }}
          sm={{ span: 15, order: 1 }}
          md={{ span: 15, order: 1 }}
          lg={17}
          xl={19}
        >
          <Title level={3}>{t(`professionalReport.${strongest[type].key}.title.p1`)}</Title>
          {windowWidth > 575 && <Paragraph>{t(`professionalReport.${strongest[type].key}.paragraph.p1`)}</Paragraph>}
        </Col>
        <Col
          xs={{ order: 1, span: 9, flex: 'flex-end' }}
          sm={{ order: 1, span: 9, flex: 'flex-end' }}
          md={{ span: 9, order: 2 }}
          lg={7}
          xl={5}
        >
          {image && (
            <img
              src={image}
              alt="strongestOfLithuania"
              style={{ padding: 0, width: '100%', maxWidth: 190, display: 'block', marginTop: 0 }}
            />
          )}
        </Col>
        {windowWidth <= 575 && (
          <Col xs={{ order: 3, span: 24 }} sm={{ order: 3, span: 24 }} md={{ order: 3, span: 24 }}>
            <Paragraph>{t(`professionalReport.${strongest[type].key}.paragraph.p1`)}</Paragraph>
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default StrongestCompanyCard;
