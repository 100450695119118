import { createAsyncThunk } from '@reduxjs/toolkit';
import personService from 'api/personService';

export const getPerson = createAsyncThunk('person/getIndividualEntities', async (requestBody, { rejectWithValue }) => {
  try {
    const response = await personService.getPerson(requestBody);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getPersonDetails = createAsyncThunk(
  'person/getIndividualDetails',
  async (orderId, { rejectWithValue }) => {
    try {
      const response = await personService.getPersonDetails(orderId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getIndividualProfessionalReportPdf = createAsyncThunk(
  'person/getIndividualProfessionalReportPdf',
  async (orderId, { rejectWithValue }) => {
    try {
      const response = await personService.getIndividualProfessionalReportPdf(orderId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
