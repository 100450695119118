import { Col, Row, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReactComponent as Rating } from 'assets/img/icons/rating.svg';

import CollapsibleCard from 'components/reusable/CollapsibleCard';
import { basicInfo } from 'store/entities/prescoreReport/selectors/basicDataSelectors';
import CompanyDataCard from 'components/reusable/CompanyDataCard';
import { generalStatuses } from 'constants/prescore';
import useFormatDate, { DATE_FORMAT_YEAR_ONLY, DATE_FORMAT_MONTH_ONLY } from 'hooks/format/useFormatDate';
import { getEconomicStatus } from '../utils/prescoreHelpers';
import React, { useEffect, useMemo, useState } from 'react';
import PrescoreChartV2 from '../../CompanySearchResult/components/Report/PrescoreChartV2';
import useWindowSize from '../../../hooks/dom/useWindowSize';

const { Paragraph } = Typography;

const Prescore = ({ prescoreObject, pdfReport = false }) => {
  const { width: windowWidth } = useWindowSize();
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const { rating, averageSalaryDate } = useSelector(basicInfo);
  const currentPrescoreObj = rating ?? prescoreObject?.rating;

  const PRESC = useMemo(() => {
    if (!currentPrescoreObj) return null;

    if (currentPrescoreObj) {
      const {
        indicator,
        averageSalariesIndicator,
        creditHistoryIndicator,
        economicStatusIndicator,
        sodraDebtsIndicator,
      } = currentPrescoreObj;

      const economyStatus = getEconomicStatus(
        indicator === 3 ? -1 : economicStatusIndicator,
        'prescoreReport.prescore.economic.status.progress'
      );
      const creditHistory = getEconomicStatus(
        indicator === 3 ? -1 : creditHistoryIndicator,
        'prescoreReport.prescore.credit.history.progress'
      );
      const sodraDebts = getEconomicStatus(
        indicator === 3 ? -1 : sodraDebtsIndicator,
        'prescoreReport.prescore.sodra.debts.progress'
      );
      const averageSalaries = getEconomicStatus(
        indicator === 3 ? -1 : averageSalariesIndicator,
        'prescoreReport.prescore.average.salary.progress'
      );
      const generalStatus = generalStatuses.get(indicator);

      return { economyStatus, creditHistory, sodraDebts, averageSalaries, indicator, generalStatus };
    }

    return null;
  }, [prescoreObject, rating, averageSalaryDate]);

  if (!currentPrescoreObj) return null;

  const updatedData = [PRESC.economyStatus, PRESC.sodraDebts, PRESC.averageSalaries, PRESC.creditHistory].map(item => {
    if (item.amt > 0) {
      return {
        ...item,
        amt: 0,
        uv: 6000,
      };
    }
    return item;
  });

  return (
    <>
      <CompanyDataCard icon={<Rating className="icon--gray" />} title={t('companyResult.prescore.title')}>
        <Row>
          {windowWidth > 750 ? (
            <Col span={24} className={PRESC.generalStatus.className} style={{ fontSize: 25 }}>
              <PRESC.generalStatus.icon className={`prescore-title__icon ${PRESC.generalStatus.iconClassColor}`} />
              {t(PRESC.generalStatus.text)}
            </Col>
          ) : (
            <Col
              style={{
                height: 60,
                fontSize: 20,
              }}
              className="credit-risk-graph-container__wrapper"
            >
              <PRESC.generalStatus.icon className={`prescore-title__icon ${PRESC.generalStatus.iconClassColor}`} />
              {t(PRESC.generalStatus.text)}
            </Col>
          )}
        </Row>
        <PrescoreChartV2 data={updatedData} pdfReport={pdfReport} />
        <Trans
          i18nKey="prescoreReport.prescore.last.salary"
          values={{
            year: averageSalaryDate ? formatDate(averageSalaryDate, DATE_FORMAT_YEAR_ONLY) : '-',
            month: averageSalaryDate ? formatDate(averageSalaryDate, DATE_FORMAT_MONTH_ONLY) : '-',
          }}
        />
        <CollapsibleCard isCardCollapsed={true} title={t('companyResult.prescore.desc.title')}>
          {['creditRisk', 'economic', 'soDra', 'salaryAverage', 'creditHistory'].map(value => (
            <Paragraph>
              <Trans i18nKey={`companyResult.prescore.desc.${value}`} components={{ bold: <strong /> }} />
            </Paragraph>
          ))}
        </CollapsibleCard>
      </CompanyDataCard>
    </>
  );
};

export default Prescore;
