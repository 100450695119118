import { useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import VisibilitySensor from 'react-visibility-sensor';

// This component is used to navigate the tab menu based on the active block.
// id - the name of the block
// onChange - function that triggers active menu item based on in and scroll direction
const VisibilitySensorBlock = ({ id, onChange, children }) => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [scrollDirection, setScrollDirection] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      if (currentScrollPos === 0) {
        onChange('summary', scrollDirection);
        return false;
      }

      if (currentScrollPos > prevScrollPos) {
        setScrollDirection('down');
      } else if (currentScrollPos < prevScrollPos) {
        setScrollDirection('up');
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    onChange('summary', scrollDirection);
  }, []);

  return (
    <Row id={id}>
      <Col md={24} lg={24}>
        <VisibilitySensor
          offset={{ bottom: 50 }}
          scrollCheck
          intervalDelay={10}
          onChange={isVisible => {
            if (isVisible) {
              onChange(id, scrollDirection);
            }
          }}
        >
          {children}
        </VisibilitySensor>
      </Col>
    </Row>
  );
};

export default VisibilitySensorBlock;
