import { useSelector } from 'react-redux';

const useIsPrescoreReportLoading = () => {
  const { orderIdPending, basicDataPending, orderIdError, basicDataError } = useSelector(state => state.prescoreReport);

  return {
    isLoading: orderIdPending || basicDataPending,
    hasError: orderIdError || orderIdError || basicDataError,
  };
};

export default useIsPrescoreReportLoading;
