import { createAsyncThunk } from '@reduxjs/toolkit';
import feedbackService from 'api/feedbackService';
import otherProductsService from 'api/otherProductsService';

const getLegalEntities = createAsyncThunk('otherProducts/getLegalEntities', async (_, { rejectWithValue }) => {
  try {
    const response = await otherProductsService.getLegalEntities();
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

const addLegalEntity = createAsyncThunk('otherProducts/addLegalEntity', async (companyCode, { rejectWithValue }) => {
  try {
    const response = await otherProductsService.postLegalEntity(companyCode);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

const deleteLegalEntity = createAsyncThunk('otherProducts/deleteLegalEntity', async (watchId, { rejectWithValue }) => {
  try {
    const response = await otherProductsService.deleteLegalEntity(watchId);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

const updateLegalEntity = createAsyncThunk('otherProducts/updateLegalEntity', async (value, { rejectWithValue }) => {
  try {
    const response = await otherProductsService.updateLegalEntity(value);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

const deleteAllLegalEntities = createAsyncThunk(
  'otherProducts/deleteAllLegalEntities',
  async (_, { rejectWithValue }) => {
    try {
      const response = await otherProductsService.deleteAllLegalEntities();
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const getIndividualEntities = createAsyncThunk(
  'otherProducts/getIndividualEntities',
  async (_, { rejectWithValue }) => {
    try {
      const response = await otherProductsService.getIndividualEntities();
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const getUserSettings = createAsyncThunk('otherProducts/getUserSettings', async (_, { rejectWithValue }) => {
  try {
    const response = await otherProductsService.getUserSettings();
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

const addIndividualEntity = createAsyncThunk(
  'otherProducts/addIndividualEntity',
  async ({ personCode, personRequest }, { rejectWithValue }) => {
    try {
      const response = await otherProductsService.postIndividualEntity(personCode, personRequest);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const setUserSettings = createAsyncThunk('otherProducts/setUserSettings', async (settings, { rejectWithValue }) => {
  try {
    const response = await otherProductsService.postUserSettings(settings);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

const deleteIndividualEntity = createAsyncThunk(
  'otherProducts/deleteIndividualEntity',
  async (watchId, { rejectWithValue }) => {
    try {
      const response = await otherProductsService.deleteIndividualEntity(watchId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const updateIndividualEntity = createAsyncThunk(
  'otherProducts/updateIndividualEntity',
  async (value, { rejectWithValue }) => {
    try {
      const response = await otherProductsService.updateIndividualEntity(value);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const deleteAllIndividuals = createAsyncThunk('otherProducts/deleteAllIndividuals', async (_, { rejectWithValue }) => {
  try {
    const response = await otherProductsService.deleteAllIndividuals();
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

const uploadFile = createAsyncThunk('otherProducts/uploadFile', async (file, { rejectWithValue }) => {
  try {
    const response = await otherProductsService.uploadFile(file);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

const getCompanyMonitoringEvents = createAsyncThunk(
  'monitoring/events/company',
  async (params, { rejectWithValue }) => {
    try {
      return await otherProductsService.getCompanyMonitoringEvents(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const getPersonMonitoringEvents = createAsyncThunk('monitoring/events/person', async (params, { rejectWithValue }) => {
  try {
    return await otherProductsService.getPersonMonitoringEvents(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

const getCompanyMonitoringSummary = createAsyncThunk(
  'monitoring/administration/summary/company',
  async (params, { rejectWithValue }) => {
    try {
      return await otherProductsService.getCompanyMonitoringSummary();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const getPersonMonitoringSummary = createAsyncThunk(
  'monitoring/administration/summary/person',
  async (params, { rejectWithValue }) => {
    try {
      return await otherProductsService.getPersonMonitoringSummary();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getMonitoringSubjectsExcel = createAsyncThunk(
  '/monitoring/administration/getSubjectsExcel',
  async (value, { rejectWithValue }) => {
    try {
      return await otherProductsService.getMonitoringSubjectsExcel(value);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getMonitoringEventsExcel = createAsyncThunk(
  '/monitoring/events/getEventsExcel',
  async (value, { rejectWithValue }) => {
    try {
      return await otherProductsService.getMonitoringEventsExcel(value);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const postFeedback = createAsyncThunk('/feedbackService/postFeedback', async (params, { rejectWithValue }) => {
  try {
    const response = await feedbackService.postFeedback(params);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export {
  getLegalEntities,
  getIndividualEntities,
  getUserSettings,
  setUserSettings,
  addLegalEntity,
  deleteLegalEntity,
  updateLegalEntity,
  deleteAllLegalEntities,
  addIndividualEntity,
  deleteIndividualEntity,
  updateIndividualEntity,
  deleteAllIndividuals,
  uploadFile,
  getCompanyMonitoringEvents,
  getPersonMonitoringEvents,
  getCompanyMonitoringSummary,
  getPersonMonitoringSummary,
  postFeedback,
};
