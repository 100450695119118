import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LoadingSpinner from 'components/reusable/LoadingSpinner';
import Report from 'views/CompanySearchResult/components/Report';
import BuyReport from 'views/CompanySearchResult/components/BuyReport';
import {
  basicData,
  unregisteredReportData,
} from 'store/entities/companySearchResult/selectors/unregisteredReportSelector';
import { getCompanyData } from 'store/entities/companySearchResult/thunks';
import { setPage, resetCompanySearchResult, setCompany } from 'store/entities/companySearchResult';

import { Row, Col, Typography, Skeleton } from 'antd';
import UnauthenticatedWrapper from 'views/Search/UnauthenticatedSearch/UnauthenticatedWrapper';

const { Title } = Typography;

const CompanySearchResult = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { companyId } = useParams();
  const { page, getCompanyDataPending } = useSelector(unregisteredReportData);
  const { companyName } = useSelector(basicData);

  useEffect(() => {
    dispatch(setCompany(companyId));

    return () => {
      dispatch(resetCompanySearchResult());
    };
  }, []);

  useEffect(() => {
    if (page === 'report') {
      dispatch(getCompanyData({ companyCode: companyId, languageCode: i18n.language }));
    }
  }, [i18n.language]);

  const handlePageChange = type => dispatch(setPage(type));

  return (
    <LoadingSpinner size="large" spinning={getCompanyDataPending}>
      <UnauthenticatedWrapper>
        {!getCompanyDataPending ? (
          <Row className="space-vertical-lg" gutter={[16, 16]}>
            <Col span={24}>
              {page === 'report' ? (
                <>
                  <Row gutter={[16, 16]}>
                    <Col span={24} className="company-title">
                      <Title level={2}>{companyName}</Title>
                    </Col>
                  </Row>
                  <Report handleBuyReport={() => handlePageChange('order')} />
                </>
              ) : (
                <BuyReport />
              )}
            </Col>
          </Row>
        ) : (
          <Skeleton />
        )}
      </UnauthenticatedWrapper>
    </LoadingSpinner>
  );
};

export default CompanySearchResult;
