import React from 'react';
import { useTranslation } from 'react-i18next';
import _isEmpty from 'lodash.isempty';

import useFormatDate, { DATE_FORMAT_LONG } from 'hooks/format/useFormatDate';
import useNumberFormatter from 'hooks/format/useNumberFormatter';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import { CHART_MARGINS } from 'constants/chart';
import colors from 'constants/colors';

import CustomLegend from '../Legend';
import CustomTooltip from './CustomTooltip';
import ChartWrapper from '../ChartWrapper';
import LazyLoader from '../LazyLoader';
import PdfLabel from './PdfLabel';

import { ComposedChart, XAxis, YAxis, Tooltip, Legend, CartesianGrid, Line, Area, Bar, LabelList } from 'recharts';
import CustomLabelList from 'components/reusable/graphs/ComposedChart/CustomLabelList';

const CIComposedChart = chartProps => (
  <ChartWrapper {...chartProps}>{props => <Chart {...props}></Chart>}</ChartWrapper>
);

const Chart = ({
  id = 'chart',
  activeDefs = false,
  monotone = false,
  data = [],
  series = [],
  hiddenSeries = [], //extra data to show in tooltip only
  xAxisDataKey = 'date',
  xAxisFormat = DATE_FORMAT_LONG,
  yAxisLabel,
  drawYAxisTicks = true,
  showLegend = true,
  domain = [0, 'auto'],
  yAxisWidth,
  width,
  height,
  callbackRef,
  allowDecimalsY = true,
  tooltipLabelFormat = DATE_FORMAT_LONG,
  noCustomLabel = 'none', // none, line, bar, both
  CustomLabel,
  useValueFormatter,
  hideLabels = false,
  noInformationText = 'global.noInformation',
}) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const formatCurrency = useNumberFormatter();
  const formatNumber = useNumberFormatter({ style: 'decimal' });
  const isUiHidden = useIsUiHidden();

  const combinedDataList = ['casNumberOfEmployees', 'casAverageWages'];

  return (
    <div className="chart justify-content-center no-break" ref={callbackRef}>
      <LazyLoader defaultValue={isUiHidden}>
        {(waypoint, isVisible) => (
          <>
            {waypoint}
            {isVisible && _isEmpty(data) && (
              <div
                style={{
                  height: 50,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {t(noInformationText)}
              </div>
            )}
            {isVisible && data.length > 0 && (
              <ComposedChart
                width={width}
                height={isUiHidden ? height * 0.6 : height}
                data={data.map(d => ({ ...d, combinedLabelData: d }))}
                margin={CHART_MARGINS}
              >
                {activeDefs && (
                  <defs>
                    {series.map(({ dataKey, stroke }, i) => (
                      <linearGradient
                        key={`gradient-${dataKey}-${i}`}
                        id={`color${id}-${i}`}
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                      >
                        <stop offset="5%" stopColor={stroke} stopOpacity={0.8} />
                        <stop offset="95%" stopColor={stroke} stopOpacity={0} />
                      </linearGradient>
                    ))}
                  </defs>
                )}
                <YAxis
                  domain={domain}
                  width={yAxisWidth}
                  label={yAxisLabel ?? null}
                  tick={drawYAxisTicks}
                  allowDecimals={allowDecimalsY}
                  tickFormatter={formatNumber}
                />
                <CartesianGrid vertical={false} strokeDasharray="3 3" />
                <XAxis
                  dataKey={xAxisDataKey}
                  padding={{ left: 50, right: 30 }}
                  tickFormatter={tickValue => {
                    const isDate = new Date(tickValue).getDate();
                    return isDate ? formatDate(tickValue, xAxisFormat) : tickValue;
                  }}
                />
                <Tooltip
                  labelFormatter={value => formatDate(value, tooltipLabelFormat)}
                  formatter={(value, name) => [
                    useValueFormatter ? formatCurrency(value) : formatNumber(value),
                    t(`graph.${id}.dataKey.${name}`),
                  ]}
                  content={<CustomTooltip hiddenSeries={hiddenSeries} />}
                />
                {showLegend && <Legend layout="horizontal" graphId={id} content={<CustomLegend />} />}

                {series.map(
                  (
                    { type, dataKey, stroke, labelUnder = false, minusValuesLabelUnder = false, ...otherAttributes },
                    i
                  ) => {
                    if (type === 'line') {
                      return (
                        <Line
                          key={`${dataKey}-${type}-${i}`}
                          dataKey={dataKey}
                          type={monotone ? 'monotone' : ''}
                          activeDot={{ r: 8, fill: colors.COLOR_WHITE, stroke: stroke }}
                          dot={({ cx, cy, stroke, strokeWidth }) => (
                            <circle
                              key={cx}
                              cx={cx}
                              cy={cy}
                              r={5}
                              fill={colors.COLOR_WHITE}
                              stroke={stroke}
                              strokeWidth={strokeWidth}
                            />
                          )}
                          stroke={stroke}
                          strokeWidth="3"
                          isAnimationActive={false}
                          {...otherAttributes}
                        >
                          {!combinedDataList.includes(id) && isUiHidden && !hideLabels && !CustomLabel && (
                            <LabelList content={<PdfLabel />} />
                          )}
                          {CustomLabel && isUiHidden && !hideLabels && (
                            <LabelList
                              dataKey={combinedDataList.includes(id) ? 'combinedLabelData' : null}
                              content={CustomLabel}
                              offset={10}
                              position="top"
                              type="line"
                            />
                          )}
                        </Line>
                      );
                    } else if (type === 'area') {
                      return (
                        <Area
                          key={`${dataKey}-${type}-${i}`}
                          dataKey={dataKey}
                          type={monotone ? 'monotone' : ''}
                          stroke={stroke}
                          fill={activeDefs ? `url(#color${id}-${i})` : 'white'} // Use gradient or stroke color
                          isAnimationActive={false}
                          activeDot={{ r: 8, fill: colors.COLOR_WHITE, stroke: stroke }}
                          dot={({ cx, cy, stroke, strokeWidth }) => (
                            <circle
                              key={cx}
                              cx={cx}
                              cy={cy}
                              r={5}
                              fill={colors.COLOR_WHITE}
                              stroke={stroke}
                              strokeWidth={strokeWidth}
                            />
                          )}
                          strokeWidth={2}
                          {...otherAttributes}
                        >
                          {!combinedDataList.includes(id) && isUiHidden && !hideLabels && (
                            <LabelList
                              content={
                                <PdfLabel labelUnder={labelUnder} minusValuesLabelUnder={minusValuesLabelUnder} />
                              }
                            />
                          )}
                        </Area>
                      );
                    } else {
                      return (
                        <Bar
                          maxBarSize={30}
                          key={`${dataKey}-${type}-${i}`}
                          dataKey={dataKey}
                          isAnimationActive={false}
                          {...otherAttributes}
                        >
                          {isUiHidden && !hideLabels && (
                            <LabelList
                              dataKey={combinedDataList.includes(id) ? 'combinedLabelData' : null}
                              content={CustomLabel ? CustomLabel : <CustomLabelList />}
                              offset={10}
                              position="top"
                              type="bar"
                            />
                          )}
                        </Bar>
                      );
                    }
                  }
                )}
              </ComposedChart>
            )}
          </>
        )}
      </LazyLoader>
    </div>
  );
};

export default CIComposedChart;
