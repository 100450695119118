const colors = {
  COLOR_SECONDARY: '#C40F11',
  COLOR_WARNING: '#ff7900',
  COLOR_WARNING_LIGHT: '#FFF500',
  COLOR_AVERAGE: '#FFDE51',
  COLOR_SUCCESS: '#1DC800',
  COLOR_SUCCESS_DARK: '#0FA500',
  COLOR_ERROR: '#ef0000',
  COLOR_INFO: '#1996bf',
  COLOR_WHITE: '#ffffff',
  COLOR_GRAY: '#666666',
  COLOR_LIGHTER_GRAY: '#6d7179',
  COLOR_BLACK: '#333333',
  COLOR_MUTED: '#EDEDED',
};

export default colors;
