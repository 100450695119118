import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18n';
import {
  getBasicData,
  getOrderId,
  getSummary,
  updateContacts,
  getNegativeData,
  getFinancialData,
  getOptionalFinancialStatements,
  getChildOrderIdFinancialStatement,
  getFinancialStatements,
  getChildOrderId,
  getChildNumberOfQueries,
  getChildVehiclesValue,
  getChildAutomatedCreditLimit,
  getChildBankruptcyRating,
  getChildDefaultRating,
  getChildOpenDebts,
  getChildPaymentHistory,
  getChildRegisteredArrests,
  getChildUnregisteredArrests,
  getStartReportPdf,
} from 'store/entities/startReport/thunks.js';

const initialState = {
  orderId: null,
  orderIdPending: false,
  orderIdError: false,
  childOrderId: null,
  childOrderIdPending: false,
  childOrderIdError: false,
  childNumberOfQueriesPending: false,
  childNumberOfQueriesError: false,
  childVehiclesValueData: null,
  childVehiclesValuePending: false,
  childVehiclesValueError: false,
  childAutomatedCreditLimitData: null,
  childAutomatedCreditLimitPending: false,
  childAutomatedCreditLimitError: false,
  childBankruptcyRatingPending: false,
  childBankruptcyRatingError: false,
  childDefaultRatingPending: false,
  childDefaultRatingError: false,
  activeDebtsDetailsPending: false,
  activeDebtsDetailsError: false,
  paidDebtsDetailsPending: false,
  paidDebtsDetailsError: false,
  registeredArrestsDetailsPending: false,
  registeredArrestsDetailsError: false,
  unregisteredArrestsDetailsPending: false,
  unregisteredArrestsDetailsError: false,
  activePaymentHistory: null,
  activePaymentHistoryPending: false,
  activePaymentHistoryError: false,
  orderDetails: null,
  activeTab: 'summary',
  activeFullReportTab: 'summary',
  menuAffixed: false,
  summary: null,
  summaryPending: false,
  summaryError: false,
  basicData: null,
  basicDataPending: false,
  basicDataError: false,
  updateContactsPending: false,
  negativeData: null,
  negativeDataPending: false,
  negativeDataError: false,
  financialData: null,
  financialDataPending: false,
  financialDataError: false,
  selectableFinancialStatements: null,
  selectableFinancialStatementsPending: false,
  selectableFinancialStatementsError: false,
  financialStatementChildOrderId: null,
  financialStatementChildOrderIdPending: false,
  financialStatementChildOrderIdError: false,
  purchasedFinancialStatementsPending: false,
  purchasedFinancialStatementsError: false,
  isPdfReportDownloadLoading: false,
  productCode: null,
  pdfReport: false,
};

const slice = createSlice({
  name: 'startReport',
  initialState: initialState,
  reducers: {
    resetStartReport: state => initialState,
    setPdfReport: (state, { payload }) => {
      state.pdfReport = payload;
    },
    setActiveFullReportTab: (state, { payload }) => {
      state.activeFullReportTab = payload;
    },
    setMenuAffixed: (state, { payload }) => {
      state.menuAffixed = payload;
    },
    setProductCode: (state, { payload }) => {
      state.productCode = payload;
    },
  },
  extraReducers: {
    [getOrderId.fulfilled]: (state, { payload }) => {
      state.orderId = payload.id;
      state.orderIdPending = false;
      state.orderIdError = false;
    },
    [getOrderId.pending]: state => {
      state.orderIdPending = true;
      state.orderIdError = false;
    },
    [getOrderId.rejected]: state => {
      state.orderIdPending = false;
      state.orderIdError = true;
    },
    [getChildOrderId.fulfilled]: (state, { payload }) => {
      state.childOrderId = payload.id;
      state.childOrderIdPending = false;
      state.childOrderIdError = false;
    },
    [getChildOrderId.pending]: state => {
      state.childOrderId = null;
      state.childOrderIdPending = true;
      state.childOrderIdError = false;
    },
    [getChildOrderId.rejected]: state => {
      state.childOrderId = null;
      state.childOrderIdPending = false;
      state.childOrderIdError = true;
    },
    [getChildNumberOfQueries.fulfilled]: (state, { payload }) => {
      state.basicData.companyStatisticsBySectors = payload;
      state.childNumberOfQueriesPending = false;
      state.childNumberOfQueriesError = false;
    },
    [getChildNumberOfQueries.pending]: state => {
      state.childNumberOfQueriesPending = true;
      state.childNumberOfQueriesError = false;
    },
    [getChildNumberOfQueries.rejected]: state => {
      state.childNumberOfQueriesPending = false;
      state.childNumberOfQueriesError = true;
    },
    [getChildVehiclesValue.fulfilled]: (state, { payload }) => {
      state.basicData.vehiclesFleetValues = payload;
      state.childVehiclesValuePending = false;
      state.childVehiclesValueError = false;
    },
    [getChildVehiclesValue.pending]: state => {
      state.childVehiclesValuePending = true;
      state.childVehiclesValueError = false;
    },
    [getChildVehiclesValue.rejected]: state => {
      state.childVehiclesValuePending = false;
      state.childVehiclesValueError = true;
    },
    [getChildAutomatedCreditLimit.fulfilled]: (state, { payload }) => {
      state.childAutomatedCreditLimitData = payload;
      state.childAutomatedCreditLimitPending = false;
      state.childAutomatedCreditLimitError = false;
    },
    [getChildAutomatedCreditLimit.pending]: state => {
      state.childAutomatedCreditLimitPending = true;
      state.childAutomatedCreditLimitError = false;
    },
    [getChildAutomatedCreditLimit.rejected]: state => {
      state.childAutomatedCreditLimitPending = false;
      state.childAutomatedCreditLimitError = true;
    },
    [getChildBankruptcyRating.fulfilled]: (state, { payload }) => {
      state.summary.bankruptcyRatingSummary = payload;
      state.childBankruptcyRatingPending = false;
      state.childBankruptcyRatingError = false;
    },
    [getChildBankruptcyRating.pending]: state => {
      state.childBankruptcyRatingPending = true;
      state.childBankruptcyRatingError = false;
    },
    [getChildBankruptcyRating.rejected]: state => {
      state.childBankruptcyRatingPending = false;
      state.childBankruptcyRatingError = true;
    },
    [getChildDefaultRating.fulfilled]: (state, { payload }) => {
      state.summary.defaultRatingSummary = payload;
      state.childDefaultRatingPending = false;
      state.childDefaultRatingError = false;
    },
    [getChildDefaultRating.pending]: state => {
      state.childDefaultRatingPending = true;
      state.childDefaultRatingError = false;
    },
    [getChildDefaultRating.rejected]: state => {
      state.childDefaultRatingPending = false;
      state.childDefaultRatingError = true;
    },
    [getChildOpenDebts.fulfilled]: (state, { payload }) => {
      state.negativeData.activeDebtsDetails = payload;
      state.activeDebtsDetailsPending = false;
      state.activeDebtsDetailsError = false;
    },
    [getChildOpenDebts.pending]: state => {
      state.activeDebtsDetailsPending = true;
      state.activeDebtsDetailsError = false;
    },
    [getChildOpenDebts.rejected]: state => {
      state.activeDebtsDetailsPending = false;
      state.activeDebtsDetailsError = true;
    },
    [getChildPaymentHistory.fulfilled]: (state, { payload }) => {
      state.negativeData.paidDebtsDetails = payload;
      state.paidDebtsDetailsPending = false;
      state.paidDebtsDetailsError = false;
    },
    [getChildPaymentHistory.pending]: state => {
      state.paidDebtsDetailsPending = true;
      state.paidDebtsDetailsError = false;
    },
    [getChildPaymentHistory.rejected]: state => {
      state.paidDebtsDetailsPending = false;
      state.paidDebtsDetailsError = true;
    },
    [getChildRegisteredArrests.fulfilled]: (state, { payload }) => {
      state.negativeData.registeredArrestDetails = payload;
      state.registeredArrestsDetailsPending = false;
      state.registeredArrestsDetailsError = false;
    },
    [getChildRegisteredArrests.pending]: state => {
      state.registeredArrestsDetailsPending = true;
      state.registeredArrestsDetailsError = false;
    },
    [getChildRegisteredArrests.rejected]: state => {
      state.registeredArrestsDetailsPending = false;
      state.registeredArrestsDetailsError = true;
    },
    [getChildUnregisteredArrests.fulfilled]: (state, { payload }) => {
      state.negativeData.unregisteredArrestDetails = payload;
      state.unregisteredArrestsDetailsPending = false;
      state.unregisteredArrestsDetailsError = false;
    },
    [getChildUnregisteredArrests.pending]: state => {
      state.unregisteredArrestsDetailsPending = true;
      state.unregisteredArrestsDetailsError = false;
    },
    [getChildUnregisteredArrests.rejected]: state => {
      state.unregisteredArrestsDetailsPending = false;
      state.unregisteredArrestsDetailsError = true;
    },
    [getSummary.fulfilled]: (state, { payload }) => {
      state.orderDetails = payload?.orderDetails;
      state.summary = { ...payload?.summary, lang: i18n.language };
      state.summaryPending = false;
      state.summaryError = false;
    },
    [getSummary.pending]: state => {
      state.summaryPending = true;
      state.summaryError = false;
    },
    [getSummary.rejected]: state => {
      state.summaryPending = false;
      state.summaryError = true;
    },
    [getBasicData.fulfilled]: (state, { payload }) => {
      state.basicData = { ...payload, lang: i18n.language };
      state.basicDataPending = false;
      state.basicDataError = false;
      // this has differnt approach because of null and undefined value meaning difference
      if (payload?.creditLimitCountedAmount) {
        state.childAutomatedCreditLimitData = payload.creditLimitCountedAmount;
      }
    },
    [getBasicData.pending]: state => {
      state.basicDataPending = true;
      state.basicDataError = false;
    },
    [getBasicData.rejected]: state => {
      state.basicDataPending = false;
      state.basicDataError = true;
    },
    [updateContacts.fulfilled]: state => {
      state.updateContactsPending = false;
    },
    [updateContacts.pending]: state => {
      state.updateContactsPending = true;
    },
    [updateContacts.rejected]: state => {
      state.updateContactsPending = false;
    },
    [getNegativeData.fulfilled]: (state, { payload }) => {
      state.negativeData = { ...payload, lang: i18n.language };
      state.negativeDataPending = false;
      state.negativeDataError = false;
    },
    [getNegativeData.pending]: state => {
      state.negativeDataPending = true;
      state.negativeDataError = false;
    },
    [getNegativeData.rejected]: state => {
      state.negativeDataPending = false;
      state.negativeDataError = true;
    },
    [getFinancialData.fulfilled]: (state, { payload }) => {
      state.financialData = payload;
      state.financialDataPending = false;
      state.financialDataError = false;
    },
    [getFinancialData.pending]: state => {
      state.financialDataPending = true;
      state.financialDataError = false;
    },
    [getFinancialData.rejected]: state => {
      state.financialDataPending = false;
      state.financialDataError = true;
    },
    [getOptionalFinancialStatements.fulfilled]: (state, { payload }) => {
      state.selectableFinancialStatements = payload;
      state.selectableFinancialStatementsPending = false;
      state.selectableFinancialStatementsError = false;
    },
    [getOptionalFinancialStatements.pending]: state => {
      state.selectableFinancialStatementsPending = true;
      state.selectableFinancialStatementsError = false;
    },
    [getOptionalFinancialStatements.rejected]: state => {
      state.selectableFinancialStatementsPending = false;
      state.selectableFinancialStatementsError = true;
    },
    [getChildOrderIdFinancialStatement.pending]: state => {
      state.financialStatementChildOrderIdPending = true;
      state.financialStatementChildOrderIdError = false;
    },
    [getChildOrderIdFinancialStatement.fulfilled]: (state, { payload }) => {
      state.financialStatementChildOrderId = payload?.id;
      state.financialStatementChildOrderIdPending = false;
      state.financialStatementChildOrderIdError = false;
    },
    [getChildOrderIdFinancialStatement.rejected]: state => {
      state.financialStatementChildOrderIdPending = false;
      state.financialStatementChildOrderIdError = true;
    },
    [getFinancialStatements.pending]: state => {
      state.purchasedFinancialStatementsPending = true;
      state.purchasedFinancialStatementsError = false;
    },
    [getFinancialStatements.fulfilled]: (state, { payload }) => {
      state.financialData.activePurchasedFinancialStatements = [
        ...(state.financialData?.activePurchasedFinancialStatements || []), // Use an empty array if undefined
        ...payload,
      ];
      state.purchasedFinancialStatementsPending = false;
      state.purchasedFinancialStatementsError = false;
    },
    [getFinancialStatements.rejected]: state => {
      state.purchasedFinancialStatementsPending = false;
      state.purchasedFinancialStatementsError = true;
    },
    [getStartReportPdf.fulfilled]: (state, { payload }) => {
      state.isPdfReportDownloadLoading = false;
    },
    [getStartReportPdf.pending]: state => {
      state.isPdfReportDownloadLoading = true;
    },
    [getStartReportPdf.rejected]: state => {
      state.isPdfReportDownloadLoading = false;
    },
  },
});

export const { resetStartReport, setActiveFullReportTab, setMenuAffixed, setProductCode, setPdfReport } = slice.actions;

export default slice.reducer;
