import React from 'react';

const AverageWagesCombinedLabel = props => {
  const { x, y, width, value, position, type } = props;
  const showUpper =
    value.employeesNumber + 1 === value.employeesNumberInSectors ||
    (value.employeesNumber > value.employeesNumberInSectors * 0.85 &&
      value.employeesNumber < value.employeesNumberInSectors);

  const WagesLabel = (
    <g>
      <text
        x={x + width / 2 || x}
        y={y - (type === 'line' ? 14 : 10)}
        fill="#000"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        <tspan fill="#C40F11">{value.companyAverageWage}</tspan>/<tspan fill="#0C9CB2">{value.sectorAverageWage}</tspan>
      </text>
    </g>
  );

  if (type === 'line' && showUpper) {
    return WagesLabel;
  } else if (type === 'bar' && !showUpper) {
    return WagesLabel;
  }

  return null;
};

export default AverageWagesCombinedLabel;
