import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

import useFormatDate from 'hooks/format/useFormatDate';
import useCurrentLanguage from 'hooks/user/useCurrentLanguage';
import useNumberFormatter from 'hooks/format/useNumberFormatter';
import PersonalScoreCard from 'views/IndividualReport/OpenDebts/PersonalScoreCard';
import ReportsCount from 'views/IndividualReport/components/ReportsCount';
import PersonsRegister from 'views/IndividualReport/components/PersonsRegister';
import IdentityProtection from 'views/IndividualReport/Protection';
import CollapsibleTable from 'components/reusable/CollapsibleTable';
import CompanyDataCard, { CompanyDataRow } from 'components/reusable/CompanyDataCard';
import TableColumnDate from 'components/reusable/TableColumnDate';
import { ReactComponent as History } from 'assets/img/icons/history.svg';
import { summaryInfo } from 'store/entities/professionalReport/selectors/summaryDataSelectors';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import CollapsibleCard from '../../../components/reusable/CollapsibleCard';

const OpenDebts = ({
  bankruptcies,
  protectYourIdentity,
  activeDebts,
  activeDebtsDetails,
  creditRestriction,
  isProfReport = false,
}) => {
  const { t } = useTranslation();
  const { rating } = useSelector(summaryInfo);
  const isUiHidden = useIsUiHidden();
  const lang = useCurrentLanguage();
  const formatDate = useFormatDate();
  const formatNumber = useNumberFormatter({ style: 'decimal' });
  const isActiveDebtDetailsNotPresent = !activeDebtsDetails || activeDebtsDetails.length === 0;
  const formatCurrency = useNumberFormatter();
  const claimsColumns = useMemo(() => {
    return [
      {
        title: t('openDebts.claims.detailedInformation.claimDate'),
        dataIndex: 'date',
        key: 'date',
        render: TableColumnDate,
        sorter: (a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB - dateA;
        },
        sortDirections: ['ascend', 'descend'],
        width: isUiHidden ? 80 : 120,
      },
      {
        title: t('openDebts.claims.detailedInformation.amount'),
        dataIndex: 'amount',
        key: 'amount',
        sorter: (a, b) => a.amount.localeCompare(b.amount),
        render: price => {
          return formatNumber(price);
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: t('openDebts.claims.detailedInformation.creditor'),
        dataIndex: ['creditorName', 'creditorSource'],
        key: 'creditorName',
        render: (claimsData, { creditorName, creditorSource }) => {
          if (creditorName === creditorSource) {
            return creditorName;
          } else {
            return `${creditorName} / ${creditorSource}`;
          }
        },
        sorter: (a, b) => a.creditorName.localeCompare(b.creditorName),
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: t('openDebts.claims.detailedInformation.claimType'),
        dataIndex: 'claimType',
        key: 'claimType',
        sorter: (a, b) => a.claimType.localeCompare(b.claimType),
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: t('openDebts.claims.detailedInformation.claimId'),
        dataIndex: 'caseNumber',
        key: 'caseNumber',
        sorter: (a, b) => a.caseNumber.localeCompare(b.caseNumber),
        sortDirections: ['ascend', 'descend'],
      },
    ];
  }, [t, TableColumnDate]);

  const claimsData = useMemo(() => {
    if (isActiveDebtDetailsNotPresent) {
      return [];
    }

    return activeDebtsDetails.map(debt => {
      const { date, amount, creditorName, creditorSource, claimType, caseNumber, expandableInfo } = debt;

      return {
        date,
        // TODO: info likely received from list of individual entities
        // expandableInfo: expandableInfo
        //   ? expandableInfo
        //   : 'Reg. no:: John Johnson| Legal address: Vilnius, VIRSULISKIU 12-15',
        amount,
        creditorName,
        creditorSource,
        claimType,
        caseNumber,
      };
    });
  }, [isActiveDebtDetailsNotPresent, activeDebtsDetails]);

  if (
    (!bankruptcies || bankruptcies.length) === 0 &&
    !protectYourIdentity &&
    !activeDebts &&
    isActiveDebtDetailsNotPresent
  ) {
    return <></>;
  }

  const [bankruptcy] = bankruptcies;
  const {
    name,
    surname,
    status,
    statusEn,
    court,
    bankruptcyAdmin,
    noticeCreditorsDate,
    statementBankruptcyDate,
    rulingComesIntoForceDate,
    planConfirmationDate,
    creditorsClaimDate = null,
    terminationBankruptcyDate = null,
    finishDecisionDate,
    finishEnactionDate,
    caseRefusalDate = null,
  } = bankruptcy || {};

  return (
    <>
      {protectYourIdentity?.isProtected && (
        <IdentityProtection isIdentityProtected={protectYourIdentity?.isProtected} />
      )}
      {rating && <PersonalScoreCard />}
      <h3 className="section-title individual-section-title">{t('openDebts.negativeData.title')}</h3>
      {bankruptcy ? (
        <>
          <CompanyDataCard
            icon={<WarningOutlined className="icon--gray" style={{ fontSize: 20 }} />}
            title={t('openDebts.bankruptcy.bankruptcyInfo')}
          >
            <CompanyDataRow type={t('openDebts.bankruptcy.nameSurname')} value={`${name} ${surname}`} />
            <CompanyDataRow type={t('openDebts.bankruptcy.status')} value={lang === 'lt-LT' ? status : statusEn} />
            <CompanyDataRow type={t('openDebts.bankruptcy.court')} value={court} />
            <CollapsibleCard
              className="space-top-md"
              title={t('global.detailedInformation')}
              isCardCollapsed={isUiHidden}
            >
              <Row gutter={16} className="bottom-bordered-row">
                <Col xs={24} md={12} lg={12} xl={12}>
                  {t('openDebts.bankruptcy.noticeToCreditors')}
                </Col>
                <Col xs={24} md={12} lg={12} xl={12}>
                  {noticeCreditorsDate ? formatDate(new Date(noticeCreditorsDate)) : '-'}
                </Col>
              </Row>
              <Row gutter={16} className="bottom-bordered-row">
                <Col xs={24} md={12} lg={12} xl={12}>
                  {t('openDebts.bankruptcy.stateForBankruptcy')}
                </Col>
                <Col xs={24} md={12} lg={12} xl={12}>
                  {statementBankruptcyDate ? formatDate(new Date(statementBankruptcyDate)) : '-'}
                </Col>
              </Row>
              <Row gutter={16} className="bottom-bordered-row">
                <Col xs={24} md={12} lg={12} xl={12}>
                  {t('openDebts.bankruptcy.rulingComesIntoForce')}
                </Col>
                <Col xs={24} md={12} lg={12} xl={12}>
                  {rulingComesIntoForceDate ? formatDate(new Date(rulingComesIntoForceDate)) : '-'}
                </Col>
              </Row>
              <Row gutter={16} className="bottom-bordered-row">
                <Col xs={24} md={12} lg={12} xl={12}>
                  {t('openDebts.bankruptcy.planConfirmation')}
                </Col>
                <Col xs={24} md={12} lg={12} xl={12}>
                  {planConfirmationDate ? formatDate(new Date(planConfirmationDate)) : '-'}
                </Col>
              </Row>
              <Row gutter={16} className="bottom-bordered-row">
                <Col xs={24} md={12} lg={12} xl={12}>
                  {t('openDebts.bankruptcy.bankruptcyAdministrator')}
                </Col>
                <Col xs={24} md={12} lg={12} xl={12}>
                  {bankruptcyAdmin}
                </Col>
              </Row>
              <Row gutter={16} className="bottom-bordered-row">
                <Col xs={24} md={12} lg={12} xl={12}>
                  {t('openDebts.bankruptcy.creditorsClaimsDate')}
                </Col>
                <Col xs={24} md={12} lg={12} xl={12}>
                  {creditorsClaimDate ? formatDate(new Date(creditorsClaimDate)) : '-'}
                </Col>
              </Row>
              <Row gutter={16} className="bottom-bordered-row">
                <Col xs={24} md={12} lg={12} xl={12}>
                  {t('openDebts.bankruptcy.terminationDateOfBankruptcyProcess')}
                </Col>
                <Col xs={24} md={12} lg={12} xl={12}>
                  {terminationBankruptcyDate ? formatDate(new Date(terminationBankruptcyDate)) : '-'}
                </Col>
              </Row>
              <Row gutter={16} className="bottom-bordered-row">
                <Col xs={24} md={12} lg={12} xl={12}>
                  {t('openDebts.bankruptcy.decisionToFinishBankruptcy')}
                </Col>
                <Col xs={24} md={12} lg={12} xl={12}>
                  {finishDecisionDate ? formatDate(new Date(finishDecisionDate)) : '-'}
                </Col>
              </Row>
              <Row gutter={16} className="bottom-bordered-row">
                <Col xs={24} md={12} lg={12} xl={12}>
                  {t('openDebts.bankruptcy.enactionToFinishBankruptcy')}
                </Col>
                <Col xs={24} md={12} lg={12} xl={12}>
                  {finishEnactionDate ? formatDate(new Date(finishEnactionDate)) : '-'}
                </Col>
              </Row>

              <Row gutter={16} className="bottom-bordered-row">
                <Col xs={24} md={12} lg={12} xl={12}>
                  {t('openDebts.bankruptcy.intiationRefusalDate')}
                </Col>
                <Col xs={24} md={12} lg={12} xl={12}>
                  {caseRefusalDate ? formatDate(new Date(caseRefusalDate)) : '-'}
                </Col>
              </Row>
            </CollapsibleCard>
          </CompanyDataCard>
        </>
      ) : (
        <>
          <CompanyDataCard
            icon={<WarningOutlined className="icon--gray" style={{ fontSize: 20 }} />}
            title={t('openDebts.bankruptcy.bankruptcyInfo')}
          >
            <Card className="card-transparent space-vertical-sm">{t('openDebts.bankruptcy.noEntires')}</Card>
          </CompanyDataCard>
        </>
      )}
      <PersonsRegister creditRestriction={creditRestriction} />
      <IdentityProtection isIdentityProtected={protectYourIdentity?.isProtected} shortened />
      {isActiveDebtDetailsNotPresent ? (
        <>
          <CompanyDataCard icon={<History className="icon--gray" />} title={t('openDebts.claims.title')}>
            <Card className="card-transparent space-vertical-sm">{t('openDebts.negativeData.claims.noEntries')}</Card>
          </CompanyDataCard>
        </>
      ) : (
        <>
          <CompanyDataCard icon={<History className="icon--gray" />} title={t('openDebts.claims.title')}>
            <CompanyDataRow type={t('openDebts.claims.numberOfDebts')} value={activeDebts.total} />
            <CompanyDataRow
              type={t('openDebts.claims.latestDebtDate')}
              value={formatDate(new Date(activeDebts.latestDate))}
            />
            <CompanyDataRow
              type={t('openDebts.claims.averagePaymentTerm')}
              value={<span>{`${activeDebts.averageDays} ${t('global.days')}`}</span>}
            />
            <CompanyDataRow
              type={t('openDebts.claims.amount')}
              value={activeDebts?.amount ? formatCurrency(activeDebts.amount) : '-'}
            />
            <Row>
              <CollapsibleTable
                className="space-top-md"
                title={t('openDebts.claims.detailedInformation')}
                columns={claimsColumns}
                data={claimsData}
                scroll={false}
              />
            </Row>
          </CompanyDataCard>
        </>
      )}
      {!isProfReport && <ReportsCount />}
    </>
  );
};

export default OpenDebts;
