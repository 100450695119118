import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { bankruptcyInfoSelector } from 'store/entities/professionalReport/selectors/basicDataSelectors';

import CompanyDataCard, { CompanyDataRow } from 'components/reusable/CompanyDataCard';

import { Col, Divider } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import useFormatDate from '../../../../hooks/format/useFormatDate';
import { memo, useMemo } from 'react';

const BankruptcyInfo = ({ bankruptcyInformation }) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();

  const data = useMemo(
    () =>
      bankruptcyInformation?.map(record => [
        {
          value: record?.courtDate ? formatDate(record.courtDate) : '-',
          title: t('professionalReport.basicData.bankruptcyInfo.courtDate'),
        },
        {
          value: record?.decisionDate ? formatDate(record.decisionDate) : '-',
          title: t('professionalReport.basicData.bankruptcyInfo.decisionDate'),
        },
        {
          value: record?.simplifiedBankruptcyDecisionDate ? formatDate(record.simplifiedBankruptcyDecisionDate) : '-',
          title: t('professionalReport.basicData.bankruptcyInfo.simplifiedBankruptcyDecisionDate'),
        },
        {
          value: record?.dismissDate ? formatDate(record.dismissDate) : '-',
          title: t('professionalReport.basicData.bankruptcyInfo.dismissDate'),
        },
        {
          value: record?.decisionDeregistrationDate ? formatDate(record.decisionDeregistrationDate) : '-',
          title: t('professionalReport.basicData.bankruptcyInfo.decisionDeregistrationDate'),
        },
        {
          value: record?.deregistrationDate ? formatDate(record.deregistrationDate) : '-',
          title: t('professionalReport.basicData.bankruptcyInfo.deregistrationDate'),
        },
        {
          value: record?.court ? record.court : '-',
          title: t('professionalReport.basicData.bankruptcyInfo.bankruptcyCourt'),
        },
        {
          value: record?.administrator ? record.administrator : '-',
          title: t('professionalReport.basicData.bankruptcyInfo.bankruptcyAdministrator'),
        },
        {
          value: record?.address ? record.address : '-',
          title: t('common.address'),
        },
        {
          value: record?.phoneNumber ? record.phoneNumber : '-',
          title: t('common.phone'),
        },
        {
          value: record?.creditorsClaimDate ? formatDate(record.creditorsClaimDate) : '-',
          title: t('professionalReport.basicData.bankruptcyInfo.dateOfTheCreditorClaim'),
        },
        {
          value: record?.creditorsClaimPlace ? record.creditorsClaimPlace : '-',
          title: t('professionalReport.basicData.bankruptcyInfo.placeOfTheCreditorClaim'),
        },
        {
          value: record?.intentionalBankruptcy ? record.intentionalBankruptcy : '-',
          title: t('professionalReport.basicData.bankruptcyInfo.theDateOfEstablishmentOfFaud'),
        },
      ]) ?? [],
    [t, bankruptcyInformation]
  );

  const renderContent = !bankruptcyInformation?.length ? (
    t('global.noInformation')
  ) : (
    <Col>
      {data.map((array, index) => (
        <div key={index}>
          {array.map(item => (
            <CompanyDataRow key={item.title} type={item.title} value={item.value} />
          ))}
          {index < data.length - 1 && <Divider />} {/* Add Divider between records */}
        </div>
      ))}
    </Col>
  );

  return !bankruptcyInformation?.length ? null : (
    <div id="shares">
      <CompanyDataCard
        icon={<InfoCircleOutlined className="icon--gray" />}
        title={t('professionalReport.basicData.bankruptcyInfo.title')}
      >
        {renderContent}
      </CompanyDataCard>
    </div>
  );
};

export default BankruptcyInfo;
