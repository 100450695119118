import React from 'react';
import { Space, Switch } from 'antd';
import { useTranslation } from 'react-i18next';

const AddToMonitoringSwitch = ({ addedToMonitoring, setMonitoringModalOpen }) => {
  const { t } = useTranslation();

  return (
    <Space size="large">
      <span>{t('companySearch.results.basic.companyReport.addToMonitoring')} </span>
      <Switch
        checkedChildren={t('global.yes')}
        unCheckedChildren={t('global.no')}
        checked={addedToMonitoring}
        onChange={() => setMonitoringModalOpen(true)}
        disabled={addedToMonitoring}
      />
    </Space>
  );
};

export default AddToMonitoringSwitch;
