import { fetchFromApi, optionsForGetRequest, optionsForPostRequest } from './';

const getCompanies = filters => fetchFromApi('/company/search', optionsForPostRequest(filters));

const getCompaniesUnregistered = filters => fetchFromApi('/data-availability/search', optionsForPostRequest(filters));

const getCompanyBasicData = companyCode => fetchFromApi(`/company/${companyCode}/basic`, optionsForPostRequest());

const getCompanyFinStatDataUnregistered = companyCode => {
  return fetchFromApi(`/data-availability/financial-statements?companyCode=${companyCode}`, optionsForGetRequest());
};

const getCompanyOtherInformation = companyCode =>
  fetchFromApi(`/company/${companyCode}/other-information`, optionsForGetRequest());

export default {
  getCompanies,
  getCompanyBasicData,
  getCompaniesUnregistered,
  getCompanyOtherInformation,
  getCompanyFinStatDataUnregistered,
};
