/**
 * Transforms the input data into a group item object based on a provided configuration.
 * The configuration defines how to map input data fields to the group item structure.
 * Fields can be directly mapped, chosen from a list of alternatives, or computed dynamically.
 */
import { generateId } from './helpers';
import { RELATION_TYPE_MAP } from './constants';

export const transformDataToGroupItem = (data, config, historical = false) => {
  if (!data) return [];

  let groupItem = {
    id: `group-${generateId()}`,
    className: `node__group-item ${historical && 'node__group-item__historical'}`,
    expanded: false,
    isHistorical: !!data.toDate,
    hoverableIcons: [],
    toDate: data.toDate,
    fromDate: data.fromDate,
    country: data.company?.country || data.person?.country,
    isPerson: !!data.company && !!data.person ? false : !!data.person,
  };

  Object.entries(config).forEach(([key, value]) => {
    if (typeof value === 'function') {
      groupItem[key] = value(data);
    } else if (Array.isArray(value)) {
      for (let option of value) {
        if (data[option] !== undefined) {
          groupItem[key] = data[option];
          break;
        }
      }
    } else {
      groupItem[key] = data[value];
    }
  });

  filterGroupIcons(data, groupItem);

  return groupItem;
};

export const filterGroupIcons = (data, groupItem) => {
  if (data.negativeInfo) {
    groupItem.hoverableIcons = data.negativeInfo;
  }

  if (data?.company?.negativeInfo) {
    groupItem.hoverableIcons = { ...groupItem.hoverableIcons, ...data.company.negativeInfo };
  }

  if (!data.person?.country && !data.company) {
    if (!data?.country && !data.company?.country && data.person?.country !== 'LT') {
      groupItem.hoverableIcons = { ...groupItem.hoverableIcons, foreigner: true };
    }
  }

  if (data.person?.unknownCode ?? data?.company?.unknownCode) {
    groupItem.hoverableIcons = { ...groupItem.hoverableIcons, unknownCode: true };
  }

  if (data?.company?.isNewCompany) {
    groupItem.hoverableIcons = { ...groupItem.hoverableIcons, isNewCompany: true };
  }

  if (data?.company?.hasShareholderList) {
    groupItem.hoverableIcons = { ...groupItem.hoverableIcons, hasShareholderList: true };
  }

  if (data?.company?.status?.id && data?.company?.status?.id !== 1) {
    const statusId = data.company.status.id;
    const statusKey = `status${statusId}`;
    groupItem.hoverableIcons = {
      ...groupItem.hoverableIcons,
      [statusKey]: true,
    };
  }

  if (groupItem?.country) {
    if (groupItem?.country === 'NOTSPECIFIED' || groupItem?.country === 'LT') {
      return false;
    }
    const countryCode = groupItem?.country;
    const countryKey = `country_${countryCode}`;

    groupItem.hoverableIcons = {
      ...groupItem.hoverableIcons,
      [countryKey]: true,
    };
  }
};

export const propertiesRelationsMap = data => {
  let result = {};
  Object.keys(data).forEach(key => {
    if (RELATION_TYPE_MAP[key]) {
      result[key] = {
        ...RELATION_TYPE_MAP[key],
        data: data[key],
      };
    }
  });

  return result;
};

export const splitShareholders = data => {
  if (!data) return;

  const spiderShareholdersIn = [];
  const spiderShareholdersOut = [];
  const founderRelationsIn = [];
  const founderRelationsOut = [];

  data.forEach(item => {
    if (item.shareholderDirection === 'In') {
      if (item.type !== 'Founder') {
        spiderShareholdersIn.push(item);
      } else {
        founderRelationsIn.push(item);
      }
    } else if (item.shareholderDirection === 'Out') {
      if (item.type !== 'Founder') {
        spiderShareholdersOut.push(item);
      } else {
        founderRelationsOut.push(item);
      }
    }
  });

  return {
    spiderShareholdersIn,
    spiderShareholdersOut,
    founderRelationsIn,
    founderRelationsOut,
  };
};

export const constructTreeData = mappedResponse => {
  let rootItem = null;
  let children = [];

  const rootGroupMapConfig = {
    apiID: ['identityNumber', 'ciId'],
    groupTitle: ['fullName'],
  };

  // Define the desired order of the relation types
  const relationOrder = [
    'spiderShareholdersIn',
    'spiderShareholdersOut',
    'executiveRelations',
    'founderRelationsIn',
    'founderRelationsOut',
    'boardMemberRelations',
    // Any remaining types will go here
  ];

  const sortedKeys = Object.keys(mappedResponse).sort((a, b) => {
    const aIndex = relationOrder.indexOf(a);
    const bIndex = relationOrder.indexOf(b);
    if (aIndex === -1 && bIndex === -1) return 0; // Both not found, keep original order
    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;
    return aIndex - bIndex;
  });

  sortedKeys.forEach(key => {
    const item = mappedResponse[key];
    if (item.relationType === 'rootItem') {
      if (!rootItem) {
        rootItem = {
          id: `node-${generateId()}`,
          icon: item.icon,
          type: item.relationType,
          lineClassName: item.lineClassName,
          showAllGroups: false,
          arrowDirection: null,
          children: [],
          group: [
            {
              ...transformDataToGroupItem(item.data, rootGroupMapConfig),
              expanded: true,
            },
          ],
          ...(item.person ? { person: item.person } : {}),
        };
      }
    } else {
      const childrenGroupConfig = {
        apiID: data => (data?.company ? data.company?.identityNumber || '' : data.person?.identityNumber || ''),
        groupTitle: data =>
          data?.company
            ? data.posedzio_Data ||
              data.address ||
              data.web ||
              data.email ||
              data.phone ||
              data.position ||
              data.company?.fullName ||
              ''
            : data.position || data.fullName || data.person?.fullName || '',
        groupSubtitle: data =>
          data?.company
            ? (data.share ? `${data.share}%` : '') || data.company?.fullName || data.company?.country || ''
            : (data.share ? `${data.share}%` : '') ||
              data.fullName ||
              data.person?.fullName ||
              data.address ||
              data.web ||
              data.email ||
              data.phone ||
              '',
      };

      const activeGroupElements = item.data.filter(item => !item.toDate);

      const group = activeGroupElements.slice(0, 5).map(item => transformDataToGroupItem(item, childrenGroupConfig));

      const moreGroup = activeGroupElements.slice(5).map(item => transformDataToGroupItem(item, childrenGroupConfig));

      const historicalGroup = item.data
        .filter(item => !!item.toDate)
        .map(item => transformDataToGroupItem(item, childrenGroupConfig, true));

      //Skipping nodes that are empty
      if (!group?.length && !historicalGroup?.length) return null;

      let arrowDirection;
      if (item?.data[0]?.shareholderDirection === 'Out') {
        arrowDirection = 'end';
      } else if (item?.data[0]?.shareholderDirection === 'In') {
        arrowDirection = 'start';
      } else if (item.relationType === 'boardMemberRelations' || item.relationType === 'executiveRelations') {
        arrowDirection = 'start';
      }

      const childItem = {
        arrowDirection,
        id: `node-${generateId()}`,
        icon: item.icon,
        type: item.relationType,
        lineClassName: item.lineClassName,
        historicalExpanded: false,
        moreExpanded: false,
        showAllGroups: false,
        children: [],
        group: [...group],
        moreGroup: [...moreGroup],
        historicalGroup: [...historicalGroup],
      };
      children.push(childItem);
    }
  });

  if (rootItem) {
    rootItem.children = children;
    return rootItem;
  } else {
    return {};
  }
};

export const initTreeData = data => {
  let dataClone = { ...data };

  const leftRightShareholders = splitShareholders(data?.spiderShareholders);

  // Inject shareholders
  if (leftRightShareholders) {
    dataClone = { ...leftRightShareholders, ...dataClone };
  }

  const mappedData = propertiesRelationsMap(dataClone);
  return constructTreeData(mappedData);
};
