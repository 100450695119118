import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setChargeModal } from 'store/entities/foreignCompany';

import { Button, Row, Col, Typography, Space } from 'antd';
import Modal from 'components/reusable/Modal';
import { ReactComponent as Close } from 'assets/img/icons/close.svg';

const { Title } = Typography;

const AdditionalChargeModal = ({ onContinue }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { chargeModal } = useSelector(state => state.foreignCompany);

  return (
    <Modal isOpen={!!chargeModal} setIsOpen={isOpen => dispatch(setChargeModal(isOpen))} closable={false} size="sm">
      <div className="modal-svg"></div>
      <Row>
        <Col span={24} className="justify-content-end">
          <span className="icon-btn space-top-md" role="button" onClick={() => dispatch(setChargeModal(false))}>
            <Close />
          </span>
        </Col>
      </Row>
      <Row className="space-bottom-lg">
        <Col span={3}></Col>
        <Col span={18}>
          <Title level={3} className="color-secondary">
            {t('foreignCompanySearch.additionalChargeModal.title')}
          </Title>
          <p className="fs-18">
            <strong>{t('foreignCompanySearch.additionalChargeModal.text')}</strong>
          </p>
          <p className="fs-18">
            <strong>{t('foreignCompanySearch.additionalChargeModal.continue')}</strong>
          </p>
        </Col>
        <Col span={3}></Col>
      </Row>

      <Row className="space-bottom-lg">
        <Col span={24} className="justify-content-end">
          <Space size="large" align="end">
            <Button type="default" onClick={() => dispatch(setChargeModal(false))}>
              {t('global.cancel')}
            </Button>
            <Button className="btn-secondary" onClick={() => onContinue(chargeModal.countryCode)}>
              {t('global.continue')}
            </Button>
          </Space>
        </Col>
      </Row>
    </Modal>
  );
};

export default AdditionalChargeModal;
