import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Form, Input, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import useQueryParams from 'hooks/navigation/useQueryParams';
import { getCompaniesUnregistered } from 'store/entities/company/thunks';

import LoadingSpinner from 'components/reusable/LoadingSpinner';

const CompanySearchForm = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const [form] = Form.useForm();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { getCompaniesPending } = useSelector(state => state.company);
  const companySearch = queryParams.get('search');

  const [loading, setLoading] = useState(true);

  const handleRecaptchaVerification = async ({ name }) => {
    try {
      const token = await executeRecaptcha('search');
      dispatch(getCompaniesUnregistered({ name, token }));
    } catch (error) {
      console.error('Recaptcha error:', error);
      throw new Error(error);
    }
  };

  const retryRecaptcha = (searchPayload, retries = 5) => {
    const interval = setInterval(async () => {
      if (executeRecaptcha) {
        clearInterval(interval);
        await handleRecaptchaVerification(searchPayload);
        setLoading(false);
      } else if (retries <= 0) {
        clearInterval(interval);
        setLoading(false);
      } else {
        retries -= 1;
      }
    }, 500);
  };

  useEffect(() => {
    if (companySearch) {
      const searchPayload = { name: companySearch };

      form.setFieldsValue(searchPayload);
      retryRecaptcha(searchPayload);
    } else if (executeRecaptcha) {
      setLoading(false);
    }
  }, [executeRecaptcha, companySearch, form]);

  const handleSubmit = values => {
    handleRecaptchaVerification(values);
  };

  return (
    <Form form={form} hideRequiredMark={true} layout="vertical" name="companySearchFilters" onFinish={handleSubmit}>
      <LoadingSpinner size="large" spinning={loading || getCompaniesPending}>
        <Col span={24} className="search-banner">
          <a href="#" target="_blank" rel="noopener noreferrer">
            <div className={`login-image ${i18n.language === 'en-US' ? 'login-image--en' : 'login-image--lt'}`}>
              <div className="top-text">{t('banner.top.text')}</div>
              <div className="bottom-text">{t('banner.bottom.text')}</div>
            </div>
          </a>
        </Col>
        <Form.Item name="name">
          <div
            style={{
              background: '#0a7a001a',
              padding: 20,
              borderRadius: 15,
              border: '1px solid #e2e8f0',
              textAlign: 'center',
            }}
          >
            <span>
              <Trans i18nKey={`login.option.freeInformationTitle`} components={{ bold: <strong /> }} />
            </span>
            <Input
              className="login-block-input"
              placeholder={t('login.search.placeholder')}
              size="large"
              suffix={<SearchOutlined onClick={() => form.submit()} />}
            />
          </div>
        </Form.Item>
      </LoadingSpinner>
    </Form>
  );
};

export default CompanySearchForm;
