import { createSelector } from 'reselect';
import _pick from 'lodash.pick';

export const unregisteredReportData = createSelector(
  state => state.companySearchResult,
  data => _pick(data, ['companyId', 'page', 'report', 'getCompanyDataPending'])
);

export const basicData = createSelector(
  state => state.companySearchResult.report,
  data => ({
    ..._pick(data?.summaryInformation, ['companyCode', 'companyName', 'status']),
    ..._pick(data?.contacts, ['address']),
  })
);

export const breadcrumbData = createSelector(
  state => state.companySearchResult,
  data => ({
    ..._pick(data, ['page']),
    ..._pick(data?.report?.summaryInformation, ['companyName', 'companyCode']),
  })
);

export const contactsData = createSelector(
  state => state.companySearchResult.report,
  data => _pick(data, ['contacts'])
);

export const prescoreData = createSelector(
  state => state.companySearchResult.report,
  data => _pick(data, ['prescore'])
);

export const summaryInformation = createSelector(
  state => state.companySearchResult.report,
  data => _pick(data, ['summaryInformation'])
);
