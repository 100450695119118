import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useFormatDate from 'hooks/format/useFormatDate';
import { Button, Card, Col, Row } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as CarIcon } from 'assets/img/icons/car.svg';
import ReportBlockHeader from 'components/reusable/report/ReportBlockHeader';
import VehicleFleetTable from 'components/reusable/report/tables/VehicleFleetTable';
import React, { useMemo } from 'react';
import RestrictedAccess from '../../../../components/RestrictedAccess';
import LoadingSpinner from '../../../../components/reusable/LoadingSpinner';
import { setProductCode } from 'store/entities/startReport';

const VehicleFleet = ({ reportBasicDataSelector, showVehiclesValueSectionByDefault }) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const { vehiclesFleetOwnersAndManagers, vehiclesFleetManagers, vehiclesFleetOwners } =
    useSelector(reportBasicDataSelector);
  const dispatch = useDispatch();
  const { childOrderIdPending, childVehiclesValuePending, pdfReport } = useSelector(state => state.startReport);

  const createColumns = columns =>
    columns.map(column => {
      const isDateColumn = column?.dataIndex.includes('date');
      return {
        ...column,
        title: isDateColumn ? formatDate(column?.title) : t(column?.title),
      };
    });

  const vehiclesFleetOwnersAndManagersColumns = useMemo(() =>
    createColumns(vehiclesFleetOwnersAndManagers?.fleetTableColumns)
  );
  const vehiclesFleetManagersColumns = useMemo(() => createColumns(vehiclesFleetManagers?.fleetTableColumns));
  const vehiclesFleetOwnersColumns = useMemo(() => createColumns(vehiclesFleetOwners?.fleetTableColumns));

  return (
    <LoadingSpinner spinning={childOrderIdPending || childVehiclesValuePending}>
      <ReportBlockHeader
        icon={<Icon component={CarIcon} className="icon--gray" />}
        title={t('professionalReport.basicData.vehicles.valueOfVehicles.title')}
      />
      {showVehiclesValueSectionByDefault ? (
        <>
          <VehicleFleetTable
            dataKey="ownersAndManagers"
            table={vehiclesFleetOwnersAndManagers?.fleetTable}
            columns={vehiclesFleetOwnersAndManagersColumns}
          />
          <VehicleFleetTable
            dataKey="managers"
            table={vehiclesFleetManagers?.fleetTable}
            columns={vehiclesFleetManagersColumns}
          />
          <VehicleFleetTable
            dataKey="owners"
            table={vehiclesFleetOwners?.fleetTable}
            columns={vehiclesFleetOwnersColumns}
          />
        </>
      ) : pdfReport ? (
        <Row>
          <Card className="card-transparent space-vertical-sm">{t('startReport.basic.vehicles.pdf.notBought')}</Card>
        </Row>
      ) : (
        <RestrictedAccess permission="/product/valueofvehicles/">
          <Col xs={24} sm={22} md={20} lg={7}>
            <Button className="vehicles-button" onClick={() => dispatch(setProductCode('ValueOfVehicles'))}>
              {t('startReport.basic.vehicles.buy.button')}
            </Button>
          </Col>
        </RestrictedAccess>
      )}
    </LoadingSpinner>
  );
};

export default VehicleFleet;
