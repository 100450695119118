import { useTranslation } from 'react-i18next';

import useIsUiHidden from 'hooks/dom/useIsUiHidden';

import { Table } from 'antd';
import useNumberFormatter from '../../../../hooks/format/useNumberFormatter';

const RevenueTable = ({ tableData, incomes, type = 'revenue' }) => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();
  const formatNumber = useNumberFormatter({ minimumFractionDigits: 2, maximumFractionDigits: 2 });

  const tableColumns = [
    ...incomes
      .map(({ financialYear }) => {
        const parsedYear = new Date(financialYear).getFullYear();

        return {
          title: parsedYear,
          dataIndex: parsedYear,
          key: parsedYear,
        };
      })
      .filter(item => (!isUiHidden ? parseInt(item.dataIndex) >= new Date().getFullYear() - 8 : true)),
  ];

  tableData.forEach(row => {
    for (const key in row) {
      if (key !== type) {
        const numericValue = parseFloat(row[key].replace(/\s/g, '').replace(',', '.'));

        if (!isNaN(numericValue)) {
          row[key] = formatNumber(numericValue);
        } else {
          row[key] = row[key];
        }
      }
    }
  });

  return (
    <Table
      rowKey={record => (type === 'revenue' ? record.revenue : record.income)}
      bordered
      dataSource={tableData}
      columns={tableColumns}
      scroll={isUiHidden ? false : { x: 'max-content' }}
      pagination={false}
    />
  );
};

export default RevenueTable;
