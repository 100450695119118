import React from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumb } from 'antd';

const DefaultBreadcrumb = ({ location }) => {
  const { t } = useTranslation();
  const breadcrumbsArr = location.pathname.split('/').slice(1);

  return location.pathname
    .split('/')
    .slice(1)
    .map((urlPart, i) => (
      <Breadcrumb.Item key={`breadcrumbItem-${i}`} href={`/${breadcrumbsArr.slice(0, i + 1).join('/')}`}>
        {t(`header.breadcrumb.${urlPart}`)}
      </Breadcrumb.Item>
    ));
};

export default DefaultBreadcrumb;
