import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as d3 from 'd3';
import { ArrowMarker, ArrowStartMarker, HierarchyNode, HierarchyGroup } from './graph-components';
import {
  adjustNodePositions,
  calculateDepthHeights,
  checkLinkLength,
  findAllGroupsInNodes,
  findAllNodes,
  calculateIndividualNodesHeights,
  getHeightByNodeId,
  calculateSingleNodeHeight,
} from '../utils/helpers';
import { initTreeData } from '../utils/data-helpers';

const graphConfig = () => {
  const margin = { left: 0, right: 0, top: 20, bottom: 20 };
  const width = window.innerWidth - margin.left - margin.right;
  const height = window.innerHeight;
  const initialScale = 1;

  return {
    basicHeight: 34,
    groupHeight: 86,
    margin,
    width,
    height,
    initialScale,
    duration: 750,
    separation: 220,
    zoomScaleExtent: [0.1, 1.5],
  };
};

const HierarchyTree = ({ data }) => {
  const d3Container = useRef(null);
  const treemapRef = useRef(null);
  const hierarchyRootRef = useRef(null);
  const animationSourceRef = useRef(null);
  const svgRef = useRef(null);
  const zoomRef = useRef(null);
  const [graphData, setGraphData] = useState({});
  const [, setKeepRenderingCount] = useState(0);
  const { basicHeight, groupHeight } = graphConfig();
  const depthHeightsRef = useRef(calculateDepthHeights(graphData, basicHeight, groupHeight));
  const individualHeightsRef = useRef(calculateIndividualNodesHeights(graphData, basicHeight, groupHeight));

  const forceUpdateReactPortalNodes = () => setKeepRenderingCount(prevCount => prevCount + 1);

  const createLink = (childNode, parentNode) => {
    const { basicHeight } = graphConfig();
    const startX = parentNode.x + 0.1 * (childNode.x - parentNode.x);
    const parentHeight = getHeightByNodeId(individualHeightsRef.current, parentNode?.data?.id, basicHeight);

    const path = `M ${startX} ${parentNode.y + parentHeight}
                  C ${startX} ${parentNode.y + parentHeight + 100},
                    ${childNode.x} ${parentNode.y + parentHeight + 50},
                    ${childNode.x} ${childNode.y - 15}`;
    return path;
  };

  const prepareDataAndLayout = root => {
    const { basicHeight, groupHeight } = graphConfig();
    const treemap = treemapRef.current;
    const adjustedData = treemap(root);
    const nodes = adjustedData.descendants();
    const links = adjustedData.descendants().slice(1);

    // Adapting node positions
    nodes.forEach(node =>
      adjustNodePositions(node, checkLinkLength(node, basicHeight, groupHeight, depthHeightsRef.current))
    );

    return { nodes, links };
  };

  const updateNodes = (svg, nodes, source, duration) => {
    const { basicHeight, groupHeight } = graphConfig();
    let node = svg.selectAll('g.node').data(nodes, d => d.data.id);

    // Enter nodes
    let nodeEnter = node
      .enter()
      .append('g')
      .attr('class', d => `node ${d.data.id}`)
      .attr('transform', () => `translate(${source.x0},${source.y0})`)
      .on('mouseenter', (event, d) => (animationSourceRef.current = { ...d, x0: d.x, y0: d.y }))
      .on('click', (event, d) => (animationSourceRef.current = { ...d, x0: d.x, y0: d.y }));

    nodeEnter
      .append('foreignObject')
      .attr('width', 190)
      // Calculating the height for individual Nodes
      .attr('height', d => {
        return calculateSingleNodeHeight(d.data, basicHeight, groupHeight);
      })
      .attr('x', -95)
      .attr('y', 0)
      .attr('class', 'node')
      .attr('id', d => d.data.id)
      .selectAll('div.item')
      .data(function (d) {
        return d.data.group || [];
      })
      .enter()
      .append('xhtml:div')
      .attr('id', d => d.id)
      .attr('class', d => d.className);

    // Update + Merge nodes
    nodeEnter
      .style('opacity', 0)
      .merge(node)
      .transition()
      .duration(duration)
      .attr('transform', d => `translate(${d.x},${d.y})`)
      .style('opacity', 1);

    node
      .exit()
      .transition()
      .duration(duration)
      .attr('transform', () => `translate(${source.x},${source.y})`)
      .style('opacity', 0)
      .remove();
  };

  const updateLinks = (svg, links, source, duration) => {
    let link = svg.selectAll('path.link').data(links, d => d.data.id);

    // Enter links
    let linkEnter = link
      .enter()
      .insert('path', 'g')
      .attr(
        'class',
        d =>
          `link ${
            d.data.arrowDirection === 'start' ? 'arrow-start' : d.data.arrowDirection === 'end' ? 'arrow-end' : ''
          } ${d?.parent?.data?.lineClassName ?? ''}`
      )
      .attr('d', () => createLink({ x: source.x0, y: source.y0 }, { x: source.x0, y: source.y0 }));

    // Update + Merge links
    linkEnter
      .style('opacity', 0)
      .merge(link)
      .transition()
      .duration(duration)
      .attr('d', d => createLink(d, d.parent))
      .style('opacity', 1);

    // Exit links
    link
      .exit()
      .transition()
      .duration(duration)
      .attr('d', () => createLink({ x: source.x, y: source.y }, { x: source.x, y: source.y }))
      .style('opacity', 0)
      .remove();
  };

  const focusOnLastHierarchyNode = d3Nodes => {
    const nodeWidthChildren = d3Nodes.filter(item => item.children?.length);
    const lastNode = nodeWidthChildren.slice(-1)[0];

    if (lastNode) {
      console.log(lastNode);
      zoomRef.current.translateTo(svgRef.current, lastNode.x, lastNode.y + 300);
    }
  };

  const updateVisuals = () => {
    svgRef.current = d3.select(d3Container.current).select('g');
    const { duration } = graphConfig();
    const { nodes, links } = prepareDataAndLayout(hierarchyRootRef.current);

    updateNodes(svgRef.current, nodes, animationSourceRef.current, duration);
    updateLinks(svgRef.current, links, animationSourceRef.current, duration);
    forceUpdateReactPortalNodes();

    //TODO: This is nice to have and contains issues. Also does not currently have smooth transition.
    // Agree with PM should be more time spent here or not.

    // focusOnLastHierarchyNode(nodes);
  };

  const initZoom = () => {
    const { zoomScaleExtent, initialScale } = graphConfig();
    const svgElement = d3.select(d3Container.current);

    const zoom = d3
      .zoom()
      .scaleExtent(zoomScaleExtent)
      .on('zoom', e => svgElement.select('g').attr('transform', e.transform));

    zoomRef.current = zoom;

    zoom.scaleTo(svgElement, initialScale);
    svgElement.call(zoom);
  };

  const setupSvg = ({ width, height, margin }) => {
    if (!d3Container.current) return;

    d3.select(d3Container.current).selectAll('g').remove();

    d3.select(d3Container.current)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .attr('viewBox', [-width / 2, 1 / 2, width, height])
      .append('g');
  };

  const initGraph = () => {
    if (!d3Container.current) return;

    const { margin, width, height, separation } = graphConfig();
    setupSvg({ width, height, margin });
    initZoom();

    treemapRef.current = d3
      .tree()
      .size([width, height])
      .nodeSize([0.9, 150])
      .separation((a, b) => separation);
    hierarchyRootRef.current = d3.hierarchy(graphData, d => d.children);
    hierarchyRootRef.current.x0 = 0;
    hierarchyRootRef.current.y0 = 0;
    animationSourceRef.current = hierarchyRootRef.current;
    updateVisuals();
  };

  useEffect(() => {
    setGraphData(initTreeData(data));
    initGraph();
  }, []);

  // Draw elements when graphData changes
  useEffect(() => {
    if (graphData && d3Container.current) {
      hierarchyRootRef.current = d3.hierarchy(graphData, d => d.children);
      depthHeightsRef.current = calculateDepthHeights(graphData, basicHeight, groupHeight);
      individualHeightsRef.current = calculateIndividualNodesHeights(graphData, basicHeight, groupHeight);

      updateVisuals();
    }
  }, [graphData]);

  const groups = useMemo(() => findAllGroupsInNodes(graphData), [graphData]);
  const nodes = useMemo(() => findAllNodes(graphData), [graphData]);

  return (
    <div className="hierarchy-tree">
      <svg ref={d3Container} />
      <ArrowMarker />
      <ArrowStartMarker />
      {groups.map(item => (
        <HierarchyGroup item={item} key={item.id} setGraphData={setGraphData} />
      ))}
      {nodes.map(item => (
        <HierarchyNode
          item={item}
          key={item.id}
          setGraphData={setGraphData}
          svgRef={svgRef}
          d3Container={d3Container}
        />
      ))}
    </div>
  );
};

export default HierarchyTree;
