import { CheckCircleFilled, CloseCircleFilled, MinusCircleFilled, WarningFilled } from '@ant-design/icons';
import React from 'react';

const MAX_AMT = 6000;
const generalStatus = Object.freeze({
  lowCreditRisk: 0,
  averageCreditRisk: 1,
  highCreditRisk: 2,
  noInformationProvided: 3,
  subjectIsInactive: 4,
});
const economicIndicator = Object.freeze({
  none: -1,
  low1: 0,
  low2: 1,
  average1: 2,
  average2: 3,
  high: 4,
});
const economicStatus = Object.freeze({
  warning: 'warning',
  error: 'error',
  ok: 'ok',
  none: 'none',
});

const generalStatuses = new Map([
  [
    generalStatus.lowCreditRisk,
    {
      icon: CheckCircleFilled,
      iconClassColor: 'prescore-title--low',
      className: 'prescore-title',
      text: 'companyResult.prescore.status.low',
    },
  ],
  [
    generalStatus.averageCreditRisk,
    {
      icon: MinusCircleFilled,
      iconClassColor: 'prescore-title--average',
      className: 'prescore-title',
      text: 'companyResult.prescore.status.average',
    },
  ],
  [
    generalStatus.highCreditRisk,
    {
      icon: CloseCircleFilled,
      iconClassColor: 'prescore-title--high',
      className: 'prescore-title',
      text: 'companyResult.prescore.status.high',
    },
  ],
  [
    generalStatus.noInformationProvided,
    {
      icon: MinusCircleFilled,
      iconClassColor: '',
      className: 'prescore-title',
      text: 'companyResult.prescore.status.none',
    },
  ],
  [
    generalStatus.subjectIsInactive,
    {
      icon: CloseCircleFilled,
      iconClassColor: 'prescore-title--inactive',
      className: 'prescore-title',
      text: 'companyResult.prescore.status.inactive',
    },
  ],
]);

const lowEconomicStatus = {
  icon: <CheckCircleFilled className="prescore-axis-text prescore-axis-text--low" />,
  status: economicStatus.ok,
};
const averageEconomicStatus = {
  icon: <WarningFilled className="prescore-axis-text prescore-axis-text--average" />,
  status: economicStatus.warning,
};
const highEconomicStatus = {
  icon: <CloseCircleFilled className="prescore-axis-text prescore-axis-text--high" />,
  status: economicStatus.error,
};
const noneEconomicStatus = {
  icon: <WarningFilled className="prescore-axis-text" />,
  status: economicStatus.none,
};

export {
  MAX_AMT,
  generalStatus,
  economicIndicator,
  economicStatus,
  generalStatuses,
  lowEconomicStatus,
  averageEconomicStatus,
  highEconomicStatus,
  noneEconomicStatus,
};
