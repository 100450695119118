import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from 'antd';
import { getBasicData } from 'store/entities/prescoreReport/thunks';
import useCurrentLanguage from 'hooks/user/useCurrentLanguage';

import Prescore from 'views/PrescoreReport/Data/Prescore';
import Basic from 'views/PrescoreReport/Data/Basic';
import Interests from 'views/PrescoreReport/Data/Interests';

const Data = () => {
  const lang = useCurrentLanguage();
  const dispatch = useDispatch();
  const { orderId, basicData, basicDataPending } = useSelector(state => state.prescoreReport);

  useEffect(() => {
    if ((orderId && basicData && lang !== basicData.lang) || !basicData) dispatch(getBasicData(orderId));
  }, []);

  return (
    <>
      {basicDataPending ? (
        <Skeleton />
      ) : (
        <>
          {basicData && (
            <>
              <Prescore />
              <Basic />
              <Interests />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Data;
