import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Tabs, message } from 'antd';

import LegalEntities from 'views/OtherProducts/CreditMonitoring/LegalEntities';
import IndividualPerson from 'views/OtherProducts/CreditMonitoring/IndividualPerson';
import FileUpload from 'views/OtherProducts/CreditMonitoring/FileUpload';
import useRedirect from 'hooks/navigation/useRedirect';
import useLastPathname from 'hooks/navigation/useLastPathname';
import Popup from 'components/Modals/Popup';
import { useSelector } from 'react-redux';
import otherProducts from '../../../store/entities/otherProducts';
import LoadingSpinner from '../../../components/reusable/LoadingSpinner';

const tabsKeys = Object.freeze({
  legalEntities: 'legal-entities',
  individualPerson: 'individual-person',
  fileUpload: 'file-upload',
});
const { TabPane } = Tabs;

const CreditMonitoring = () => {
  const { t } = useTranslation();
  const redirect = useRedirect();
  const lastPathname = useLastPathname();
  const [isPopUpModalOpen, setIsPopUpModalOpen] = useState(false);
  const [callHandleDeleteAll, setCallHandleDeleteAll] = useState(0);
  const { uploadFilePending } = useSelector(state => state.otherProducts);

  const handleTabChange = useCallback(
    activeKey => {
      if (activeKey !== lastPathname) {
        redirect(`/monitorings/credit-monitoring/${activeKey}`);
      }
    },
    [redirect]
  );

  useEffect(() => {
    if (uploadFilePending) {
      message.success({ content: t('administration.debts.excel.loading'), key: 'loadingMessage', duration: 5 * 60 });
    } else {
      message.destroy('loadingMessage');
    }
  }, [uploadFilePending]);

  return (
    <LoadingSpinner size="large" spinning={uploadFilePending}>
      <Row className="container">
        <Col span={24}>
          <Tabs defaultActiveKey={lastPathname} style={{ height: 'auto', width: '100%' }} onChange={handleTabChange}>
            <TabPane tab={t('otherProducts.tabs.legalEntities')} key={tabsKeys.legalEntities}>
              <LegalEntities setIsPopUpModalOpen={setIsPopUpModalOpen} callHandleDeleteAll={callHandleDeleteAll} />
            </TabPane>
            <TabPane tab={t('otherProducts.tabs.individualPerson')} key={tabsKeys.individualPerson}>
              <IndividualPerson setIsPopUpModalOpen={setIsPopUpModalOpen} callHandleDeleteAll={callHandleDeleteAll} />
            </TabPane>
            <TabPane tab={t('otherProducts.tabs.fileUpload')} key={tabsKeys.fileUpload}>
              <FileUpload />
            </TabPane>
          </Tabs>
          {isPopUpModalOpen && (
            <Popup
              financial={false}
              onClose={() => setIsPopUpModalOpen(false)}
              onContinue={() => (
                setIsPopUpModalOpen(false), setCallHandleDeleteAll(callHandleDeleteAll => callHandleDeleteAll + 1)
              )}
            />
          )}
        </Col>
      </Row>
    </LoadingSpinner>
  );
};

export default CreditMonitoring;
