import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { setSelectedCompany } from 'store/entities/company';
import { setSearchCompanyCode } from 'store/entities/companySearchResult';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import { Table, Typography } from 'antd';

const { Text } = Typography;

const SearchResultsTable = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { searchResults, getCompaniesPending } = useSelector(state => state.company);
  const { isAuthenticated } = useSelector(state => state.user);

  const currentLang = useMemo(() => i18n.language.split('-')[0], [i18n]);
  const sortOpts = useMemo(() => ({ sensitivity: 'base' }), []);

  const columns = [
    {
      title: t('companySearch.results.code'),
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => a.code - b.code,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('companySearch.results.companyName'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <>
          <p>
            <Text type="info">{text}</Text>
          </p>
          {record.oldName && <p>({record.oldName})</p>}
        </>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name, 'lt', sortOpts),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('companySearch.results.address'),
      dataIndex: 'address',
      key: 'address',
      sorter: (a, b) => a.address.localeCompare(b.address, 'lt', sortOpts),
    },
    {
      title: t('companySearch.results.status'),
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status, currentLang, sortOpts),
      render: (status, record) => {
        if (record?.isAfterBankruptcy) {
          return `${status} ${t('companyBasicInformation.basicData.basicInformation.isAfterBankruptcy')}`;
        } else return status;
      },
    },
  ];

  const handleUnregisteredRowClick = code => {
    dispatch(setSearchCompanyCode({ searchCompanyCode: code }));

    history.push(`/search/company/${code}/result`);
  };

  const handleRowClick = record => {
    // history.push(`/search/company/${record.code}`);

    isAuthenticated ? dispatch(setSelectedCompany(record.code)) : handleUnregisteredRowClick(record.code);
  };

  const sortedResults = useMemo(() => {
    return [...searchResults].sort((a, b) => {
      if (
        (a.status === 'Veikiantis subjektas' && b.status !== 'Veikiantis subjektas') ||
        (a.status === 'Active subject' && b.status !== 'Active subject')
      ) {
        return -1;
      }
      if (
        (a.status !== 'Veikiantis subjektas' && b.status === 'Veikiantis subjektas') ||
        (a.status !== 'Active subject' && b.status === 'Active subject')
      ) {
        return 1;
      }

      const statusComparison = a.status.localeCompare(b.status, currentLang, sortOpts);
      if (statusComparison !== 0) {
        return statusComparison;
      }
      return a.name.localeCompare(b.name, 'lt', sortOpts);
    });
  }, [searchResults, currentLang, sortOpts]);

  return (
    searchResults.length > 0 && (
      <div className="space-vertical-lg">
        <LoadingSpinner size="large" spinning={getCompaniesPending} indicator={null}>
          <Table
            dataSource={sortedResults}
            columns={columns}
            onRow={record => ({
              onClick: () => handleRowClick(record),
            })}
            rowKey="id"
            rowClassName={record => `ant-table-row-clickable ${record.isHistoricalManager ? 'ant-table-row-dark' : ''}`}
            scroll={{ x: 'max-content' }}
            pagination={{ defaultPageSize: 20 }}
            showSorterTooltip={false}
          />
        </LoadingSpinner>
      </div>
    )
  );
};

export default SearchResultsTable;
