import { createAsyncThunk } from '@reduxjs/toolkit';
import companyFinancialReportService from 'api/companyFinancialReportService';

export const getFinancialOrderId = createAsyncThunk(
  'companyFinancialReport/getOrderId',
  async ({ companyCode, request }, { rejectWithValue }) => {
    try {
      const response = await companyFinancialReportService.getOrderId(companyCode, request);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBasicData = createAsyncThunk(
  'companyFinancialReport/getBasicData',
  async (companyCode, { rejectWithValue }) => {
    try {
      const response = await companyFinancialReportService.getBasicData(companyCode);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getFinancialStatementPdf = createAsyncThunk(
  'companyFinancialReport/getFinancialStatementPdf',
  async (orderId, { rejectWithValue }) => {
    try {
      const response = await companyFinancialReportService.getFinancialStatementPdf(orderId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCompanyFinReportPerYearThunk = createAsyncThunk(
  'getCompanyFinReportPerYear/perYear',
  async (orderId, { rejectWithValue }) => {
    try {
      return await companyFinancialReportService.getCompanyFinReportPerYear(orderId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
