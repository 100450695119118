import React from 'react';
import { useTranslation } from 'react-i18next';
import ReportBlockHeader from '../../../components/reusable/report/ReportBlockHeader';
import { ReactComponent as PeopleIcon } from '../../../assets/img/icons/people.svg';
import CompanyBoardMembers from '../../../components/reusable/report/tables/CompanyBoardMembers';
import { Card } from 'antd';

const Employees = ({ boardMembersData }) => {
  const { t } = useTranslation();

  return (
    <>
      <Card className="card-simple card-simple-padding-none space-vertical-sm has-shadow" style={{ width: '100%' }}>
        <ReportBlockHeader title={t('startReport.basic.board.title')} icon={<PeopleIcon />} />
        <CompanyBoardMembers boardMembersData={boardMembersData} />
      </Card>
    </>
  );
};

export default Employees;
