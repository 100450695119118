import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import reducer from './reducer';
import i18n from '../i18n';

const middleware = getDefaultMiddleware({
  serializableCheck: false,
  thunk: { extraArgument: { i18n } },
});

const store = configureStore({
  reducer,
  middleware,
});

export default store;
