import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import VisibilitySensor from 'react-visibility-sensor';

import { setActiveTab } from 'store/entities/professionalReport';
import { basicEmployeesAndWages } from 'store/entities/professionalReport/selectors/basicDataSelectors';
import colors from 'constants/colors';

import ComposedChart from 'components/reusable/graphs/ComposedChart';

import { Col, Row, Card } from 'antd';

import { StockOutlined } from '@ant-design/icons';
import useIsUiHidden from '../../../hooks/dom/useIsUiHidden';
import { getQuarterlyAverageWages } from 'utils/report/professionalReport';
import NumberOfEmployeesCombinedLabel from 'components/reusable/graphs/ComposedChart/NumberOfEmployeesCombinedLabel';
import AverageWagesCombinedLabel from 'components/reusable/graphs/ComposedChart/AverageWagesCombinedLabel';
import { BlockHeader } from '../../../components/reusable/BlockHeader';
import useNumberFormatter from '../../../hooks/format/useNumberFormatter';

const Wages = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isUiHidden = useIsUiHidden();
  const { wages, companyEmployeesSectorsNumbers, companyAndSectorsAverageWages } = useSelector(basicEmployeesAndWages);
  const formatNumber = useNumberFormatter({ style: 'decimal' });

  const quarterlyAverageWages = getQuarterlyAverageWages(wages);

  return (
    <div>
      <div id="wages">
        <Card
          className="card-simple card-simple-padding-none space-vertical-sm has-shadow no-break"
          style={{ width: '100%' }}
        >
          <Row justify="start" align="middle">
            <Col>
              <BlockHeader
                icon={<StockOutlined />}
                title={t('professionalReport.basicData.employees.averageWages.title')}
              />
            </Col>
          </Row>
          <VisibilitySensor
            offset={{ bottom: 50 }}
            scrollCheck
            intervalDelay={10}
            onChange={isVisible => isVisible && dispatch(setActiveTab('wages'))}
          >
            <Row className="space-vertical-md">
              <Col md={24} lg={24} className="no-break">
                <ComposedChart
                  id="averageWages"
                  xAxisDataKey={isUiHidden ? 'quarterName' : 'date'}
                  series={[
                    {
                      type: 'bar',
                      dataKey: 'average',
                      fill: '#C40F11',
                    },
                  ]}
                  hiddenSeries={[
                    {
                      dataKey: 'averageMale',
                      index: 0,
                      color: colors.COLOR_GRAY,
                    },
                    {
                      dataKey: 'averageFemale',
                      index: 0,
                      color: colors.COLOR_GRAY,
                      render: amount => {
                        return formatNumber(amount);
                      },
                    },
                  ]}
                  data={isUiHidden ? quarterlyAverageWages : wages}
                  showLegend={false}
                  lazyLoaded
                />
              </Col>
            </Row>
          </VisibilitySensor>
        </Card>
      </div>
      <Card
        className="card-simple card-simple-padding-none space-vertical-sm has-shadow no-break"
        style={{ width: '100%' }}
      >
        <Row>
          <Col md={24} lg={24}>
            <div className="justify-content-center">
              {t('professionalReport.basicData.employees.casNumberOfEmployees.title')}
            </div>
            <ComposedChart
              id="casNumberOfEmployees"
              series={[
                {
                  type: 'bar',
                  dataKey: 'employeesNumber',
                  fill: '#C40F11',
                },
                {
                  type: 'line',
                  dataKey: 'employeesNumberInSectors',
                  stroke: '#0C9CB2',
                },
              ]}
              data={companyEmployeesSectorsNumbers?.numberOfEmployeesInThePeriods ?? []}
              CustomLabel={<NumberOfEmployeesCombinedLabel />}
              lazyLoaded
            />
          </Col>
        </Row>
      </Card>
      <Card
        className="card-simple card-simple-padding-none space-vertical-sm has-shadow no-break"
        style={{ width: '100%' }}
      >
        <Row>
          <Col md={24} lg={24}>
            <div className="justify-content-center">
              {t('professionalReport.basicData.employees.casAverageWages.title')}
            </div>
            <ComposedChart
              id="casAverageWages"
              series={[
                {
                  type: 'bar',
                  dataKey: 'companyAverageWage',
                  fill: '#C40F11',
                },
                {
                  type: 'area',
                  dataKey: 'sectorAverageWage',
                  stroke: '#0C9CB2',
                },
              ]}
              monotone
              activeDefs
              data={companyAndSectorsAverageWages?.averageWagesInThePeriods ?? []}
              CustomLabel={<AverageWagesCombinedLabel />}
              lazyLoaded
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Wages;
