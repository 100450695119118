import { createSelector } from 'reselect';
import _pick from 'lodash.pick';

export const companyBasicInfo = createSelector(
  state => state.companyCreditLimitReport?.basicData,
  basicData => _pick(basicData, ['basicInformation'])
);

export const companyContactInfo = createSelector(
  state => state.companyCreditLimitReport?.basicData,
  basicData => _pick(basicData, ['companyContact'])
);

export const companyCreditLimit = createSelector(
  state => state.companyCreditLimitReport?.basicData,
  basicData => _pick(basicData, ['countedAmount'])
);

export const selectBasicInformation = createSelector(
  state => state.companyCreditLimitReport?.basicData,
  basicData => basicData?.basicInformation || {}
);
