import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import VisibilitySensor from 'react-visibility-sensor';

import { setActiveTab } from 'store/entities/professionalReport';

import CompanyDataCard, { CompanyDataRow } from 'components/reusable/CompanyDataCard';

import { ReactComponent as ExportIcon } from 'assets/img/icons/export.svg';

import Icon from '@ant-design/icons';
import { Col } from 'antd';
import { basicExport } from '../../../store/entities/professionalReport/selectors/basicDataSelectors';
import useNumberFormatter from '../../../hooks/format/useNumberFormatter';

const Exports = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formatCurrency = useNumberFormatter();
  const { export: exportedValue } = useSelector(basicExport);

  return (
    <div id="companyExports">
      <VisibilitySensor
        offset={{ bottom: 50 }}
        scrollCheck
        intervalDelay={10}
        onChange={isVisible => isVisible && dispatch(setActiveTab('companyExports'))}
      >
        <CompanyDataCard
          icon={<Icon component={ExportIcon} className="icon--gray" />}
          title={t('professionalReport.basicData.exports.title')}
        >
          {!!exportedValue?.exportPercent || !!exportedValue?.exportAmount || !!exportedValue?.countries ? (
            <Col>
              <CompanyDataRow
                type={t('professionalReport.basicData.export.exportPercent')}
                value={exportedValue.exportPercent ?? t('professionalReport.basicData.export.noInformation')}
              />
              <CompanyDataRow
                type={t('professionalReport.basicData.export.exportAmount')}
                value={
                  formatCurrency(exportedValue.exportAmount) ?? t('professionalReport.basicData.export.noInformation')
                }
              />
              <CompanyDataRow
                type={t('professionalReport.basicData.export.countries')}
                value={exportedValue.countries ?? t('professionalReport.basicData.export.noInformation')}
              />
              <CompanyDataRow
                type={t('professionalReport.basicData.export.source')}
                value='"B2BLithuania" (Eksportuotojų duomenų bazė (EDB))'
              />
              <CompanyDataRow
                type={t('professionalReport.basicData.export.more')}
                value={
                  <a
                    href="https://eksportogidas.inovacijuagentura.lt/knowledge-base/eksportuotoju-duomenu-baze/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'underline' }}
                  >
                    {t('professionalReport.basicData.export.readSource')}
                  </a>
                }
              />
            </Col>
          ) : (
            <Col>{t('professionalReport.basicData.export.noData')}</Col>
          )}
        </CompanyDataCard>
      </VisibilitySensor>
    </div>
  );
};

export default Exports;
