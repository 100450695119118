export const defaultRatingPercentages = [
  { score: 1, percentages: [0, 0.08] },
  {
    score: 2,
    percentages: [0.081, 0.17],
  },
  {
    score: 3,
    percentages: [0.171, 0.33],
  },
  { score: 4, percentages: [0.331, 0.55] },
  { score: 5, percentages: [0.551, 1.08] },
  {
    score: 6,
    percentages: [1.081, 1.98],
  },
  {
    score: 7,
    percentages: [1.931, 5],
  },
  {
    score: 8,
    percentages: [5.001, 8],
  },
  {
    score: 9,
    percentages: [8.001, 10],
  },
  {
    score: 10,
    percentages: [10.001, 100],
  },
];

export const bankruptcyRatingPercentages = [
  { score: 1, percentages: [0, 0.03] },
  {
    score: 2,
    percentages: [0.031, 0.08],
  },
  {
    score: 3,
    percentages: [0.081, 0.16],
  },
  { score: 4, percentages: [0.161, 0.19] },
  { score: 5, percentages: [0.191, 0.3] },
  {
    score: 6,
    percentages: [0.301, 0.57],
  },
  {
    score: 7,
    percentages: [0.571, 1.53],
  },
  {
    score: 8,
    percentages: [1.531, 5],
  },
  {
    score: 9,
    percentages: [5.001, 10],
  },
  {
    score: 10,
    percentages: [10.001, 100],
  },
];

export const personalRatingPercentages = [
  { score: 1, percentages: [0, 0.44] },
  { score: 2, percentages: [0.45, 1.71] },
  { score: 3, percentages: [1.72, 5.04] },
  { score: 4, percentages: [5.05, 21.9] },
  { score: 5, percentages: [21.91, 100.0] },
];
