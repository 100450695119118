import React from 'react';
import { useTranslation } from 'react-i18next';

import CompanyDataCard, { CompanyDataRow } from 'components/reusable/CompanyDataCard';
import CollapsibleTable from 'components/reusable/CollapsibleTable';
import TableColumnDate from 'components/reusable/TableColumnDate';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import useFormatDate, { DATE_FORMAT_LONG } from 'hooks/format/useFormatDate';
import useNumberFormatter from 'hooks/format/useNumberFormatter';

import { Col } from 'antd';
import { ReactComponent as Money } from 'assets/img/icons/money.svg';

const AuthorizedCapital = ({ showHistorical = true, authorizedCapital, authorizedCapitalHistory }) => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();
  const formatDate = useFormatDate();
  const formatCurrency = useNumberFormatter();
  const formatNumber = useNumberFormatter({ style: 'decimal' });

  const historicalInformationColumns = [
    {
      title: t('global.dateFrom'),
      dataIndex: 'confirmationDate',
      key: 'confirmationDate',
      render: TableColumnDate,
      sorter: (a, b) => {
        const dateA = new Date(a.confirmationDate);
        const dateB = new Date(b.confirmationDate);
        return dateB - dateA;
      },
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: `${t('global.amount')}`,
      dataIndex: 'amount',
      render: (amount, data) => `${formatNumber(amount)} ${data.currency}`,
      key: 'amount',
      sorter: (a, b) => a.amount - b.amount,
      sortDirections: ['ascend', 'descend'],
    },
  ];

  if (authorizedCapital?.amount) {
    return (
      <>
        <CompanyDataCard
          icon={<Money className="icon--gray" />}
          title={t('professionalReport.basicData.authorizedCapital.title')}
        >
          <Col>
            <CompanyDataRow
              type={formatDate(authorizedCapital?.confirmationDate, DATE_FORMAT_LONG)}
              value={formatCurrency(authorizedCapital?.amount)}
            />
          </Col>
          {!isUiHidden && showHistorical && authorizedCapitalHistory?.length ? (
            <CollapsibleTable
              className="space-top-md"
              title={t('professionalReport.basicData.authorizedCapital.historical')}
              data={authorizedCapitalHistory}
              columns={historicalInformationColumns}
              bordered
            />
          ) : null}
        </CompanyDataCard>
      </>
    );
  } else return null;
};

export default AuthorizedCapital;
