import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PASSWORD_REGEX } from 'constants/form';

import { Form, Input, Button, Row, Col, Typography, Space, Descriptions } from 'antd';
import { changePassword } from 'store/entities/user/thunks';
import { showSuccessNotification } from 'utils/notification';

const { Title } = Typography;

const ChangePasswordForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { session } = useSelector(state => state.user);
  const [form] = Form.useForm();

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: {
      sm: { span: 24 },
      md: { span: 24 },
      lg: { span: 24 },
      xl: { span: 12 },
    },
  };

  const tailLayout = {
    wrapperCol: { span: 24 },
  };

  const onFinish = async values => {
    const submitResult = await dispatch(changePassword(values));

    if (changePassword.fulfilled.match(submitResult)) {
      showSuccessNotification('Password changed!');
      form.resetFields();
    }
  };

  return (
    <>
      <Row>
        <Col>
          <Title level={3} className="space-vertical-xl">
            {t('profile.settings.changePassword.title')}
          </Title>
        </Col>
      </Row>
      <Row className="space-vertical-lg">
        {session && (
          <Descriptions layout="vertical" column={2}>
            <Descriptions.Item label={t('profile.settings.changePassword.companyName')}>
              {session.companyName}
            </Descriptions.Item>
            <Descriptions.Item label={t('profile.settings.changePassword.userName')}>
              {session.username}
            </Descriptions.Item>
            <Descriptions.Item label={t('profile.settings.changePassword.email')}>{session.email}</Descriptions.Item>
          </Descriptions>
        )}
      </Row>
      <Row>
        <Col span={24}>
          <Form {...layout} form={form} name="changePassword" onFinish={onFinish} validateTrigger="onSubmit">
            <Form.Item
              label={t('profile.settings.changePassword.currentPassword')}
              name="currentPassword"
              className="margin-bottom-none"
              rules={[
                {
                  required: true,
                  message: t('profile.settings.changePassword.currentPassword.required'),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label={t('profile.settings.changePassword.newPassword')}
              name="newPassword"
              className="margin-bottom-none"
              rules={[
                {
                  required: true,
                  message: t('profile.settings.changePassword.newPassword.required'),
                },
                {
                  pattern: PASSWORD_REGEX,
                  message: t('profile.settings.changePassword.newPassword.pattern'),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label={t('profile.settings.changePassword.verifyNewPassword')}
              name="newPasswordRepeat"
              className="margin-bottom-none"
              rules={[
                {
                  required: true,
                  message: t('profile.settings.changePassword.verifyNewPassword.required'),
                },
                {
                  pattern: PASSWORD_REGEX,
                  message: t('profile.settings.changePassword.verifyNewPassword.pattern'),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item {...tailLayout} className="space-top-xl">
              <Space>
                <Button className="btn-secondary" htmlType="submit">
                  {t('profile.settings.change')}
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ChangePasswordForm;
