import React from 'react';

const PdfLabel = ({ x, y, value, labelUnder, minusValuesLabelUnder }) => {
  return (
    <g>
      <text x={x} y={labelUnder || (minusValuesLabelUnder && value < 0) ? y + 20 : y - 10} textAnchor="middle">
        {value}
      </text>
    </g>
  );
};

export default PdfLabel;
