import React from 'react';
import { useTranslation } from 'react-i18next';

import CompanyDataCard, { CompanyDataRow } from 'components/reusable/CompanyDataCard';

import { InfoCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

const MainData = () => {
  const { t } = useTranslation();

  const { session } = useSelector(state => state.user);

  return (
    <CompanyDataCard
      key="title"
      icon={<InfoCircleOutlined />}
      title={t('administration.debtManagement.mainData.title')}
    >
      <CompanyDataRow
        key="companyCode"
        type={t('administration.debtManagement.mainData.companyCode')}
        value={session?.companyCode ?? '-'}
      />
      <CompanyDataRow
        key="compnayName"
        type={t('administration.debtManagement.mainData.companyName')}
        value={session?.companyName ?? '-'}
      />
    </CompanyDataCard>
  );
};

export default MainData;
