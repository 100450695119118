const sections = [
  {
    name: 'score',
    value: 10,
    score: 1,
    text: 'openDebts.personalScore.card.desc.classA',
    active: false,
    className: 'A',
  },
  {
    name: 'score',
    value: 10,
    score: 2,
    text: 'openDebts.personalScore.card.desc.classB',
    active: false,
    className: 'B',
  },
  {
    name: 'score',
    value: 10,
    score: 3,
    text: 'openDebts.personalScore.card.desc.classC',
    active: false,
    className: 'C',
  },
  {
    name: 'score',
    value: 10,
    score: 4,
    text: 'openDebts.personalScore.card.desc.classD',
    active: false,
    className: 'D',
  },
  {
    name: 'score',
    value: 10,
    score: 5,
    text: 'openDebts.personalScore.card.desc.classE',
    active: false,
    className: 'E',
  },
];

export default sections;
