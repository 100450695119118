import { useEffect } from 'react';
import MainData from 'components/reusable/MainData';
import CoveredDebtManagementTable from '../../../components/reusable/CoveredDebtManagementTable';
import UncoveredDebtManagementTable from '../../../components/reusable/UncoveredDebtManagementTable';
import {
  getCompanyOrPersonUncoveredDebtsList,
  getCompanyOrPersonCoveredDebtsList,
} from '../../../store/entities/administration/thunks';
import { useDispatch, useSelector } from 'react-redux';

const Companies = () => {
  const dispatch = useDispatch();
  const { companyOrPersonDebtsLoading, companyCoveredDebts, companyUncoveredDebts, debtsExcelLoading } = useSelector(
    state => state.administration
  );
  const handleOnFilter = (v, type) => {
    if (type === 'covered') {
      dispatch(getCompanyOrPersonCoveredDebtsList({ companyOrPerson: 'company', ...v }));
    }
    if (type === 'uncovered') {
      dispatch(getCompanyOrPersonUncoveredDebtsList({ companyOrPerson: 'company', ...v }));
    }
  };

  useEffect(() => {
    dispatch(getCompanyOrPersonUncoveredDebtsList({ companyOrPerson: 'company' }));
    dispatch(getCompanyOrPersonCoveredDebtsList({ companyOrPerson: 'company' }));
  }, []);

  return (
    <>
      <MainData />
      <UncoveredDebtManagementTable
        data={companyUncoveredDebts}
        type="company"
        isLoading={companyOrPersonDebtsLoading || debtsExcelLoading}
        handleOnFilter={handleOnFilter}
      />
      <CoveredDebtManagementTable
        data={companyCoveredDebts}
        type="company"
        isLoading={companyOrPersonDebtsLoading || debtsExcelLoading}
        handleOnFilter={handleOnFilter}
      />
    </>
  );
};

export default Companies;
