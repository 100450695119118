import { useEffect } from 'react';
import { useIdleTimer, workerTimers } from 'react-idle-timer';

const useIdleSession = ({ onIdle, valueToCancel }) => {
  const idleTimer = useIdleTimer({
    timeout: 1000 * 60 * 13.5,
    onIdle,
    timers: workerTimers,
    syncTimers: 200,
    startOnMount: true,
    name: 'idle-timer',
  });

  useEffect(() => {
    if (valueToCancel) {
      idleTimer.pause();
    } else {
      idleTimer.reset();
    }
  }, [valueToCancel, idleTimer]);

  return idleTimer;
};

export default useIdleSession;
