import CodeForm from 'views/OtherProducts/CreditMonitoring/CodeForm';
import { useSelector } from 'react-redux';

const LegalEntities = ({ setIsPopUpModalOpen, callHandleDeleteAll }) => {
  const { companyMonitoringSummary } = useSelector(state => state.otherProducts);

  return (
    <CodeForm
      codeText="otherProducts.monitorings.creditMonitoring.legalEntities.code"
      currentlyMonitoring={companyMonitoringSummary?.watchCount}
      freeMonitoring={companyMonitoringSummary?.watchMax}
      youAreMonitoring={companyMonitoringSummary?.watchCountUser}
      isLegalEntity
      callHandleDeleteAll={callHandleDeleteAll}
      setIsPopUpModalOpen={setIsPopUpModalOpen}
    />
  );
};

export default LegalEntities;
