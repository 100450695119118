import { createSelector } from 'reselect';

export const bankruptcyRatingInfo = createSelector(
  state => state.startReport?.companyRatings,
  ratings => {
    const { rating, bankruptcyRatingFactor } = ratings || {};
    const { bankruptcy: bankruptcyData, bankruptcyExpertDate } = rating || {};
    const { positive: bankruptcyRatingPositiveFactors, negative: bankruptcyRatingNegativeFactors } =
      bankruptcyRatingFactor || {};
    const bankruptcyRating = Object.assign([], bankruptcyData);
    bankruptcyRating.bankruptcyExpertDate = bankruptcyExpertDate;

    return {
      bankruptcyRating,
      bankruptcyRatingPositiveFactors,
      bankruptcyRatingNegativeFactors,
    };
  }
);

export const defaultRatingInfo = createSelector(
  state => state.startReport?.companyRatings,
  ratings => {
    const { rating, defaultRatingFactor } = ratings || {};
    const { default: defaultData, defaultExpertDate } = rating || {};
    const { positive: defaultRatingPositiveFactors, negative: defaultRatingNegativeFactors } =
      defaultRatingFactor || {};

    const defaultRating = Object.assign([], defaultData);
    defaultRating.defaultExpertDate = defaultExpertDate;
    return {
      defaultRating,
      defaultRatingPositiveFactors,
      defaultRatingNegativeFactors,
    };
  }
);

export const defaultScoringHistoryChart = createSelector(
  state => state.startReport?.companyRatings,
  ratings => {
    const { defaultRatingHistory } = ratings || {};
    const { companyRatingHistory = [], companyRatingRiskClasses = [] } = defaultRatingHistory || {};

    return {
      defaultRatingHistory: companyRatingHistory
        .map(series => {
          const riskClassInfo =
            companyRatingRiskClasses.find(({ class: riskClass }) => riskClass === series.riskClass) || {};
          const { rangeFrom, rangeTo } = riskClassInfo;
          const companyChartValue =
            series.riskClass + ((series.probability - rangeFrom) * 100) / (rangeTo - rangeFrom) / 100;

          return {
            date: series.date,
            riskClass: series.riskClass,
            company: companyChartValue,
            companyPercentage: series.probability,
          };
        })
        .sort((a, b) => a.date.localeCompare(b.date)),
      defaultRatingRiskClasses: companyRatingRiskClasses,
    };
  }
);

export const bankruptcyRatingHistoryChart = createSelector(
  state => state.startReport?.companyRatings,
  ratings => {
    const { bankruptcyRatingHistory } = ratings || {};
    const { companyRatingHistory = [], companyRatingRiskClasses = [] } = bankruptcyRatingHistory || {};

    return {
      bankruptcyRatingHistory: companyRatingHistory
        .map(series => {
          const riskClassInfo =
            companyRatingRiskClasses.find(({ class: riskClass }) => riskClass === series.riskClass) || {};
          const { rangeFrom, rangeTo } = riskClassInfo;
          const companyChartValue =
            series.riskClass + ((series.probability - rangeFrom) * 100) / (rangeTo - rangeFrom) / 100;

          return {
            date: series.date,
            riskClass: series.riskClass,
            company: companyChartValue,
            companyPercentage: series.probability,
          };
        })
        .sort((a, b) => a.date.localeCompare(b.date)),
      bankruptcyRatingRiskClasses: companyRatingRiskClasses,
    };
  }
);

export const summaryRatings = createSelector(
  state => state.startReport?.summary,
  summary => {
    const { bankruptcyRatingSummary, defaultRatingSummary } = summary || {};
    return { bankruptcyRatingSummary, defaultRatingSummary };
  }
);
