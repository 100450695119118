import MainData from 'components/reusable/MainData';
import NewDebtIndividual from 'components/reusable/NewDebtIndividual';
import UncoveredDebtManagementTable from 'components/reusable/UncoveredDebtManagementTable';
import React, { useEffect } from 'react';
import CoveredDebtManagementTable from '../../../components/reusable/CoveredDebtManagementTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCompanyOrPersonCoveredDebtsList,
  getCompanyOrPersonUncoveredDebtsList,
} from '../../../store/entities/administration/thunks';

const NewDebtIndividuals = () => {
  const dispatch = useDispatch();
  const { companyOrPersonDebtsLoading, personUncoveredDebts, personCoveredDebts, debtsExcelLoading } = useSelector(
    state => state.administration
  );
  const handleOnFilter = (v, type) => {
    if (type === 'covered') {
      dispatch(getCompanyOrPersonCoveredDebtsList({ companyOrPerson: 'person', ...v }));
    }
    if (type === 'uncovered') {
      dispatch(getCompanyOrPersonUncoveredDebtsList({ companyOrPerson: 'person', ...v }));
    }
  };

  useEffect(() => {
    dispatch(getCompanyOrPersonCoveredDebtsList({ companyOrPerson: 'person' }));
    dispatch(getCompanyOrPersonUncoveredDebtsList({ companyOrPerson: 'person' }));
  }, []);

  return (
    <>
      <NewDebtIndividual />
      <MainData />
      <UncoveredDebtManagementTable
        data={personUncoveredDebts}
        type="individual"
        isLoading={companyOrPersonDebtsLoading || debtsExcelLoading}
        handleOnFilter={handleOnFilter}
      />
      <CoveredDebtManagementTable
        data={personCoveredDebts}
        type="individual"
        isLoading={companyOrPersonDebtsLoading || debtsExcelLoading}
        handleOnFilter={handleOnFilter}
      />
    </>
  );
};

export default NewDebtIndividuals;
