import colors from 'constants/colors';

const classColors = {
  A: colors.COLOR_SUCCESS_DARK,
  B: colors.COLOR_SUCCESS,
  C: colors.COLOR_WARNING_LIGHT,
  D: colors.COLOR_WARNING,
  E: colors.COLOR_SECONDARY,
};

export default classColors;
