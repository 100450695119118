import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import VisibilitySensor from 'react-visibility-sensor';

import { setActiveTab } from 'store/entities/professionalReport';
import { basicEmployeesAndWages } from 'store/entities/professionalReport/selectors/basicDataSelectors';

import { Col, Row, Card, Space, Divider } from 'antd';
import { ReactComponent as PeopleIcon } from 'assets/img/icons/people.svg';
import EmployeesTable from 'components/reusable/report/tables/EmployeesTable';
import AverageEmployeesChart from 'components/reusable/report/graphs/AverageEmployeesChart';
import { BlockHeader } from '../../../components/reusable/BlockHeader';

const Employees = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    employeesNumber,
    employeesTable,
    companyEmployeesSectorsNumbers: { numberOfEmployeesInThePeriods },
  } = useSelector(basicEmployeesAndWages);

  return (
    <VisibilitySensor
      offset={{ bottom: 50 }}
      scrollCheck
      intervalDelay={10}
      onChange={isVisible => isVisible && dispatch(setActiveTab('employees'))}
    >
      <div className="no-break" id="employees">
        <Card
          className="card-simple card-simple-padding-none space-vertical-sm has-shadow no-break"
          style={{ width: '100%' }}
        >
          <Row justify="start" align="middle">
            <Col>
              <BlockHeader
                icon={<PeopleIcon />}
                title={t('professionalReport.basicData.employees.averageNumberOfEmployees.title')}
              />
            </Col>
          </Row>
          <AverageEmployeesChart tableData={employeesNumber} />
        </Card>
        <Card className="card-simple card-simple-padding-none space-vertical-sm has-shadow" style={{ width: '100%' }}>
          <EmployeesTable numberOfEmployeesInThePeriods={numberOfEmployeesInThePeriods ?? []} />
        </Card>
      </div>
    </VisibilitySensor>
  );
};

export default Employees;
