import React from 'react';
import { Breadcrumb } from 'antd';
import { useSelector } from 'react-redux';

import { selectBasicInformation } from 'store/entities/companyCreditLimitReport/selectors/basicDataSelectors';
import useBreadcrumbTitles from 'hooks/report/useBreadcrumbTitles';

const MainInformationBreadcrumb = ({ location }) => {
  const basicInformation = useSelector(selectBasicInformation);
  const breadcrumbTitles = useBreadcrumbTitles(basicInformation);
  const { selectedCompanyData } = useSelector(state => state.company);
  const breadcrumbsArr = location.pathname.split('/').slice(1);

  if (!selectedCompanyData?.name) return null;

  return breadcrumbTitles.map((title, i) => (
    <Breadcrumb.Item key={title} href={`/${breadcrumbsArr.slice(0, i + 1).join('/')}`}>
      {title}
    </Breadcrumb.Item>
  ));
};

export default MainInformationBreadcrumb;
