import React from 'react';
import { useTranslation } from 'react-i18next';

import { Card, Col, Row, Space } from 'antd';
import { FileTextOutlined, WarningOutlined } from '@ant-design/icons';
import CompanyDataCard, { CompanyDataRow } from '../../../components/reusable/CompanyDataCard';

const PersonsRegister = ({ creditRestriction }) => {
  const { t } = useTranslation();

  const restrictionsNames = creditRestriction?.restrictions.map(restriction => restriction.name).join(', ');

  return (
    <CompanyDataCard
      icon={<FileTextOutlined className="icon--gray" style={{ fontSize: 20 }} />}
      title={t('individualReport.personsRegister.title')}
    >
      {creditRestriction?.restrictions?.length ? (
        <>
          <CompanyDataRow
            extraClass="color-secondary"
            type={t('individualReport.personsRegister.status')}
            value={t('individualReport.personsRegister.status.creditRestricted')}
          />
          <CompanyDataRow
            extraClass="color-secondary"
            type={t('individualReport.personsRegister.sectors')}
            value={restrictionsNames}
          />
        </>
      ) : (
        <Card className="card-transparent space-vertical-sm">
          {t('individualReport.personsRegister.status.notRegistered')}
        </Card>
      )}
    </CompanyDataCard>
  );
};

export default PersonsRegister;
