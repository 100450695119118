import { createSlice } from '@reduxjs/toolkit';
import { getOrderId, getBasicData, getCreditLimitReportPdf } from './thunks';

const initialState = {
  orderId: null,
  orderIdPending: false,
  orderIdError: false,
  basicData: null,
  basicDataPending: false,
  basicDataError: false,
  creditLimitReportPdfPending: false,
};

const slice = createSlice({
  name: 'companyCreditLimitReport',
  initialState: initialState,
  reducers: {
    resetCompanyCreditLimitReport: state => initialState,
  },
  extraReducers: {
    [getOrderId.fulfilled]: (state, { payload }) => {
      state.orderId = payload?.id;
      state.orderIdPending = false;
      state.orderIdError = false;
    },
    [getOrderId.pending]: state => {
      state.orderIdPending = true;
      state.orderIdError = false;
    },
    [getOrderId.rejected]: state => {
      state.orderIdPending = false;
      state.orderIdError = true;
    },
    [getBasicData.fulfilled]: (state, { payload }) => {
      state.basicData = payload;
      state.basicDataPending = false;
      state.basicDataError = false;
    },
    [getBasicData.pending]: state => {
      state.basicDataPending = true;
      state.basicDataError = false;
    },
    [getBasicData.rejected]: state => {
      state.basicDataPending = false;
      state.basicDataError = true;
    },
    [getCreditLimitReportPdf.fulfilled]: state => {
      state.creditLimitReportPdfPending = false;
    },
    [getCreditLimitReportPdf.pending]: state => {
      state.creditLimitReportPdfPending = true;
    },
    [getCreditLimitReportPdf.rejected]: state => {
      state.creditLimitReportPdfPending = false;
    },
  },
});

export const { resetCompanyCreditLimitReport } = slice.actions;

export default slice.reducer;
