import { fetchFromApi, optionsForPostRequest } from './';

const signIn = credentials => fetchFromApi('/account/sign-in', optionsForPostRequest(credentials));

const signOut = () => fetchFromApi('/account/logout', optionsForPostRequest());

const refreshToken = () => fetchFromApi('/account/refresh-token', optionsForPostRequest());

export default {
  signIn,
  signOut,
  refreshToken,
};
