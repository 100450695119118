import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Button } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';

import { getSelectedCompanyData } from 'store/entities/company/thunks';
import { getOrderId, getMainInformationPdf, getBasicInfo } from 'store/entities/mainInformation/thunks';
import ReportHeader from 'components/reusable/report/ReportHeader';
import BasicData from 'views/MainInformation/BasicData';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import { b64toBlob, downloadAsFile } from 'utils/download';
import { useMainInformationPdfFilename } from 'hooks/report/professionalReport/usePdfFilename';
import PdfFooterDisclaimer from '../Pdfs/PdfFooterDisclaimer';
import { useParams } from 'react-router-dom';
import useCurrentLanguage from '../../hooks/user/useCurrentLanguage';

const MainInformation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pdfFileName = useMainInformationPdfFilename();
  const { companyId } = useParams();
  const lang = useCurrentLanguage();

  const { selectedCompanyData } = useSelector(state => state.company);

  const { orderId, basicInfoPending, orderIdPending, mainInformationPdfPending } = useSelector(
    state => state.mainInformation
  );

  useEffect(() => {
    dispatch(getOrderId(companyId));
  }, []);

  useEffect(() => {
    if (orderId) {
      dispatch(getBasicInfo(orderId));
    }
  }, [orderId]);

  useEffect(() => {
    companyId && dispatch(getSelectedCompanyData(companyId));
  }, [companyId, lang]);

  const handlePdfDownload = async () => {
    const response = await dispatch(getMainInformationPdf(orderId));

    const data = b64toBlob(response.payload.data);
    downloadAsFile(data, pdfFileName);
  };

  return (
    <div className="main-information container">
      <Col span={24}>
        <ReportHeader
          title={selectedCompanyData?.name}
          actions={
            <Button onClick={handlePdfDownload}>
              <PrinterOutlined />
              {t('global.print')}
            </Button>
          }
        />
      </Col>
      <LoadingSpinner spinning={orderIdPending || mainInformationPdfPending || basicInfoPending}>
        <BasicData />
        <PdfFooterDisclaimer />
      </LoadingSpinner>
    </div>
  );
};

export default MainInformation;
