import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'antd';

const IndividualDataBlock = ({ data }) => {
  const { t } = useTranslation();

  const { name, personalCode } = data;

  const IndividualDataRow = ({ type, value }) => (
    <>
      <Col xs={12} lg={6}>
        <strong>{type}</strong>
      </Col>
      <Col xs={12} lg={6}>
        {value}
      </Col>
    </>
  );

  return (
    <div style={{ padding: '0 1.5rem', margin: '3rem 0 2rem' }}>
      <Row>
        <IndividualDataRow type={t('individualSearch.result.basic.code')} value={personalCode} />
        <IndividualDataRow type={t('individualSearch.result.basic.name')} value={name} />
      </Row>
    </div>
  );
};

export default IndividualDataBlock;
