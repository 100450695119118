import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import CollapsibleCard from 'components/reusable/CollapsibleCard';

import { Table, Typography } from 'antd';
import useNumberFormatter from 'hooks/format/useNumberFormatter';

const { Paragraph } = Typography;

const FinancialRatioTable = ({ financialRatiosTableData, ratioTimeline }) => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();
  const formatNumber = useNumberFormatter({ minimumFractionDigits: 2, maximumFractionDigits: 2 });

  const financialRatiosColumns = useMemo(
    () => [
      {
        title: t('professionalReport.financialData.financialRatios.title'),
        dataIndex: 'ratio',
        render: ratio => t(`professionalReport.financialData.financialRatios.${ratio}`),
        key: 'ratio',
      },
      ...ratioTimeline
        .map(({ financialYear, financialMonth, hasRatiosForecasted }) => ({
          className: 'differentFinancialYears',
          title: `${financialYear} (${t('global.months', {
            months: financialMonth,
          })})`,
          key: 'title',
          children: [
            {
              className: 'differentFinancialYears',
              title: t('professionalReport.financialData.companies'),
              dataIndex: `companies${financialYear}`,
              key: `companies${financialYear}`,
              render: value => (value ? formatNumber(value) : ''),
            },
            {
              title: t('professionalReport.financialData.sector'),
              dataIndex: `sector${financialYear}`,
              key: `sector${financialYear}`,
              render: value => (value ? formatNumber(value) : ''),
            },
            ...(hasRatiosForecasted
              ? [
                  {
                    title: (
                      <Trans
                        i18nKey="professionalReport.financialData.sector.extra"
                        components={{ 1: <span className="color-error" /> }}
                      />
                    ),
                    dataIndex: `sectorForecasted${financialYear}`,
                    key: `sectorForecasted${financialYear}`,
                  },
                ]
              : []),
          ],
        }))
        .filter((item, idx) => (isUiHidden ? idx < 3 : true)),
    ],
    [financialRatiosTableData]
  );

  return (
    <div className="space-vertical-sm">
      <Table
        rowKey={record => record.ratio}
        dataSource={financialRatiosTableData}
        columns={financialRatiosColumns}
        scroll={isUiHidden ? false : { x: 'max-content' }}
        pagination={false}
        bordered
      />
      {ratioTimeline.some(item => item.hasRatiosForecasted) && (
        <Paragraph className="explanation" key="explanation">
          <span>*</span>
          {t('professionalReport.financialData.sector.explanation')}
        </Paragraph>
      )}
      <CollapsibleCard
        className="space-top-md"
        title={t('professionalReport.financialData.financialRatios.description.title')}
      >
        <div className="padding-x-lg padding-top-lg">
          {financialRatiosTableData.map((item, i) => (
            <Paragraph key={`financialRatios${i}`}>
              <Trans
                i18nKey={`professionalReport.financialData.financialRatios.description.${item.ratio}`}
                components={{ bold: <strong /> }}
              />
            </Paragraph>
          ))}
        </div>
      </CollapsibleCard>
    </div>
  );
};

export default FinancialRatioTable;
