import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useCurrentLanguage from 'hooks/user/useCurrentLanguage';
import { getBasicData } from 'store/entities/companyCreditLimitReport/thunks';
import Basic from './Basic';
import CompanyCredit from './CompanyCredit';
import PdfFooterDisclaimer from '../../Pdfs/PdfFooterDisclaimer';

const CompanyCreditLimitReport = ({ pdfReport = false }) => {
  const dispatch = useDispatch();
  const lang = useCurrentLanguage();

  const { orderId, basicData } = useSelector(state => state.companyCreditLimitReport);

  useEffect(() => {
    if ((orderId && basicData && basicData?.lang && lang !== basicData?.lang) || !basicData)
      dispatch(getBasicData(orderId));
  }, []);

  return (
    <>
      {basicData && (
        <>
          <CompanyCredit />
          <Basic />
        </>
      )}
    </>
  );
};

export default CompanyCreditLimitReport;
