import React, { useState } from 'react';
import Modal from 'components/reusable/Modal';
import { Button, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import Title from 'antd/lib/typography/Title';
import MonitoringAlerts from './MonitoringAlerts';
import AdditionalFilters from './AdditionalFilters';
import { useDispatch } from 'react-redux';
import { setUserSettings } from 'store/entities/otherProducts/thunks';

const MonitoringSettings = ({ modalOpen, setModalOpen, legalEntities }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [enabledEntities, setEnabledEntities] = useState([]);

  const handleSettingsSubmit = () => {
    dispatch(setUserSettings(enabledEntities));
    setModalOpen(false);
  };

  return (
    <Modal isOpen={modalOpen} setIsOpen={setModalOpen}>
      <div className="container monitoring-settings">
        <Row>
          <Title>{t('webMonitoring.settings.title')}</Title>
        </Row>
        <MonitoringAlerts legalEntities={legalEntities} setEnabledEntities={setEnabledEntities} />
        <AdditionalFilters />
        <Button className="btn-secondary confirm" onClick={handleSettingsSubmit}>
          {t('global.confirm.settings')}
        </Button>
        <Button className="btn-secondary close" onClick={() => setModalOpen(false)}>
          {t('global.close')}
        </Button>
      </div>
    </Modal>
  );
};

export default MonitoringSettings;
