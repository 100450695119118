import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomExpandIcon from './CustomExpandIcon';
import useFormatDate from 'hooks/format/useFormatDate';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';

import { Table } from 'antd';

const NumberOfVehiclesTable = ({ vehicles }) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const isUiHidden = useIsUiHidden();

  const numberOfVehiclesColumns = [
    {
      title: t('professionalReport.basicData.vehicles.formOfOwnership'),
      dataIndex: 'form',
      width: 180,
      render: (text, row) =>
        row.nested ? (
          <strong>{text}</strong>
        ) : (
          t(`professionalReport.basicData.vehicles.category.${text.toLowerCase()}`)
        ),
      key: 'form',
    },
    ...Object.keys(vehicles[0] ?? {})
      .filter(dataKey => dataKey !== 'form' && dataKey !== 'nested' && dataKey !== 'rowKey')
      .map(dataKey => ({
        title: dataKey && formatDate(dataKey),
        dataIndex: dataKey,
        align: 'center',
        key: 'dataKey',
      }))
      .filter((_, idx, arr) => (isUiHidden ? idx > arr.length - 6 : true)),
  ];

  return (
    <Table
      dataSource={vehicles}
      columns={numberOfVehiclesColumns}
      scroll={isUiHidden ? false : { x: 'max-content' }}
      pagination={false}
      className="ant-table--dark"
      expandIcon={props => CustomExpandIcon(props)}
      rowKey={record => record.rowKey}
      expandable={{
        expandedRowRender: record => (
          <Table
            dataSource={record?.nested}
            columns={numberOfVehiclesColumns}
            pagination={false}
            className="ant-table--nested"
            rowKey={record => record.rowKey}
          />
        ),
        rowExpandable: record => record.nested.length > 0,
        defaultExpandAllRows: isUiHidden,
      }}
    />
  );
};

export default NumberOfVehiclesTable;
