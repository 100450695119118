import { format, isDate, isValid } from 'date-fns';
import lt from 'date-fns/locale/lt';
import en from 'date-fns/locale/en-GB';
import useCurrentLanguage from 'hooks/user/useCurrentLanguage';

const locales = { 'lt-LT': lt, 'en-US': en };

const useFormatDate = () => {
  const lang = useCurrentLanguage();
  const locale = locales[lang];

  return (date, dateFormat = DATE_FORMAT_LONG) => {
    const formattedDate = isValid(new Date(date)) ? new Date(date) : undefined;

    if (!formattedDate) return null;

    if (!isNaN(formattedDate.getTime())) {
      return format(formattedDate, dateFormat, { locale });
    } else return null;
  };
};

export const DATE_FORMAT_LONG = 'P';
export const DATE_FORMAT_YEAR_ONLY = 'yyyy';
export const DATE_FORMAT_TIME_ONLY = 'p';
export const DATE_FORMAT_MONTH_ONLY = 'MM';

export default useFormatDate;
