import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ReportBlockHeader from 'components/reusable/report/ReportBlockHeader';
import ReportsTable from 'components/reusable/report/tables/ReportsTable';
import { Button, Card, Col, Row } from 'antd';
import { ReactComponent as ComposedChart } from 'assets/img/icons/composedChart.svg';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import RestrictedAccess from '../../../components/RestrictedAccess';
import { setProductCode } from '../../../store/entities/startReport';
import { basicInfo } from '../../../store/entities/startReport/selectors/basicDataSelectors';

const Reports = ({ statistics }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { childNumberOfQueriesPending, pdfReport } = useSelector(state => state.startReport);
  const { companyStatisticsBySectors } = useSelector(basicInfo);

  const numberOfReports = useMemo(
    () => [
      {
        sectorTitle: t('startReport.total.reports'),
        lastMonth: statistics.lastMonth,
        lastQuarter: statistics.lastQuarter,
        lastHalfYear: statistics.lastHalfYear,
      },
    ],
    [statistics, t]
  );

  return (
    <div className="start-report reports">
      <LoadingSpinner spinning={childNumberOfQueriesPending}>
        <ReportBlockHeader
          icon={<ComposedChart className="icon--gray" />}
          title={t('startReport.basic.reports.title')}
        />
        {statistics && (
          <>
            {companyStatisticsBySectors ? (
              <ReportsTable reports={companyStatisticsBySectors} />
            ) : (
              <>
                <ReportsTable reports={numberOfReports} />
                {pdfReport ? (
                  <Row>
                    <Card className="card-transparent space-vertical-sm">
                      {t('startReport.basic.vehicles.pdf.notBought')}
                    </Card>
                  </Row>
                ) : (
                  <RestrictedAccess permission="/product/subjectsearchescount/">
                    <Row>
                      <Col xs={24} sm={22} md={20} lg={18} style={{ maxWidth: 420 }}>
                        <Button onClick={() => dispatch(setProductCode('SubjectSearchesCount'))}>
                          {t('startReport.basic.buy.report.button')}
                        </Button>
                      </Col>
                    </Row>
                  </RestrictedAccess>
                )}
              </>
            )}
          </>
        )}
      </LoadingSpinner>
    </div>
  );
};

export default Reports;
