import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { b64toBlob, downloadAsFile } from '../../../../utils/download';
import {
  usePdfFilename,
  usePrescorePdfFilename,
  useSpiderWebReportPdfFilename,
  useStartReportPdfFilename,
} from '../../../../hooks/report/professionalReport/usePdfFilename';

export const DownloadPdfBtn = ({ fetcher, reportType, text, ...rest }) => {
  const { t } = useTranslation();
  const profReportFilename = usePdfFilename();
  const prescoreReportFilename = usePrescorePdfFilename();
  const startReportFilename = useStartReportPdfFilename();
  const spiderWebReportFilename = useSpiderWebReportPdfFilename();

  const getPdfFilename = reportType => {
    switch (reportType) {
      case 'professional':
        return profReportFilename;
      case 'start':
        return startReportFilename;
      case 'prescore':
        return prescoreReportFilename;
      case 'spiderWeb':
        return spiderWebReportFilename;
    }
  };

  const handlePdfDownload = async () => {
    const response = await fetcher();

    const data = b64toBlob(response.payload.data);
    downloadAsFile(data, getPdfFilename(reportType));
  };

  return (
    <Button onClick={handlePdfDownload} {...rest}>
      {t(text ? text : 'global.print')}
    </Button>
  );
};
