export const ArrowMarker = () => (
  <svg
    style={{
      width: 0,
      height: 0,
    }}
  >
    <defs>
      <marker
        id="arrow-end"
        markerWidth="22"
        markerHeight="22"
        refX="18"
        refY="12"
        orient="auto"
        viewBox="0 0 24 24"
        markerUnits="strokeWidth"
      >
        <path
          d="M18.78 11.37l-4.78-6a1 1 0 0 0-1.41-.15 1 1 0 0 0-.15 1.41L16.71 12l-4.48 5.37a1 1 0 0 0 .13 1.41A1 1 0 0 0 13 19a1 1 0 0 0 .77-.36l5-6a1 1 0 0 0 .01-1.27z"
          className="arrow"
        ></path>
      </marker>
    </defs>
  </svg>
);

export const ArrowStartMarker = () => (
  <svg
    style={{
      width: 0,
      height: 0,
    }}
  >
    <defs>
      <marker
        id="arrow-start"
        markerWidth="22"
        markerHeight="22"
        refX="19"
        refY="12"
        orient="auto-start-reverse"
        viewBox="0 0 24 24"
        markerUnits="strokeWidth"
      >
        <path
          d="M18.78 11.37l-4.78-6a1 1 0 0 0-1.41-.15 1 1 0 0 0-.15 1.41L16.71 12l-4.48 5.37a1 1 0 0 0 .13 1.41A1 1 0 0 0 13 19a1 1 0 0 0 .77-.36l5-6a1 1 0 0 0 .01-1.27z"
          className="arrow"
        ></path>
      </marker>
    </defs>
  </svg>
);
