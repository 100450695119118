import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { getOrderId } from 'store/entities/prescoreReport/thunks';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import { setIsUiHidden } from 'store/entities/ui';
import Prescore from './Prescore';
import Basic from './Basic';
import Interests from './Interests';
import { getBasicData } from '../../../store/entities/prescoreReport/thunks';
import useIsPrescoreReportLoading from '../../../hooks/report/usePrescoreReportState';
import useCurrentLanguage from '../../../hooks/user/useCurrentLanguage';
import PdfHeader from '../../Pdfs/PdfHeader';
import PdfFooterDisclaimer from '../../Pdfs/PdfFooterDisclaimer';
import CompanyDataBlock from '../../Search/CompanySearch/CompanyDataBlock';
import { getSelectedCompanyData } from '../../../store/entities/company/thunks';

const PrescoreReportPdf = () => {
  const { companyId } = useParams();
  const lang = useCurrentLanguage();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { orderId, basicData } = useSelector(state => state.prescoreReport);
  const { selectedCompanyData } = useSelector(state => state.company);
  const { isLoading, hasError } = useIsPrescoreReportLoading();

  useEffect(() => {
    companyId && dispatch(getSelectedCompanyData(companyId));
  }, [companyId, lang]);

  useEffect(() => {
    dispatch(setIsUiHidden(true));

    return () => {
      dispatch(setIsUiHidden(false));
    };
  }, []);

  useEffect(() => {
    (async () => {
      const currentOrderId = await dispatch(getOrderId(companyId));

      if ((currentOrderId.payload.id && basicData && lang !== basicData.lang) || !basicData) {
        dispatch(getBasicData(currentOrderId.payload.id));
      }
    })();
  }, []);

  return (
    <LoadingSpinner size="large" className="loader-overlay" spinning={isLoading}>
      {hasError && <Redirect to="/search/company" exact />}
      <div className="pdf-wrapper">
        <PdfHeader title={basicData?.basicInformation?.companyName} subtitle={t('header.breadcrumb.prescore-report')} />
        {orderId && basicData && selectedCompanyData && (
          <>
            <CompanyDataBlock companyData={selectedCompanyData} enablePdf />
            <Prescore pdfReport />
            <Basic />
            <Interests />
          </>
        )}
        <PdfFooterDisclaimer />
      </div>
    </LoadingSpinner>
  );
};

export default PrescoreReportPdf;
