import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';

import { basicReports } from 'store/entities/professionalReport/selectors/basicDataSelectors';
import { setActiveTab } from 'store/entities/professionalReport';
import { sumArrayByAttribute } from 'utils/data';

import { Col, Row, Divider, Space } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import ReportsTable from 'components/reusable/report/tables/ReportsTable';
import { BlockHeader } from '../../../components/reusable/BlockHeader';

const Reports = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { statisticsBySector } = useSelector(basicReports);

  const numberOfReportsDataSource = useMemo(
    () => [
      {
        sectorTitle: t('professionalReport.basicData.reports.total'),
        lastMonth: sumArrayByAttribute(statisticsBySector, 'lastMonth'),
        lastQuarter: sumArrayByAttribute(statisticsBySector, 'lastQuarter'),
        lastHalfYear: sumArrayByAttribute(statisticsBySector, 'lastHalfYear'),
        nested: statisticsBySector,
      },
    ],
    []
  );

  return (
    <div className="no-break">
      <Row id="reports" justify="start">
        <Col>
          <BlockHeader icon={<FileOutlined />} title={t('professionalReport.basicData.reports.title')} />
        </Col>
      </Row>
      <Row className="space-bottom-sm" id="reports-table">
        <Col xs={24} md={24}>
          <VisibilitySensor
            offset={{ bottom: 50 }}
            scrollCheck
            intervalDelay={10}
            onChange={isVisible => isVisible && dispatch(setActiveTab('reports'))}
            partialVisibility
            minTopValue={200}
          >
            <ReportsTable reports={numberOfReportsDataSource} />
          </VisibilitySensor>
        </Col>
      </Row>
    </div>
  );
};

export default Reports;
