import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useCurrentLanguage from 'hooks/user/useCurrentLanguage';
import { getBasicData } from 'store/entities/startReport/thunks';

import { Row, Col, Skeleton } from 'antd';
import Basic from './Basic';

const BasicData = () => {
  const dispatch = useDispatch();
  const lang = useCurrentLanguage();

  const { orderId, basicData, basicDataPending, basicDataError } = useSelector(state => state.startReport);

  useEffect(() => {
    if ((orderId && basicData && lang !== basicData?.lang) || (!basicData && !basicDataError))
      dispatch(getBasicData(orderId));
  }, [lang]);

  return (
    <Row>
      <Col span={24}>{basicDataPending ? <Skeleton /> : <>{basicData && <Basic />}</>}</Col>
    </Row>
  );
};

export default BasicData;
