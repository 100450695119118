import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row, /*Space,*/ Typography } from 'antd';
import ChangePasswordForm from './ChangePasswordForm';
import ChangeSettingsForm from './ChangeSettingsForm';

const { Title } = Typography;

const CompanySearch = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <Col span={24}>
        <Row gutter={[16, 16]} justify="space-between">
          <Col xs={24} md={24} lg={10}>
            <Title level={2}>{t('profile.settings.title')}</Title>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={24} lg={12}>
            <ChangePasswordForm />
          </Col>
          <Col xs={24} md={24} lg={12}>
            <ChangeSettingsForm />
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default CompanySearch;
