import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18n';
import {
  getFinancialOrderId,
  getBasicData,
  getFinancialStatementPdf,
  getCompanyFinReportPerYearThunk,
} from './thunks.js';
import { getCompanyFinStatDataUnregisteredThunk } from '../company/thunks';

const initialState = {
  statementId: null,
  orderId: null,
  orderIdPending: false,
  orderIdError: false,
  orderDetails: null,
  basicData: null,
  basicDataPending: false,
  basicDataError: false,
  financialStatementPdfPending: false,
  companyYearFinReport: null,
  companyYearFinReportPending: false,
  companyYearFinReportError: false,
};

const slice = createSlice({
  name: 'companyFinancialReport',
  initialState: initialState,
  reducers: {
    setStatementId: (state, { payload }) => {
      state.statementId = payload;
    },
    resetCompanyFinancialReport: () => initialState,
  },
  extraReducers: {
    [getFinancialOrderId.fulfilled]: (state, { payload }) => {
      state.orderId = payload.id;
      state.orderIdPending = false;
      state.orderIdError = false;
    },
    [getFinancialOrderId.pending]: state => {
      state.orderIdPending = true;
      state.orderIdError = false;
    },
    [getFinancialOrderId.rejected]: state => {
      state.orderIdPending = false;
      state.orderIdError = true;
    },
    [getBasicData.fulfilled]: (state, { payload }) => {
      state.basicData = { ...payload, lang: i18n.language };
      state.basicDataPending = false;
      state.basicDataError = false;
    },
    [getBasicData.pending]: state => {
      state.basicDataPending = true;
      state.basicDataError = false;
    },
    [getBasicData.rejected]: state => {
      state.basicDataPending = false;
      state.basicDataError = true;
    },
    [getCompanyFinReportPerYearThunk.fulfilled]: (state, { payload }) => {
      state.companyYearFinReport = payload;
      state.companyYearFinReportPending = false;
      state.companyYearFinReportError = false;
    },
    [getCompanyFinReportPerYearThunk.pending]: state => {
      state.companyYearFinReportPending = true;
      state.companyYearFinReportError = false;
    },
    [getCompanyFinReportPerYearThunk.rejected]: state => {
      state.companyYearFinReportPending = false;
      state.companyYearFinReportError = true;
    },
    [getCompanyFinStatDataUnregisteredThunk.fulfilled]: (state, { payload }) => {
      state.basicData = { basicInformation: payload, lang: i18n.language };
      state.basicDataPending = false;
      state.basicDataError = false;
    },
    [getCompanyFinStatDataUnregisteredThunk.pending]: state => {
      state.basicDataPending = true;
      state.basicDataError = false;
    },
    [getCompanyFinStatDataUnregisteredThunk.rejected]: state => {
      state.basicDataPending = false;
      state.basicDataError = true;
    },
    [getFinancialStatementPdf.fulfilled]: state => {
      state.financialStatementPdfPending = false;
    },
    [getFinancialStatementPdf.pending]: state => {
      state.financialStatementPdfPending = true;
    },
    [getFinancialStatementPdf.rejected]: state => {
      state.financialStatementPdfPending = false;
    },
  },
});

export const { setStatementId, resetCompanyFinancialReport } = slice.actions;

export default slice.reducer;
