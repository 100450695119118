import { createAsyncThunk } from '@reduxjs/toolkit';
import companyService from 'api/companyService';
import districtService from 'api/districtService';

export const getDistricts = createAsyncThunk('company/getDistricts', async (_, { rejectWithValue }) => {
  try {
    const response = await districtService.getDistricts();
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getCompanies = createAsyncThunk('company/getCompanies', async (filters, { rejectWithValue }) => {
  try {
    const response = await companyService.getCompanies(filters);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getCompanyFinStatDataUnregisteredThunk = createAsyncThunk(
  'company/dataAvailability/financialStatements',
  async (companyCode, { rejectWithValue }) => {
    try {
      return await companyService.getCompanyFinStatDataUnregistered(companyCode);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCompaniesUnregistered = createAsyncThunk(
  'company/getCompaniesUnregistered',
  async (data, { rejectWithValue }) => {
    try {
      const response = await companyService.getCompaniesUnregistered(data);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getSelectedCompanyData = createAsyncThunk(
  'company/getSelectedCompanyData',
  async (companyCode, { rejectWithValue }) => {
    try {
      const response = await companyService.getCompanyBasicData(companyCode);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getSelectedCompanyOtherData = createAsyncThunk(
  'company/getSelectedCompanyOtherData',
  async (companyCode, { rejectWithValue }) => {
    try {
      const response = await companyService.getCompanyOtherInformation(companyCode);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
