import React from 'react';
import { useTranslation } from 'react-i18next';

import CompanyDataCard, { CompanyDataRow } from 'components/reusable/CompanyDataCard';
import useFormatDate from 'hooks/format/useFormatDate';

import { UserOutlined } from '@ant-design/icons';

const Procurist = ({ prokurist }) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();

  return (
    <CompanyDataCard
      icon={<UserOutlined className="icon--gray" />}
      title={t('professionalReport.basicData.procurist.title')}
    >
      <CompanyDataRow type={t('professionalReport.basicData.procurist.name')} value={prokurist.name} />

      <CompanyDataRow
        type={t('professionalReport.basicData.procurist.dateFrom')}
        value={prokurist.dateFrom ? formatDate(prokurist.dateFrom) : '-'}
      />
    </CompanyDataCard>
  );
};

export default Procurist;
