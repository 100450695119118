import React from 'react';
import { Col, Row } from 'antd';
import RatingCard from 'views/ProfessionalReport/CompanyRatings/RatingCard';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import { setProductCode } from '../../../store/entities/startReport';
import { summaryRatings } from '../../../store/entities/startReport/selectors/companyRatingsSelectors';
import ScoringHistoryGraph from 'components/reusable/graphs/ScoringHistoryGraph';
import colors from 'constants/colors';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';

const Ratings = () => {
  const { childBankruptcyRatingPending, childDefaultRatingPending, summaryPending, pdfReport } = useSelector(
    state => state.startReport
  );
  const dispatch = useDispatch();
  const isUiHidden = useIsUiHidden();
  const { bankruptcyRatingSummary, defaultRatingSummary } = useSelector(summaryRatings);

  return (
    <LoadingSpinner spinning={childDefaultRatingPending || summaryPending || childBankruptcyRatingPending}>
      <Row gutter={isUiHidden ? [3] : [16, 16]} className="ant-row-pdf-gap">
        <Col className="flex no-break" sm={24} md={24} lg={24} xxl={24}>
          <RatingCard
            type="defaultRating"
            rating={defaultRatingSummary?.defaultScore}
            positiveFactors={defaultRatingSummary?.defaultScorePositiveAndNegativeFactors?.positiveFactorsReasonCodes}
            negativeFactors={defaultRatingSummary?.defaultScorePositiveAndNegativeFactors?.negativeFactorsReasonCodes}
            isEvaluatedByExpert={!!defaultRatingSummary?.defaultScore?.expertRatingDate}
            setProductCode={() => dispatch(setProductCode('DefaultRating'))}
            userPermission="/product/defaultrating/"
            pdfReport={pdfReport}
          >
            {defaultRatingSummary?.defaultScoreHistory?.length ? (
              <ScoringHistoryGraph
                type="defaultRating"
                series={[
                  {
                    type: 'line',
                    dataKey: 'sector',
                    stroke: colors.COLOR_GRAY,
                  },
                  {
                    type: 'line',
                    dataKey: 'company',
                    stroke: colors.COLOR_ERROR,
                  },
                ]}
                data={[...(defaultRatingSummary?.defaultScoreHistory || []), defaultRatingSummary?.defaultScore]}
                riskClasses={defaultRatingSummary?.defaultScoreDistributionsBySector}
                lazyLoaded
                className="no-break"
              />
            ) : null}
          </RatingCard>
        </Col>
        <Col className="flex no-break" sm={24} md={24} lg={24} xxl={24}>
          <RatingCard
            type="bankruptcyRating"
            rating={bankruptcyRatingSummary?.bankruptcyScore}
            positiveFactors={
              bankruptcyRatingSummary?.bankruptcyScorePositiveAndNegativeFactors?.positiveFactorsReasonCodes
            }
            negativeFactors={
              bankruptcyRatingSummary?.bankruptcyScorePositiveAndNegativeFactors?.negativeFactorsReasonCodes
            }
            isEvaluatedByExpert={!!bankruptcyRatingSummary?.bankruptcyScore?.expertRatingDate}
            setProductCode={() => dispatch(setProductCode('BankruptcyRating'))}
            userPermission="/product/bankruptcyrating/"
            pdfReport={pdfReport}
          >
            {bankruptcyRatingSummary?.bankruptcyScoreHistory?.length ? (
              <ScoringHistoryGraph
                type="bankruptcyRating"
                series={[
                  {
                    type: 'line',
                    dataKey: 'sector',
                    stroke: colors.COLOR_GRAY,
                  },
                  {
                    type: 'line',
                    dataKey: 'company',
                    stroke: colors.COLOR_ERROR,
                  },
                ]}
                data={[
                  ...(bankruptcyRatingSummary?.bankruptcyScoreHistory || []),
                  bankruptcyRatingSummary?.bankruptcyScore,
                ]}
                riskClasses={bankruptcyRatingSummary?.bankruptcyScoreDistributionsBySector}
                lazyLoaded
                className="no-break"
              />
            ) : null}
          </RatingCard>
        </Col>
      </Row>
    </LoadingSpinner>
  );
};

export default Ratings;
