import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { updateContacts } from 'store/entities/startReport/thunks';
import { showSuccessNotification } from 'utils/notification';

import { Form, Input, Button, Row, Col, Typography, Space } from 'antd';
import Modal from 'components/reusable/Modal';
import LoadingSpinner from 'components/reusable/LoadingSpinner';

import { ReactComponent as Close } from 'assets/img/icons/close.svg';

const { Title } = Typography;

const UpdateContactInfo = ({ modalOpen, setModalOpen, initialValues }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { updateContactsPending } = useSelector(state => state.startReport);

  const onFinish = useCallback(
    async newContacts => {
      const submitResult = await dispatch(updateContacts(newContacts));

      if (updateContacts.fulfilled.match(submitResult)) {
        showSuccessNotification('Contacts update request sent!');
        setModalOpen(false);
      }
    },
    [dispatch, updateContacts, showSuccessNotification, setModalOpen]
  );

  useEffect(() => {
    form.setFieldsValue(modalOpen ? initialValues : {});
  }, [modalOpen]);

  return (
    <Modal isOpen={modalOpen} setIsOpen={setModalOpen} closable={!updateContactsPending} getContainer={false}>
      <LoadingSpinner size="large" spinning={updateContactsPending}>
        <div className="container">
          <Row>
            <Col span={20}>
              <Title className="space-vertical-xl">
                {t('professionalReport.basicData.contactInformation.update.title')}
              </Title>
            </Col>
            <Col span={4} className="justify-content-end">
              <span className="icon-btn space-top-md" role="button" onClick={() => setModalOpen(false)}>
                <Close />
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={24} lg={18} xl={14}>
              {modalOpen && (
                <Form form={form} name="profReportUpdateContacts" onFinish={onFinish} labelCol={{ span: 24 }}>
                  <Row gutter={[32]}>
                    <Col md={24} lg={12}>
                      <Form.Item label={t('professionalReport.basicData.contactInformation.phone')} name="phone">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={24} lg={12}>
                      <Form.Item label={t('professionalReport.basicData.contactInformation.mobile')} name="mobile">
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[32]}>
                    <Col md={24} lg={12}>
                      <Form.Item label={t('professionalReport.basicData.contactInformation.uri')} name="uri">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={24} lg={12}>
                      <Form.Item label={t('professionalReport.basicData.contactInformation.email')} name="email">
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[32]}>
                    <Col md={24} lg={12}>
                      <Form.Item label={t('professionalReport.basicData.contactInformation.iban')} name="iban">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={24} lg={12}>
                      <Form.Item label={t('professionalReport.basicData.contactInformation.bank')} name="bank">
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[32]}>
                    <Col md={24} lg={12}>
                      <Form.Item
                        label={t('professionalReport.basicData.contactInformation.visitAddress')}
                        name="visitAddress"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item wrapperCol={{ span: 24 }}>
                    <Space>
                      <Button htmlType="button" onClick={() => setModalOpen(false)}>
                        {t('professionalReport.basicData.contactInformation.update.button.cancel')}
                      </Button>
                      <Button className="btn-secondary" htmlType="submit">
                        {t('professionalReport.basicData.contactInformation.update.button.send')}
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              )}
            </Col>
          </Row>
        </div>
      </LoadingSpinner>
    </Modal>
  );
};

export default UpdateContactInfo;
