import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Menu, Drawer, Button, Row, Col, Layout } from 'antd';
import {
  CloseOutlined,
  MenuOutlined,
  LeftOutlined,
  RightOutlined,
  GlobalOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { ReactComponent as CiLogo } from 'assets/img/ci-logo-on-white.svg';
import { setIsUiHidden } from 'store/entities/ui';
import useWindowSize from '../../../hooks/dom/useWindowSize';
import { LogoLink } from '../../../components/reusable/LogoLink';
import useRedirect from 'hooks/navigation/useRedirect';
import cx from 'classnames';

const { Sider, Content } = Layout;

const UnauthenticatedWrapper = ({ children }) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(true);
  const { width: windowWidth } = useWindowSize();
  const redirect = useRedirect();
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 80) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    dispatch(setIsUiHidden(true));
    return () => {
      dispatch(setIsUiHidden(false));
    };
  }, []);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const largeScreen = windowWidth > 991;

  const logoClassName = cx('logo', {
    'logo--collapsed': collapsed,
  });

  return (
    <Layout>
      {largeScreen ? (
        <Sider
          collapsible
          collapsed={collapsed}
          width={320}
          collapsedWidth={105}
          theme="light"
          style={{ overflow: 'auto', height: '100vh', position: 'sticky', left: 0, top: 0, bottom: 0 }}
          trigger={
            <>
              {largeScreen && (
                <span role="button" onClick={() => handleCollapse()}>
                  {!collapsed ? <LeftOutlined /> : <RightOutlined />}
                </span>
              )}
            </>
          }
          className="border-free-drawer"
        >
          <LogoLink content={<div className={logoClassName}></div>} />
          <Menu mode="inline" selectedKeys={[]} className="unauthenticated-menu">
            <Menu.Item
              key="about"
              style={{ paddingLeft: largeScreen && collapsed ? 36 : 16 }}
              icon={<SearchOutlined className="icon--lg" />}
              title={null}
            >
              <a href="/search" rel="noopener noreferrer">
                <span>{t('sidebar.label.search')}</span>
              </a>
            </Menu.Item>
            <Menu.SubMenu
              key="languageSubMenu"
              title={t('global.language')}
              icon={<GlobalOutlined className="icon--lg" />}
            >
              <Menu.Item
                className="selectable"
                key="langEN"
                onClick={() => {
                  i18n.changeLanguage('en-US');
                  handleCollapse();
                }}
              >
                {t('global.ENG')}
              </Menu.Item>
              <Menu.Item
                className="selectable"
                key="langLT"
                onClick={() => {
                  i18n.changeLanguage('lt-LT');
                  handleCollapse();
                }}
              >
                {t('global.LTU')}
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
          <Col span={24} className="justify-content-center space-vertical-md">
            <Button className="login-btn" onClick={() => redirect('/')}>
              {t('login.login')}
            </Button>
          </Col>
        </Sider>
      ) : (
        <Drawer
          title={
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>Menu</span>
              <Button type="text" icon={<CloseOutlined />} onClick={handleCollapse} />
            </div>
          }
          placement="left"
          closable={false}
          onClose={handleCollapse}
          visible={!collapsed}
          width={largeScreen ? 345 : windowWidth}
          className="border-free-drawer"
          getContainer={false}
        >
          <Menu mode="inline" onClick={handleCollapse} selectedKeys={[]} className="unauthenticated-menu">
            <Menu.Item key="about" style={{ paddingLeft: largeScreen && collapsed ? 36 : 16 }}>
              <a href="/search" rel="noopener noreferrer">
                <span>{t('sidebar.label.search')}</span>
              </a>
            </Menu.Item>
            <Menu.SubMenu key="languageSubMenu" title={t('global.language')}>
              <Menu.Item
                className="selectable"
                key="langEN"
                onClick={() => {
                  i18n.changeLanguage('en-US');
                  handleCollapse();
                }}
              >
                {t('global.ENG')}
              </Menu.Item>
              <Menu.Item
                className="selectable"
                key="langLT"
                onClick={() => {
                  i18n.changeLanguage('lt-LT');
                  handleCollapse();
                }}
              >
                {t('global.LTU')}
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
          <Col span={24} className="justify-content-center space-vertical-md">
            <Button className="login-btn" onClick={() => redirect('/')}>
              {t('login.login')}
            </Button>
          </Col>
        </Drawer>
      )}
      <Content className="unauthenticated-content">
        {collapsed && !largeScreen && (
          <Row className={isSticky ? 'sticky-navbar' : 'navbar'}>
            <Col span={18}>
              <div>
                <CiLogo style={{ maxHeight: 70 }} onClick={() => redirect('/')} className="cursor-pointer" />
              </div>
            </Col>
            <Col span={6} className="justify-content-end align-items-center">
              <a
                href="/search"
                rel="noopener noreferrer"
                style={{ fontSize: 24, marginRight: 20, color: 'var(--color-secondary)' }}
              >
                <SearchOutlined />
              </a>
              <MenuOutlined style={{ fontSize: 24 }} onClick={() => handleCollapse()} />
            </Col>
          </Row>
        )}
        {children}
      </Content>
    </Layout>
  );
};

export default UnauthenticatedWrapper;
