import React, { useMemo, useCallback, useState } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { useTranslation } from 'react-i18next';

import useResponsiveContainer from 'hooks/graph/useResponsiveContainer';
import sections from './sections';
import colors from 'constants/colors';
import { personalRatingPercentages } from 'constants/rating';
import personalScoreClasses from 'constants/personalScoreClasses';

import ScoreIndicator from '../CreditScoreGraph/ScoreIndicator';
import classColors from 'components/reusable/graphs/PersonalScoreGraph/classColors';

const PersonalScore = ({ scoreClass = 'A', percentage = 0.87 }) => {
  const { t } = useTranslation();
  const [{ width, height }, setContainerNode] = useResponsiveContainer(220);
  const [activeIndex, setActiveIndex] = useState(null);
  const callbackRef = useCallback(
    node => {
      setContainerNode(node);
    },
    [setContainerNode]
  );
  const mainScoreClass = scoreClass.split('')[0];
  const scoreIndex = personalScoreClasses[mainScoreClass];

  const graphSections = useMemo(() => {
    const percentages = personalRatingPercentages;

    return sections.map(section => ({
      ...section,
      tooltipBorder: classColors[mainScoreClass],
      percentages: percentages.find(({ score }) => score === section.score).percentages,
    }));
  }, [mainScoreClass]);

  const data = useMemo(
    () =>
      graphSections.map(({ score, active, ...otherAttributes }) => ({
        active: personalScoreClasses[mainScoreClass] === score,
        score,
        ...otherAttributes,
      })),
    [mainScoreClass]
  );

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const onPieLeave = () => setActiveIndex(null);

  return (
    <div className="chart" ref={callbackRef}>
      <PieChart width={width} height={160}>
        <text x={width / 2} y={height / 2.3} textAnchor="middle" dominantBaseline="middle" fontSize="20px">
          {`${percentage.toLocaleString()}%`}
        </text>
        <Pie
          activeIndex={activeIndex}
          cy="80%"
          data={data}
          startAngle={180}
          endAngle={0}
          innerRadius={100}
          outerRadius={110}
          dataKey="value"
          activeShape={<ScoreIndicator isPersonalScore personalScoreClass={mainScoreClass} />}
          label={<ScoreIndicator isPersonalScore personalScoreClass={mainScoreClass} isLabel />}
          labelLine={false}
          isAnimationActive={false}
          onMouseEnter={onPieEnter}
          onMouseLeave={onPieLeave}
        >
          {graphSections.map((entry, index) => {
            return (
              <Cell
                key={`cell-${index}`}
                fill={index < scoreIndex ? classColors[mainScoreClass] : colors.COLOR_LIGHTER_GRAY}
                stroke=""
              />
            );
          })}
        </Pie>

        <text x={width / 2} y={height / 1.85} textAnchor="middle" dominantBaseline="middle">
          {t('global.probability')}
        </text>
      </PieChart>
    </div>
  );
};

export default PersonalScore;
