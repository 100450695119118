import React from 'react';
import CompanyDataCard, { CompanyDataRow } from 'components/reusable/CompanyDataCard';
import { ReactComponent as History } from 'assets/img/icons/history.svg';
import { useTranslation } from 'react-i18next';
import useFormatDate from 'hooks/format/useFormatDate';
import { Button, Row, Card } from 'antd';

const PaymentHistoryCard = ({
  paymentHistoryData,
  showNoEntries = false,
  buyReportButton = false,
  historyPurchesed,
  children,
}) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();

  return (
    <CompanyDataCard
      icon={<History className="icon--gray" />}
      title={t('professionalReport.negativeData.paymentHistory.title')}
    >
      {!paymentHistoryData?.total ? (
        <Row>
          <Card className="card-transparent space-top-md">
            {t('professionalReport.negativeData.paymentHistory.noInformation')}
          </Card>
        </Row>
      ) : (
        <>
          <CompanyDataRow
            type={t('professionalReport.negativeData.paymentHistory.numberOfPayments')}
            value={paymentHistoryData?.total || '-'}
          />
          <CompanyDataRow
            type={t('professionalReport.negativeData.paymentHistory.latestDebtCoveringDate')}
            value={paymentHistoryData?.latestDate ? formatDate(paymentHistoryData?.latestDate) : '-'}
          />
          <CompanyDataRow
            type={t('professionalReport.negativeData.paymentHistory.averagePaymentTerm')}
            value={
              paymentHistoryData?.averageDays ? `${paymentHistoryData.averageDays} ${t('global.daysInShort')}` : '-'
            }
          />
          {buyReportButton ? (
            <Button className="buy-report" onClick={historyPurchesed}>
              {t('startReport.negativeData.buy.report.button')}
            </Button>
          ) : null}
        </>
      )}
      {children}
    </CompanyDataCard>
  );
};

export default PaymentHistoryCard;
