import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getReportStatistics } from 'store/entities/footer/thunks';
import { DATE_FORMAT_TIME_ONLY } from 'hooks/format/useFormatDate';
import useCurrentLanguage from 'hooks/user/useCurrentLanguage';
import colors from 'constants/colors';

import ComposedChart from 'components/reusable/graphs/ComposedChart';

import { Card, Col, Row } from 'antd';

import protectedDataLt from 'assets/img/protectedDataLt.svg';
import protectedDataEn from 'assets/img/protectedDataEn.svg';

const ReportStatisticsCard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lang = useCurrentLanguage();

  const { totalReports, numberOfReportsChart } = useSelector(state => state.footer);

  useEffect(() => {
    dispatch(getReportStatistics());
  }, []);

  return (
    <Row>
      <Card
        className="card-simple card-simple-padding-none card-statistics space-vertical-lg has-shadow"
        style={{ width: '100%' }}
      >
        <Row justify="center" align="middle" gutter={[16, 16]}>
          <Col md={12} lg={6} xl={8}>
            <Row justify="center">
              <img src={lang === 'lt-LT' ? protectedDataLt : protectedDataEn} alt="slogan"></img>
            </Row>
          </Col>
          <Col md={12} lg={6} xl={8}>
            <div className={`ant-statistic ant-statistic-sm`}>
              <div className="ant-statistic-title justify-content-center">{t('footer.createdReports')}</div>
              <div className="ant-statistic-content">
                <span className="ant-statistic-content-value">
                  <span className="ant-statistic-content-value-int">{totalReports ?? '-'}</span>
                </span>
              </div>
            </div>
          </Col>
          <Col md={24} lg={10} xl={8}>
            <ComposedChart
              id="createdReports"
              series={[
                {
                  type: 'line',
                  dataKey: 'reports',
                  stroke: colors.COLOR_INFO,
                  isAnimationActive: false,
                  strokeWidth: '4',
                  dot: { r: 4 },
                },
              ]}
              data={numberOfReportsChart}
              xAxisDataKey="time"
              minHeight={150}
              showLegend={false}
              xAxisFormat={DATE_FORMAT_TIME_ONLY}
              yAxisLabel={{
                value: t('graph.createdReports.dataKey.reports').toUpperCase(),
                angle: -90,
                position: 'insideTopRight',
                offset: 20,
              }}
              drawYAxisTicks={false}
              tooltipLabelFormat={DATE_FORMAT_TIME_ONLY}
            />
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default ReportStatisticsCard;
