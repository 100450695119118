import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { b64toBlob, downloadAsFile } from '../../../../utils/download';
import useFormatDate from '../../../../hooks/format/useFormatDate';
import sanitize from 'sanitize-filename';

export const DownloadExcel = ({ fetcher, fileName, fileDate, text, ...rest }) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();

  const getExcelFileName = name => {
    const date = fileDate ? fileDate : formatDate(new Date()).replaceAll('/', '-');
    const productName = t(name);
    const fileName = `${productName} ${date}.xlsx`;

    return sanitize(fileName);
  };

  const handleExcelDownload = async () => {
    const response = await fetcher();

    const data = b64toBlob(response.payload.data);
    downloadAsFile(data, getExcelFileName(fileName));
  };

  return (
    <Button onClick={handleExcelDownload} {...rest}>
      {t(text ? text : 'global.print.excel')}
    </Button>
  );
};
