import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useCurrentLanguage from './hooks/user/useCurrentLanguage';
import { languages } from 'i18n';
import useAuthCheck from './hooks/auth/useAuthCheck';
import useIdleSession from './hooks/user/useIdleSession';

import { ConfigProvider } from 'antd';

import AppLayout from './components/Layout';
import Router from './components/Router';
import InactiveSessionModal from 'components/Modals/InactiveSessionModal';
import { init, RouteTracker } from 'utils/general/GoogleAnalytics';
import { initializeAmplitude, logPageView } from './utils/general/amplitudeService';
import * as amplitude from '@amplitude/analytics-browser';

const App = () => {
  const [isSessionInactiveModalOpen, setIsSessionInactiveModalOpen] = useState(false);
  const [initialTime, setInitialTime] = useState();
  const lang = useCurrentLanguage();
  const { hasSession, isAuthenticated } = useSelector(state => state.user);
  const location = useLocation();

  useEffect(() => {
    initializeAmplitude();
  }, []);

  useEffect(() => {
    const pagePath = location.pathname;
    const pageUrl = window.location.href;
    const queryParams = location.search || 'N/A';

    logPageView(pagePath, pageUrl, queryParams, lang, isAuthenticated);
  }, [window.location.href, lang, isAuthenticated]);

  useIdleSession({
    onIdle: () => {
      setInitialTime(new Date());
      if (!isSessionInactiveModalOpen && isAuthenticated) setIsSessionInactiveModalOpen(true);
    },
    valueToCancel: !hasSession,
  });

  useAuthCheck();

  const trackCustomEvent = (eventName, properties) => {
    amplitude.track(eventName, properties);
  };

  const handleClickEvent = event => {
    const target = event.target;
    let eventName = '';
    let eventProperties = {};

    if (
      target.matches('button') ||
      target.closest('button') ||
      target.matches('.ant-menu-item') ||
      target.closest('.ant-menu-item') ||
      target.matches('a') ||
      target.closest('a') ||
      target.matches('.ant-table-row-clickable') ||
      target.closest('.ant-table-row-clickable') ||
      target.matches('.anticon') ||
      target.closest('.anticon') ||
      target.matches('#report-row') ||
      target.closest('#report-row')
    ) {
      eventName = 'Button Clicked';
      eventProperties = {
        clickedElement: target.outerHTML,
        text: target.innerText || target.getAttribute('aria-label') || target.getAttribute('href') || 'Unnamed Button',
        href: target.getAttribute('href') || 'No href',
        pagePath: location.pathname,
        pageUrl: window.location.href,
        'logged in': isAuthenticated,
        language: lang,
      };
    } else if (target.matches('input[type="checkbox"]')) {
      eventName = 'Checkbox Toggled';
      eventProperties = {
        checked: target.checked,
        id: target.id || 'Unnamed Checkbox',
        clickedElement: target.outerHTML,
        pagePath: location.pathname,
        pageUrl: window.location.href,
        'logged in': isAuthenticated,
        language: lang,
      };
    } else if (target.matches('input') || target.closest('input')) {
      eventName = 'Input Clicked';
      eventProperties = {
        id: target.id || 'Unnamed Input',
        value: target.value || '',
        clickedElement: target.outerHTML,
        href: target.getAttribute('href') || 'No href',
        pagePath: location.pathname,
        pageUrl: window.location.href,
        'logged in': isAuthenticated,
        language: lang,
      };
    }

    // If a specific event was detected, log it with Amplitude
    if (eventName) {
      trackCustomEvent(eventName, eventProperties);
    }
  };

  useEffect(() => {
    // Attach the event listener
    document.addEventListener('click', handleClickEvent);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickEvent);
    };
  }, [location.pathname, isAuthenticated]);

  return (
    <>
      <ConfigProvider locale={languages.find(({ codeShort }) => codeShort === lang)?.antdLocale}>
        <AppLayout>
          {init() && <RouteTracker />}
          <Router />
        </AppLayout>
      </ConfigProvider>
      {hasSession && isSessionInactiveModalOpen && (
        <InactiveSessionModal initialTime={initialTime} onClose={() => setIsSessionInactiveModalOpen(false)} />
      )}
    </>
  );
};

export default App;
