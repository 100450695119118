import React from 'react';
import { useTranslation } from 'react-i18next';

import TableColumnDate from 'components/reusable/TableColumnDate';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';

import { Table } from 'antd';
import CustomExpandIcon from './CustomExpandIcon';

const PressReleasesTable = ({ pressReleases, type = 'regular' }) => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();

  const pressReleasesColumns = [
    {
      title: t('professionalReport.basicData.press.pressReleases.date'),
      dataIndex: 'publishDate',
      render: TableColumnDate,
      key: 'publishDate',
      width: 200,
    },
    {
      title: '',
      dataIndex: 'title',
      key: 'title',
    },
  ];

  return type === 'regular' ? (
    <Table
      rowKey={pressReleases.id}
      dataSource={pressReleases?.map((p, i) => ({
        ...p,
        key: 'pressRelease' + i,
      }))}
      columns={pressReleasesColumns}
      pagination={isUiHidden ? false : { defaultPageSize: 10, showSizeChanger: true }}
      className="ant-table--dark"
      expandIcon={props => CustomExpandIcon(props)}
      expandable={{
        expandedRowRender: record => (
          <div>
            <p>{record.description}</p>
            <p>
              {t('professionalReport.basicData.rekvizitaiContactInformation.uri')}:
              <a href={record.uri} target="_blank" rel="noopener noreferrer" style={{ marginLeft: 5 }}>
                {record.uri}
              </a>
            </p>
          </div>
        ),
        rowExpandable: record => record.description,
        defaultExpandAllRows: isUiHidden,
      }}
    />
  ) : (
    <Table
      rowKey={({ title, publishDate }) => `press-${title}-${publishDate}`}
      dataSource={pressReleases?.map((p, i) => ({
        ...p,
        key: 'pressReleasesVersloZinios' + i,
      }))}
      columns={pressReleasesColumns}
      pagination={isUiHidden ? false : { defaultPageSize: 10, showSizeChanger: true }}
      showSorterTooltip={false}
      className="ant-table--dark"
      expandIcon={props => CustomExpandIcon(props)}
      expandable={{
        expandedRowRender: record => (
          <div>
            <p>{record.description}</p>
            <p>
              {t('professionalReport.basicData.rekvizitaiContactInformation.uri')}:
              <a href={record.uri} target="_blank" rel="noopener noreferrer" style={{ marginLeft: 5 }}>
                {record.uri}
              </a>
            </p>
          </div>
        ),
        rowExpandable: record => record.description,
        defaultExpandAllRows: isUiHidden,
      }}
    />
  );
};

export default PressReleasesTable;
