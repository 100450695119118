import { Col, Input, Row, Table, Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const AdditionalFilters = () => {
  const { t } = useTranslation();

  const additionalColumns = [
    {
      title: t('webMonitoring.settings.additional'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '',
      dataIndex: 'info',
      key: 'info',
    },
    {
      title: '',
      dataIndex: 'input',
      key: 'input',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const additionalData = [
    {
      name: t('webMonitoring.settings.debt.notes'),
      info: t('webMonitoring.settings.enter.reg'),
      input: <Input />,
      action: <a>{t('global.insert')}</a>,
    },
    {
      name: t('webMonitoring.settings.debts.up.to'),
      info: t('webMonitoring.settings.sum'),
      input: <Input />,
      action: <a>{t('global.save')}</a>,
    },
    {
      name: t('webMonitoring.settings.debts.sodra'),
      info: t('webMonitoring.settings.sum'),
      input: <Input />,
      action: <a>{t('global.save')}</a>,
    },
  ];

  return (
    <Row className="additional-settings">
      <Col xs={24} md={24} lg={24} xxl={24}>
        <Table
          rowKey="id"
          dataSource={additionalData}
          columns={additionalColumns}
          scroll={false}
          pagination={false}
          showSorterTooltip={false}
        />
      </Col>
    </Row>
  );
};

export default AdditionalFilters;
