import React from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import routes from 'components/Router/routes';
import { Breadcrumb } from 'antd';

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(routes, { disableDefaults: true });

  return (
    <Breadcrumb.Separator>
      {breadcrumbs.length > 0 && breadcrumbs[breadcrumbs.length - 1]?.breadcrumb}
    </Breadcrumb.Separator>
  );
};

export default Breadcrumbs;
