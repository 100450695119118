import React from 'react';
import { useTranslation } from 'react-i18next';

import CompanyDataCard, { CompanyDataRow } from 'components/reusable/CompanyDataCard';
import TableColumnDate from 'components/reusable/TableColumnDate';
import CollapsibleTable from 'components/reusable/CollapsibleTable';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import useFormatDate from 'hooks/format/useFormatDate';

import { Col, Row, Card } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';

const BailiffsInformation = ({ bailiffsReminder, bailiffsRemaindersDetails }) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const isUiHidden = useIsUiHidden();

  const bailiffsInformationColumns = [
    {
      title: t('startReport.negativeData.bailiffsInformation.reminder.date'),
      dataIndex: 'reminderDate',
      key: 'reminder_date',
      render: TableColumnDate,
    },
    {
      title: t('startReport.negativeData.bailiffsInformation.amount'),
      dataIndex: 'recoverableAmount',
      key: 'amount',
    },
    {
      title: t('startReport.negativeData.bailiffsInformation.plaintiff'),
      dataIndex: 'plaintiffName',
      key: 'plaintiff',
    },
    {
      title: t('startReport.negativeData.bailiffsInformation.case.no'),
      dataIndex: 'caseNumber',
      key: 'case_no',
    },
  ];

  return (
    <>
      <CompanyDataCard
        icon={<FileTextOutlined className="icon--gray" />}
        title={t('startReport.negativeData.bailiffsInformation.title')}
      >
        {bailiffsRemaindersDetails.length ? (
          <Col>
            <CompanyDataRow
              type={t('startReport.negativeData.bailiffsInformation.bailiffs.count')}
              value={bailiffsReminder?.total ?? '-'}
            />
            <CompanyDataRow
              type={t('startReport.negativeData.bailiffsInformation.bailiffs.date')}
              value={formatDate(bailiffsReminder?.latestDate) ?? '-'}
            />
          </Col>
        ) : (
          <Row>
            <Card className="card-transparent space-top-md">
              {t('professionalReport.negativeData.bailiffsInformation.bailiffsCalls.noInformation')}
            </Card>
          </Row>
        )}

        {bailiffsRemaindersDetails?.length ? (
          <CollapsibleTable
            className="space-top-md"
            title={t('professionalReport.negativeData.openDebts.detailedInformation.title')}
            data={bailiffsRemaindersDetails}
            columns={bailiffsInformationColumns}
            pagination={isUiHidden ? false : { defaultPageSize: 10, showSizeChanger: true }}
            bordered
          />
        ) : null}
      </CompanyDataCard>
    </>
  );
};

export default BailiffsInformation;
