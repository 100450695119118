import React from 'react';
import { useSelector } from 'react-redux';

import CompanySearchForm from './CompanySearchForm';
import SearchResultsTable from './SearchResultsTable';
import Basic from './Basic';
import HistoricalSearchTable from './HistoricalSearchTable';
import { Card } from 'antd';
import { ReactComponent as AlertIcon } from 'assets/img/icons/foreignAlert.svg';
import Icon from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ForeignReportOrderModal from './ForeignReportOrderModal';

const ForeignCompanySearch = () => {
  const { t } = useTranslation();
  const { visibleCompanyView } = useSelector(state => state.foreignCompany);

  return (
    <div className="foreign-company-report">
      <ForeignReportOrderModal />
      <Card className="space-vertical-lg has-shadow sm-padding card-simple">
        <Icon className="icon--lg" component={AlertIcon} style={{ marginRight: 10, marginLeft: 16 }} />
        {t('foreignCompanySearch.disclaimer1')}
      </Card>
      {(visibleCompanyView === 'table' || visibleCompanyView === 'details') && <CompanySearchForm />}
      {visibleCompanyView === 'table' && <SearchResultsTable />}
      {visibleCompanyView === 'details' && <Basic />}
      {(visibleCompanyView === 'table' || visibleCompanyView === 'details') && <HistoricalSearchTable />}
    </div>
  );
};

export default ForeignCompanySearch;
