import CodeForm from 'views/OtherProducts/CreditMonitoring/CodeForm';
import { useSelector } from 'react-redux';

const IndividualPerson = ({ setIsPopUpModalOpen, callHandleDeleteAll }) => {
  const { personMonitoringSummary } = useSelector(state => state.otherProducts);

  return (
    <CodeForm
      codeText="otherProducts.monitorings.creditMonitoring.individualPerson.code"
      currentlyMonitoring={personMonitoringSummary?.watchCount}
      freeMonitoring={personMonitoringSummary?.watchMax}
      youAreMonitoring={personMonitoringSummary?.watchCountUser}
      setIsPopUpModalOpen={setIsPopUpModalOpen}
      callHandleDeleteAll={callHandleDeleteAll}
    />
  );
};

export default IndividualPerson;
