import React, { useMemo } from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import useFormatDate from 'hooks/format/useFormatDate';

const EmployeesTable = props => {
  const { numberOfEmployeesInThePeriods, children } = props;
  const { t } = useTranslation();
  const formatDate = useFormatDate();

  const columns = useMemo(
    () => [
      ...numberOfEmployeesInThePeriods.map((obj, i) => ({
        title: formatDate(obj.date),
        dataIndex: i,
        align: 'center',
        key: i,
      })),
    ],
    [numberOfEmployeesInThePeriods]
  );

  const dataSource = useMemo(() => {
    const result = {};

    numberOfEmployeesInThePeriods.forEach((obj, i) => {
      result[i] = obj.employeesNumber;
    });

    return [result];
  }, [numberOfEmployeesInThePeriods]);

  return (
    <>
      <Table
        rowKey={({ date }) => `employee.${date}`}
        dataSource={dataSource}
        columns={columns}
        scroll={{ x: 'max-content' }}
        pagination={false}
        bordered
      />
      {children}
    </>
  );
};

export default EmployeesTable;
