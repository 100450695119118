import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { setSearchCompanyCode } from 'store/entities/companySearchResult';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import { Table, Typography, Row } from 'antd';
import useWindowSize from '../../../hooks/dom/useWindowSize';
import useFilteredUtmParams from '../../../hooks/navigation/useFilteredUtmParams';
import SingleResult from 'components/reusable/SingleResult';
import { Link } from 'react-router-dom';

const { Text } = Typography;

const SearchResultsTable = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const { width: windowWidth } = useWindowSize();
  const { searchResults, getCompaniesPending } = useSelector(state => state.company);
  const utmQueryString = useFilteredUtmParams(search);

  const currentLang = useMemo(() => i18n.language.split('-')[0], [i18n]);
  const sortOpts = useMemo(() => ({ sensitivity: 'base' }), []);

  const columns = [
    {
      title: t('companySearch.results.code'),
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => a.code - b.code,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('companySearch.results.companyName'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <>
          <p>
            <Text type="info">{text}</Text>
          </p>
          {record.oldName && <p>({record.oldName})</p>}
        </>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name, 'lt', sortOpts),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('companySearch.results.address'),
      dataIndex: 'address',
      key: 'address',
      sorter: (a, b) => a.address.localeCompare(b.address, 'lt', sortOpts),
    },
    {
      title: t('companySearch.results.status'),
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status, currentLang, sortOpts),
      render: (status, record) => {
        if (record?.isAfterBankruptcy) {
          return `${status} ${t('companyBasicInformation.basicData.basicInformation.isAfterBankruptcy')}`;
        } else return status;
      },
    },
  ];

  const handleUnregisteredRowClick = code => {
    dispatch(setSearchCompanyCode({ searchCompanyCode: code }));
    history.push(`/search/company/${code}/result${utmQueryString && `?${utmQueryString}`}`);
  };

  const handleRowClick = record => {
    handleUnregisteredRowClick(record.code);
  };

  const sortedResults = useMemo(() => {
    return [...searchResults].sort((a, b) => {
      if (
        (a.status === 'Veikiantis subjektas' && b.status !== 'Veikiantis subjektas') ||
        (a.status === 'Active subject' && b.status !== 'Active subject')
      ) {
        return -1;
      }
      if (
        (a.status !== 'Veikiantis subjektas' && b.status === 'Veikiantis subjektas') ||
        (a.status !== 'Active subject' && b.status === 'Active subject')
      ) {
        return 1;
      }

      const statusComparison = a.status.localeCompare(b.status, currentLang, sortOpts);
      if (statusComparison !== 0) {
        return statusComparison;
      }
      return a.name.localeCompare(b.name, 'lt', sortOpts);
    });
  }, [searchResults, currentLang, sortOpts]);

  useEffect(() => {
    if (searchResults?.length === 1) handleRowClick(searchResults[0]);
  }, [searchResults]);

  return (
    searchResults.length > 0 && (
      <div className="space-vertical-lg">
        <LoadingSpinner size="large" spinning={getCompaniesPending} indicator={null}>
          {windowWidth < 992 ? (
            searchResults.length ? (
              <>
                <Row>Results</Row>
                {sortedResults?.map(result => {
                  return <SingleResult result={result} onClick={handleRowClick} />;
                })}
              </>
            ) : null
          ) : (
            <Table
              dataSource={sortedResults}
              columns={columns}
              onRow={record => ({ onClick: () => handleRowClick(record) })}
              rowKey="id"
              rowClassName={record =>
                `ant-table-row-clickable ${record.isHistoricalManager ? 'ant-table-row-dark' : ''}`
              }
              scroll={{ x: 'max-content' }}
              pagination={{ defaultPageSize: 20 }}
              showSorterTooltip={false}
            />
          )}
        </LoadingSpinner>
      </div>
    )
  );
};

export default SearchResultsTable;
