import React from 'react';
import { Col, Row } from 'antd';
import { Trans } from 'react-i18next';

const PdfFooterDisclaimer = ({ text }) => {
  return (
    <Row className="disclaimer space-vertical-sm">
      <Col span={24}>
        <Trans i18nKey={text ?? 'prescoreReport.interests.disclaimer'} />
      </Col>
    </Row>
  );
};

export default PdfFooterDisclaimer;
