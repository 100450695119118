import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Button, Card, Col, Form, Input, message, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { setVisiblePersonView } from 'store/entities/person';
import { getPerson } from 'store/entities/person/thunks';

const IndividualSearchForm = () => {
  const [searched, setSearched] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { getPersonError } = useSelector(state => state.person);

  const handleRecaptchaVerification = useCallback(
    ({ personCode }) => {
      executeRecaptcha('getPerson').then(token => {
        dispatch(
          getPerson({
            personCode: Number.parseInt(personCode, 10),
            token,
          })
        ).then(() => {
          dispatch(setVisiblePersonView('details'));
          !searched && setSearched(true);
        });
      });
    },
    [executeRecaptcha, dispatch]
  );

  const handleSubmit = useCallback(
    values => {
      handleRecaptchaVerification(values);
    },
    [handleRecaptchaVerification]
  );

  useEffect(() => {
    searched && getPersonError && message.error(t('individualReport.error.400'));
  }, [getPersonError, searched]);

  return (
    <Form form={form} labelCol={{ span: 24 }} onFinish={handleSubmit}>
      <Card className="card-simple space-vertical-lg has-shadow">
        <Row gutter={[16, 16]}>
          <Col xs={24} md={24} lg={24} xl={12} xxl={15}>
            <Form.Item name="personCode">
              <Input placeholder={t('individualSearch.code')} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12} xxl={9} className="justify-content-end align-items-center">
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                {t('companySearch.search')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export default IndividualSearchForm;
