import React from 'react';
import { useTranslation } from 'react-i18next';

import CompanyDataCard, { CompanyDataRow } from 'components/reusable/CompanyDataCard';
import useFormatDate from 'hooks/format/useFormatDate';
import { Button, Row, Card } from 'antd';

import { ReactComponent as Money } from 'assets/img/icons/money.svg';
import useNumberFormatter from '../../../../hooks/format/useNumberFormatter';

const OpenDebtsCard = ({
  openDebtsData,
  showNoEntries = false,
  buyReportButton,
  historyPurchesed,
  isActiveDebtsPurchesed,
  children,
}) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const formatCurrency = useNumberFormatter();

  return (
    <CompanyDataCard
      icon={<Money className="icon--gray" />}
      title={t('professionalReport.negativeData.openDebts.title')}
    >
      {!openDebtsData?.total ? (
        <Row>
          <Card className="card-transparent space-top-md">
            {t('professionalReport.negativeData.openDebts.noInformation')}
          </Card>
        </Row>
      ) : (
        <>
          <CompanyDataRow
            type={t('professionalReport.negativeData.openDebts.numberOfDebts')}
            value={openDebtsData?.total || '-'}
          />
          <CompanyDataRow
            type={t('professionalReport.negativeData.openDebts.lastTrialDate')}
            value={openDebtsData?.latestDate ? formatDate(openDebtsData?.latestDate) : '-'}
          />
          <CompanyDataRow
            type={t('professionalReport.negativeData.openDebts.averagePaymentTerm')}
            value={openDebtsData?.averageDays ? `${openDebtsData.averageDays} ${t('global.daysInShort')}` : '-'}
          />
          <CompanyDataRow
            type={t('professionalReport.negativeData.openDebts.amount')}
            value={openDebtsData?.amount ? formatCurrency(openDebtsData.amount) : '-'}
          />
          {buyReportButton ? (
            <Button className="buy-report" onClick={historyPurchesed}>
              {t('startReport.negativeData.buy.report.button')}
            </Button>
          ) : null}
          {children}
        </>
      )}
    </CompanyDataCard>
  );
};

export default OpenDebtsCard;
