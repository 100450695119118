import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CompanyDataCard from 'components/reusable/CompanyDataCard';

import useCurrentLanguage from 'hooks/user/useCurrentLanguage';
import { getCompanyOrderId, getCompanyPdfReport } from 'store/entities/foreignCompany/thunks';
import { setSelectedCompanyOrderId } from 'store/entities/foreignCompany';

import LoadingSpinner from 'components/reusable/LoadingSpinner';

import { Row } from 'antd';
import { b64toBlob, downloadAsFile } from '../../../utils/download';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { IdcardOutlined } from '@ant-design/icons';

const Basic = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lang = useCurrentLanguage();
  const [pdfUrl, setPdfUrl] = useState(null);

  const {
    selectedCompany,
    selectedCompanyDataPending,
    selectedCompanyOrderId,
    selectedCompanyPdfReport,
    selectedCompanyOrderIdPending,
    selectedCompanyPdfReportPending,
  } = useSelector(state => state.foreignCompany);

  //  TODO: think about if lang chagne should regenerate the report with new request
  useEffect(() => {
    if (selectedCompany) {
      if (selectedCompany.orderId) {
        dispatch(setSelectedCompanyOrderId(selectedCompany.orderId));
      } else {
        dispatch(getCompanyOrderId(selectedCompany));
      }
    }
  }, [selectedCompany]);

  useEffect(() => {
    selectedCompanyOrderId && dispatch(getCompanyPdfReport(selectedCompanyOrderId));
  }, [selectedCompanyOrderId, lang]);

  useEffect(() => {
    if (selectedCompanyPdfReport) {
      const data = b64toBlob(selectedCompanyPdfReport.data);
      // downloadAsFile(data, 'testingForeignReport2.pdf');
      const url = URL.createObjectURL(data);
      setPdfUrl(url);
    } else {
      setPdfUrl(null);
    }
  }, [selectedCompanyPdfReport]);

  return (
    <LoadingSpinner size="large" spinning={selectedCompanyOrderIdPending || selectedCompanyPdfReportPending}>
      <div>
        <Row>
          <CompanyDataCard
            icon={<IdcardOutlined className="icon--gray" />}
            title={selectedCompany?.companyName}
            disclaimer={`${t('foreignCompanySearch.results.basic.disclaimer')} - ${selectedCompany?.companyCode}`}
          >
            {pdfUrl ? (
              <div style={{ width: '100%', height: '90vh', minHeight: 600 }} className="space-top-md">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <Viewer fileUrl={pdfUrl} plugins={[defaultLayoutPluginInstance]} />
                </Worker>
              </div>
            ) : null}
          </CompanyDataCard>
        </Row>
      </div>
    </LoadingSpinner>
  );
};

export default Basic;
