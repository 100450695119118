import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { setFeedbackModalActive } from 'store/entities/otherProducts';
import { ReactComponent as FeedbackModalIcon } from 'assets/img/icons/feedbackModalIcon.svg';
import { Button, Row, Col, Input, Upload, message, Form } from 'antd';
import Modal from 'components/reusable/Modal';
import { ReactComponent as Close } from 'assets/img/icons/close.svg';
import { ReactComponent as Attach } from 'assets/img/icons/attach.svg';
import { ReactComponent as Success } from 'assets/img/icons/success.svg';
import { postFeedback } from 'store/entities/otherProducts/thunks';
import LoadingSpinner from 'components/reusable/LoadingSpinner';

const { TextArea } = Input;

const FeedbackModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { postFeedbackPending, postFeedbackError, postFeedbackData, feedbackModalActive } = useSelector(
    state => state.otherProducts
  );
  const [fileList, setFileList] = useState([]);
  const [text, setText] = useState('');
  const [path, setPath] = useState('');

  const getReportType = path => {
    switch (true) {
      case /^\/search\/company(\/[\w-]+)?$|^\/search\/company\/[\w-]+\/result$/.test(path) ||
        /^\/search\/individual(\/[\w-]+)?$/.test(path):
        return 'Search';
      case /^\/search\/company\/[\w-]+\/professional-report$/.test(path):
        return 'CompanyProfessionalReport';
      case /^\/search\/company\/consultation$/.test(path):
        return 'ConsultationRegistration';
      case /^\/search\/company\/[\w-]+\/start-report$/.test(path):
        return 'CompanyStartReport';
      case /^\/search\/company\/[\w-]+\/financial-statements(\/[\w-]+)?$/.test(path):
        return 'FinancialStatementReport';
      case /^\/search\/company\/[\w-]+\/company-credit-limit$/.test(path):
        return 'CompanyCreditLimitReport';
      case /^\/search\/company\/[\w-]+\/prescore-report$/.test(path):
        return 'PrescoreReport';
      case /^\/search\/company\/[\w-]+\/main-information$/.test(path):
        return 'MainInformationReport';
      case /^\/search\/individual\/[\w-]+\/professional-report$/.test(path):
        return 'IndividualProfessionalReport';
      case /^\/other-products\/monitorings(\/[\w-]+)?$|^\/other-products\/monitorings\/credit-monitoring(\/[\w-]+)?$|^\/other-products\/monitorings\/web-monitoring(\/[\w-]+)?$/.test(
        path
      ):
        return 'Monitoring';
      case /^\/other-products\/spider-web(\/[\w-]+)?$/.test(path):
        return 'SpiderWeb';
      case /^\/administration\/debtors-administration(\/[\w-]+)?$/.test(path):
        return 'DebtAdministration';
      default:
        return 'Undefined';
    }
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('Text', text);
    formData.append('ReportType', getReportType(path));
    fileList?.length &&
      fileList.forEach(file => {
        formData.append('files', file);
      });
    dispatch(postFeedback(formData));
  };

  useEffect(() => {
    if (!postFeedbackPending && postFeedbackError && !postFeedbackData) {
      message.error(t('feedback.modal.errorMsg'), 5);
    }
    if (feedbackModalActive && !postFeedbackPending && !postFeedbackError && postFeedbackData) {
      setTimeout(() => {
        setFileList([]);
        dispatch(setFeedbackModalActive(false));
      }, 5000);
    }
  }, [postFeedbackPending]);

  const props = {
    fileList,
    onChange: info => {
      if (info.file.status === 'removed') {
        const updatedFileList = fileList.filter(file => file.uid !== info.file.uid);
        setFileList(updatedFileList);
      }
    },
    beforeUpload: file => {
      const allowedTypes = [
        'application/pdf',
        'message/rfc822',
        'image/jpeg',
        'image/png',
        'application/vnd.ms-powerpoint',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ];
      const fileType = file.type.toLowerCase();

      if (!allowedTypes.includes(fileType)) {
        message.error(`${file.name} ${t('feedback.modal.fileUpload.incorrectFormat')}`);
        return false;
      }

      if (fileType === 'message/rfc822' && !file.name.toLowerCase().endsWith('.eml')) {
        message.error(`${file.name} ${t('feedback.modal.fileUpload.incorrectFormat')}`);
        return false;
      }

      setFileList(prevList => [...prevList, file]);
      return false;
    },
  };

  useEffect(() => {
    if (!feedbackModalActive) {
      fileList?.length && setFileList([]);
      text && setText('');
      path && setPath('');
    } else {
      setPath(window.location.pathname);
    }
  }, [feedbackModalActive]);

  return (
    <Modal
      isOpen={feedbackModalActive}
      setIsOpen={isOpen => dispatch(setFeedbackModalActive(isOpen))}
      closable={false}
      size="sm"
    >
      {postFeedbackData ? (
        <div style={{ minHeight: 165 }}>
          <Row>
            <Col span={24} className="justify-content-end">
              <Row justify="end">
                <span className="icon-btn" role="button" onClick={() => dispatch(setFeedbackModalActive(false))}>
                  <Close />
                </span>
              </Row>
            </Col>
          </Row>
          <div
            className="feedback-modal__title"
            span={24}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 110 }}
          >
            <Success style={{ marginRight: '10px' }} />
            <div className="color-success">{t('feedback.modal.successMsg')}</div>
          </div>
        </div>
      ) : (
        <LoadingSpinner size="large" spinning={postFeedbackPending}>
          <Form onFinish={handleSubmit}>
            <Row>
              <Col className="feedback-modal__title" span={20} style={{ display: 'flex', alignItems: 'center' }}>
                <FeedbackModalIcon style={{ marginRight: '10px' }} />
                <div className="color-secondary">{t('feedback.modal.title')}</div>
              </Col>
              <Col span={4} className="justify-content-end">
                <span className="icon-btn" role="button" onClick={() => dispatch(setFeedbackModalActive(false))}>
                  <Close />
                </span>
              </Col>
            </Row>
            <p>
              <Trans i18nKey="feedback.modal.text" components={{ bold: <strong className="bigger-font" /> }} />
            </p>
            <Upload name="file" {...props}>
              <Button
                className="btn-secondary feedback-modal__button"
                style={{ textTransform: 'none', fontWeight: 400 }}
                icon={<Attach />}
              >
                {t('feedback.modal.addFile')}
              </Button>
            </Upload>
            <Form.Item name="text" rules={[{ required: true, message: t('feedback.modal.noText') }]}>
              <TextArea
                showCount
                maxLength={500}
                placeholder={t('feedback.modal.message')}
                autoSize={{ minRows: 5, maxRows: 15 }}
                className="feedback-modal__text-area"
                onChange={e => setText(e.target.value)}
                value={text}
              />
            </Form.Item>
            <div>
              <Button
                style={{ width: '100%', justifyContent: 'center' }}
                className="btn-secondary feedback-modal__button"
                htmlType="submit"
              >
                {t('feedback.modal.submitBtn')}
              </Button>
            </div>
          </Form>
        </LoadingSpinner>
      )}
    </Modal>
  );
};

export default FeedbackModal;
