import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import personHooks from 'store/entities/person/selectors';
import { resetPerson } from 'store/entities/person';
import { resetProfessionalReport } from 'store/entities/professionalReport';
import { getOrderId, getSummary } from 'store/entities/professionalReport/thunks';
import OpenDebts from 'views/IndividualReport/OpenDebts';
import PaymentHistory from 'views/IndividualReport/PaymentHistory';
import OtherDebts from 'views/IndividualReport/components/OtherDebts';
import ReportsCount from 'views/IndividualReport/components/ReportsCount';
import { useParams } from 'react-router-dom';

const ProfessionalReport = ({ isProfReport = false }) => {
  const dispatch = useDispatch();
  const { orderId, summary } = useSelector(state => state.professionalReport);
  const { individualId } = useParams();

  useEffect(() => {
    dispatch(getOrderId({ code: individualId, isCompany: false }));

    return () => {
      dispatch(resetPerson());
      dispatch(resetProfessionalReport());
    };
  }, []);

  useEffect(() => {
    if (orderId) {
      dispatch(getSummary({ code: individualId, isCompany: false, orderId }));
    }
  }, [orderId]);

  if (summary === null) {
    return <></>;
  }

  const {
    bankruptcies,
    protectYourIdentity,
    otherDebts,
    otherDebtsDetails,
    activeDebts,
    activeDebtsDetails,
    paidDebts,
    paidDebtsDetails,
    statistics,
    statisticsBySector,
    creditRestriction,
  } = summary || {};

  return (
    <>
      <OpenDebts
        bankruptcies={bankruptcies}
        protectYourIdentity={protectYourIdentity}
        activeDebts={activeDebts}
        activeDebtsDetails={activeDebtsDetails}
        creditRestriction={creditRestriction}
        isProfReport={isProfReport}
      />
      <PaymentHistory paidDebts={paidDebts} paidDebtsDetails={paidDebtsDetails} />
      <OtherDebts otherDebts={otherDebts} otherDebtsDetails={otherDebtsDetails} />
      <ReportsCount statistics={statistics} statisticsBySector={statisticsBySector} />
    </>
  );
};

export default ProfessionalReport;
