import { useSelector } from 'react-redux';

const useIsStartReportLoading = () => {
  const {
    orderIdPending,
    orderIdError,
    summaryPending,
    summaryError,
    basicDataPending,
    basicDataError,
    negativeDataError,
    companyRatingsError,
    isPdfReportDownloadLoading,
    financialDataError,
  } = useSelector(state => state.startReport);

  return {
    isLoading: orderIdPending || summaryPending || basicDataPending || isPdfReportDownloadLoading,
    hasError:
      orderIdError || summaryError || basicDataError || negativeDataError || financialDataError || companyRatingsError,
  };
};

export default useIsStartReportLoading;
