import { useTranslation } from 'react-i18next';

const CustomLegend = () => {
  const { t } = useTranslation();
  return (
    <ul className="recharts-default-legend" style={{ paddingTop: 10 }}>
      <div
        className={`recharts-legend-item`}
        style={{
          color: 'grey',
        }}
      >
        <span>{t(`graph.ratingScoringHistory.explanation`)}</span>
      </div>
    </ul>
  );
};

export default CustomLegend;
