import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setOrderReportModal } from 'store/entities/foreignCompany';

import { Button, Row, Col, Typography, Space, Form, Checkbox } from 'antd';
import Modal from 'components/reusable/Modal';
import { ReactComponent as Close } from 'assets/img/icons/close.svg';
import React, { useState } from 'react';

const { Title } = Typography;

const ForeignReportOrderModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { session } = useSelector(state => state.user);

  const [form] = Form.useForm();

  const { orderReportModal } = useSelector(state => state.foreignCompany);

  return (
    <Modal
      isOpen={orderReportModal}
      setIsOpen={isOpen => dispatch(setOrderReportModal(isOpen))}
      closable={false}
      size="sm"
    >
      {/*<div className="modal-svg"></div>*/}
      <Row>
        <Col span={24} className="justify-content-end">
          <span className="icon-btn space-top-md" role="button" onClick={() => dispatch(setOrderReportModal(false))}>
            <Close />
          </span>
        </Col>
      </Row>
      <Row className="space-bottom-lg">
        <Col span={3}></Col>
        <Col span={18}>
          <Title level={3} className="color-secondary">
            {t('foreignCompanySearch.foreignReportOrderModal.title')}
          </Title>
          {/*<p className="fs-18">*/}
          {/*    <strong>{t('foreignCompanySearch.foreignReportOrderModal.text')}</strong>*/}
          {/*</p>*/}
          {/*<p className="fs-18">*/}
          {/*    <strong>{t('foreignCompanySearch.foreignReportOrderModal.continue')}</strong>*/}
          {/*</p>*/}
          {session?.companyName && session.companyName},{session?.fullName && session.fullName}
        </Col>
        <Col span={3}></Col>
      </Row>

      <Form
        form={form}
        onFinish={() => console.log(form, 'form')}
        name="consultationRegisterForm"
        className="form-compact form-padded"
        labelCol={{ span: 24 }}
        // initialValues={{
        //     ...companyBuyerInformation,
        //     ...personBuyerInformation,
        // }}
      >
        <Row gutter={[16, 16]} className="buy-report-checks">
          <Col xs={24}>
            <Form.Item
              name="isTaxInvoiceRequired"
              valuePropName="checked"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Checkbox>{t('companyResult.buyReport.check.vat')}</Checkbox>
            </Form.Item>
            <Form.Item
              name="agreedConsultation"
              valuePropName="checked"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Checkbox>{t('companyResult.buyReport.check.consultation')}</Checkbox>
            </Form.Item>
            <Form.Item
              name="isPrivacyPolicyAccepted"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message: t('companyResult.buyReport.check.privacyPolicy.required'),
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(new Error(t('companyResult.buyReport.check.privacyPolicy.required'))),
                },
              ]}
              required
            >
              <Checkbox className="required">
                <a
                  // href={i18n.language === 'en-US' ? privacyPolicy : privacyPolicyLt}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="underlined"
                >
                  {t('companyResult.buyReport.check.privacyPolicy')}
                </a>
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="rulesAccepted"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message: t('companyResult.buyReport.check.terms.required'),
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(new Error(t('companyResult.buyReport.check.terms.required'))),
                },
              ]}
              required
            >
              <Checkbox className="required">
                <a
                  // href={i18n.language === 'en-US' ? terms : termsLt}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="underlined"
                >
                  {t('companyResult.buyReport.check.terms')}
                </a>
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Row className="space-bottom-lg">
        <Col span={24} className="justify-content-end">
          <Space size="large" align="end">
            <Button type="default" className="" onClick={() => dispatch(setOrderReportModal(false))}>
              {t('global.cancel')}
            </Button>
            <Button className="btn-secondary " onClick={() => console.log('continue')}>
              {t('global.continue')}
            </Button>
          </Space>
        </Col>
      </Row>
    </Modal>
  );
};

export default ForeignReportOrderModal;
