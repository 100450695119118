import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import useCurrentLanguage from 'hooks/user/useCurrentLanguage';
import { changeLanguage, changePhoneNumber } from 'store/entities/user/thunks';
import { languages } from 'i18n';
import { showSuccessNotification } from 'utils/notification';

import { Form, Button, Row, Col, Typography, Space, Select } from 'antd';

const { Title } = Typography;

const ChangePasswordForm = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const lang = useCurrentLanguage();

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: {
      sm: { span: 24 },
      md: { span: 24 },
      lg: { span: 24 },
      xl: { span: 12 },
    },
  };

  const tailLayout = {
    wrapperCol: { span: 24 },
  };

  const onFinish = async values => {
    const submitResult = await dispatch(changePhoneNumber({ phoneNumber: values.phoneNumber }));

    if (changePhoneNumber.fulfilled.match(submitResult)) {
      showSuccessNotification('Phone number changed!');
    }
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const handleChangeLanguage = async languageCode => {
    const changeResult = await dispatch(changeLanguage(languageCode));

    if (changeLanguage.fulfilled.match(changeResult)) {
      i18n.changeLanguage(languageCode);
    }
  };

  return (
    <>
      <Row>
        <Col>
          <Title level={3} className="space-vertical-xl">
            {t('profile.settings.changeSettings.title')}
          </Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form {...layout} name="changeSettings" onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Form.Item
              label={t('profile.settings.changeSettings.changeLanguage')}
              name="lang"
              className="margin-bottom-none"
            >
              <Select optionFilterProp="children" onChange={value => handleChangeLanguage(value)} defaultValue={lang}>
                {languages.map(({ codeShort, codeLong }) => (
                  <Select.Option key={`selectLang-${codeShort}`} value={codeShort}>
                    {codeLong}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item {...tailLayout} className="space-top-xl">
              <Space>
                <Button className="btn-secondary" htmlType="submit">
                  {t('profile.settings.change')}
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ChangePasswordForm;
