import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import CompanyDataCard, { CompanyDataRow, CompanyDataTruncatedRow } from 'components/reusable/CompanyDataCard';
import useFormatDate from 'hooks/format/useFormatDate';

import { FileTextOutlined } from '@ant-design/icons';
import { Button, Table, Row, Col } from 'antd';
import ExpandableButton from '../../ExpandableButton';
import TableColumnDate from '../../TableColumnDate';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';

const CompanyBasicInformation = ({ companyData }) => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();
  const formatDate = useFormatDate();
  const isPdfReportPage = !!window.location.pathname.includes('pdf-print');
  const [isLegalStatusExpanded, setIsLegalStatusExpanded] = useState(isPdfReportPage);
  const [isCompaniesExpanded, setIsCompaniesExpanded] = useState(isPdfReportPage);

  const statusColumns = [
    {
      title: t('global.dateFrom'),
      dataIndex: 'dateFrom',
      key: 'dateFrom',
      render: TableColumnDate,
      width: isUiHidden ? 80 : 120,
    },
    {
      title: t('global.dateUntil'),
      dataIndex: 'dateTo',
      key: 'dateTo',
      render: TableColumnDate,
      width: isUiHidden ? 80 : 120,
    },
    {
      title: t('companySearch.results.basic.status'),
      dataIndex: 'status',
      key: 'status',
    },
  ];

  const namesColumns = [
    {
      title: t('global.dateFrom'),
      dataIndex: 'dateFrom',
      key: 'dateFrom',
      render: TableColumnDate,
      width: isUiHidden ? 80 : 120,
    },
    {
      title: t('global.dateUntil'),
      dataIndex: 'dateTo',
      key: 'dateTo',
      render: TableColumnDate,
      width: isUiHidden ? 80 : 120,
    },
    {
      title: t('companySearch.results.basic.name'),
      dataIndex: 'name',
      key: 'name',
    },
  ];

  const concatCompanyDataRowValues = () => {
    const rows = [
      {
        title: t('professionalReport.basicData.basicInformation.companyName'),
        value: companyData?.companyName ?? '',
      },
      {
        title: t('professionalReport.basicData.basicInformation.status'),
        value: companyData?.status
          ? `${companyData.status} ${
              companyData?.isAfterBankruptcy
                ? t('companyBasicInformation.basicData.basicInformation.isAfterBankruptcy')
                : ''
            }`
          : '',
      },
      {
        title: t('professionalReport.basicData.basicInformation.legalForm'),
        value: companyData?.legalForm ?? '',
      },
      {
        title: t('professionalReport.basicData.basicInformation.registered'),
        value: companyData?.registered ? formatDate(companyData?.registered) : '',
      },
      {
        title: t('professionalReport.basicData.basicInformation.regNo'),
        value: companyData?.companyCode ?? '',
      },
      {
        title: t('professionalReport.basicData.basicInformation.vatNo'),
        value: companyData?.vatCode ?? t('professionalReport.summary.basicInformation.vatCode.none'),
      },
      {
        title: t('professionalReport.basicData.basicInformation.vatRegDate'),
        value: companyData?.vatRegistrationDate ? formatDate(companyData?.vatRegistrationDate) : '',
      },
      {
        title: t('professionalReport.basicData.basicInformation.mainActivity'),
        value: companyData?.nace2 ?? '',
      },
      {
        title: t('professionalReport.basicData.basicInformation.institutionalSector'),
        value: companyData?.institutionalSector
          ? `${companyData?.institutionalSector?.sectorId} - ${companyData?.institutionalSector?.sectorDescription}`
          : t('global.noEntries'),
      },
      {
        title: t('professionalReport.basicData.basicInformation.internationalSanction'),
        value: companyData?.internationalSanction?.isInternationalSanctioned ? t('global.yes') : t('global.no'),
      },
      {
        title: t('professionalReport.basicData.basicInformation.municipality'),
        value: companyData?.municipality ?? '',
      },
      {
        title: t('professionalReport.basicData.basicInformation.ruleOfRepresentation'),
        value: companyData?.ruleOfRepresentationDescription
          ? `${t(`global.ruleOfRepresentation.representationType.${companyData?.ruleOfRepresentation}`)} (${
              companyData?.ruleOfRepresentationDescription
            })`
          : '',
      },
      {
        title: t('professionalReport.basicData.basicInformation.target'),
        value: companyData?.target ?? '',
      },
    ];

    return rows.map(row => `${row.title} - ${row.value}`).join('\r\n');
  };

  return (
    <CompanyDataCard
      icon={<FileTextOutlined className="icon--gray" />}
      title={t('professionalReport.basicData.basicInformation.title')}
    >
      <CompanyDataRow
        type={t('professionalReport.basicData.basicInformation.companyName')}
        value={
          companyData?.companyName && companyData?.previousCompanyNames?.length > 0 ? (
            <ExpandableButton onClick={() => setIsCompaniesExpanded(prev => !prev)} isActive={isCompaniesExpanded}>
              {companyData?.companyName ?? ''}
            </ExpandableButton>
          ) : (
            companyData?.companyName ?? ''
          )
        }
      />
      {isCompaniesExpanded && (
        <Table
          rowKey={record => record.dateFrom}
          dataSource={companyData?.previousCompanyNames}
          columns={namesColumns}
          pagination={false}
          bordered
          size="small"
          className="expanded-basic-status"
        />
      )}
      <CompanyDataRow
        type={t('professionalReport.basicData.basicInformation.status')}
        value={
          companyData?.status && companyData?.statusHistory?.length > 1 ? (
            <ExpandableButton onClick={() => setIsLegalStatusExpanded(prev => !prev)} isActive={isLegalStatusExpanded}>
              {companyData.status}
              {companyData?.isAfterBankruptcy &&
                ` ${t('companyBasicInformation.basicData.basicInformation.isAfterBankruptcy')}`}
            </ExpandableButton>
          ) : companyData?.status ? (
            `${companyData.status} ${
              companyData?.isAfterBankruptcy
                ? t('companyBasicInformation.basicData.basicInformation.isAfterBankruptcy')
                : ''
            }`
          ) : (
            ''
          )
        }
      />
      {isLegalStatusExpanded && (
        <Table
          rowKey={record => record.dateFrom}
          dataSource={companyData?.statusHistory}
          columns={statusColumns}
          pagination={false}
          bordered
          size="small"
          className="expanded-basic-status"
        />
      )}
      <CompanyDataRow
        type={t('professionalReport.basicData.basicInformation.legalForm')}
        value={companyData?.legalForm ?? ''}
      />
      <CompanyDataRow
        type={t('professionalReport.basicData.basicInformation.registered')}
        value={companyData?.registered ? formatDate(companyData?.registered) : ''}
      />
      <CompanyDataRow
        type={t('professionalReport.basicData.basicInformation.regNo')}
        value={companyData?.companyCode ?? ''}
      />
      <CompanyDataRow
        type={t('professionalReport.basicData.basicInformation.vatNo')}
        value={companyData?.vatCode ?? t('professionalReport.summary.basicInformation.vatCode.none')}
      />
      {companyData?.vatCode && (
        <CompanyDataRow
          type={t('professionalReport.basicData.basicInformation.vatRegDate')}
          value={companyData?.vatRegistrationDate ? formatDate(companyData?.vatRegistrationDate) : ''}
        />
      )}
      <CompanyDataRow
        type={t('professionalReport.basicData.basicInformation.mainActivity')}
        value={companyData?.nace2 ?? ''}
      />
      <CompanyDataRow
        type={t('professionalReport.basicData.basicInformation.institutionalSector')}
        value={
          companyData?.institutionalSector
            ? `${companyData?.institutionalSector?.sectorId} - ${companyData?.institutionalSector?.sectorDescription}`
            : t('global.noEntries')
        }
      />
      <CompanyDataRow
        type={t('professionalReport.basicData.basicInformation.internationalSanction')}
        value={companyData?.internationalSanction?.isInternationalSanctioned ? t('global.yes') : t('global.no')}
      />
      <CompanyDataRow
        type={t('professionalReport.basicData.basicInformation.municipality')}
        value={companyData?.municipality ?? ''}
      />
      <CompanyDataRow
        type={t('professionalReport.basicData.basicInformation.ruleOfRepresentation')}
        value={
          companyData?.ruleOfRepresentationDescription
            ? `${t(`global.ruleOfRepresentation.representationType.${companyData?.ruleOfRepresentation}`)} (${
                companyData?.ruleOfRepresentationDescription
              })`
            : ''
        }
      />
      <CompanyDataTruncatedRow
        type={t('professionalReport.basicData.basicInformation.target')}
        value={companyData?.target ?? ''}
      />
      <Row justify="end">
        <Col>
          <CopyToClipboard text={concatCompanyDataRowValues()}>
            <Button size="small">{t('global.copyClipboard')}</Button>
          </CopyToClipboard>
        </Col>
      </Row>
    </CompanyDataCard>
  );
};

export default CompanyBasicInformation;
