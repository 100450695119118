import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import useCurrentLanguage from 'hooks/user/useCurrentLanguage';
import { getBasicData } from 'store/entities/professionalReport/thunks';
import { setActiveTab, setMenuAffixed } from 'store/entities/professionalReport';
import { scrollToSection } from 'utils/dom';

import { Menu, Affix, Row, Col, Skeleton } from 'antd';
import Basic from './Basic';
import Contact from './Contact';
import Board from './Board';
import Shares from './Shares';
import Employees from './Employees';
import Wages from './Wages';
import Vehicles from './Vehicles';
import Reports from './Reports';
import PressReleases from './Press';
import Trademarks from './Trademarks';
import Exports from './Exports';
import Procurement from './Procurement';
import NavigationTabs from 'components/reusable/report/NavigationTabs';
import Licences from './Licences';
import BankruptcyInfo from '../../../components/reusable/report/cards/BankruptcyInfo';
import { ProcurementInformation } from 'components/reusable/report/cards/ProcurementInformation';
import Restructurizations from '../../../components/reusable/report/cards/Restructurizations';
import SocialSecurityContributions from '../../../components/reusable/report/cards/SocialSecurityContributions';

const scrollOffsetMapping = {
  contact: -300,
  board: -300,
};

const BasicData = ({ activeParentTab, setActiveParentTab, isFullReport = false, briefPdf = false }) => {
  const dispatch = useDispatch();
  const lang = useCurrentLanguage();
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();

  const { orderId, menuAffixed, activeTab, basicData, basicDataPending, basicDataError } = useSelector(
    state => state.professionalReport
  );

  useEffect(() => {
    if ((orderId && basicData && lang !== basicData?.lang) || (!basicData && !basicDataError))
      dispatch(getBasicData(orderId));
  }, [lang]);

  const handleMenuClick = e => {
    e.domEvent.preventDefault();
    e.domEvent.stopPropagation();
    scrollToSection(e.key, { offset: scrollOffsetMapping[e.key] || -150 });
    dispatch(setActiveTab(e.key));
  };

  return (
    <Row>
      {isUiHidden && (
        <Col span={24}>
          <h2>{t('professionalReport.tab.basicData')}</h2>
        </Col>
      )}
      <Col span={24}>
        {basicDataPending ? (
          <Skeleton />
        ) : (
          <>
            {basicData && !basicDataPending && (
              <>
                <Basic />
                <Licences licences={basicData?.licenses} briefPdf={briefPdf} />
                <Contact />
                <BankruptcyInfo bankruptcyInformation={basicData?.bankruptcyInformation} />
                <Restructurizations restructurizations={basicData?.restructurizations} />
                <Board />
                <Shares />
                <Employees />
                <Wages />
                <SocialSecurityContributions
                  tableData={basicData?.socialInsuranceContributionLastYearsSummary}
                  chartData={basicData?.socialInsuranceContributions}
                />
                <Vehicles briefPdf={briefPdf} />
                <ProcurementInformation
                  wonPublicProcurementTenders={basicData?.wonPublicProcurementTenders}
                  announcedPublicProcurementTenders={basicData?.announcedPublicProcurementTenders}
                />
                <Reports />
                <PressReleases briefPdf={briefPdf} />
                <Trademarks briefPdf={briefPdf} />
                <Exports />
                {/*
                  TODO
                  in the future this section will be added back
                  <Procurement />
                */}
              </>
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

export default BasicData;
