import { ReactComponent as CompanyCode } from 'assets/img/icons/companyCode.svg';
import { EnvironmentOutlined, BellOutlined } from '@ant-design/icons';
import { Row, Col, Divider, Card } from 'antd';

const SingleResult = ({ result, onClick = null, carded = false, children }) => {
  return carded ? (
    <Card className="card-simple space-vertical-lg has-shadow">
      <div className="single-result" onClick={onClick ? () => onClick(result) : null}>
        <Row>
          <Col xs={24} className="space-bottom-sm">
            <strong>{result?.name ?? '-'}</strong>
          </Col>
          <Col xs={24} className="single-result__value">
            <EnvironmentOutlined />
            {result?.address ?? '-'}
          </Col>
          <Col xs={24} className="single-result__value">
            <CompanyCode />
            {result?.code ?? '-'}
          </Col>
          <Col xs={24} className="single-result__value">
            <BellOutlined />
            {
              <div
                className={
                  result.status === 'Veikiantis subjektas' || result.status === 'Active subject'
                    ? 'color-success'
                    : 'color-secondary'
                }
              >
                {result.status}
              </div>
            }
          </Col>
        </Row>
      </div>
      {children}
    </Card>
  ) : (
    <div className="single-result" onClick={onClick ? () => onClick(result) : null}>
      <Row>
        <Col xs={24}>
          <strong>{result?.name ?? '-'}</strong>
        </Col>
        <Col xs={24} className="single-result__value">
          <EnvironmentOutlined />
          {result?.address ?? '-'}
        </Col>
        <Col xs={24} className="single-result__value">
          <CompanyCode />
          {result?.code ?? '-'}
        </Col>
        <Col xs={24} className="single-result__value">
          <BellOutlined />
          {
            <div
              className={
                result.status === 'Veikiantis subjektas' || result.status === 'Active'
                  ? 'color-success'
                  : 'color-secondary'
              }
            >
              {result.status}
            </div>
          }
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Divider />
        </Col>
      </Row>
    </div>
  );
};

export default SingleResult;
