import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useRedirect from 'hooks/navigation/useRedirect';
import { useParams } from 'react-router-dom';

import useCurrentLanguage from 'hooks/user/useCurrentLanguage';
import CompanyDataBlock from 'views/Search/CompanySearch/CompanyDataBlock';
import { getSelectedCompanyData } from 'store/entities/company/thunks';
import ReportHeader from '../../components/reusable/report/ReportHeader';
import FinancialStatementData from 'views/FinancialStatements/FinancialStatementData';
import Popup from 'components/Modals/Popup';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import { getFinancialStatementPdf, getFinancialOrderId } from 'store/entities/companyFinancialReport/thunks';
import { resetProfessionalReport } from 'store/entities/professionalReport';

import { Col, Row } from 'antd';
import { b64toBlob, downloadAsFile } from '../../utils/download';
import { useFinancialStatementPdfFilename } from '../../hooks/report/professionalReport/usePdfFilename';

const FinancialStatements = () => {
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const lang = useCurrentLanguage();
  const { t } = useTranslation();
  const [isPopUpModalOpen, setIsPopUpModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('financialStatementData');
  const [report, setReport] = useState();
  const pdfFileName = useFinancialStatementPdfFilename();

  const { orderId, basicData, basicDataPending, financialStatementPdfPending } = useSelector(
    state => state.companyFinancialReport
  );
  const { selectedCompanyData } = useSelector(state => state.company);
  const { financialDataPending, orderIdPending } = useSelector(state => state.professionalReport);

  useEffect(() => {
    companyId && dispatch(getSelectedCompanyData(companyId));
  }, [companyId, lang]);

  const request = {
    financialYear: report?.financialYear,
    financialStatementId: report?.financialStatementId,
  };

  const orderReport = useCallback(async () => {
    const orderIdResult = await dispatch(getFinancialOrderId({ companyCode: companyId, request }));
    if (!getFinancialOrderId.fulfilled.match(orderIdResult)) {
      redirect('/search/company');
    }
  }, [dispatch, redirect, getFinancialOrderId]);

  useEffect(() => {
    orderReport();
    return () => dispatch(resetProfessionalReport());
  }, [companyId]);

  const financialId = useSelector(state => state.companyFinancialReport.orderId);

  const handlePdfDownload = async () => {
    const response = await dispatch(getFinancialStatementPdf(financialId));
    setIsPopUpModalOpen(false);

    const data = b64toBlob(response.payload.data);
    downloadAsFile(data, pdfFileName);
  };

  return (
    <LoadingSpinner
      size="large"
      spinning={basicDataPending || financialDataPending || orderIdPending || financialStatementPdfPending}
    >
      <div className="container financial-statements">
        <Row>
          <Col span={24}>
            <ReportHeader title={selectedCompanyData?.name} />
            {selectedCompanyData && activeTab === 'financialStatementData' && (
              <CompanyDataBlock companyData={selectedCompanyData} />
            )}
            {orderId && activeTab === 'financialStatementData' && (
              <FinancialStatementData
                setIsPopUpModalOpen={() => setIsPopUpModalOpen(true)}
                basicInformation={basicData?.basicInformation}
                setReport={setReport}
                report={report}
              />
            )}
            {isPopUpModalOpen && (
              <Popup
                financial={true}
                onClose={() => setIsPopUpModalOpen(false)}
                onContinue={() => (
                  redirect(`/search/company/${companyId}/financial-statements/${report?.financialStatementId}`),
                  setIsPopUpModalOpen(false)
                )}
              />
            )}
          </Col>
        </Row>
      </div>
    </LoadingSpinner>
  );
};

export default FinancialStatements;
