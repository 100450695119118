import { createAsyncThunk } from '@reduxjs/toolkit';
import startReportService from 'api/startReportService';

export const getOrderId = createAsyncThunk('startReport/getOrderId', async (companyCode, { rejectWithValue }) => {
  try {
    const response = await startReportService.getOrderId(companyCode);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getChildOrderId = createAsyncThunk(
  'startReport/getChildOrderId',
  async ({ companyCode, productCodeRequest }, { rejectWithValue }) => {
    try {
      const response = await startReportService.getChildOrderId(companyCode, productCodeRequest);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getSummary = createAsyncThunk(
  'startReport/getSummary',
  async ({ companyCode, orderId }, { rejectWithValue }) => {
    try {
      const orderDetailsResponse = await startReportService.getOrderDetails(companyCode, orderId);

      const summaryResponse = await startReportService.getSummary(orderId);

      return { summary: summaryResponse, orderDetails: orderDetailsResponse };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBasicData = createAsyncThunk('startReport/getBasicData', async (orderId, { rejectWithValue }) => {
  try {
    const response = await startReportService.getBasicData(orderId);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateContacts = createAsyncThunk(
  'startReport/updateContacts',
  async (newContacts, { rejectWithValue, getState }) => {
    try {
      const { orderId } = getState().startReport;
      const response = await startReportService.updateContacts(orderId, newContacts);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getNegativeData = createAsyncThunk('startReport/getNegativeData', async (orderId, { rejectWithValue }) => {
  try {
    const response = await startReportService.getNegativeData(orderId);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getFinancialData = createAsyncThunk(
  'startReport/getFinancialData',
  async (orderId, { rejectWithValue }) => {
    try {
      const response = await startReportService.getFinancialData(orderId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOptionalFinancialStatements = createAsyncThunk(
  'startReport/getOptionalFinancialStatements',
  async (companyCode, { rejectWithValue }) => {
    try {
      const response = await startReportService.getOptionalFinancialStatements(companyCode);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getChildOrderIdFinancialStatement = createAsyncThunk(
  'startReport/getChildOrderIdFinancialStatement',
  async ({ companyCode, optionalObj }, { rejectWithValue }) => {
    try {
      const response = await startReportService.getChildOrderIdFinancialStatement(companyCode, optionalObj);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getFinancialStatements = createAsyncThunk(
  'startReport/getFinancialStatements',
  async (childOrderId, { rejectWithValue }) => {
    try {
      const response = await startReportService.getFinancialStatements(childOrderId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getChildNumberOfQueries = createAsyncThunk(
  'start/getChildNumberOfQueries',
  async (childOrderId, { rejectWithValue }) => {
    try {
      const response = await startReportService.getChildNumberOfQueries(childOrderId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getChildVehiclesValue = createAsyncThunk(
  'start/getChildVehiclesValue',
  async (childOrderId, { rejectWithValue }) => {
    try {
      const response = await startReportService.getChildVehiclesValue(childOrderId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getChildAutomatedCreditLimit = createAsyncThunk(
  'start/getChildAutomatedCreditLimit',
  async (childOrderId, { rejectWithValue }) => {
    try {
      const response = await startReportService.getChildAutomatedCreditLimit(childOrderId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getChildBankruptcyRating = createAsyncThunk(
  'start/getChildBankruptcyRating',
  async (childOrderId, { rejectWithValue }) => {
    try {
      const response = await startReportService.getChildBankruptcyRating(childOrderId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getChildDefaultRating = createAsyncThunk(
  'start/getChildDefaultRating',
  async (childOrderId, { rejectWithValue }) => {
    try {
      const response = await startReportService.getChildDefaultRating(childOrderId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getChildOpenDebts = createAsyncThunk(
  'start/getChildOpenDebts',
  async (childOrderId, { rejectWithValue }) => {
    try {
      const response = await startReportService.getChildOpenDebts(childOrderId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getChildPaymentHistory = createAsyncThunk(
  'start/getChildPaymentHistory',
  async (childOrderId, { rejectWithValue }) => {
    try {
      const response = await startReportService.getChildPaymentHistory(childOrderId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getChildRegisteredArrests = createAsyncThunk(
  'start/getChildRegisteredArrests',
  async (childOrderId, { rejectWithValue }) => {
    try {
      const response = await startReportService.getChildRegisteredArrests(childOrderId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getChildUnregisteredArrests = createAsyncThunk(
  'start/getChildUnregisteredArrests',
  async (childOrderId, { rejectWithValue }) => {
    try {
      const response = await startReportService.getChildUnregisteredArrests(childOrderId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getStartReportPdf = createAsyncThunk(
  'startReport/getStartReportPdf',
  async ({ orderId }, { rejectWithValue }) => {
    try {
      return await startReportService.getStartReportPdf({ orderId });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
