import { Row, Col } from 'antd';
import Basic from 'views/MainInformation/BasicData/Basic';

const BasicData = () => {
  return (
    <>
      <Row>
        <Col span={24}>
          <Basic />
        </Col>
      </Row>
    </>
  );
};

export default BasicData;
