import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, Typography } from 'antd';

import ReportRow from 'views/Search/components/ReportRow';
import { ReactComponent as BasicDataIcon } from 'assets/img/icons/basic.svg';
import useRedirect from 'hooks/navigation/useRedirect';
import useLastPathname from 'hooks/navigation/useLastPathname';
import { DownloadPdfBtn } from '../../components/reusable/report/buttons';
import { getSpiderWebReportPdf } from '../../store/entities/spiderWeb/thunks';
import LoadingSpinner from '../../components/reusable/LoadingSpinner';

const { Paragraph } = Typography;

const OtherProducts = () => {
  const { t } = useTranslation();
  const redirect = useRedirect();
  const lastPathname = useLastPathname();
  const { basicData, spiderWebPdfLoading, selectedCompany } = useSelector(state => state.spiderWeb);
  const dispatch = useDispatch();

  return (
    <LoadingSpinner size="large" spinning={spiderWebPdfLoading}>
      <Row className="container">
        <Col span={24}>
          {lastPathname === 'spider-web' && selectedCompany && (
            <DownloadPdfBtn
              fetcher={() =>
                dispatch(
                  getSpiderWebReportPdf({
                    companyCode: selectedCompany,
                  })
                )
              }
              reportType="spiderWeb"
              className="spider-web-pdf-download"
              disabled={!basicData?.company || lastPathname === 'monitorings'}
            />
          )}

          <Card
            className="card-simple card-simple-padding-none space-vertical-sm has-shadow"
            title={t('otherProducts.selectProduct.title')}
            style={{ width: '100%' }}
          >
            <Row gutter={[32, 16]}>
              <Col xs={24} md={24} lg={24} xl={10}>
                <Paragraph>{t('otherProducts.selectProduct.paragraph')}</Paragraph>
              </Col>
              <Col xs={24} md={24} lg={24} xl={14}>
                <ReportRow
                  key="creditMonitoring"
                  btnText="global.selectMonitoring"
                  reportName={t('otherProducts.selectProduct.creditMonitoring')}
                  onBtnClick={() => {
                    redirect('/monitorings/credit-monitoring');
                  }}
                />
                <ReportRow
                  key="webMonitoring"
                  btnText="global.selectMonitoring"
                  reportName={t('otherProducts.selectProduct.webMonitoring')}
                  onBtnClick={() => {
                    redirect('/monitorings/web-monitoring');
                  }}
                />
                <BasicDataIcon className="card-icon" />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </LoadingSpinner>
  );
};

export default OtherProducts;
