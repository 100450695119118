import React, { useMemo, useCallback, useState } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { useTranslation } from 'react-i18next';

import useResponsiveContainer from 'hooks/graph/useResponsiveContainer';
import sections from './sections';
import { defaultRatingPercentages, bankruptcyRatingPercentages } from 'constants/rating';

import ScoreIndicator from './ScoreIndicator';
import useIsUiHidden from '../../../../hooks/dom/useIsUiHidden';

const CreditScore = ({ value = 4, percentage = 0.87, id = 'creditScore', type = 'defaultRating' }) => {
  const { t } = useTranslation();
  const [{ width, height }, setContainerNode] = useResponsiveContainer(220);
  const [activeIndex, setActiveIndex] = useState(null);
  const isUiHidden = useIsUiHidden();
  const callbackRef = useCallback(
    node => {
      setContainerNode(node);
    },
    [setContainerNode]
  );

  const reversedSections = sections.slice().reverse();

  const graphSections = useMemo(() => {
    const percentages = type === 'defaultRating' ? defaultRatingPercentages : bankruptcyRatingPercentages;
    return reversedSections.map(section => ({
      ...section,
      percentages: percentages.find(({ score }) => score === section.score).percentages,
    }));
  }, []);

  const data = useMemo(
    () =>
      graphSections.map(({ score, active, ...otherAttributes }) => ({
        active: value === score,
        score,
        ...otherAttributes,
      })),
    [value]
  );

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const onPieLeave = () => setActiveIndex(null);

  return (
    <div className="chart" ref={callbackRef}>
      <PieChart width={isUiHidden ? 343.34375 : width} height={160}>
        <text
          x={(isUiHidden ? 343.34375 : width) / 2}
          y={height / 2.3}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={isUiHidden ? 15 : 20}
        >
          {`${percentage.toLocaleString()} %`}
        </text>
        <defs>
          <linearGradient id={`${id}_gradient1`}>
            <stop offset="0%" stopColor="rgb(255, 0, 0)" stopOpacity="1" />
            <stop offset="100%" stopColor="rgb(255, 0, 0) " stopOpacity="1" />
          </linearGradient>
          <linearGradient id={`${id}_gradient2`}>
            <stop offset="0%" stopColor="rgb(255, 91, 0)" stopOpacity="1" />
            <stop offset="100%" stopColor="rgb(255, 0, 0)" stopOpacity="1" />
          </linearGradient>
          <linearGradient id={`${id}_gradient3`}>
            <stop offset="0%" stopColor="rgb(255, 174, 0)" stopOpacity="1" />
            <stop offset="100%" stopColor="rgb(255, 91, 0)" stopOpacity="1" />
          </linearGradient>
          <linearGradient id={`${id}_gradient4`}>
            <stop offset="0%" stopColor="rgb(255, 207, 0)" stopOpacity="1" />
            <stop offset="100%" stopColor="rgb(255, 174, 0)" stopOpacity="1" />
          </linearGradient>
          <linearGradient id={`${id}_gradient5`}>
            <stop offset="0%" stopColor="rgb(255, 245, 0)" stopOpacity="1" />
            <stop offset="100%" stopColor="rgb(255, 207, 0)" stopOpacity="1" />
          </linearGradient>
          <linearGradient id={`${id}_gradient6`}>
            <stop offset="0%" stopColor="rgb(197, 226, 1)" stopOpacity="1" />
            <stop offset="100%" stopColor="rgb(255, 245, 0)" stopOpacity="1" />
          </linearGradient>
          <linearGradient id={`${id}_gradient7`}>
            <stop offset="0%" stopColor="rgb(51, 178, 2)" stopOpacity="1" />
            <stop offset="100%" stopColor="rgb(197, 226, 1)" stopOpacity="1" />
          </linearGradient>
          <linearGradient id={`${id}_gradient8`}>
            <stop offset="0%" stopColor="rgb(51, 178, 2)" stopOpacity="1" />
            <stop offset="100%" stopColor="rgb(51, 178, 2)" stopOpacity="1" />
          </linearGradient>
        </defs>
        <Pie
          activeIndex={activeIndex}
          cy="80%"
          data={data}
          startAngle={0}
          endAngle={180}
          innerRadius={100}
          outerRadius={110}
          dataKey="value"
          activeShape={<ScoreIndicator graphId={id} />}
          label={<ScoreIndicator graphId={id} isLabel />}
          labelLine={false}
          isAnimationActive={false}
          onMouseEnter={onPieEnter}
          onMouseLeave={onPieLeave}
        >
          {graphSections.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={`url(#${id}_${entry.gradientId})`} stroke="" />
          ))}
        </Pie>

        <text x={(isUiHidden ? 343.34375 : width) / 2} y={height / 1.85} textAnchor="middle" dominantBaseline="middle">
          {t('global.probability')}
        </text>
      </PieChart>
    </div>
  );
};

export default CreditScore;
