import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import useFormatDate from 'hooks/format/useFormatDate';
import { setExpertReportModalOpen } from 'store/entities/professionalReport';

import { Button, Card, Col, Row, Space, Typography } from 'antd';
import Icon from '@ant-design/icons';

import Statistic from 'components/reusable/Statistic';
import CreditScore from 'components/reusable/graphs/CreditScoreGraph';
import FactorsList from 'components/reusable/FactorsList';

import { ReactComponent as RatingIcon } from 'assets/img/icons/rating.svg';
import RestrictedAccess from '../../../components/RestrictedAccess';
import { BlockHeader } from '../../../components/reusable/BlockHeader';
import useIsUiHidden from '../../../hooks/dom/useIsUiHidden';

const { Paragraph } = Typography;

const RatingCard = params => {
  const {
    type,
    rating,
    positiveFactors,
    negativeFactors,
    setProductCode,
    userPermission,
    pdfReport = false,
    children,
  } = params;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formatDate = useFormatDate();
  const isUiHidden = useIsUiHidden();

  const expertEvaluationDate = rating?.expertRatingDate;

  const RatingClassType = {
    _0: 0,
    _1: 1,
    _2: 2,
    _3: 3,
    _4: 4,
    _5: 5,
    _6: 6,
    _7: 7,
    _8: 8,
    _9: 9,
    _10: 10,
    _99: 99,
  };

  const ratingClass = RatingClassType[rating?.ratingClass];

  return (
    <Card
      className="fill-rating-card card-simple card-simple-padding-none card-statistics space-vertical-sm has-shadow"
      style={{ width: '100%' }}
    >
      <Row justify="start">
        <Col>
          <BlockHeader
            icon={<Icon component={RatingIcon} className="icon--gray" />}
            title={t(`professionalReport.companyRatings.${type}.title`)}
          />
        </Col>
      </Row>

      {(ratingClass === 0 || ratingClass) && (
        <Row gutter={[16, 16]} justify="center" align="middle" className="no-break">
          <Col>
            <Statistic
              value={`${t('global.class')} ${ratingClass}`}
              description={t(`professionalReport.companyRatings.description.${ratingClass}`)}
            />
          </Col>
          {ratingClass > 0 && ratingClass < 11 && (
            <Col sm={24} md={12} lg={24} xl={12}>
              <CreditScore id={type} type={type} value={ratingClass} percentage={rating?.pd} />
            </Col>
          )}
          {(ratingClass === 0 || ratingClass === 99) && (
            <Col sm={24} md={12} lg={24} xl={12}>
              <Statistic
                description={`${rating?.ratingSubClass} - ${t(
                  `professionalReport.companyRatings.description.subClass.${rating.ratingSubClass}`
                )}`}
              />
            </Col>
          )}
          <Col>
            {expertEvaluationDate && (
              <Statistic
                value={formatDate(expertEvaluationDate)}
                description={t(`professionalReport.companyRatings.${type}.expertEvaluation`)}
              />
            )}
          </Col>
        </Row>
      )}
      {rating?.riskClassDescription && !rating?.pd && (
        <Row justify="space-between" align="middle" className="space-bottom-md no-break">
          <span>{rating?.riskClassDescription}</span>
          <Button onClick={() => dispatch(setExpertReportModalOpen(true))}>
            {t('professionalReport.companyRatings.recommendMessage.button')}
          </Button>
        </Row>
      )}

      {(positiveFactors || negativeFactors) && rating?.pd && !expertEvaluationDate && (
        <Row className="no-break">
          {!!positiveFactors?.length && (
            <Col sm={isUiHidden ? 12 : 24} md={12}>
              <FactorsList
                title={t('professionalReport.companyRatings.positiveFactors')}
                direction="positive"
                items={positiveFactors}
              />
            </Col>
          )}
          {!!negativeFactors?.length && (
            <Col sm={isUiHidden ? 12 : 24} md={12}>
              <FactorsList
                title={t('professionalReport.companyRatings.negativeFactors')}
                direction="negative"
                items={negativeFactors}
              />
            </Col>
          )}
        </Row>
      )}

      {!rating &&
        (pdfReport ? (
          <Row>
            <Card className="card-transparent space-vertical-sm no-break">
              {t('startReport.comapnyRating.pdf.notBought')}
            </Card>
          </Row>
        ) : (
          <Row className="no-break">
            <Col span={24}>
              <Paragraph>{t('professionalReport.companyRatings.noCriteria')}</Paragraph>
            </Col>
            <Col span={24} className="space-between">
              <Button onClick={() => dispatch(setExpertReportModalOpen(true))}>{t('global.order')}</Button>
              <RestrictedAccess permission={userPermission}>
                <Button onClick={() => setProductCode()}>{t('startReport.comapnyRating.buy.report.button')}</Button>
              </RestrictedAccess>
            </Col>
          </Row>
        ))}
      {children}
    </Card>
  );
};

export default RatingCard;
