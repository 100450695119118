import { createAsyncThunk } from '@reduxjs/toolkit';
import companyCreditLimitReportService from 'api/companyCreditLimitReportService';

export const getOrderId = createAsyncThunk(
  'companyCreditLimitReport/getOrderId',
  async (companyCode, { rejectWithValue }) => {
    try {
      const response = await companyCreditLimitReportService.getOrderId(companyCode);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBasicData = createAsyncThunk(
  'companyCreditLimitReport/getBasicData',
  async (orderId, { rejectWithValue }) => {
    try {
      const response = await companyCreditLimitReportService.getBasicData(orderId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCreditLimitReportPdf = createAsyncThunk(
  'companyCreditLimitReport/getCreditLimitReportPdf',
  async (orderId, { rejectWithValue }) => {
    try {
      const response = await companyCreditLimitReportService.getCreditLimitReportPdf(orderId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
