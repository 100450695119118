import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, Text, XAxis, YAxis } from 'recharts';

import colors from 'constants/colors';
import { economicStatus } from 'constants/prescore';
import { useTranslation } from 'react-i18next';
import useWindowSize from '../../../../hooks/dom/useWindowSize';

const getTickText = (props, data, enableSmallScreenSize) => {
  const {
    y,
    x,
    payload: { value, index },
  } = props;

  const economicObject = data[index];

  return !!enableSmallScreenSize ? (
    <>
      <foreignObject x={x + 8} y={y - 30} width={16} height={16}>
        {economicObject?.icon}
      </foreignObject>
      <Text
        x={x + 30}
        y={y - 18}
        textAnchor="start"
        verticalAnchor="end"
        style={{ fontWeight: 'normal', fontSize: 11 }}
      >
        {i18next.t(value)}
      </Text>
    </>
  ) : (
    <>
      <foreignObject x={88} y={y - 8} width={16} height={16}>
        {economicObject?.icon}
      </foreignObject>
      <Text x={112} y={y - 1} textAnchor="start" verticalAnchor="middle" style={{ fontWeight: 'normal' }}>
        {i18next.t(value)}
      </Text>
    </>
  );
};

const getBarColor = status =>
  ({
    [economicStatus.warning]: colors.COLOR_AVERAGE,
    [economicStatus.error]: colors.COLOR_SECONDARY,
    [economicStatus.ok]: colors.COLOR_SUCCESS,
    [economicStatus.none]: colors.COLOR_GRAY,
  }[status]);

const barSizeDesktop = 13;
const barSizeSmall = 8;

const getBar = (x, y, width, isNormal, barSize) => {
  const halfBarSize = barSize / 2;

  return `
M ${x + (isNormal ? halfBarSize : -halfBarSize)}, ${y + barSize}
A ${-halfBarSize}, ${-halfBarSize}, 0, ${isNormal ? '0, 1' : '1, 0'}, ${
    x + (isNormal ? halfBarSize : -halfBarSize)
  }, ${y}
L ${x + width - halfBarSize}, ${y}
A ${-halfBarSize}, ${-halfBarSize}, 0, 1, 0, ${x + width}, ${y + halfBarSize}
A ${-halfBarSize}, ${-halfBarSize}, 0, 1, 0, ${x + width - halfBarSize}, ${y + barSize}
Z`;
};

const getCustomRoundedBar = props => {
  const { fill, x, y, width, uv, barSize } = props;
  const isBarSizeForSmallScreen = barSize > 8;

  if (uv > 0) {
    return (
      <>
        <path d={getBar(x, y, width, false, barSize)} stroke="none" fill={fill} />
        <circle
          cx={x - (isBarSizeForSmallScreen ? 10 : 5)}
          cy={y + (isBarSizeForSmallScreen ? 6 : 4)}
          r={barSize > 8 ? 10 : 7}
          stroke={getBarColor(props.payload.status)}
          strokeWidth="2"
          fill={fill}
        />
      </>
    );
  }

  return <path d={getBar(x, y, width, true, barSize)} stroke="none" fill={fill} />;
};

const CreditHistoryLabel = ({ x, y, value, barSizeSmall = false, enableCreditHistoryBar }) => {
  const { t } = useTranslation();
  let text = t('prescoreReport.prescore.graph.detailed_information_provided_detailed_report');

  if (!value.includes('history') || enableCreditHistoryBar) return null;

  // // SVG text element can't auto wrap, so need to force it to break
  // if (barSizeSmall) {
  //   const words = text.split(' ');
  //   const halfLength = Math.floor(words.length / 2);
  //   const firstHalf = words.slice(0, halfLength).join(' ');
  //   const secondHalf = words.slice(halfLength).join(' ');

  //   text = (
  //     <>
  //       <tspan x={x} y={y + 10} dy="1.2em">
  //         {firstHalf}
  //       </tspan>
  //       <tspan x={x} y={y + 20} dy="1.2em">
  //         {secondHalf}
  //       </tspan>
  //     </>
  //   );
  // }

  return (
    <text
      x={x}
      y={y + 39}
      dy={-10}
      fontSize={!barSizeSmall ? 11 : 10}
      textAnchor="start"
      fill="#66666"
      lengthAdjust="spacing"
    >
      {text}
    </text>
  );
};

const PrescoreChart = ({ data, enableCreditHistoryBar = false }) => {
  const { width: windowWidth } = useWindowSize();

  const separateCreditHistoryBarIfNotVisible = data => {
    if (enableCreditHistoryBar) return data;

    const dataClone = [...data];
    const i = dataClone.findIndex(item => item.name.includes('history'));
    dataClone[i].uv = 0;
    dataClone[i].amt = 6000;
    dataClone[i].value = 4;

    return dataClone;
  };

  return windowWidth > 750 ? (
    <ResponsiveContainer width="100%" height={200} debounce={50} style={{ top: -50 }}>
      <BarChart
        height={130}
        data={separateCreditHistoryBarIfNotVisible(data)}
        layout="vertical"
        margin={{ right: 75, left: 200 }}
      >
        <YAxis
          type="category"
          dataKey="name"
          interval={0}
          minTickGap={0}
          axisLine={false}
          tickLine={false}
          tick={props => getTickText(props, data)}
        />
        <XAxis type="number" hide />
        <Bar dataKey="uv" stackId="bar" radius={36} barSize={barSizeDesktop}>
          <LabelList dataKey="name" content={<CreditHistoryLabel enableCreditHistoryBar={enableCreditHistoryBar} />} />
          {data.map(entry => (
            <Cell key={entry?.name} fill={getBarColor(entry?.status)} />
          ))}
        </Bar>
        <Bar
          dataKey="amt"
          fill="#ededed"
          stackId="bar"
          radius={36}
          barSizeDesktop={barSizeDesktop}
          shape={props => getCustomRoundedBar({ ...props, barSize: 13 })}
        />
      </BarChart>
    </ResponsiveContainer>
  ) : (
    <ResponsiveContainer width="100%" height={250} debounce={50}>
      <BarChart
        height={130}
        data={separateCreditHistoryBarIfNotVisible(data)}
        layout="vertical"
        margin={{ top: 10, left: -60 }}
      >
        <YAxis
          type="category"
          dataKey="name"
          interval={0}
          minTickGap={0}
          axisLine={false}
          tickLine={false}
          tick={props => getTickText(props, data, true)}
        />
        <XAxis type="number" hide />
        <Bar dataKey="uv" stackId="bar" radius={36} barSize={barSizeSmall}>
          <LabelList
            dataKey="name"
            content={<CreditHistoryLabel barSizeSmall enableCreditHistoryBar={enableCreditHistoryBar} />}
          />
          {data.map(entry => (
            <Cell key={entry?.name} fill={getBarColor(entry?.status)} />
          ))}
        </Bar>
        <Bar
          dataKey="amt"
          fill="#ededed"
          stackId="bar"
          radius={36}
          barSizeDesktop={barSizeDesktop}
          shape={props => getCustomRoundedBar({ ...props, barSize: 8 })}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default PrescoreChart;
