import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form, Input, Row, Col, Typography } from 'antd';
import { resetPassword } from '../../store/entities/user/thunks';
import { useDispatch } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import useCurrentLanguage from '../../hooks/user/useCurrentLanguage';

const { Title } = Typography;

const ForgotPassword = ({ setLoginOption }) => {
  const { t } = useTranslation();
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const lang = useCurrentLanguage();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleFinishForm = async values => {
    const token = await executeRecaptcha('login');
    await dispatch(
      resetPassword({
        ...values,
        languageCode: lang,
        token: token || '',
      })
    );
    setEmailSuccess(true);
  };

  return (
    <>
      <Row>
        {emailSuccess ? (
          <Col span={24}>
            <Title level={3} className="login-title">
              {t('login.forgotPassword.form.title.success')}{' '}
            </Title>
          </Col>
        ) : (
          <Col span={24} className="login-subtitle">
            {t('login.forgotPassword.form.title')}
          </Col>
        )}

        {emailSuccess ? (
          <Row>
            <Col>
              <p className="login-password-reset-success">{t('login.forgotPassword.success')}</p>
            </Col>
            <Col span={24}>
              <Button className="login-btn btn-xl__secondary-green" onClick={() => setLoginOption('registered')}>
                {t('login.option.registeredUsers')}
              </Button>
            </Col>
          </Row>
        ) : (
          <Col>
            <Form
              form={form}
              name="forgotPassword"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              onFinish={handleFinishForm}
              autoComplete="off"
              requiredMark={false}
            >
              <Form.Item
                rules={[{ required: true, message: t('login.forgotPassword.required') }]}
                label={t('login.forgotPassword.label')}
                name="usernameOrEmail"
              >
                <Input placeholder={t('placeholder.email')} className="login-block-input" />
              </Form.Item>

              <Row justify="space-between">
                <Col xs={24} md={11}>
                  <Button onClick={() => setLoginOption('registered')}>{t('global.back')}</Button>
                </Col>
                <Col xs={24} md={11}>
                  <Form.Item>
                    <Button className="login-btn btn-xl__secondary-green" htmlType="submit">
                      {t('login.forgotPassword.button')}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        )}
      </Row>
    </>
  );
};

export default ForgotPassword;
