import React from 'react';

import CollapsibleCard from 'components/reusable/CollapsibleCard';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';

import { Col, Row, Table } from 'antd';

const CollapsibleTable = ({
  title,
  data,
  rowKey,
  columns,
  className,
  disclaimer,
  rowClickable,
  onRowClick,
  showSorterTooltip = false,
  pagination = { defaultPageSize: 10, showSizeChanger: true },
  scroll = { x: true },
  bordered,
  isCardCollapsed,
  ...otherProps
}) => {
  const isUiHidden = useIsUiHidden();

  const tableProps = {
    rowKey: rowKey,
    dataSource: data,
    columns: columns,
    scroll: scroll,
    pagination,
    showSorterTooltip,
    ...otherProps,
    ...(rowClickable && { rowClassName: 'ant-table-row-clickable' }),
    ...(onRowClick && {
      onRow: (record, rowIndex) => {
        return {
          onClick: event => onRowClick(record, rowIndex),
        };
      },
    }),
  };

  return !isUiHidden ? (
    <CollapsibleCard className={className} title={title} isCardCollapsed={isCardCollapsed}>
      {disclaimer && (
        <Row>
          <Col>
            <div className="space-bottom-sm">
              <span>{disclaimer}</span>
            </div>
          </Col>
        </Row>
      )}
      <Table rowKey={rowKey} bordered={bordered} {...tableProps} />
    </CollapsibleCard>
  ) : data?.length ? (
    <CollapsibleCard title={title}>
      {disclaimer && (
        <Row>
          <Col>
            <div className="space-bottom-sm">
              <span>{disclaimer}</span>
            </div>
          </Col>
        </Row>
      )}
      <Table rowKey={rowKey} bordered={bordered} {...tableProps} />
    </CollapsibleCard>
  ) : null;
};

export default CollapsibleTable;
