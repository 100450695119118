import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Sidebar from './Sidebar';
import Header from './Header';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import ReportStatisticsCard from 'views/Search/CompanySearch/ReportStatisticsCard';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import FeedbackModal from './FeedbackModal';

import { Layout } from 'antd';

const { Content, Footer } = Layout;

const AppLayout = ({ children }) => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();
  const { hasSession, languageChangePending } = useSelector(state => state.user);

  return (
    <LoadingSpinner size="large" spinning={languageChangePending}>
      <Layout style={{ minHeight: '100vh' }}>
        {!isUiHidden && <Sidebar />}
        <Layout>
          <FeedbackModal />
          {!isUiHidden && <Header />}
          <Content>{children}</Content>
          {!isUiHidden && hasSession && (
            <Footer>
              <ReportStatisticsCard />
              {t('footer.contact')}
            </Footer>
          )}
        </Layout>
      </Layout>
    </LoadingSpinner>
  );
};

export default AppLayout;
