import { createAsyncThunk } from '@reduxjs/toolkit';
import mainInformationService from 'api/mainInformationService';

export const getOrderId = createAsyncThunk('mainInformation/getOrderId', async (companyCode, { rejectWithValue }) => {
  try {
    const response = await mainInformationService.getOrderId(companyCode);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getBasicInfo = createAsyncThunk('mainInformation/getBasicInfo', async (orderId, { rejectWithValue }) => {
  try {
    const response = await mainInformationService.getBasicInfo(orderId);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getOrderDetails = createAsyncThunk(
  'mainInformation/getOrderDetails',
  async ({ companyCode, orderId }, { rejectWithValue }) => {
    try {
      const response = await mainInformationService.getOrderDetails(companyCode, orderId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getMainInformationPdf = createAsyncThunk(
  'mainInformation/getMainInformationPdf',
  async (orderId, { rejectWithValue }) => {
    try {
      const response = await mainInformationService.getMainInformationPdf(orderId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
