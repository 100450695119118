import { Card, Col, Row, Table } from 'antd';
import { BlockHeader } from './BlockHeader';
import { useTranslation } from 'react-i18next';
import useNumberFormatter from '../../hooks/format/useNumberFormatter';
import { ReactComponent as SimilarIcon } from 'assets/img/icons/similar.svg';

const SimilarCompanies = ({ similarCompanies }) => {
  const { t } = useTranslation();
  const formatNumber = useNumberFormatter({ style: 'decimal' });

  const columns = [
    {
      title: t('professionalReport.financialData.similarCompanies.companyName'),
      dataIndex: 'companyName',
      key: 'companyName',
      render: (companyName, similarCompany) => (
        <a href={`/search/company/${similarCompany.companyCode}`} target="_blank">
          {companyName}
        </a>
      ),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('professionalReport.financialData.similarCompanies.revenue'),
      dataIndex: 'revenue',
      key: 'revenue',
      render: revenue => formatNumber(revenue),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('professionalReport.financialData.similarCompanies.numberOfEmployees'),
      dataIndex: 'numberOfEmployees',
      key: 'numberOfEmployees',
      sortDirections: ['ascend', 'descend'],
    },
  ];

  return (
    <>
      <Card className="card-simple card-simple-padding-none space-vertical-sm has-shadow" style={{ width: '100%' }}>
        <Row justify="start" align="middle">
          <Col>
            <BlockHeader title={t('professionalReport.financialData.similarCompanies.title')} icon={<SimilarIcon />} />
          </Col>
        </Row>
        {similarCompanies?.similarCompaniesDetails?.length ? (
          <>
            <Row>
              <Col>
                <div className="space-bottom-sm">
                  <span>{`${t('professionalReport.financialData.similarCompanies.activity')}: ${
                    similarCompanies?.activityCode
                  } - ${similarCompanies?.activityName}`}</span>
                </div>
              </Col>
            </Row>
            <Table
              dataSource={similarCompanies?.similarCompaniesDetails}
              pagination={{ defaultPageSize: 10, showSizeChanger: true }}
              columns={columns}
              scroll={{ x: 'max-content' }}
              bordered
            />
          </>
        ) : (
          <Row>
            <Card className="card-transparent">
              {t('professionalReport.financialData.similarCompanies.noInformation')}
            </Card>
          </Row>
        )}
      </Card>
    </>
  );
};

export default SimilarCompanies;
