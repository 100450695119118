import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useCurrentLanguage from 'hooks/user/useCurrentLanguage';
import { b64toBlob, downloadAsFile } from 'utils/download';

import { getOrderId } from 'store/entities/companyCreditLimitReport/thunks';
import { resetCompanyCreditLimitReport } from 'store/entities/companyCreditLimitReport';
import useRedirect from 'hooks/navigation/useRedirect';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import { getSelectedCompanyData } from 'store/entities/company/thunks';
import ReportHeader from '../../components/reusable/report/ReportHeader';
import CompanyCreditLimitReport from './CompanyCreditLimitReport';
import { useCreditLimitPdfFilename } from 'hooks/report/professionalReport/usePdfFilename';
import { getCreditLimitReportPdf } from 'store/entities/companyCreditLimitReport/thunks';

import { PrinterOutlined } from '@ant-design/icons';
import { Col, Row, Button } from 'antd';

const CompanyCreditLimit = () => {
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const { t } = useTranslation();
  const lang = useCurrentLanguage();
  const pdfFileName = useCreditLimitPdfFilename();

  const { orderId, basicDataPending, creditLimitReportPdfPending } = useSelector(
    state => state.companyCreditLimitReport
  );
  const { selectedCompanyData } = useSelector(state => state.company);

  const orderReport = useCallback(async () => {
    const orderIdResult = await dispatch(getOrderId(companyId));

    if (!getOrderId.fulfilled.match(orderIdResult)) {
      redirect('/search/company');
    }
  }, [dispatch, redirect, getOrderId]);

  useEffect(() => {
    orderReport();

    return () => dispatch(resetCompanyCreditLimitReport());
  }, []);

  useEffect(() => {
    companyId && dispatch(getSelectedCompanyData(companyId));
  }, [companyId, lang]);

  const handlePdfDownload = async () => {
    const response = await dispatch(getCreditLimitReportPdf(orderId));

    const data = b64toBlob(response.payload.data);
    downloadAsFile(data, pdfFileName);
  };

  return (
    <LoadingSpinner size="large" spinning={basicDataPending || creditLimitReportPdfPending}>
      <div className="container company-credit-limit">
        <Row>
          <Col span={24}>
            <ReportHeader
              title={selectedCompanyData?.name}
              actions={
                <Button onClick={handlePdfDownload}>
                  <PrinterOutlined />
                  {t('global.print')}
                </Button>
              }
            />
            {orderId && <CompanyCreditLimitReport />}
          </Col>
        </Row>
      </div>
    </LoadingSpinner>
  );
};

export default CompanyCreditLimit;
