const NumberOfEmployeesCombinedLabel = props => {
  const { x, y, width, value, type } = props;
  const showUpper =
    value.employeesNumber + 1 === value.employeesNumberInSectors ||
    (value.employeesNumber > value.employeesNumberInSectors * 0.85 &&
      value.employeesNumber < value.employeesNumberInSectors);

  const EmployeeLabel = (
    <g>
      <text
        x={x + width / 2 || x}
        y={y - (type === 'line' ? 14 : 10)}
        fill="#000"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        <tspan fill="#C40F11">{value.employeesNumber}</tspan>/
        <tspan fill="#0C9CB2">{value.employeesNumberInSectors}</tspan>
      </text>
    </g>
  );

  if (type === 'line' && showUpper) {
    return EmployeeLabel;
  } else if (type === 'bar' && !showUpper) {
    return EmployeeLabel;
  }

  return null;
};

export default NumberOfEmployeesCombinedLabel;
