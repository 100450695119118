import React from 'react';
import { useTranslation } from 'react-i18next';

import { Space, Typography } from 'antd';

import { ReactComponent as ArrowUpIcon } from 'assets/img/icons/arrowGreenUp.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/img/icons/arrowRedDown.svg';

import Icon from '@ant-design/icons';

const { Title } = Typography;

const FactorsList = ({ title, direction = 'positive', items = [] }) => {
  const { t } = useTranslation();

  const translatedFactors = [...items.map(item => t(`professionalReport.companyRatings.${item}`))];
  const uniqueFactors = [...new Set(translatedFactors)];

  return (
    <>
      {title && <Title level={5}>{title}</Title>}
      <ul className="list-style-none">
        {uniqueFactors.map(factor => (
          <li key={`${title}-${factor}`}>
            <Space size="middle" className="space-vertical-none">
              <Icon component={direction === 'positive' ? ArrowUpIcon : ArrowDownIcon} />
              <span>{factor}</span>
            </Space>
          </li>
        ))}
      </ul>
    </>
  );
};

export default FactorsList;
