import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import { DATE_FORMAT_YEAR_ONLY } from 'hooks/format/useFormatDate';
import { profitMargin } from 'store/entities/professionalReport/selectors/financialDataSelectors';

import ComposedChart from 'components/reusable/graphs/ComposedChart';

import { Card, Col, Row, Space, Table } from 'antd';
import Icon from '@ant-design/icons';

import { ReactComponent as PeopleIcon } from 'assets/img/icons/people.svg';
import { BlockHeader } from '../../../components/reusable/BlockHeader';
import useNumberFormatter from 'hooks/format/useNumberFormatter';

const ProfitMargin = () => {
  const { t } = useTranslation();
  const { profitMarginChart, profitMarginTable, financialProfitMargin } = useSelector(profitMargin);
  const isUiHidden = useIsUiHidden();
  const formatNumber = useNumberFormatter({ minimumFractionDigits: 2, maximumFractionDigits: 2 });

  const filterProfitMargins = margins => {
    const marginsList = margins;
    for (var i = 0; i < marginsList.length; i++) {
      if (marginsList[i].profitMargin === undefined) {
        marginsList.splice(i, 1);
        i--;
      }
    }
    return marginsList;
  };

  const profitMarginColumns = useMemo(() => {
    return [
      ...financialProfitMargin
        .slice(0, 10)
        .map(({ financialYear }) => {
          return {
            title: `${financialYear}`,
            dataIndex: `${financialYear}`,
            key: `${financialYear}`,
            render: value => (value ? formatNumber(value) : '-'),
          };
        })
        .reverse()
        .filter(item => (isUiHidden ? parseInt(item.dataIndex) >= new Date().getFullYear() - 9 : true)),
    ];
  }, [t, financialProfitMargin]);

  return (
    <>
      <Card
        className="card-simple card-simple-padding-none space-vertical-sm has-shadow no-break"
        style={{ width: '100%' }}
      >
        <Row justify="start" align="middle">
          <Col>
            <BlockHeader
              icon={<Icon component={PeopleIcon} className="icon--gray" />}
              title={t('professionalReport.financialData.profitMargin.title')}
            />
          </Col>
        </Row>
        <Row className="space-vertical-md no-break">
          <Col md={24} lg={24}>
            <ComposedChart
              id="profitMargin"
              series={[
                {
                  type: 'area',
                  dataKey: 'profitMargin',
                  stroke: '#0C9CB2',
                  minusValuesLabelUnder: true,
                },
              ]}
              activeDefs
              data={filterProfitMargins(profitMarginChart)}
              xAxisDataKey="date"
              showLegend={false}
              xAxisFormat={DATE_FORMAT_YEAR_ONLY}
              noCustomLabel
              lazyLoaded
            />
            <Table
              rowKey={record => record.profitMargin}
              bordered
              dataSource={profitMarginTable}
              columns={profitMarginColumns}
              scroll={{ x: 'max-content' }}
              pagination={false}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ProfitMargin;
