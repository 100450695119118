import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  siderCollapsed: false,
  isUiHidden: false,
};

const slice = createSlice({
  name: 'ui',
  initialState: initialState,
  reducers: {
    setSiderCollapsed: (state, { payload }) => {
      state.siderCollapsed = payload;
    },
    setIsUiHidden: (state, { payload }) => {
      state.isUiHidden = payload;
    },
  },
  extraReducers: {},
});

export const { setSiderCollapsed, setIsUiHidden } = slice.actions;

export default slice.reducer;
