import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setActiveTab } from 'store/entities/professionalReport';
import { MailOutlined } from '@ant-design/icons';

import VisibilitySensor from 'react-visibility-sensor';
import { Button } from 'antd';
import UpdateContactInfo from './UpdateContactInfo';
import CompanyDataCard, { CompanyDataRow } from 'components/reusable/CompanyDataCard';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';

const Contact = ({ contactData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const isUiHidden = useIsUiHidden();

  return (
    <div id="main-information contact">
      <VisibilitySensor
        offset={{ bottom: 50 }}
        scrollCheck
        intervalDelay={10}
        onChange={isVisible => isVisible && dispatch(setActiveTab('contact'))}
      >
        <CompanyDataCard
          icon={<MailOutlined />}
          title={t('mainInformation.contactInformation.title')}
          extra={
            !isUiHidden ? (
              <Button
                type="button"
                onClick={() => {
                  setUpdateModalOpen(true);
                }}
              >
                {t('mainInformation.contactInformation.update')}
              </Button>
            ) : (
              false
            )
          }
        >
          <CompanyDataRow
            type={t('mainInformation.contactInformation.legalAddress')}
            value={contactData?.address ?? ''}
          />
          <CompanyDataRow
            type={t('mainInformation.contactInformation.officeAddress')}
            value={contactData?.visitAddress ?? ''}
          />
          <CompanyDataRow type={t('mainInformation.contactInformation.phone')} value={contactData?.phone ?? ''} />
          <CompanyDataRow type={t('mainInformation.contactInformation.mobile')} value={contactData?.mobile ?? ''} />
          <CompanyDataRow type={t('mainInformation.contactInformation.email')} value={contactData?.email ?? ''} />
          <CompanyDataRow
            type={t('mainInformation.contactInformation.webpage')}
            value={
              <a
                href={contactData?.uri ?? ''}
                rel="external noreferrer noopener"
                referrerPolicy="no-referrer-when-downgrade strict-origin-when-cross-origin"
                target="_blank"
              >
                {contactData?.uri ?? ''}
              </a>
            }
          />
        </CompanyDataCard>
      </VisibilitySensor>
      <UpdateContactInfo modalOpen={updateModalOpen} setModalOpen={setUpdateModalOpen} initialValues={[]} />
    </div>
  );
};

export default Contact;
