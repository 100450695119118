import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';

import personHooks from 'store/entities/person/selectors';

const IndividualReportBreadcrumb = props => {
  const { match, location } = props;
  const { t } = useTranslation();
  const person = useSelector(personHooks.selectPersonInformation);

  if (person === null) {
    return <></>;
  }

  const { firstName, lastName, personCode } = person;

  const fullTitle = () => {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    } else {
      return `${personCode}`;
    }
  };

  const breadcrumbsArr = location.pathname.split('/').slice(1);

  return location.pathname
    .split('/')
    .slice(1)
    .map((urlPart, i, arr) => (
      <Breadcrumb.Item key={`breadcrumbItem-${urlPart}`} href={`/${breadcrumbsArr.slice(0, i + 1).join('/')}`}>
        {urlPart === match.params.individualId
          ? fullTitle()
          : t(`header.breadcrumb.${i === arr.length - 1 ? `individual.${urlPart}` : urlPart}`)}
      </Breadcrumb.Item>
    ));
};

export default IndividualReportBreadcrumb;
