import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useCurrentLanguage from 'hooks/user/useCurrentLanguage';
import { getFinancialData } from 'store/entities/professionalReport/thunks';
import {
  financialDataStatement,
  financialRatiosTable,
} from 'store/entities/professionalReport/selectors/financialDataSelectors';

import { Row, Col, Skeleton } from 'antd';

import Revenue from './Revenue';
import SimilarCompanies from '../../../components/reusable/SimilarCompanies';
import ProfitMargin from './ProfitMargin';
import FinancialRatioTable from 'components/reusable/report/tables/FinancialRatioTable';
import { useTranslation } from 'react-i18next';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import ExpertEvalutationTable from '../../../components/reusable/report/tables/ExpertEvaluationTable';
import { DetailedFinancialStatements } from '../../../components/reusable/DetailedFinancialStatements';

const FinancialData = () => {
  const dispatch = useDispatch();
  const lang = useCurrentLanguage();
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();

  const { orderId, financialData, financialDataPending, financialDataError } = useSelector(
    state => state.professionalReport
  );

  const { financialRatiosTableData, ratioTimeline } = useSelector(financialRatiosTable);
  const { financialStatement, financialStatementDetails } = useSelector(financialDataStatement);

  useEffect(() => {
    if ((orderId && financialData && lang !== financialData.lang) || (!financialData && !financialDataError))
      dispatch(getFinancialData(orderId));
  }, [lang]);

  return (
    <Row>
      {isUiHidden && (
        <Col span={24}>
          <h2>{t('professionalReport.tab.financialData')}</h2>
        </Col>
      )}
      <Col span={24}>
        {financialData && !financialDataPending ? (
          <>
            <Revenue />
            <ProfitMargin />
            <DetailedFinancialStatements
              financialStatement={financialStatementDetails}
              statement={financialStatement}
              profReport={true}
            />
            <FinancialRatioTable
              financialRatiosTableData={financialRatiosTableData}
              ratioTimeline={ratioTimeline.slice(0, 3)}
            />
            <ExpertEvalutationTable expertEvaluations={financialData.expertEvaluations} />
            <SimilarCompanies similarCompanies={financialData.similarCompany} />
          </>
        ) : (
          <Skeleton />
        )}
      </Col>
    </Row>
  );
};

export default FinancialData;
