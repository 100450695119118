import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';

import { setActiveTab } from 'store/entities/professionalReport';
import { basicPressReleases } from 'store/entities/professionalReport/selectors/basicDataSelectors';
import PressReleasesTable from 'components/reusable/report/tables/PressReleasesTable';

import { Col, Row, Typography, Card } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import ReportBlockHeader from '../../../components/reusable/report/ReportBlockHeader';
import { CompanyDataRow } from '../../../components/reusable/CompanyDataCard';

const { Text } = Typography;

const PressReleases = ({ briefPdf }) => {
  const [customReport, setCustomReport] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pressReleases, pressReleasesVersloZinios } = useSelector(basicPressReleases);

  const { customPdfValues } = useSelector(state => state.professionalReport);

  useEffect(() => {
    if (customPdfValues && 'PressRelease' in customPdfValues) {
      setCustomReport(customPdfValues['PressRelease']);
    }
  }, [customPdfValues]);

  return (
    <div className="space-top-lg">
      <ReportBlockHeader
        icon={<FileOutlined />}
        title={t('professionalReport.basicData.press.pressReleases.title')}
        disclaimer={
          !customPdfValues &&
          t(`professionalReport.basicData.press.pressReleases.${briefPdf ? 'briefDisclaimer' : 'disclaimer'}`)
        }
      />
      {customPdfValues && !customReport ? (
        <>
          <CompanyDataRow
            type={t('professionalReport.basicData.press.count')}
            value={pressReleases?.length + pressReleasesVersloZinios?.length}
          />
          <Card className="card-transparent space-vertical-sm">
            {t('professionalReport.basicData.press.customDisclaimer')}
          </Card>
        </>
      ) : (
        <>
          <VisibilitySensor
            offset={{ bottom: 50 }}
            scrollCheck
            intervalDelay={10}
            onChange={isVisible => isVisible && dispatch(setActiveTab('press'))}
            partialVisibility
            minTopValue={200}
          >
            {pressReleases.length > 0 ? (
              <PressReleasesTable pressReleases={briefPdf ? pressReleases.slice(0, 10) : pressReleases} />
            ) : (
              <Card className="card-transparent space-vertical-sm">
                {t('professionalReport.basicData.press.noPressReleases')}
              </Card>
            )}
          </VisibilitySensor>
          <Row className="space-bottom-md">
            <Col>
              <Text level={4}>{t('professionalReport.basicData.press.vzArchive')}</Text>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24}>
              {pressReleasesVersloZinios.length > 0 ? (
                <PressReleasesTable
                  pressReleases={briefPdf ? pressReleasesVersloZinios.slice(0, 10) : pressReleasesVersloZinios}
                  type="versloZinios"
                />
              ) : (
                <Card className="card-transparent space-vertical-sm">
                  {t('professionalReport.basicData.press.noPressReleases')}
                </Card>
              )}
            </Col>
          </Row>
          {briefPdf && (
            <Row className="space-bottom-md">
              <Card className="card-transparent space-vertical-sm">
                {t('professionalReport.basicData.press.brief.disclaimer')}
              </Card>
            </Row>
          )}
        </>
      )}
    </div>
  );
};

export default PressReleases;
