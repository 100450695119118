import React from 'react';
import { Form, Input, Button, Row, Col, Typography, Space } from 'antd';
import Modal from 'components/reusable/Modal';

import updateImage from 'assets/img/update.png';

const { Title } = Typography;

const UpdateContactInfo = ({ modalOpen, setModalOpen }) => {
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: {
      sm: { span: 24 },
      md: { span: 24 },
      lg: { span: 24 },
      xl: { span: 12 },
    },
  };

  const tailLayout = {
    wrapperCol: { span: 24 },
  };

  const onFinish = values => {
    console.log('Success:', values);
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Modal isOpen={modalOpen} setIsOpen={setModalOpen} getContainer={false}>
      <div className="container">
        <Row>
          <Col>
            <Title className="space-vertical-xl">Update contact information</Title>
          </Col>
        </Row>
        <Row>
          <Col md={24} lg={12}>
            {modalOpen && (
              <Form
                form={form}
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item label="Phone" name="phone" className="margin-bottom-none">
                  <Input />
                </Form.Item>

                <Form.Item label="Mobile" name="mobile" className="margin-bottom-none">
                  <Input />
                </Form.Item>

                <Form.Item label="Email" name="email" className="margin-bottom-none">
                  <Input />
                </Form.Item>

                <Form.Item label="Webpage" name="webpage" className="margin-bottom-none">
                  <Input />
                </Form.Item>

                <Form.Item label="Office address" name="officeAddress" className="margin-bottom-none">
                  <Input />
                </Form.Item>

                <Form.Item label="IBAN" name="iban" className="margin-bottom-none">
                  <Input />
                </Form.Item>

                <Form.Item label="Bank" name="bank">
                  <Input />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Space>
                    <Button htmlType="button" onClick={() => setModalOpen(false)}>
                      Cancel
                    </Button>
                    <Button className="btn-secondary" htmlType="submit">
                      Update
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            )}
          </Col>
          <Col md={24} lg={12}>
            <Row justify="center" className="space-vertical-xl">
              <img src={updateImage} alt="update"></img>
            </Row>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default UpdateContactInfo;
