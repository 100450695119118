import { fetchFromApi } from './';

const getOrderId = companyCode => fetchFromApi(`/company/${companyCode}/report/credit-limit/order`);

const getBasicData = orderId => fetchFromApi(`/company/${orderId}/report/credit-limit/basic`);

const getCreditLimitReportPdf = orderId => fetchFromApi(`/company/${orderId}/report/credit-limit/pdf/preview`);

export default {
  getOrderId,
  getBasicData,
  getCreditLimitReportPdf,
};
