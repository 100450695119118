import { createSelector } from 'reselect';
import _pick from 'lodash.pick';

export const negativeDataUnreliableCompanies = createSelector(
  state => state.startReport?.negativeData,
  negativeData => _pick(negativeData, ['unreliableCompanies', 'unreliableCompaniesDetails'])
);

export const negativeDataBailiffsInformation = createSelector(
  state => state.startReport?.negativeData,
  negativeData => _pick(negativeData, ['bailiffsRemainders', 'bailiffsRemaindersDetails'])
);

export const negativeDataSodra = createSelector(
  state => state.startReport?.negativeData,
  negativeData => _pick(negativeData, ['sodraDebts', 'sodraDebtsHistory'])
);

export const negativeDataVmi = createSelector(
  state => state.startReport?.negativeData,
  negativeData => _pick(negativeData, ['vmiDebt', 'vmiDebtsHistory'])
);

export const negativeDataPaidDepts = createSelector(
  state => state.startReport?.negativeData,
  negativeData => _pick(negativeData, ['paidDebts', 'paidDebtsDetails'])
);

export const negativeDataOutPropertyArrests = createSelector(
  state => state.startReport?.negativeData,
  negativeData =>
    _pick(negativeData, ['unregisteredArrests', 'unregisteredArrestDetails', 'arrestAndMortageStatements'])
);

export const negativeDataPropertyArrests = createSelector(
  state => state.startReport?.negativeData,
  negativeData => _pick(negativeData, ['registeredArrests', 'registeredArrestDetails'])
);

export const negativeDataLegalProceedings = createSelector(
  state => state.startReport?.negativeData,
  negativeData =>
    _pick(negativeData, [
      'legalProceeding',
      'legalProceedingsDetailedDefendant',
      'legalProceedingsDetailedPlaintiff',
      'legalProceedingsDetailedThirdParty',
      'legalProceedingOther',
      'legalProceedingsDetailedOther',
    ])
);

export const negativeDataOpenDebts = createSelector(
  state => state.startReport?.negativeData,
  negativeData => _pick(negativeData, ['activeDebts', 'activeDebtsDetails'])
);
