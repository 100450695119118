import sanitize from 'sanitize-filename';
import useFormatDate from 'hooks/format/useFormatDate';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const usePdfFilename = () => {
  const { t } = useTranslation();
  const { orderDetails } = useSelector(state => state.professionalReport);
  const formatDate = useFormatDate();
  const date = formatDate(new Date()).replaceAll('/', '-');
  const productName = t('professionalReport.title');
  const companyName = orderDetails?.companyTitle;
  const fileName = `${companyName} - ${date} ${productName}.pdf`;

  return sanitize(fileName);
};

export const useIndividualPdfFilename = () => {
  const { t } = useTranslation();
  const { person } = useSelector(state => state.person);
  const formatDate = useFormatDate();
  const date = formatDate(new Date()).replaceAll('/', '-');
  const productName = t('professionalReport.title');
  const fullTitle = () => {
    if (person?.firstName && person?.lastName) {
      return `${person?.firstName} ${person?.lastName}`;
    } else {
      return `${t('global.personalCode.short')} ${person?.personCode}`;
    }
  };
  const fileName = `${fullTitle()} - ${date} ${productName}.pdf`;

  return sanitize(fileName);
};

export const useCreditLimitPdfFilename = () => {
  const { t } = useTranslation();
  const { basicData } = useSelector(state => state.companyCreditLimitReport);
  const formatDate = useFormatDate();
  const date = formatDate(new Date()).replaceAll('/', '-');
  const productName = t('companyCreditLimit.title');
  const companyName = basicData?.basicInformation?.companyName;
  const fileName = `${companyName} - ${date} ${productName}.pdf`;

  return sanitize(fileName);
};

export const useMainInformationPdfFilename = () => {
  const { t } = useTranslation();
  const { basicInfo } = useSelector(state => state.mainInformation);
  const formatDate = useFormatDate();
  const date = formatDate(new Date()).replaceAll('/', '-');
  const productName = t('header.breadcrumb.main-information');
  const companyName = basicInfo?.basicInformation?.companyName;
  const fileName = `${companyName} - ${date} ${productName}.pdf`;

  return sanitize(fileName);
};

export const useFinancialStatementPdfFilename = () => {
  const { t } = useTranslation();
  const { selectedCompanyData } = useSelector(state => state.company);
  const formatDate = useFormatDate();
  const date = formatDate(new Date()).replaceAll('/', '-');
  const productName = t('professionalReport.financialData.periodOfStatement.title');
  const companyName = selectedCompanyData?.name;
  const fileName = `${companyName} - ${date} ${productName}.pdf`;

  return sanitize(fileName);
};

export const usePrescorePdfFilename = () => {
  const { t } = useTranslation();
  const { basicData } = useSelector(state => state.prescoreReport);
  const formatDate = useFormatDate();
  const date = formatDate(new Date()).replaceAll('/', '-');
  const productName = t('prescoreReport.prescore.title');
  const companyName = basicData?.basicInformation?.companyName;
  const fileName = `${companyName} - ${date} ${productName}.pdf`;

  return sanitize(fileName);
};

export const useStartReportPdfFilename = () => {
  const { t } = useTranslation();
  const { orderDetails } = useSelector(state => state.startReport);
  const formatDate = useFormatDate();
  const date = formatDate(new Date()).replaceAll('/', '-');
  const productName = t('header.breadcrumb.start-report');
  const companyName = orderDetails?.companyTitle;
  const fileName = `${companyName} - ${date} ${productName}.pdf`;

  return sanitize(fileName);
};

export const useSpiderWebReportPdfFilename = () => {
  const { t } = useTranslation();
  const { basicData } = useSelector(state => state.spiderWeb);
  const formatDate = useFormatDate();
  const date = formatDate(new Date()).replaceAll('/', '-');
  const productName = t('header.breadcrumb.other-products.spider-web-report');
  const companyName = basicData?.company?.fullName || basicData?.person?.fullName;
  const fileName = `${companyName} - ${date} ${productName}.pdf`;

  return sanitize(fileName);
};
