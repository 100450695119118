import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setActivePdfModal } from 'store/entities/professionalReport';

import { Button, Row, Col, Typography, Space, Switch } from 'antd';
import Modal from 'components/reusable/Modal';
import { ReactComponent as Close } from 'assets/img/icons/close.svg';
import { Divider } from 'antd/es';
import { useState } from 'react';
import { getProfessionalReportPdf } from '../../store/entities/professionalReport/thunks';
import { useParams } from 'react-router-dom';
import { b64toBlob, downloadAsFile } from '../../utils/download';
import { usePdfFilename } from '../../hooks/report/professionalReport/usePdfFilename';

const { Title } = Typography;

const CustomReportModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [switchValues, setSwitchValues] = useState({
    licenses: false,
    carPark: false,
    companyTrademarks: false,
    pressRelease: false,
    courts: false,
  });

  const { companyId } = useParams();
  const { orderId, activePdfModal } = useSelector(state => state.professionalReport);

  const handleSwitchChange = name => value => {
    setSwitchValues(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const profReportFilename = usePdfFilename();

  const handlePdfDownload = async () => {
    dispatch(setActivePdfModal(false));
    const response = await dispatch(
      getProfessionalReportPdf({
        companyCode: companyId,
        orderId,
        customPdfValues: switchValues,
      })
    );
    const data = b64toBlob(response.payload.data);
    downloadAsFile(data, profReportFilename);
  };

  return (
    <Modal isOpen={activePdfModal} setIsOpen={isOpen => dispatch(setActivePdfModal(isOpen))} closable={false} size="sm">
      <div className="modal-puzzle-svg"></div>
      <Row>
        <Col span={20}>
          <Title level={3} className="color-secondary">
            {t('global.customPdf')}
          </Title>
        </Col>
        <Col span={4} className="justify-content-end">
          <span className="icon-btn space-top-md" role="button" onClick={() => dispatch(setActivePdfModal(false))}>
            <Close />
          </span>
        </Col>
      </Row>
      <p>
        <strong>{t('professionalReport.customPdf.modal.text')}</strong>
      </p>

      <Divider className="space-bottom-lg" />

      <Row>
        <Col span={7}></Col>
        <Col span={17}>
          {Object.entries(switchValues).map(([name, value]) => (
            <Row key={name}>
              <Col span={21}>
                <span style={{ color: '#C40F11', fontWeight: 600 }}>
                  {t(`professionalReport.customPdf.modal.${name}`)}
                </span>
                <p>{t('professionalReport.customPdf.modal.selectText')}</p>
              </Col>
              <Col span={3} style={{ alignSelf: 'center' }}>
                <Switch
                  style={{ justifySelf: 'end' }}
                  defaultChecked={false}
                  onChange={handleSwitchChange(name)}
                  checked={value}
                />{' '}
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
      <Row>
        <Col span={24} className="justify-content-end">
          <Space size="large" align="end">
            <Button type="default" className="customPdf-button" onClick={() => dispatch(setActivePdfModal(false))}>
              {t('global.cancel')}
            </Button>
            <Button className="btn-secondary customPdf-button" onClick={handlePdfDownload}>
              {t('global.generate')}
            </Button>
          </Space>
        </Col>
      </Row>
    </Modal>
  );
};

export default CustomReportModal;
