import { Space, Table } from 'antd';
import useFormatDate from '../../../../hooks/format/useFormatDate';
import { useTranslation } from 'react-i18next';
import useNumberFormatter from '../../../../hooks/format/useNumberFormatter';
import CustomExpandIcon from './CustomExpandIcon';
import React from 'react';
import useIsUiHidden from '../../../../hooks/dom/useIsUiHidden';

const ExpertEvalutationTable = ({ expertEvaluations }) => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();
  const formatDate = useFormatDate();
  const formatNumber = useNumberFormatter({ style: 'decimal' });

  const columns = [
    {
      title: t('professionalReport.financialData.expertEvaluation.evaluationDate'),
      dataIndex: 'evaluationDate',
      render: value => formatDate(value, 'P'),
      key: 'evaluationDate',
      sorter: (a, b) => {
        const dateA = new Date(a.evaluationDate);
        const dateB = new Date(b.evaluationDate);
        return dateB - dateA;
      },
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('professionalReport.financialData.expertEvaluation.recommendedLimit'),
      dataIndex: 'recommendedLimit',
      key: 'recommendedLimit',
      render: (value, record) => `${formatNumber(value)} ${record.currency}`,
      sorter: (a, b) => a.recommendedLimit - b.recommendedLimit,
      sortDirections: ['ascend', 'descend'],
    },
  ];

  const expandedRowRender = record => (
    <div>
      <p>{record.description}</p>
    </div>
  );

  if (!expertEvaluations?.length) {
    return null;
  }

  return (
    <div className="space-vertical-md">
      <Space size="large">
        <strong>{t('professionalReport.financialData.expertEvaluation')}</strong>
      </Space>
      <Table
        className="ant-table--dark"
        rowKey="evaluationDate"
        dataSource={expertEvaluations.map(item => ({
          ...item,
          key: item.evaluationDate,
        }))}
        columns={columns}
        expandIcon={props => CustomExpandIcon(props)}
        expandable={{ expandedRowRender, defaultExpandAllRows: isUiHidden }}
        pagination={{ defaultPageSize: 10, showSizeChanger: true }}
        showSorterTooltip={false}
      />
    </div>
  );
};

export default ExpertEvalutationTable;
