import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Col, Row, Button } from 'antd';
import { getFinancialData } from 'store/entities/professionalReport/thunks';
import { resetCompanyFinancialReport } from 'store/entities/companyFinancialReport';
import { getBasicData, getCompanyFinReportPerYearThunk } from 'store/entities/companyFinancialReport/thunks';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import { PrinterOutlined } from '@ant-design/icons';
import ReportHeader from '../../../components/reusable/report/ReportHeader';
import { getFinancialStatementPdf } from 'store/entities/companyFinancialReport/thunks';
import { b64toBlob, downloadAsFile } from 'utils/download';
import { useFinancialStatementPdfFilename } from 'hooks/report/professionalReport/usePdfFilename';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import { getFinancialOrderId } from 'store/entities/companyFinancialReport/thunks';
import useIsFinancialReportLoading from '../../../hooks/report/useFinancialReportState';
import { DetailedFinancialStatements } from '../../../components/reusable/DetailedFinancialStatements';

const FinancialStatementDetailedData = ({ pdf = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pdfFileName = useFinancialStatementPdfFilename();
  const { companyId, statementId } = useParams();
  const isUiHidden = useIsUiHidden();

  const { orderId } = useSelector(state => state.professionalReport);
  const { selectedCompanyData } = useSelector(state => state.company);
  const { basicData, companyYearFinReport } = useSelector(state => state.companyFinancialReport);
  const financialId = useSelector(state => state.companyFinancialReport.orderId);
  const { isLoading } = useIsFinancialReportLoading();

  const statement = basicData?.basicInformation.find(item => item.financialStatementId.toString() === statementId);

  useEffect(() => {
    (async () => {
      const basicDataRes = await dispatch(getBasicData(companyId));
      if (getBasicData.fulfilled.match(basicDataRes)) {
        const statement = basicDataRes?.payload?.basicInformation.find(
          item => item.financialStatementId.toString() === statementId
        );
        const request = {
          year: statement?.financialYear,
          financialStatementId: statementId,
        };
        const res = await dispatch(getFinancialOrderId({ companyCode: companyId, request: request }));

        if (getFinancialOrderId.fulfilled.match(res)) {
          dispatch(getCompanyFinReportPerYearThunk(res.payload.id));
        }
      }
    })();
    return () => dispatch(resetCompanyFinancialReport());
  }, [companyId]);

  useEffect(() => {
    if (orderId) {
      dispatch(getFinancialData(orderId));
    }
  }, [orderId]);

  const handlePdfDownload = async () => {
    const response = await dispatch(getFinancialStatementPdf(financialId));

    const data = b64toBlob(response.payload.data);
    downloadAsFile(data, pdfFileName);
  };

  return (
    <LoadingSpinner size="large" spinning={isLoading}>
      <div className={`${!isUiHidden ? 'container' : ''} financial-statements`}>
        <Row>
          <Col span={24}>
            {!isUiHidden && (
              <ReportHeader
                title={selectedCompanyData?.name}
                actions={
                  <Button onClick={handlePdfDownload}>
                    <PrinterOutlined />
                    {t('global.print')}
                  </Button>
                }
              />
            )}
            <DetailedFinancialStatements financialStatement={companyYearFinReport} statement={statement} />
          </Col>
        </Row>
      </div>
    </LoadingSpinner>
  );
};

export default FinancialStatementDetailedData;
