import { useLocation } from 'react-router-dom';

const useLastPathname = () => {
  const location = useLocation();

  const pathnameArray = location.pathname.split('/');
  return pathnameArray[pathnameArray.length - 1];
};

export default useLastPathname;
