import { Card, Col, Row, Space } from 'antd';
import React from 'react';
import ReportRow from '../../Search/components/ReportRow';
import WEB from '../../../assets/pdf/WEB.pdf';
import XLS from '../../../assets/pdf/XLS.pdf';
import XML from '../../../assets/pdf/XML.pdf';
import { useTranslation } from 'react-i18next';
import Paragraph from 'antd/es/typography/Paragraph';
import Icon from '@ant-design/icons';
import { ReactComponent as AddDebtIcon } from '../../../assets/img/icons/addDebt.svg';
import { BlockHeader } from '../../../components/reusable/BlockHeader';

const Instructions = () => {
  const { t } = useTranslation();

  return (
    <>
      <Row justify="start">
        <Col>
          <BlockHeader
            icon={<Icon component={AddDebtIcon} className="icon--gray" />}
            title={t('administration.tabs.instructions')}
          />
        </Col>
      </Row>
      <Card className="card-simple space-vertical-lg has-shadow">
        <Row gutter={[32, 16]} className="container">
          <Col xs={24} md={24} lg={5} xl={24}>
            <Paragraph></Paragraph>
          </Col>
          <Col xs={24} md={24} lg={19} xl={16}>
            <ReportRow
              reportName={t('administration.selectProduct.documents.web')}
              onBtnClick={() => {
                window.open(`${WEB}`, '_blank');
              }}
            />
            <ReportRow
              reportName={t('administration.selectProduct.documents.xls')}
              onBtnClick={() => {
                window.open(`${XLS}`, '_blank');
              }}
            />
            <ReportRow
              reportName={t('administration.selectProduct.documents.xml')}
              onBtnClick={() => {
                window.open(`${XML}`, '_blank');
              }}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Instructions;
