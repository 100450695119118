import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Select, Button, Input, Card } from 'antd';
import { SearchOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { getCompanies } from 'store/entities/spiderWeb/thunks';
import { resetSpiderWeb, setSelectedCompany } from 'store/entities/spiderWeb';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import countries from 'mockData/countries.json';

const { Option } = Select;

const SearchForm = ({
  setIsSpiderwebOpen,
  setSelectedCountry,
  showMoreFilters,
  handleFilterChange,
  selectedCountry,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { isAuthenticated } = useSelector(state => state.user);
  const { getSearchResultsPending } = useSelector(state => state.spiderWeb);

  const handleSubmit = values => {
    dispatch(resetSpiderWeb());
    const searchRequest = {
      searchPhraseCompany: values.searchPhraseCompany || '',
      searchPhrasePerson: values.searchPhrasePerson || '',
      country: selectedCountry,
      relationTypes: [],
    };
    if (isAuthenticated) {
      dispatch(setSelectedCompany(null));
      dispatch(getCompanies(searchRequest));
    }
  };

  const renderBasicFields = () => (
    <>
      <Col xs={24} md={12}>
        <Form.Item name="searchPhraseCompany" style={{ marginBottom: 0 }}>
          <Input placeholder={t('companySearch.companyName')} />
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item name="searchPhrasePerson" style={{ marginBottom: 0 }}>
          <Input placeholder={t('companySearch.person.name')} />
        </Form.Item>
      </Col>
    </>
  );

  const renderToggleFiltersButton = () => (
    <Form.Item style={{ marginBottom: 0 }}>
      <Button
        type="link"
        onClick={() => handleFilterChange(!showMoreFilters)}
        icon={showMoreFilters ? <MinusOutlined /> : <PlusOutlined />}
      >
        {showMoreFilters ? t('spiderwebSearch.less') : t('spiderwebSearch.more')}
      </Button>
    </Form.Item>
  );

  const renderSearchButton = () => (
    <Form.Item style={{ marginBottom: 0 }}>
      <Button type="primary" htmlType="submit" icon={<SearchOutlined />} onClick={setIsSpiderwebOpen}>
        {t('companySearch.search')}
      </Button>
    </Form.Item>
  );

  const renderMoreFilters = () => (
    <Col xs={24}>
      <Row gutter={[16, 16]}>
        {renderBasicFields()}
        <Col xs={24} md={12}>
          <Form.Item name="country" label={t('companySearch.choose.country')} style={{ marginBottom: 0 }}>
            <Select
              optionFilterProp="children"
              showSearch
              allowClear
              onChange={value => setSelectedCountry(value)}
              placeholder={t('companySearch.choose.country')}
            >
              {countries.map(country => (
                <Option key={country.value} value={country.value}>
                  {t(`search.country.${country.value}`)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
          {renderToggleFiltersButton()}
          {renderSearchButton()}
        </Col>
      </Row>
    </Col>
  );

  return (
    <Form
      form={form}
      hideRequiredMark
      layout="vertical"
      name="companySearchFilters"
      onFinish={handleSubmit}
      initialValues={{ country: 'LT' }}
    >
      <LoadingSpinner size="large" spinning={getSearchResultsPending}>
        <Card className="card-simple space-vertical-lg has-shadow">
          <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Col xs={24} lg={showMoreFilters ? 24 : 16}>
              <Row gutter={[16, 16]}>{showMoreFilters ? renderMoreFilters() : renderBasicFields()}</Row>
            </Col>
            {showMoreFilters ? null : (
              <Col
                xs={24}
                md={24}
                lg={8}
                style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}
              >
                {renderToggleFiltersButton()}
                {renderSearchButton()}
              </Col>
            )}
          </Row>
        </Card>
      </LoadingSpinner>
    </Form>
  );
};

export default SearchForm;
