import { createAsyncThunk } from '@reduxjs/toolkit';
import reportStatisticsService from 'api/reportStatisticsService';

export const getReportStatistics = createAsyncThunk('footer/getReportStatistics', async (_, { rejectWithValue }) => {
  try {
    const response = await reportStatisticsService.getReportStatistics();
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});
