import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Row, Col, Card, Checkbox, Table, Select } from 'antd';

import {
  getLegalEntities,
  addLegalEntity,
  deleteLegalEntity,
  updateLegalEntity,
  deleteAllLegalEntities,
  getIndividualEntities,
  addIndividualEntity,
  deleteIndividualEntity,
  updateIndividualEntity,
  deleteAllIndividuals,
  getPersonMonitoringSummary,
  getCompanyMonitoringSummary,
  getMonitoringSubjectsExcel,
} from 'store/entities/otherProducts/thunks';
import {
  registeredLegalEntities,
  registeredIndividualEntities,
} from 'store/entities/otherProducts/selectors/monitoringsSelectors';
import { resetOtherProducts } from 'store/entities/otherProducts';
import useCurrentLanguage from 'hooks/user/useCurrentLanguage';
import { ReactComponent as RegisteredIcon } from 'assets/img/icons/registered.svg';
import { riskClasses, riskClassesMap, riskStyleClass } from 'constants/otherProducts';
import CompanyDataCard from 'components/reusable/CompanyDataCard';
import TableColumnDate from 'components/reusable/TableColumnDate';
import { DownloadExcel } from '../../../components/reusable/report/buttons';

const { Option } = Select;

const CodeForm = props => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lang = useCurrentLanguage();
  const warnings = useSelector(state => state.otherProducts);
  const [subject, setSubject] = useState();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const addError = warnings.addLegalEntityError || warnings.addIndividualEntityError;
  const {
    codeText,
    currentlyMonitoring,
    freeMonitoring,
    youAreMonitoring,
    isLegalEntity,
    setIsPopUpModalOpen,
    callHandleDeleteAll,
  } = props;
  const [pageSize, setPageSize] = useState(20);

  useEffect(() => {
    dispatch(resetOtherProducts());
  }, [isLegalEntity]);

  useEffect(() => {
    dispatch(isLegalEntity ? getLegalEntities() : getIndividualEntities());
    dispatch(isLegalEntity ? getCompanyMonitoringSummary() : getPersonMonitoringSummary());
  }, []);

  const entities = useSelector(isLegalEntity ? registeredLegalEntities : registeredIndividualEntities);

  const handleFinish = useCallback(async values => {
    setSubject(values.code);
    const personRequest = {
      personCode: Number(values.code),
    };
    await dispatch(
      isLegalEntity
        ? addLegalEntity(values.code)
        : addIndividualEntity({
            personCode: Number(values.code),
            personRequest: personRequest,
          })
    );
    dispatch(isLegalEntity ? getLegalEntities() : getIndividualEntities());
  }, []);

  const handleDeleteEntity = useCallback(
    async value => {
      await dispatch(isLegalEntity ? deleteLegalEntity(value) : deleteIndividualEntity(value));
      dispatch(isLegalEntity ? getLegalEntities() : getIndividualEntities());
    },
    [dispatch]
  );

  const handleUpdateEntity = useCallback(
    async value => {
      await dispatch(isLegalEntity ? updateLegalEntity(value) : updateIndividualEntity(value));
      dispatch(isLegalEntity ? getLegalEntities() : getIndividualEntities());
    },
    [dispatch]
  );

  useEffect(() => {
    if (callHandleDeleteAll) {
      handleDeleteAll();
    }
  }, [callHandleDeleteAll]);

  const handleDeleteAll = useCallback(async () => {
    await dispatch(isLegalEntity ? deleteAllLegalEntities() : deleteAllIndividuals());
    dispatch(isLegalEntity ? getLegalEntities() : getIndividualEntities());
  }, [dispatch]);

  const subjectCodes = entities.map(entity => entity.subjectCode);

  const warningHandler = () => {
    if (addError) {
      return subjectCodes.includes(subject) ? (
        <p className="subject-warning">{t('otherProducts.monitoring.subject.already.exists')}</p>
      ) : (
        <p className="subject-warning">{t('otherProducts.monitoring.subject.not.found')}</p>
      );
    }
  };

  const successHandler = () => {
    if (showSuccessMessage) {
      return <p className="subject-success">{t('otherProducts.monitoring.subject.successMessage')}</p>;
    }
  };

  const registeredSubjectsDataSource = useMemo(() => {
    return entities?.map(entity => ({ ...entity })) || null;
  }, [entities]);

  const riskClassesTitles = useMemo(() => {
    return new Map([
      [riskClasses.noRiskIdentified, t('otherProducts.monitorings.riskClasses.unspecified')],
      [riskClasses.lowRisk, t('otherProducts.monitorings.riskClasses.lowRisk')],
      [riskClasses.mediumRisk, t('otherProducts.monitorings.riskClasses.mediumRisk')],
      [riskClasses.highRisk, t('otherProducts.monitorings.riskClasses.highRisk')],
      [riskClasses.veryHighRisk, t('otherProducts.monitorings.riskClasses.veryHighRisk')],
      [riskClasses.vipClients, t('otherProducts.monitorings.riskClasses.vipClients')],
    ]);
  }, [t, riskClasses, lang]);

  const SelectOptions = ({ watchId, monitoringRiskGroup }) => {
    return (
      <Select
        key={watchId}
        defaultValue={monitoringRiskGroup}
        size="middle"
        style={{ width: '100%', ...riskStyleClass.get(monitoringRiskGroup) }}
        onChange={value => handleUpdateEntity({ watchId, monitoringRiskGroup: value })}
      >
        <Option
          key={riskClasses.noRiskIdentified}
          value={riskClasses.noRiskIdentified}
          style={riskStyleClass.get(riskClasses.noRiskIdentified)}
        >
          {riskClassesTitles.get(riskClasses.noRiskIdentified)}
        </Option>
        <Option key={riskClasses.lowRisk} value={riskClasses.lowRisk} style={riskStyleClass.get(riskClasses.lowRisk)}>
          {riskClassesTitles.get(riskClasses.lowRisk)}
        </Option>
        <Option
          key={riskClasses.mediumRisk}
          value={riskClasses.mediumRisk}
          style={riskStyleClass.get(riskClasses.mediumRisk)}
        >
          {riskClassesTitles.get(riskClasses.mediumRisk)}
        </Option>
        <Option
          key={riskClasses.highRisk}
          value={riskClasses.highRisk}
          style={riskStyleClass.get(riskClasses.highRisk)}
        >
          {riskClassesTitles.get(riskClasses.highRisk)}
        </Option>
        <Option
          key={riskClasses.veryHighRisk}
          value={riskClasses.veryHighRisk}
          style={riskStyleClass.get(riskClasses.veryHighRisk)}
        >
          {riskClassesTitles.get(riskClasses.veryHighRisk)}
        </Option>
        <Option
          key={riskClasses.vipClients}
          value={riskClasses.vipClients}
          style={riskStyleClass.get(riskClasses.vipClients)}
        >
          {riskClassesTitles.get(riskClasses.vipClients)}
        </Option>
      </Select>
    );
  };

  const registeredSubjectsColumns = useMemo(() => {
    return [
      {
        dataIndex: 'subjectCode',
        key: 'subjectCode',
        title: t(
          isLegalEntity
            ? 'otherProducts.monitorings.creditMonitoring.registeredSubjects.subjectCode'
            : 'otherProducts.monitorings.creditMonitoring.registeredSubjects.individualCode'
        ),
        width: '20%',
        align: 'center',
        sorter: (a, b) => a.subjectCode.localeCompare(b.subjectCode),
        sortDirections: ['ascend', 'descend'],
      },
      {
        dataIndex: 'fullName',
        key: 'fullName',
        title: t(
          isLegalEntity
            ? 'otherProducts.monitorings.creditMonitoring.registeredSubjects.companyName'
            : 'otherProducts.monitorings.creditMonitoring.registeredSubjects.individualName'
        ),
        width: '20%',
        align: 'center',
        sorter: (a, b) => a.fullName.localeCompare(b.fullName),
        sortDirections: ['ascend', 'descend'],
      },
      {
        dataIndex: 'monitoringRiskGroup',
        key: 'monitoringRiskGroup',
        title: t('otherProducts.monitorings.creditMonitoring.registeredSubjects.riskGroup'),
        align: 'center',
        width: '20%',
        sorter: (a, b) => riskClassesMap[a.monitoringRiskGroup] - riskClassesMap[b.monitoringRiskGroup],
        sortDirections: ['ascend', 'descend'],
        render: (_, value) => {
          return SelectOptions(value);
        },
      },
      {
        dataIndex: 'createdDate',
        key: 'createdDate',
        title: t('otherProducts.monitorings.creditMonitoring.registeredSubjects.creationDate'),
        width: '30%',
        align: 'center',
        render: TableColumnDate,
        sorter: (a, b) => {
          const dateA = new Date(a.createdDate);
          const dateB = new Date(b.createdDate);
          return dateB - dateA;
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        dataIndex: 'watchId',
        key: 'watchId',
        title: '',
        width: '10%',
        align: 'center',
        render: (_, value) => (
          <Button type="text" htmlType="button" danger onClick={e => handleDeleteEntity(value.watchId, e)}>
            {t('otherProducts.monitorings.creditMonitoring.registeredSubjects.btn.delete')}
          </Button>
        ),
      },
    ];
  }, [t, riskClassesTitles, Select, Option, Button]);

  useEffect(() => {
    if (warnings.addLegalEntitySuccess || warnings.addIndividualEntitySuccess) {
      form.resetFields(['code']);
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 5000);
    }
  }, [warnings.addLegalEntitySuccess, warnings.addIndividualEntitySuccess]);

  return (
    <>
      <Form
        className="code-form"
        form={form}
        labelCol={{ span: 24 }}
        name="entities-form"
        layout="vertical"
        onFinish={handleFinish}
      >
        <Card className="ant-card-no-hover space-vertical-lg has-shadow">
          <Row gutter={16}>
            <Col xs={24} md={14} lg={14} xl={14} xxl={14}>
              <Row>
                <Col xs={24} md={24} lg={17} xl={17} xxl={17}>
                  <Form.Item name="code" label={t(codeText)}>
                    <Input />
                  </Form.Item>
                  {warningHandler()}
                  {successHandler()}
                </Col>
                <Col xs={24} md={24} lg={7} xl={7} xxl={7} className="justify-content-center">
                  <Form.Item
                    className="form-checkbox"
                    valuePropName="checked"
                    rules={[{ required: false }]}
                    style={{
                      paddingTop: 20,
                      paddingLeft: 20,
                    }}
                    name="checkbox"
                  >
                    {isLegalEntity && (
                      <Checkbox>{t('otherProducts.monitorings.creditMonitoring.checkRelatedSubjects')}</Checkbox>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col xs={12} md={17} lg={17} xl={17} xxl={17} className="space-between align-items-center">
                  <Col span={10}>
                    <Form.Item>
                      <Button type="primary" className="btn-secondary" htmlType="submit" style={{ width: '100% ' }}>
                        {t('otherProducts.monitorings.creditMonitoring.btn.register')}
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item>
                      <Button
                        className="btn-secondary"
                        htmlType="button"
                        style={{ width: '100%' }}
                        onClick={setIsPopUpModalOpen}
                      >
                        {t('otherProducts.monitorings.creditMonitoring.btn.delete')}
                      </Button>
                    </Form.Item>
                  </Col>
                </Col>
              </Row>
            </Col>
            <Col
              xs={24}
              md={10}
              lg={10}
              xl={10}
              xxl={10}
              style={{ paddingTop: 12, textTransform: 'uppercase' }}
              className="info"
            >
              <Row justify="end">
                <Trans
                  i18nKey="otherProducts.monitorings.creditMonitoring.currentlyMonitoring"
                  values={{ currentlyMonitoring }}
                />
              </Row>
              <Row justify="end">
                <Trans
                  i18nKey="otherProducts.monitorings.creditMonitoring.freeMonitoring"
                  values={{ freeMonitoring }}
                />
              </Row>
              <Row justify="end">
                <Trans
                  i18nKey="otherProducts.monitorings.creditMonitoring.youAreMonitoring"
                  values={{ youAreMonitoring }}
                />
              </Row>
            </Col>
          </Row>
        </Card>
      </Form>
      {entities && (
        <CompanyDataCard
          icon={<RegisteredIcon />}
          title={t('otherProducts.monitorings.creditMonitoring.registeredSubjects.title')}
          extra={
            <DownloadExcel
              disabled={!registeredSubjectsDataSource?.length}
              fileName={`otherProducts.monitorings.creditMonitoring.registeredSubjects.${
                isLegalEntity ? 'company' : 'person'
              }.xlsx`}
              className="monitoring-excel-download"
              fetcher={() =>
                dispatch(
                  getMonitoringSubjectsExcel({
                    value: registeredSubjectsDataSource,
                    type: isLegalEntity ? 'company' : 'person',
                  })
                )
              }
            />
          }
        >
          <Row style={{ paddingTop: 24 }} justify="center">
            <Col xs={24} md={18} lg={18} xl={18} xxl={18}>
              <Table
                rowKey={entity => entity.creditInfoId}
                dataSource={registeredSubjectsDataSource}
                columns={registeredSubjectsColumns}
                size="small"
                className="other-products-table"
                pagination={{
                  pageSize: pageSize,
                  responsive: true,
                  total: registeredSubjectsDataSource?.length,
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '20', '50', '100'],
                  onShowSizeChange: (_e, count) => setPageSize(count),
                }}
                bordered
                showSorterTooltip={false}
              />
            </Col>
          </Row>
        </CompanyDataCard>
      )}
    </>
  );
};

export default CodeForm;
