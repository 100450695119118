import React, { useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { basicInfo } from 'store/entities/prescoreReport/selectors/basicDataSelectors';
import ReportsTable from 'components/reusable/report/tables/ReportsTable';

import { Col, Row, Space } from 'antd';
import { ReactComponent as ComposedChartIcon } from 'assets/img/icons/composedChart.svg';
import Icon from '@ant-design/icons';
import { BlockHeader } from '../../../components/reusable/BlockHeader';

const Interests = () => {
  const { t } = useTranslation();
  const { statistics } = useSelector(basicInfo);

  const numberOfInterests = useMemo(
    () => [
      {
        sectorTitle: t('prescoreReport.interests.total.reports'),
        lastMonth: statistics.lastMonth,
        lastQuarter: statistics.lastQuarter,
        lastHalfYear: statistics.lastHalfYear,
      },
    ],
    []
  );

  return (
    <>
      <Row id="intrests" justify="start">
        <Col>
          <BlockHeader
            icon={<Icon component={ComposedChartIcon} className="icon--lg" />}
            title={t('prescoreReport.interests.title')}
          />
        </Col>
      </Row>
      <Row className="space-bottom-lg">
        <Col xs={24} md={24} className="vehicle-list-table">
          <ReportsTable reports={numberOfInterests} />
        </Col>
      </Row>
    </>
  );
};

export default Interests;
