import { createPortal } from 'react-dom';
import { ExpandCollapseBtn } from './ExpandCollapseBtn';
import { NODE_RELATIONS } from '../../utils/constants';
import { deepClone } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';
import { Tooltip } from './Tooltip';

export const HierarchyNode = ({ item, setGraphData, svgRef }) => {
  const { t } = useTranslation();
  const container = document.getElementById(item.id);

  if (!container) return null;

  const moreDataCount = 20;
  const minimumMoreGroupCount = 5;
  const filteredHistoricalGroups = item.group.filter(group => group.isHistorical);
  const filteredNonHistoricalGroups = item.group.filter(group => !group.isHistorical);

  const toggleHistoricalExpansion = (nodeId, historicalGroupData) => {
    svgRef.current.select(`g.${nodeId}`).remove();

    setGraphData(prev => {
      const dataClone = deepClone(prev);
      const selectedTree = selectedTreeNode(dataClone, nodeId, true);

      selectedTree.group = [
        ...filteredNonHistoricalGroups,
        ...historicalGroupData.slice(filteredHistoricalGroups.length, moreDataCount + filteredHistoricalGroups.length),
        ...filteredHistoricalGroups,
      ];

      return dataClone;
    });
  };

  const toggleHistoricalSuppress = nodeId => {
    svgRef.current.select(`g.${nodeId}`).remove();
    setGraphData(prev => {
      const dataClone = deepClone(prev);
      const selectedTree = selectedTreeNode(dataClone, nodeId, true);
      const numToRemove = Math.min(moreDataCount, selectedTree.group.filter(group => group.isHistorical).length);
      const startIndex = selectedTree.group.length - numToRemove;
      if (numToRemove === 0) {
        return dataClone;
      }
      selectedTree.group.splice(startIndex, numToRemove);
      return dataClone;
    });
  };

  const toggleMoreExpansion = (nodeId, moreGroupData) => {
    svgRef.current.select(`g.${nodeId}`).remove();
    setGraphData(prev => {
      const dataClone = deepClone(prev);
      const selectedTree = selectedTreeNode(dataClone, nodeId, false);

      selectedTree.group = [
        ...filteredNonHistoricalGroups,
        ...moreGroupData.slice(
          filteredNonHistoricalGroups.length - 5,
          moreDataCount + filteredNonHistoricalGroups.length - 5
        ),
        ...filteredHistoricalGroups,
      ];

      return dataClone;
    });
  };

  const toggleMoreSuppress = nodeId => {
    svgRef.current.select(`g.${nodeId}`).remove();
    setGraphData(prev => {
      const dataClone = deepClone(prev);
      const selectedTree = selectedTreeNode(dataClone, nodeId, false);
      const endPoint =
        filteredNonHistoricalGroups.length > minimumMoreGroupCount && filteredNonHistoricalGroups.length < 26
          ? minimumMoreGroupCount
          : filteredNonHistoricalGroups.length - moreDataCount;
      selectedTree.group = [...filteredNonHistoricalGroups.slice(0, endPoint), ...filteredHistoricalGroups];

      return dataClone;
    });
  };

  const selectedTreeNode = (data, nodeId, historical = true) => {
    const exactNode = data.children.find(node => node.id === nodeId);
    if (exactNode) {
      exactNode[historical ? 'historicalExpanded' : 'moreExpanded'] =
        !exactNode[historical ? 'historicalExpanded' : 'moreExpanded'];
      return exactNode;
    } else {
      for (const child of data.children) {
        const foundNode = selectedTreeNode(child, nodeId, historical);
        if (foundNode) return foundNode;
      }
    }
    return false;
  };

  const renderExpandableSection = () => {
    const moreCountToDisplay = item.moreGroup?.length - filteredNonHistoricalGroups?.length + 5;
    if (item.type !== 'rootItem' && item.moreGroup.length > 0) {
      return (
        <div className="node__expandable_conn">
          {item.moreGroup?.length && moreCountToDisplay > 0 ? 'Rodyti daugiau' : 'Rodyti mažiau'}
          <span className="node__expandable_conn__count">{moreCountToDisplay ? `(${moreCountToDisplay})` : ''}</span>
          {item.moreGroup.length ? (
            <div>
              {moreCountToDisplay === 0 ? (
                <div className="node__expandable_conn__btn-position">
                  <ExpandCollapseBtn iconSign={'minus'} onClick={() => toggleMoreSuppress(item.id, item?.moreGroup)} />
                </div>
              ) : filteredNonHistoricalGroups.length === 5 ? (
                <div className="node__expandable_conn__btn-position">
                  <ExpandCollapseBtn iconSign={'plus'} onClick={() => toggleMoreExpansion(item.id, item?.moreGroup)} />
                </div>
              ) : (
                <>
                  <div className="node__expandable_conn__btn-position__less">
                    <ExpandCollapseBtn
                      iconSign={'minus'}
                      onClick={() => toggleMoreSuppress(item.id, item?.moreGroup)}
                    />
                  </div>
                  <div className="node__expandable_conn__btn-position">
                    <ExpandCollapseBtn
                      iconSign={'plus'}
                      onClick={() => toggleMoreExpansion(item.id, item?.moreGroup)}
                    />
                  </div>
                </>
              )}
            </div>
          ) : null}
        </div>
      );
    }
    if (item.type !== 'rootItem' && filteredNonHistoricalGroups.length === 0) {
      return <div className="node__expandable_conn fw-700">{t('spiderweb.noActiveRelations')}</div>;
    }
    return null;
  };

  const renderHistoricalSection = () => {
    const historicalCountToDisplay = item.historicalGroup?.length - filteredHistoricalGroups?.length;
    if (item.type !== 'rootItem') {
      return (
        <div className="node__historical_conn">
          {item.historicalGroup?.length && historicalCountToDisplay > 0
            ? t('spiderweb.historical.relations')
            : t('spiderweb.historical.relations.collapse')}
          <span className="node__historical_conn__count">
            {historicalCountToDisplay ? `(${historicalCountToDisplay})` : ''}
          </span>
          {item.historicalGroup.length ? (
            historicalCountToDisplay === 0 ? (
              <div className="node__historical_conn__btn-position">
                <ExpandCollapseBtn
                  iconSign={'minus'}
                  onClick={() => toggleHistoricalSuppress(item.id, item?.historicalGroup)}
                />
              </div>
            ) : historicalCountToDisplay === item.historicalGroup.length ? (
              <div className="node__historical_conn__btn-position">
                <ExpandCollapseBtn
                  iconSign={'plus'}
                  onClick={() => toggleHistoricalExpansion(item.id, item?.historicalGroup)}
                />
              </div>
            ) : (
              <div>
                <div className="node__historical_conn__btn-position__less">
                  <ExpandCollapseBtn
                    iconSign={'minus'}
                    onClick={() => toggleHistoricalSuppress(item.id, item?.historicalGroup)}
                  />
                </div>
                <div className="node__historical_conn__btn-position">
                  <ExpandCollapseBtn
                    iconSign={'plus'}
                    onClick={() => toggleHistoricalExpansion(item.id, item?.historicalGroup)}
                  />
                </div>
              </div>
            )
          ) : null}
        </div>
      );
    }
    return null;
  };

  const tooltipType = item.type !== 'rootItem' ? item.type : item.person ? 'rootPerson' : 'rootCompany';

  return createPortal(
    <div>
      <Tooltip type={tooltipType} style={{ position: 'none', zIndex: '1 !important' }}>
        {NODE_RELATIONS[tooltipType]?.icon}
      </Tooltip>
      {renderExpandableSection()}
      {renderHistoricalSection()}
    </div>,
    container
  );
};
