import { createSlice } from '@reduxjs/toolkit';
import {
  getLegalEntities,
  addLegalEntity,
  deleteLegalEntity,
  updateLegalEntity,
  deleteAllLegalEntities,
  getIndividualEntities,
  getUserSettings,
  setUserSettings,
  addIndividualEntity,
  deleteIndividualEntity,
  updateIndividualEntity,
  deleteAllIndividuals,
  uploadFile,
  getCompanyMonitoringEvents,
  getPersonMonitoringEvents,
  getCompanyMonitoringSummary,
  getPersonMonitoringSummary,
  getMonitoringSubjectsExcel,
  getMonitoringEventsExcel,
  postFeedback,
} from 'store/entities/otherProducts/thunks';

const initialState = {
  legalEntities: [],
  legalEntitiesPending: false,
  legalEntitiesError: false,
  addLegalEntityPending: false,
  addLegalEntitySuccess: false,
  addLegalEntityError: false,
  deleteLegalEntityPending: false,
  deleteLegalEntityError: false,
  updateLegalEntityPending: false,
  updateLegalEntityError: false,
  deleteAllLegalEntitiesPending: false,
  deleteAllLegalEntitiesError: false,
  individualEntities: [],
  individualEntitiesPending: false,
  individualEntitiesError: false,
  userSettings: null,
  userSettingsPending: false,
  userSettingsError: false,
  postUserSettingsPending: false,
  postUserSettingsError: false,
  addIndividualEntityPending: false,
  addIndividualEntitySuccess: false,
  addIndividualEntityError: false,
  deleteIndividualEntityPending: false,
  deleteIndividualEntityError: false,
  updateIndividualEntityPending: false,
  updateIndividualEntityError: false,
  deleteAllIndividualsPending: false,
  deleteAllIndividualsError: false,
  uploadFileData: null,
  uploadFilePending: false,
  uploadFileError: false,
  companyMonitoringSummary: null,
  companyMonitoringSummaryLoading: false,
  personMonitoringSummary: null,
  personMonitoringSummaryLoading: false,
  monitoringSubjectsExcelLoading: false,
  monitoringEvemtsExcelLoading: false,
  monitoringDatesSelected: {},
  feedbackModalActive: false,
  postFeedbackData: null,
  postFeedbackPending: false,
  postFeedbackError: false,
};

const slice = createSlice({
  name: 'otherProducts',
  initialState: initialState,
  reducers: {
    resetOtherProducts: () => initialState,
    setFeedbackModalActive: (state, { payload }) => {
      if (state.feedbackModalActive && state.postFeedbackData) state.postFeedbackData = false;
      state.feedbackModalActive = payload;
    },
  },
  extraReducers: {
    [getLegalEntities.fulfilled]: (state, { payload }) => {
      state.legalEntities = payload;
      state.legalEntitiesPending = false;
      state.legalEntitiesError = false;
    },
    [getLegalEntities.pending]: state => {
      state.legalEntitiesPending = true;
      state.legalEntitiesError = false;
    },
    [getLegalEntities.rejected]: state => {
      state.legalEntitiesPending = false;
      state.legalEntitiesError = true;
    },
    [addLegalEntity.fulfilled]: state => {
      state.addLegalEntitySuccess = true;
      state.addLegalEntityPending = false;
      state.addLegalEntityError = false;
    },
    [addLegalEntity.pending]: state => {
      state.addLegalEntitySuccess = false;
      state.addLegalEntityPending = true;
      state.addLegalEntityError = false;
    },
    [addLegalEntity.rejected]: state => {
      state.addLegalEntitySuccess = false;
      state.addLegalEntityPending = false;
      state.addLegalEntityError = true;
    },
    [deleteLegalEntity.fulfilled]: state => {
      state.deleteLegalEntityPending = false;
      state.deleteLegalEntityError = false;
    },
    [deleteLegalEntity.pending]: state => {
      state.deleteLegalEntityPending = true;
      state.deleteLegalEntityError = false;
    },
    [deleteLegalEntity.rejected]: state => {
      state.deleteLegalEntityPending = false;
      state.deleteLegalEntityError = true;
    },
    [updateLegalEntity.fulfilled]: state => {
      state.updateLegalEntityPending = false;
      state.updateLegalEntityError = false;
    },
    [updateLegalEntity.pending]: state => {
      state.updateLegalEntityPending = true;
      state.updateLegalEntityError = false;
    },
    [updateLegalEntity.rejected]: state => {
      state.updateLegalEntityPending = false;
      state.updateLegalEntityError = true;
    },
    [updateIndividualEntity.fulfilled]: state => {
      state.updateIndividualEntityPending = false;
      state.updateIndividualEntityError = false;
    },
    [updateIndividualEntity.pending]: state => {
      state.updateIndividualEntityPending = true;
      state.updateIndividualEntityError = false;
    },
    [updateIndividualEntity.rejected]: state => {
      state.updateIndividualEntityPending = false;
      state.updateIndividualEntityError = true;
    },
    [deleteAllLegalEntities.fulfilled]: state => {
      state.deleteAllLegalEntitiesPending = false;
      state.deleteAllLegalEntitiesError = false;
    },
    [deleteAllLegalEntities.pending]: state => {
      state.deleteAllLegalEntitiesPending = true;
      state.deleteAllLegalEntitiesError = false;
    },
    [deleteAllLegalEntities.rejected]: state => {
      state.deleteAllLegalEntitiesPending = false;
      state.deleteAllLegalEntitiesError = true;
    },
    [getIndividualEntities.fulfilled]: (state, { payload }) => {
      state.individualEntities = payload;
      state.individualEntitiesPending = false;
      state.individualEntitiesError = false;
    },
    [getIndividualEntities.pending]: state => {
      state.individualEntitiesPending = true;
      state.individualEntitiesError = false;
    },
    [getIndividualEntities.rejected]: state => {
      state.individualEntitiesPending = false;
      state.individualEntitiesError = true;
    },
    [getUserSettings.fulfilled]: (state, { payload }) => {
      state.userSettings = payload;
      state.userSettingsPending = false;
      state.userSettingsError = false;
    },
    [getUserSettings.pending]: state => {
      state.userSettingsPending = true;
      state.userSettingsError = false;
    },
    [getUserSettings.rejected]: state => {
      state.userSettingsPending = false;
      state.userSettingsError = true;
    },
    [setUserSettings.pending]: state => {
      state.postUserSettingsPending = true;
      state.postUserSettingsError = false;
    },
    [setUserSettings.rejected]: state => {
      state.postUserSettingsPending = false;
      state.postUserSettingsError = true;
    },
    [addIndividualEntity.fulfilled]: state => {
      state.addIndividualEntitySuccess = true;
      state.addIndividualEntityPending = false;
      state.addIndividualEntityError = false;
    },
    [addIndividualEntity.pending]: state => {
      state.addIndividualEntitySuccess = false;
      state.addIndividualEntityPending = true;
      state.addIndividualEntityError = false;
    },
    [addIndividualEntity.rejected]: state => {
      state.addIndividualEntitySuccess = false;
      state.addIndividualEntityPending = false;
      state.addIndividualEntityError = true;
    },
    [deleteIndividualEntity.fulfilled]: state => {
      state.deleteIndividualEntityPending = false;
      state.deleteIndividualEntityError = false;
    },
    [deleteIndividualEntity.pending]: state => {
      state.deleteIndividualEntityPending = true;
      state.deleteIndividualEntityError = false;
    },
    [deleteIndividualEntity.rejected]: state => {
      state.deleteIndividualEntityPending = false;
      state.deleteIndividualEntityError = true;
    },
    [deleteAllIndividuals.fulfilled]: state => {
      state.deleteAllIndividualsPending = false;
      state.deleteIndividualsError = false;
    },
    [deleteAllIndividuals.pending]: state => {
      state.deleteAllIndividualsPending = true;
      state.deleteIndividualsError = false;
    },
    [deleteAllIndividuals.rejected]: state => {
      state.deleteAllIndividualsPending = false;
      state.deleteIndividualsError = true;
    },
    [uploadFile.fulfilled]: (state, { payload }) => {
      state.uploadFileData = payload;
      state.uploadFilePending = false;
      state.uploadFileError = false;
    },
    [uploadFile.pending]: state => {
      state.uploadFilePending = true;
      state.uploadFileError = false;
    },
    [uploadFile.rejected]: state => {
      state.uploadFilePending = false;
      state.uploadFileError = true;
    },
    [getCompanyMonitoringEvents.fulfilled]: (state, { payload, meta }) => {
      state.monitoringDatesSelected = { dateFrom: meta.arg.dateFrom, dateTo: meta.arg.dateTo };
      state.monitoringEventsData = payload;
      state.monitoringEventsDataLoading = false;
    },
    [getCompanyMonitoringEvents.pending]: state => {
      state.monitoringDatesSelected = {};
      state.monitoringEventsDataLoading = true;
    },
    [getCompanyMonitoringEvents.rejected]: state => {
      state.monitoringDatesSelected = {};
      state.monitoringEventsDataLoading = false;
    },
    [getPersonMonitoringEvents.fulfilled]: (state, { payload, meta }) => {
      state.monitoringDatesSelected = { dateFrom: meta.arg.dateFrom, dateTo: meta.arg.dateTo };
      state.monitoringEventsData = payload;
      state.monitoringEventsDataLoading = false;
    },
    [getPersonMonitoringEvents.pending]: state => {
      state.monitoringDatesSelected = {};
      state.monitoringEventsDataLoading = true;
    },
    [getPersonMonitoringEvents.rejected]: state => {
      state.monitoringDatesSelected = {};
      state.monitoringEventsDataLoading = false;
    },
    [getCompanyMonitoringSummary.fulfilled]: (state, { payload }) => {
      state.companyMonitoringSummary = payload;
      state.companyMonitoringSummaryLoading = false;
    },
    [getCompanyMonitoringSummary.pending]: state => {
      state.companyMonitoringSummaryLoading = true;
    },
    [getCompanyMonitoringSummary.rejected]: state => {
      state.companyMonitoringSummaryLoading = false;
    },
    [getPersonMonitoringSummary.fulfilled]: (state, { payload }) => {
      state.personMonitoringSummary = payload;
      state.personMonitoringSummaryLoading = false;
    },
    [getPersonMonitoringSummary.pending]: state => {
      state.personMonitoringSummaryLoading = true;
    },
    [getPersonMonitoringSummary.rejected]: state => {
      state.personMonitoringSummaryLoading = false;
    },
    [getMonitoringSubjectsExcel.fulfilled]: (state, { payload }) => {
      state.monitoringSubjectsExcelLoading = false;
    },
    [getMonitoringSubjectsExcel.pending]: state => {
      state.monitoringSubjectsExcelLoading = true;
    },
    [getMonitoringSubjectsExcel.rejected]: state => {
      state.monitoringSubjectsExcelLoading = false;
    },
    [getMonitoringEventsExcel.fulfilled]: (state, { payload }) => {
      state.monitoringEvemtsExcelLoading = false;
    },
    [getMonitoringEventsExcel.pending]: state => {
      state.monitoringEvemtsExcelLoading = true;
    },
    [getMonitoringEventsExcel.rejected]: state => {
      state.monitoringEvemtsExcelLoading = false;
    },
    [postFeedback.fulfilled]: (state, { payload }) => {
      state.postFeedbackData = true;
      state.postFeedbackPending = false;
      state.postFeedbackError = false;
      state.setFeedbackModalActive = false;
    },
    [postFeedback.pending]: state => {
      state.postFeedbackError = false;
      state.postFeedbackPending = true;
    },
    [postFeedback.rejected]: state => {
      state.postFeedbackPending = false;
      state.postFeedbackError = true;
    },
  },
});

export const { resetOtherProducts, setFeedbackModalActive } = slice.actions;

export default slice.reducer;
