import { useTranslation } from 'react-i18next';

import CompanyDataCard, { CompanyDataRow } from 'components/reusable/CompanyDataCard';

import { Col, Divider } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import useFormatDate from '../../../../hooks/format/useFormatDate';
import { memo, useMemo } from 'react';

const Restructurizations = ({ restructurizations }) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();

  const data = useMemo(
    () =>
      restructurizations?.map(restructurization => [
        {
          value: restructurization?.court ? restructurization.court : '-',
          title: t('professionalReport.basicData.restructurizations.court'),
        },
        {
          value: restructurization?.dateOfCourt ? formatDate(restructurization.dateOfCourt) : '-',
          title: t('professionalReport.basicData.restructurizations.dateOfCourt'),
        },
        {
          value: restructurization?.planApprovalDate ? formatDate(restructurization.planApprovalDate) : '-',
          title: t('professionalReport.basicData.restructurizations.planApprovalDate'),
        },
        {
          value: restructurization?.terminationDate ? formatDate(restructurization.terminationDate) : '-',
          title: t('professionalReport.basicData.restructurizations.terminationDate'),
        },
        {
          value: restructurization?.processCompletionDate ? formatDate(restructurization.processCompletionDate) : '-',
          title: t('professionalReport.basicData.restructurizations.processCompletionDate'),
        },
        {
          value: restructurization?.administrator ? restructurization.administrator : '-',
          title: t('professionalReport.basicData.restructurizations.administrator'),
        },
        {
          value: restructurization?.contactNumber ? restructurization.contactNumber : '-',
          title: t('professionalReport.basicData.restructurizations.contactNumber'),
        },
        {
          value: restructurization?.dateOfCreditorsRequiremets
            ? formatDate(restructurization.dateOfCreditorsRequiremets)
            : '-',
          title: t('professionalReport.basicData.restructurizations.dateOfCreditorsRequirements'),
        },
        {
          value: restructurization?.placeOfCreditorsRequiremets ? restructurization.placeOfCreditorsRequiremets : '-',
          title: t('professionalReport.basicData.restructurizations.placeOfCreditorsRequirements'),
        },
      ]) ?? [],
    [t, restructurizations]
  );

  const renderContent = !restructurizations?.length ? (
    t('global.noInformation')
  ) : (
    <Col>
      {data.map((array, index) => (
        <div key={index}>
          {array.map(item => (
            <CompanyDataRow key={item.title} type={item.title} value={item.value} />
          ))}
          {index < data.length - 1 && <Divider />} {/* Add Divider between records */}
        </div>
      ))}
    </Col>
  );

  return !restructurizations?.length ? null : (
    <div id="restructurization">
      <CompanyDataCard
        icon={<InfoCircleOutlined className="icon--gray" />}
        title={t('professionalReport.basicData.restructurizations.title')}
      >
        {renderContent}
      </CompanyDataCard>
    </div>
  );
};

export default Restructurizations;
