import { createAsyncThunk } from '@reduxjs/toolkit';
import professionalReportService from 'api/professionalReportService';

export const getOrderId = createAsyncThunk(
  'professionalReport/getOrderId',
  async ({ code, isCompany }, { rejectWithValue }) => {
    try {
      const response = await professionalReportService.getOrderId(code, isCompany);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOrderDetails = createAsyncThunk(
  'professionalReport/getOrderDetails',
  async ({ code, orderId, isCompany }, { rejectWithValue }) => {
    try {
      const response = await professionalReportService.getOrderDetails(code, orderId, isCompany);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getSummary = createAsyncThunk(
  'professionalReport/getSummary',
  async ({ code, orderId, isCompany }, { rejectWithValue }) => {
    try {
      const orderDetailsResponse = await professionalReportService.getOrderDetails(code, orderId, isCompany);

      const summaryReponse = await professionalReportService.getSummary(orderId, isCompany);

      return { summary: summaryReponse, orderDetails: orderDetailsResponse };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBasicData = createAsyncThunk(
  'professionalReport/getBasicData',
  async (orderId, { rejectWithValue }) => {
    try {
      const response = await professionalReportService.getBasicData(orderId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCompanyRatings = createAsyncThunk(
  'professionalReport/getCompanyRatings',
  async (orderId, { rejectWithValue }) => {
    try {
      const response = await professionalReportService.getCompanyRatings(orderId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getNegativeData = createAsyncThunk(
  'professionalReport/getNegativeData',
  async (orderId, { rejectWithValue }) => {
    try {
      const response = await professionalReportService.getNegativeData(orderId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getFinancialData = createAsyncThunk(
  'professionalReport/getFinancialData',
  async (orderId, { rejectWithValue }) => {
    try {
      const response = await professionalReportService.getFinancialData(orderId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateContacts = createAsyncThunk(
  'professionalReport/updateContacts',
  async ({ orderId, newContacts }, { rejectWithValue }) => {
    try {
      const response = await professionalReportService.updateContacts(orderId, newContacts);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const orderExpertEvaluation = createAsyncThunk(
  'professionalReport/orderExpertEvaluation',
  async ({ orderId, orderDetails }, { rejectWithValue }) => {
    try {
      const response = await professionalReportService.orderExpertEvaluation(orderId, orderDetails);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProfessionalReportPdf = createAsyncThunk(
  'professionalReport/getProfessionalReportPdf',
  async ({ companyCode, orderId, customPdfValues }, { rejectWithValue }) => {
    try {
      const response = await professionalReportService.getProfessionalReportPdf(companyCode, orderId, customPdfValues);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
