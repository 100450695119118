import { useEffect, useState } from 'react';
import useCurrentLanguage from 'hooks/user/useCurrentLanguage';

const defaultConfig = {
  style: 'currency',
  currency: 'EUR',
};

const useNumberFormatter = (config = defaultConfig) => {
  const lang = useCurrentLanguage();
  const [formatter, setFormatter] = useState(new Intl.NumberFormat(lang, config));

  useEffect(() => {
    setFormatter(new Intl.NumberFormat(lang, config));
  }, [lang]);

  return formatter.format;
};

export default useNumberFormatter;
