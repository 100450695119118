import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import { negativeDataVmi } from 'store/entities/professionalReport/selectors/negativeDataSelectors';

import TableColumnDate from 'components/reusable/TableColumnDate';
import ComposedChart from 'components/reusable/graphs/ComposedChart';

import { Col, Row, Table, Typography, Card } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import ReportBlockHeader from '../../../components/reusable/report/ReportBlockHeader';
import useFormatDate from '../../../hooks/format/useFormatDate';
import useNumberFormatter from '../../../hooks/format/useNumberFormatter';

const { Paragraph } = Typography;

const VMIDebtInformation = () => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const { vmiDebt, vmiDebtsHistory = [] } = useSelector(negativeDataVmi);
  const formatCurrency = useNumberFormatter();

  const isUiHidden = useIsUiHidden();

  const sortedAndFilteredData = useMemo(() => {
    const uniqueMonths = new Set();
    const sortedData = [...vmiDebtsHistory].sort((a, b) => a.dateFrom.localeCompare(b.dateFrom));
    return sortedData.filter(item => {
      const month = new Date(item.dateFrom).toISOString().slice(0, 7);
      if (uniqueMonths.has(month)) {
        return false;
      }
      uniqueMonths.add(month);
      return true;
    });
  }, [vmiDebtsHistory]);

  const debtColumns = [
    {
      title: t('professionalReport.negativeData.VmiPenaltiesInformation.dateFrom'),
      dataIndex: 'dateFrom',
      render: TableColumnDate,
      width: isUiHidden ? 80 : 120,
    },
    {
      title: t('professionalReport.negativeData.VmiPenaltiesInformation.dateTo'),
      dataIndex: 'dateTo',
      render: TableColumnDate,
      width: isUiHidden ? 80 : 120,
    },
    {
      title: t('professionalReport.negativeData.VmiPenaltiesInformation.amount'),
      dataIndex: 'amount',
      render: amount => formatCurrency(amount),
    },
    {
      title: t('professionalReport.negativeData.VmiPenaltiesInformation.rights'),
      dataIndex: 'deferredAmount',
      render: amount => formatCurrency(amount),
    },
  ];

  return (
    <Card className="card-simple card-simple-padding-none space-vertical-sm has-shadow" style={{ width: '100%' }}>
      <div id="vmiPenalties" className="space-top-lg">
        <ReportBlockHeader
          icon={<FileTextOutlined className="icon--gray" />}
          title={t('professionalReport.negativeData.VmiPenaltiesInformation.title')}
        />
      </div>
      {(vmiDebt?.arrear || vmiDebt?.deferredArrear || vmiDebt?.latestImportDate) && (
        <Paragraph>
          <Trans
            i18nKey={`professionalReport.negativeData.VmiPenaltiesInformation.description`}
            values={{
              arrear: vmiDebt?.arrear || '-',
              deferredArrear: vmiDebt?.deferredArrear || '-',
              latestImportDate: formatDate(vmiDebt?.latestImportDate) || '-',
            }}
          />
        </Paragraph>
      )}
      {vmiDebtsHistory.length ? (
        <Table
          bordered
          dataSource={vmiDebtsHistory}
          columns={debtColumns}
          scroll={{ x: 'max-content' }}
          pagination={isUiHidden ? false : { defaultPageSize: 10, showSizeChanger: true }}
        />
      ) : null}
      <Row className="space-vertical-md">
        <Col span={24}>
          <ComposedChart
            id="vmiPenalties"
            series={[
              {
                type: 'bar',
                dataKey: 'deferredAmount',
                fill: '#999999',
                key: 'deferredAmount',
              },
              {
                type: 'bar',
                dataKey: 'amount',
                fill: '#C40F11',
                key: 'amount',
              },
            ]}
            data={sortedAndFilteredData}
            lazyLoaded
            xAxisDataKey="dateFrom"
            useValueFormatter
            noInformationText="professionalReport.negativeData.VmiPenaltiesInformation.noInformation"
          />
        </Col>
      </Row>
    </Card>
  );
};

export default VMIDebtInformation;
