import React from 'react';

import useIsUiHidden from 'hooks/dom/useIsUiHidden';

import { Card, Col, Collapse, Row } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const CollapsibleCard = ({ title, children, className, isCardCollapsed, hideByDefault = false }) => {
  const isUiHidden = useIsUiHidden();
  const collapseProps = {};
  if (hideByDefault) {
    collapseProps.defaultActiveKey = 0;
  } else if (isUiHidden || isCardCollapsed) collapseProps.defaultActiveKey = 1;

  return (
    <Card
      className={`card-simple card-simple-padding-none has-shadow ${className}`}
      style={{ width: '100%' }}
      bodyStyle={{ padding: 12 }}
    >
      <Collapse
        ghost
        expandIcon={({ isActive }) =>
          isActive ? <MinusOutlined style={{ fontSize: '14px' }} /> : <PlusOutlined style={{ fontSize: '14px' }} />
        }
        {...collapseProps}
      >
        <Panel header={title} key="1">
          {children}
        </Panel>
      </Collapse>
    </Card>
  );
};

export default CollapsibleCard;
