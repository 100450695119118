import { fetchFromApi, optionsForGetRequest, optionsForPostRequest } from './';

const getOrderId = companyCode => fetchFromApi(`/company/${companyCode}/report/start/order`, optionsForGetRequest());

const getOrderDetails = (companyCode, orderId) =>
  fetchFromApi(`/company/${companyCode}/report/start/order/details/${orderId}`, optionsForGetRequest());

const getChildOrderId = (companyCode, productCodeRequest) => {
  return fetchFromApi(
    `/company/${companyCode}/report/start/order/child?productCode=${productCodeRequest.productCode}&parentOrderId=${productCodeRequest.parentOrderId}`,
    optionsForGetRequest()
  );
};

const getChildOrderIdFinancialStatement = (companyCode, optionalObj) => {
  return fetchFromApi(
    `/company/${companyCode}/report/start/order/financial-statements`,
    optionsForPostRequest(optionalObj)
  );
};

const getChildNumberOfQueries = childOrderId => {
  return fetchFromApi(`/company/${childOrderId}/report/start/number-of-queries`, optionsForGetRequest());
};

const getChildVehiclesValue = childOrderId => {
  return fetchFromApi(`/company/${childOrderId}/report/start/basic/vehicles-value`, optionsForGetRequest());
};

const getChildAutomatedCreditLimit = childOrderId => {
  return fetchFromApi(`/company/${childOrderId}/report/start/basic/automated-credit-limit`, optionsForGetRequest());
};

const getChildBankruptcyRating = childOrderId => {
  return fetchFromApi(`/company/${childOrderId}/report/start/summary/bankruptcy-rating`, optionsForGetRequest());
};

const getChildDefaultRating = childOrderId => {
  return fetchFromApi(`/company/${childOrderId}/report/start/summary/default-rating`, optionsForGetRequest());
};

const getChildOpenDebts = childOrderId => {
  return fetchFromApi(`/company/${childOrderId}/report/start/negative/active-debts-details`, optionsForGetRequest());
};

const getChildPaymentHistory = childOrderId => {
  return fetchFromApi(`/company/${childOrderId}/report/start/negative/paid-debts-details`, optionsForGetRequest());
};

const getOptionalFinancialStatements = companyCode => {
  return fetchFromApi(
    `/company/report/start/financial-statements-years?companyCode=${companyCode}`,
    optionsForGetRequest()
  );
};

const getChildRegisteredArrests = childOrderId => {
  return fetchFromApi(
    `/company/${childOrderId}/report/start/negative/registered-arrests-details`,
    optionsForGetRequest()
  );
};

const getChildUnregisteredArrests = childOrderId => {
  return fetchFromApi(
    `/company/${childOrderId}/report/start/negative/unregistered-arrests-details`,
    optionsForGetRequest()
  );
};

const getSummary = orderId => fetchFromApi(`/company/${orderId}/report/start/summary`, optionsForGetRequest());

const getBasicData = orderId => fetchFromApi(`/company/${orderId}/report/start/basic`, optionsForGetRequest());

const updateContacts = (orderId, newContacts) =>
  fetchFromApi(`/company/${orderId}/report/start/update-contacts`, optionsForPostRequest(newContacts));

const getNegativeData = orderId => fetchFromApi(`/company/${orderId}/report/start/negative`);

const getFinancialData = orderId => fetchFromApi(`/company/${orderId}/report/start/financial-data`);

const getFinancialStatements = childOrderId =>
  fetchFromApi(`/company/${childOrderId}/report/start/financial-statements`);

const getStartReportPdf = ({ orderId }) =>
  fetchFromApi(`/company/${orderId}/report/start/pdf/preview`, optionsForGetRequest());

export default {
  getOrderId,
  getOrderDetails,
  getSummary,
  getBasicData,
  updateContacts,
  getNegativeData,
  getFinancialData,
  getFinancialStatements,
  getChildOrderId,
  getChildNumberOfQueries,
  getChildVehiclesValue,
  getChildAutomatedCreditLimit,
  getChildBankruptcyRating,
  getChildDefaultRating,
  getChildOpenDebts,
  getChildPaymentHistory,
  getChildRegisteredArrests,
  getChildUnregisteredArrests,
  getOptionalFinancialStatements,
  getChildOrderIdFinancialStatement,
  getStartReportPdf,
};
