import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import usePathSegments from 'hooks/dom/usePathSegments';
import { useSelector } from 'react-redux';

const useBreadcrumbTitles = ({ companyCode, companyName }) => {
  const pathSegments = usePathSegments();
  const { selectedCompanyData } = useSelector(state => state.company);

  const { t } = useTranslation();
  const breadcrumbTitles = useMemo(
    () =>
      pathSegments.map(segment => {
        const isCompanyNameSegment = segment === companyCode?.toString();
        const isCompanyCodeSegment = segment === selectedCompanyData?.code.toString();

        return isCompanyNameSegment || isCompanyCodeSegment
          ? companyName ?? selectedCompanyData?.name
          : t(`header.breadcrumb.${segment}`, segment);
      }),
    [t, pathSegments, companyName, companyCode]
  );
  return breadcrumbTitles;
};

export default useBreadcrumbTitles;
