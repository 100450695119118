import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useCurrentLanguage from 'hooks/user/useCurrentLanguage';
import { getSummary } from 'store/entities/startReport/thunks';
import { Row, Col } from 'antd';

import Prescore from './Prescore';
import BasicInformation from './BasicInformation';

const Summary = () => {
  const { orderId, summary, pdfReport } = useSelector(state => state.startReport);
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const lang = useCurrentLanguage();

  useEffect(() => {
    if ((orderId && summary) || !summary) {
      dispatch(getSummary({ companyCode: companyId, orderId }));
    }
  }, [lang]);

  return (
    <>
      <Row className="no-break">
        <Col span={24}>
          {summary?.prescore && <Prescore prescoreObject={summary.prescore} pdfReport={pdfReport} />}
          <BasicInformation />
        </Col>
      </Row>
    </>
  );
};

export default Summary;
