import useFormatDate from '../../hooks/format/useFormatDate';
import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PdfHeader = ({ title, subtitle }) => {
  const formatDate = useFormatDate();
  const { t } = useTranslation();

  return (
    <>
      <div className="pdf-logo-wrapper">
        <div className="login-logo" />
      </div>
      <Row justify="space-between">
        <Col span={12} style={{ textAlign: 'left' }}>
          <span className="pdf-title">{title}</span>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <span className="pdf-report-time">
            {t('global.reportTime', { time: formatDate(new Date(), 'P HH:mm:ss') })}
          </span>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="align-items-end">
          <h4 className="pdf-subtitle">{subtitle}</h4>
        </Col>
      </Row>
    </>
  );
};

export default PdfHeader;
