import { createSlice } from '@reduxjs/toolkit';
import { getOrderId, getBasicInfo, getOrderDetails, getMainInformationPdf } from './thunks';

const initialState = {
  activeTab: 'basic',
  orderId: null,
  orderIdPending: false,
  orderIdError: false,
  basicInfo: null,
  basicInfoPending: false,
  basicInfoError: false,
  orderDetails: null,
  orderDetailsPending: false,
  orderDetailsError: false,
  mainInformationPdfPending: false,
};

const slice = createSlice({
  name: 'mainInformation',
  initialState: initialState,
  reducers: {
    resetMaininformation: state => initialState,
    setActiveTab: (state, { payload }) => {
      state.activeTab = payload;
    },
  },
  extraReducers: {
    [getOrderId.fulfilled]: (state, { payload }) => {
      state.orderId = payload?.id;
      state.orderIdPending = false;
      state.orderIdError = false;
    },
    [getOrderId.pending]: state => {
      state.orderIdPending = true;
      state.orderIdError = false;
    },
    [getOrderId.rejected]: state => {
      state.orderIdPending = false;
      state.orderIdError = true;
    },
    [getBasicInfo.fulfilled]: (state, { payload }) => {
      state.basicInfo = payload;
      state.basicInfoPending = false;
      state.basicInfoError = false;
    },
    [getBasicInfo.pending]: state => {
      state.basicInfoPending = true;
      state.basicInfoError = false;
    },
    [getBasicInfo.rejected]: state => {
      state.basicInfoPending = false;
      state.basicInfoError = true;
    },
    [getOrderDetails.fulfilled]: (state, { payload }) => {
      state.orderDetails = payload;
      state.orderDetailsPending = false;
      state.orderDetailsError = false;
    },
    [getOrderDetails.pending]: state => {
      state.orderDetailsPending = true;
      state.orderDetailsError = false;
    },
    [getOrderDetails.rejected]: state => {
      state.orderDetailsPending = false;
      state.orderDetailsError = true;
    },
    [getMainInformationPdf.fulfilled]: state => {
      state.mainInformationPdfPending = false;
    },
    [getMainInformationPdf.pending]: state => {
      state.mainInformationPdfPending = true;
    },
    [getMainInformationPdf.rejected]: state => {
      state.mainInformationPdfPending = false;
    },
  },
});

export const { resetMainInformation, setActiveTab } = slice.actions;

export default slice.reducer;
