const sections = [
  {
    name: 'score',
    value: 10,
    gradientId: 'gradient8',
    tooltipBorder: 'rgb(51, 178, 2)',
    score: 1,
    text: 'global.rating.class1',
    active: false,
  },
  {
    name: 'score',
    value: 10,
    gradientId: 'gradient8',
    tooltipBorder: 'rgb(51, 178, 2)',
    score: 2,
    text: 'global.rating.class2',
    active: false,
  },
  {
    name: 'score',
    value: 10,
    gradientId: 'gradient7',
    tooltipBorder: 'rgb(197, 226, 1)',
    score: 3,
    text: 'global.rating.class3',
    active: false,
  },
  {
    name: 'score',
    value: 10,
    gradientId: 'gradient6',
    tooltipBorder: 'rgb(255, 245, 0)',
    score: 4,
    text: 'global.rating.class4',
    active: false,
  },
  {
    name: 'score',
    value: 10,
    gradientId: 'gradient5',
    tooltipBorder: 'rgb(255, 207, 0)',
    score: 5,
    text: 'global.rating.class5',
    active: false,
  },
  {
    name: 'score',
    value: 10,
    gradientId: 'gradient4',
    tooltipBorder: 'rgb(255, 174, 0)',
    score: 6,
    text: 'global.rating.class6',
    active: false,
  },
  {
    name: 'score',
    value: 10,
    gradientId: 'gradient3',
    tooltipBorder: 'rgb(255, 91, 0)',
    score: 7,
    text: 'global.rating.class7',
    active: false,
  },
  {
    name: 'score',
    value: 10,
    gradientId: 'gradient2',
    tooltipBorder: 'rgb(255, 0, 0)',
    score: 8,
    text: 'global.rating.class8',
    active: false,
  },
  {
    name: 'score',
    value: 10,
    gradientId: 'gradient1',
    tooltipBorder: 'rgb(255, 0, 0)',
    score: 9,
    text: 'global.rating.class9',
    active: false,
  },
  {
    name: 'score',
    value: 10,
    gradientId: 'gradient1',
    tooltipBorder: 'rgb(255, 0, 0)',
    score: 10,
    text: 'global.rating.class10',
    active: false,
  },
];

export default sections;
