import { createSlice } from '@reduxjs/toolkit';
import { getReportStatistics } from './thunks';

const initialState = {
  totalReports: null,
  numberOfReportsChart: [],
  reportStatisticsPending: false,
};

const slice = createSlice({
  name: 'footer',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getReportStatistics.fulfilled]: (state, { payload }) => {
      state.totalReports = payload.total;
      state.numberOfReportsChart = payload.hourly.map(({ total, hour }) => ({
        reports: total,
        time: new Date(null, null, null, hour, 0),
      }));
      state.reportStatisticsPending = false;
    },
    [getReportStatistics.pending]: state => {
      state.reportStatisticsPending = true;
    },
    [getReportStatistics.rejected]: state => {
      state.reportStatisticsPending = false;
    },
  },
});

export default slice.reducer;
