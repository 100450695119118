import { useTranslation } from 'react-i18next';

import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import TableColumnDate from 'components/reusable/TableColumnDate';

import { Col, Row, Button, Form, Input, Card, DatePicker } from 'antd';
import Icon from '@ant-design/icons';
import { format, startOfDay } from 'date-fns';

import { ReactComponent as PastDueIcon } from 'assets/img/icons/pastDue.svg';
import React, { useState } from 'react';
import LoadingSpinner from './LoadingSpinner';
import { BlockHeader } from './BlockHeader';
import { useDispatch } from 'react-redux';
import CustomRequestTable from './CustomRequestTable';

const CoveredDebtManagementTable = ({ data, type, isLoading, handleOnFilter }) => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useState(null);
  const [sortingParams, setSortingParams] = useState(null);

  const dispatch = useDispatch();

  const handleTableChange = ({ current, pageSize: size }, _, { order, field }) => {
    const sortParams = order ? { debtSortType: field, sortOrderType: order } : null;
    setSortingParams({ sortOrderType: order, debtSortType: field });
    if (pageSize === size) {
      setPageSize(size);
      setPage(current);
      handleOnFilter({ ...searchParams, ...sortParams, page: current, pageSize: size }, 'covered');
    } else {
      setPageSize(size);
      setPage(1);
      handleOnFilter({ ...searchParams, ...sortParams, page: 1, pageSize: size }, 'covered');
    }
  };

  const handleFilterChange = values => {
    const formattedValues = {
      ...values,
      dateIncurrence: values.dateIncurrence ? format(startOfDay(values.dateIncurrence.toDate()), 'yyyy-MM-dd') : null,
      dateCoverage: values.dateCoverage ? format(startOfDay(values.dateCoverage.toDate()), 'yyyy-MM-dd') : null,
    };
    setSearchParams(formattedValues);
    setPage(1);
    handleOnFilter({ ...formattedValues, ...sortingParams, pageSize, page: 1 }, 'covered');
  };

  const coveredCompanyDebtsColumns = [
    {
      title: t(`administration.debtManagement.${type}.CoveredDebts.name`),
      dataIndex: type === 'company' ? 'companyName' : 'personFullName',
      key: type === 'company' ? 'companyName' : 'personFullName',
      render: (columnField, data) => {
        const field = type === 'company' ? data.companyName : data.personFullName;

        const handleOnNameClick = () =>
          dispatch({ type: type === 'company' ? 'SELECT_DEBTOR_COMPANY' : 'SELECT_DEBTOR_INDIVIDUAL', payload: data });

        return (
          <button onClick={handleOnNameClick} className="link-button">
            {field}
          </button>
        );
      },
    },
    {
      title: t(`administration.debtManagement.${type}.UncoveredDebts.code`),
      dataIndex: type === 'company' ? 'companyCode' : 'personCode',
      key: type === 'company' ? 'companyCode' : 'personCode',
    },
    {
      title: t(`administration.debtManagement.${type}.CoveredDebts.debtId`),
      dataIndex: 'debtId',
      key: 'debtId',
    },
    {
      title: t(`administration.debtManagement.${type}.CoveredDebts.date`),
      dataIndex: 'dateIncurrence',
      render: TableColumnDate,
      key: 'dateIncurrence',
      sorter: true,
    },
    {
      title: t(`administration.debtManagement.${type}.CoveredDebts.debtType`),
      dataIndex: 'debtType',
      key: 'debtType',
    },
    {
      title: t(`administration.debtManagement.${type}.CoveredDebts.amount`),
      dataIndex: 'amount',
      key: 'amount',
      sorter: true,
    },
    {
      title: t(`administration.debtManagement.${type}.CoveredDebts.coverageDate`),
      dataIndex: 'dateCoverage',
      render: TableColumnDate,
      key: 'dateCoverage',
    },
  ];

  return !isUiHidden ? (
    <LoadingSpinner size="large" spinning={isLoading}>
      <div className="no-break covered-debts">
        <Row justify="start">
          <Col>
            <BlockHeader
              icon={<Icon component={PastDueIcon} className="icon--gray" />}
              title={t(`administration.debtManagement.${type}.CoveredDebts.title`)}
            />
          </Col>
        </Row>
        <Form labelCol={{ span: 24 }} onFinish={handleFilterChange}>
          <Card className="card-simple space-vertical-lg has-shadow">
            <Row gutter={[16, 16]}>
              <Col xs={8} md={8} lg={8} xl={8} xxl={10}>
                <Form.Item name={type === 'company' ? 'companyName' : 'personName'}>
                  <Input placeholder={t(`administration.debtManagement.${type}.CoveredDebts.name.placeholder`)} />
                </Form.Item>
                <Form.Item name="dateIncurrence">
                  <DatePicker
                    className="datepicker"
                    style={{ width: '100%' }}
                    placeholder={t(`administration.debtManagement.${type}.CoveredDebts.date.placeholder`)}
                  />
                </Form.Item>
              </Col>
              <Col xs={8} md={8} lg={8} xl={8} xxl={10}>
                <Form.Item name={type === 'company' ? 'companyCode' : 'personCode'}>
                  <Input placeholder={t(`administration.debtManagement.${type}.CoveredDebts.code.placeholder`)} />
                </Form.Item>
                <Form.Item name="dateCoverage">
                  <DatePicker
                    className="datepicker"
                    style={{ width: '100%' }}
                    placeholder={t(`administration.debtManagement.${type}.CoveredDebts.paymentDate.placeholder`)}
                  />
                </Form.Item>
              </Col>
              <Col xs={6} lg={6} xxl={4} className="justify-content-end align-items-center">
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {t(`administration.debtManagement.${type}.CoveredDebts.filter.button`)}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form>
        <Row className="covered-debts-table">
          <Col xs={24} md={24}>
            <CustomRequestTable
              data={data?.results}
              total={data?.totalItemsCount}
              columns={coveredCompanyDebtsColumns}
              searchParams={searchParams}
              defaultPageSize={pageSize}
              current={page}
              handleTableChange={handleTableChange}
            />
          </Col>
        </Row>
      </div>
    </LoadingSpinner>
  ) : null;
};

export default CoveredDebtManagementTable;
