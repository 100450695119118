import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import TableColumnDate from 'components/reusable/TableColumnDate';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import CompanyDataCard from 'components/reusable/CompanyDataCard';
import { getBasicData } from 'store/entities/companyFinancialReport/thunks';

const DeliveredStatements = () => {
  const { basicData } = useSelector(state => state.companyFinancialReport);
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();

  useEffect(() => {
    if ((companyId && basicData) || !basicData) dispatch(getBasicData(companyId));
  }, [companyId]);

  const financialStatementsColumns = [
    {
      title: t('financialStatements.deliveredStatements.date'),
      key: 'deliverDate',
      dataIndex: 'deliverDate',
      render: TableColumnDate,
    },
    {
      title: t('financialStatements.deliveredStatements.statements'),
      key: 'deliverStatements',
      dataIndex: 'deliverStatements',
    },
  ];

  return (
    <CompanyDataCard
      icon={<FileOutlined className="icon--gray" />}
      title={t('financialStatements.deliveredStatements.title')}
    >
      <Table
        dataSource={basicData?.basicDeliverInformation}
        columns={
          isUiHidden
            ? financialStatementsColumns.slice(0, financialStatementsColumns.length - 1)
            : financialStatementsColumns
        }
        scroll={isUiHidden ? false : { x: 'max-content' }}
        pagination={false}
        rowKey={({ deliverDate, deliverStatements }) => `financialStatements-${deliverDate}-${deliverStatements}`}
      />
    </CompanyDataCard>
  );
};

export default DeliveredStatements;
