import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import useCurrentLanguage from 'hooks/user/useCurrentLanguage';
import { getCompanyRatings } from 'store/entities/professionalReport/thunks';
import { Col, Row, Skeleton } from 'antd';

import RatingCard from './RatingCard';
import OrderExpertReport from './OrderExpertReport';
import ScoringHistoryGraph from 'components/reusable/graphs/ScoringHistoryGraph';
import colors from 'constants/colors';

const CompanyRatings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lang = useCurrentLanguage();
  const isUiHidden = useIsUiHidden();

  const { orderId, companyRatings, companyRatingsPending, companyRatingsError } = useSelector(
    state => state.professionalReport
  );

  useEffect(() => {
    if ((orderId && companyRatings && lang !== companyRatings?.lang) || (!companyRatings && !companyRatingsError))
      dispatch(getCompanyRatings(orderId));
  }, [lang]);

  return (
    <Row>
      {isUiHidden ? (
        <Col span={24}>
          <h2>{t('professionalReport.tab.companyRatings')}</h2>
        </Col>
      ) : null}
      <Col span={24}>
        {companyRatings && !companyRatingsPending ? (
          <>
            <OrderExpertReport />
            <Row gutter={isUiHidden ? [3] : [16, 16]} className="ant-row-pdf-gap no-break">
              <Col className="flex" sm={24} md={24} lg={24} xxl={24}>
                <RatingCard
                  type="defaultRating"
                  rating={companyRatings?.defaultScore}
                  positiveFactors={companyRatings?.defaultScorePositiveAndNegativeFactors?.positiveFactorsReasonCodes}
                  negativeFactors={companyRatings?.defaultScorePositiveAndNegativeFactors?.negativeFactorsReasonCodes}
                  isEvaluatedByExpert={!!companyRatings?.defaultScore?.expertRatingDate}
                >
                  {companyRatings?.defaultScoreHistory?.length ? (
                    <ScoringHistoryGraph
                      type="defaultRating"
                      series={[
                        {
                          type: 'line',
                          dataKey: 'sector',
                          stroke: colors.COLOR_GRAY,
                        },
                        {
                          type: 'line',
                          dataKey: 'company',
                          stroke: colors.COLOR_ERROR,
                        },
                      ]}
                      data={[...(companyRatings?.defaultScoreHistory || []), companyRatings?.defaultScore]}
                      riskClasses={companyRatings?.defaultScoreDistributionsBySector}
                      lazyLoaded
                      className="no-break"
                    />
                  ) : null}
                </RatingCard>
              </Col>
            </Row>
            <Row gutter={isUiHidden ? [3] : [16, 16]} className="ant-row-pdf-gap no-break">
              <Col className="flex page-break" sm={24} md={24} lg={24} xxl={24}>
                <RatingCard
                  type="bankruptcyRating"
                  rating={companyRatings?.bankruptcyScore}
                  positiveFactors={
                    companyRatings?.bankruptcyScorePositiveAndNegativeFactors?.positiveFactorsReasonCodes
                  }
                  negativeFactors={
                    companyRatings?.bankruptcyScorePositiveAndNegativeFactors?.negativeFactorsReasonCodes
                  }
                  isEvaluatedByExpert={!!companyRatings?.bankruptcyScore?.expertRatingDate}
                >
                  {companyRatings?.bankruptcyScoreHistory?.length ? (
                    <ScoringHistoryGraph
                      type="bankruptcyRating"
                      series={[
                        {
                          type: 'line',
                          dataKey: 'sector',
                          stroke: colors.COLOR_GRAY,
                        },
                        {
                          type: 'line',
                          dataKey: 'company',
                          stroke: colors.COLOR_ERROR,
                        },
                      ]}
                      data={[...(companyRatings?.bankruptcyScoreHistory || []), companyRatings?.bankruptcyScore]}
                      riskClasses={companyRatings?.bankruptcyScoreDistributionsBySector}
                      lazyLoaded
                      className="no-break"
                    />
                  ) : null}
                </RatingCard>
              </Col>
            </Row>
          </>
        ) : (
          <Skeleton />
        )}
      </Col>
    </Row>
  );
};

export default CompanyRatings;
