import { isFirstDayOfMonth } from 'date-fns';
import { flattenObject } from 'utils/data';
import i18n from 'i18n';

export const unifyCompaniesAndPersons = data => [
  ...data?.companies.map(company => ({
    ...company,
    name: company?.companyName,
    isCompany: true,
  })),
  ...data?.persons.map(person => ({
    ...person,
    name: `${person?.firstName} ${person?.lastName}`,
    isCompany: false,
  })),
];

export const createEmployeesTable = employeesData => {
  const lastSevenYears = [
    ...employeesData.filter(({ date }) => {
      const parsedDate = new Date(date);
      return (
        parsedDate.getMonth() === 0 &&
        (isFirstDayOfMonth(parsedDate) || parsedDate.getUTCDate() === 2) &&
        parsedDate.getFullYear() >= new Date().getFullYear() - 6
      );
    }),
    employeesData[employeesData.length - 1],
  ];

  const tableData = { range: '0 - ...' };
  lastSevenYears.forEach(({ date, total }) => {
    const parsedDate = new Date(date);
    const propDate = `date${parsedDate.getFullYear()}${parsedDate.getUTCMonth()}`;
    const propLabel = `label${parsedDate.getFullYear()}${parsedDate.getUTCMonth()}`;

    tableData[propDate] = total;
    tableData[propLabel] = date;
  });

  return [tableData];
};

export const createVehiclesTable = (vehiclesData, dataKey) => {
  const columns = [...new Set(vehiclesData[dataKey].map(({ date }) => date))];
  const categories = [
    ...new Set(
      vehiclesData[dataKey].map(({ categories }) => categories.map(({ categoryName }) => categoryName)).flat()
    ),
  ];

  return {
    form: i18n.t(`professionalReport.basicData.vehicles.${dataKey}`),
    rowKey: `vehicles.${dataKey}`,
    ...flattenObject(
      columns.map(column => {
        const obj = vehiclesData[dataKey].find(({ date }) => column === date);
        return {
          [column]: obj.total,
        };
      })
    ),
    nested: categories.map(category => ({
      rowKey: `vehicles.${dataKey}.${category}`,
      form: category,
      ...flattenObject(
        columns.map(column => {
          const obj = vehiclesData[dataKey].find(({ date }) => column === date);
          return {
            [column]: obj?.categories.find(c => c?.categoryName === category)?.count,
          };
        })
      ),
    })),
  };
};

export const createVehicleFleetTable = fleetData => {
  const ranges = [...new Set(fleetData.map(({ valueRange }) => valueRange).filter(i => !!i))].sort().reverse();

  return {
    fleetTable: ranges.map(range => ({
      valueRange: range,
      ...flattenObject(
        fleetData
          .filter(({ valueRange }) => valueRange === range)
          .map(({ date, value }) => {
            const parsedDate = new Date(date);
            const propDate = `date${parsedDate.getFullYear()}${parsedDate.getUTCMonth()}`;
            const propLabel = `label${parsedDate.getFullYear()}${parsedDate.getUTCMonth()}`;

            return {
              [propDate]: value,
              [propLabel]: date,
            };
          })
      ),
    })),
    fleetTableColumns: [
      {
        title: 'professionalReport.basicData.vehicles.valueOfVehicles.value',
        dataIndex: 'valueRange',
        key: 'valueOfVehicles_priceRange',
      },
      ...fleetData
        .map(({ date }) => {
          const parsedDate = new Date(date);
          return {
            title: date,
            dataIndex: `date${parsedDate.getFullYear()}${parsedDate.getUTCMonth()}`,
            key: `listOfVehicles_'date${parsedDate.getFullYear()}${parsedDate.getUTCMonth()}`,
            align: 'center',
          };
        })
        .reverse(),
    ],
  };
};

export const getPercentageRanges = (begin, end, step) => {
  const result = [];

  for (let i = begin; i <= end; i += step) {
    const rangeStart = i;
    const rangeEnd = i + step === end ? end : i + (step - 0.01);

    if (i === end) break;

    result.push({
      range: [rangeStart, rangeEnd],
      label: `${i} - ${i < 0 ? '(' : ''}${i + 10}${i < 0 ? ')' : ''}%`,
    });
  }

  return result;
};

export const getNumberRanges = (begin, end, step, format) => {
  const result = [];

  for (let i = begin; i <= end; i += step) {
    const rangeStart = i > begin ? i + 1 : i;
    const rangeEnd = i + step === end ? end : i + step;

    if (i === end) break;

    result.push({
      range: [rangeStart, rangeEnd],
      label: `${format(rangeStart)} - ${format(rangeEnd)}`,
    });
  }

  return result;
};

export const getAverageEmployeeCounts = data => {
  const avgEmployeeCountByYear = new Map();
  data.forEach(({ date, total }) => {
    const year = date.split('-')[0];
    const found = avgEmployeeCountByYear.get(year);
    if (!found) {
      avgEmployeeCountByYear.set(year, { date: year, total, partsInYear: 1 });
    } else {
      const next = {
        date: year,
        total: found.total + total,
        partsInYear: found.partsInYear + 1,
      };
      avgEmployeeCountByYear.set(year, next);
    }
  });
  return Array.from(avgEmployeeCountByYear.values()).map(e => {
    e.total = Math.round(e.total / e.partsInYear);
    return e;
  });
};

export const getQuarterlyAverageWages = monthlyWages => {
  const quarterlyWages = new Map();
  monthlyWages.forEach(({ date: dateISO, average }) => {
    const date = new Date(dateISO);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const quarterName = `${year}/Q${Math.floor((month + 2) / 3)}`;
    if (!quarterlyWages.has(quarterName)) {
      quarterlyWages.set(quarterName, { quarterName, sum: 0, monthCount: 0 });
    }
    const quarter = quarterlyWages.get(quarterName);
    quarter.sum += average;
    quarter.monthCount += 1;
  });
  return [...quarterlyWages.values()].map(quarter => {
    quarter.average = Math.round(quarter.sum / quarter.monthCount);
    return quarter;
  });
};

export const getFirstQuarterData = data => {
  const firstQuarterDataByYear = new Map();

  data.forEach(({ date, total }) => {
    const [year, month] = date.split('-');
    const monthNumber = parseInt(month);

    if (monthNumber >= 1 && monthNumber <= 3) {
      if (!firstQuarterDataByYear.has(year)) {
        firstQuarterDataByYear.set(year, { date, total });
      }
    }
  });

  return Array.from(firstQuarterDataByYear.values());
};
