import React, { useState } from 'react';

import { Card, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { BlockHeader } from './BlockHeader';
import { InfoCircleOutlined } from '@ant-design/icons';
import useIsUiHidden from '../../hooks/dom/useIsUiHidden';
import { useSelector } from 'react-redux';

const semiBoldTag = text => {
  return <span style={{ fontWeight: 600 }}>{text}</span>;
};

const CompanyDataCard = ({ title, icon, children, extra, disclaimer, className = '' }) => {
  return (
    <Card
      className={`card-simple card-simple-padding-none space-vertical-sm has-shadow ${className}`}
      style={{ width: '100%' }}
    >
      <Row>
        <Col md={24} lg={24}>
          <BlockHeader icon={icon} title={title} />
          {extra && <span className="float-right">{extra}</span>}
        </Col>
      </Row>
      {disclaimer && (
        <Row>
          <Col>
            <span>{disclaimer}</span>
          </Col>
        </Row>
      )}
      <Row className="statistic-block statistic-block--company" gutter={[8, 8]}>
        <Col xs={24} md={24}>
          {children}
        </Col>
      </Row>
    </Card>
  );
};

export const CompanyDataRow = ({ type, value, extraClass = null, disclaimer = '' }) =>
  disclaimer ? (
    <Row gutter={[24, 0]} className="company-data-row">
      <Col className={extraClass}>{disclaimer}</Col>
    </Row>
  ) : (
    <Row gutter={[24, 0]} className="company-data-row">
      <Col xs={24} md={10} lg={10} xl={8} xxl={6}>
        {semiBoldTag(type)}
      </Col>
      <Col className={extraClass} xs={24} md={14} lg={14} xl={16} xxl={18}>
        {value}
      </Col>
    </Row>
  );

export const CompanySmallBlock = ({ title, value, extraClass = null, explanation = false, designIcon }) => {
  const isUiHidden = useIsUiHidden();
  const { isAuthenticated } = useSelector(state => state.user);
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => explanation && setHovered(true);
  const handleMouseLeave = () => explanation && setHovered(false);

  return (
    <Card
      className={`card-simple card-simple-padding-none has-shadow ${extraClass}`}
      style={{ height: '100%', margin: 'auto', padding: 0, position: 'relative', overflow: 'hidden' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {explanation && (!isUiHidden || !isAuthenticated) && (
        <InfoCircleOutlined className="icon--gray" style={{ position: 'absolute', top: 5, right: 5, zIndex: 999 }} />
      )}
      {hovered ? (
        <Row>
          <Col>
            <div className="title">{explanation}</div>
          </Col>
        </Row>
      ) : (
        <>
          {designIcon && designIcon}
          <Row>
            <Col>
              <div className="value">{value}</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="title">{title}</div>
            </Col>
          </Row>
        </>
      )}
    </Card>
  );
};

const capitalizeFirstLetter = value => value.charAt(0).toUpperCase() + value.slice(1);
const isPdfReportPage = !!window.location.pathname.includes('pdf-print');

export const CompanyDataTruncatedRow = ({ type, value, extraClass = null, children }) => {
  const { t } = useTranslation();
  const MAX_TEXT_LENGTH = 100;
  const [isTextFull, setIsTextFull] = useState(false);
  const truncatedText = !isTextFull && typeof value === 'string' ? `${value?.slice(0, MAX_TEXT_LENGTH)}...` : value;
  const renderText =
    value.length < MAX_TEXT_LENGTH || typeof value !== 'string' ? (
      capitalizeFirstLetter(value)
    ) : (
      <>
        {capitalizeFirstLetter(truncatedText)}
        {!isTextFull ? (
          <a onClick={() => setIsTextFull(true)} style={{ marginLeft: 5, fontWeight: 'bold' }}>
            {t('global.read_more')}
          </a>
        ) : (
          <a onClick={() => setIsTextFull(false)} style={{ marginLeft: 5, fontWeight: 'bold' }}>
            {t('global.show_less')}
          </a>
        )}
      </>
    );

  return (
    <Row gutter={[24, 0]} className="company-data-row">
      <Col xs={24} md={10} lg={10} xl={8} xxl={6}>
        {semiBoldTag(type)}
      </Col>
      <Col className={extraClass} xs={24} md={14} lg={14} xl={16} xxl={18}>
        {isPdfReportPage ? capitalizeFirstLetter(value) : renderText}
      </Col>
    </Row>
  );
};

export const CompanyDataRowTwoColumn = ({ items = [] }) => (
  <Row gutter={[14]} className="company-data-row-double">
    <Col md={24} lg={12}>
      <Row gutter={[8]}>
        <Col xs={24} xl={10}>
          {semiBoldTag(items[0]?.type)}
        </Col>
        <Col xs={24} xl={14}>
          {items[0]?.value}
        </Col>
      </Row>
    </Col>
    <Col md={24} lg={12}>
      <Row gutter={[8]}>
        <Col xs={24} xl={10}>
          {semiBoldTag(items[1]?.type)}
        </Col>
        <Col xs={24} xl={14}>
          {items[1]?.value}
        </Col>
      </Row>
    </Col>
  </Row>
);

export default CompanyDataCard;
