import {
  fetchFromApi,
  optionsForDeleteRequest,
  optionsForPostRequest,
  optionsForFilePostRequest,
  optionsForPutRequest,
} from 'api';

const getLegalEntities = () => fetchFromApi('/monitoring/administration/companies');

const postLegalEntity = companyCode =>
  fetchFromApi(`/monitoring/administration/company/${companyCode}`, optionsForPostRequest());

const deleteLegalEntity = watchId =>
  fetchFromApi(`/monitoring/administration/company/${watchId}`, optionsForDeleteRequest());

const updateLegalEntity = data =>
  fetchFromApi(`/monitoring/administration/company/monitoring-risk-group`, optionsForPutRequest(data));

const deleteAllLegalEntities = () => fetchFromApi('/monitoring/administration/company/all', optionsForDeleteRequest());

const getIndividualEntities = () => fetchFromApi('/monitoring/administration/persons');

const getUserSettings = () => fetchFromApi('/monitoring/user-settings');

const postUserSettings = settings => fetchFromApi('/monitoring/user-settings', optionsForPostRequest(settings));

const postIndividualEntity = (personCode, personRequest) =>
  fetchFromApi(`/monitoring/administration/person/${personCode}`, optionsForPostRequest(personRequest));

const deleteIndividualEntity = watchId =>
  fetchFromApi(`/monitoring/administration/person/${watchId}`, optionsForDeleteRequest());

const updateIndividualEntity = data =>
  fetchFromApi(`/monitoring/administration/person/monitoring-risk-group`, optionsForPutRequest(data));

const deleteAllIndividuals = () => fetchFromApi('/monitoring/administration/person/all', optionsForDeleteRequest());

const uploadFile = file => fetchFromApi('/monitoring/administration/bulk-upload', optionsForFilePostRequest(file));

const getCompanyMonitoringEvents = params => fetchFromApi('/monitoring/events/company', optionsForPostRequest(params));

const getPersonMonitoringEvents = params => fetchFromApi('/monitoring/events/person', optionsForPostRequest(params));

const getCompanyMonitoringSummary = () => fetchFromApi('/monitoring/administration/summary/company');

const getPersonMonitoringSummary = () => fetchFromApi('/monitoring/administration/summary/person');

const getMonitoringSubjectsExcel = async ({ value, type }) =>
  fetchFromApi(`/monitoring/administration/${type}/monitoring-subjects/xlsx/preview`, optionsForPostRequest(value));

const getMonitoringEventsExcel = async ({ value, type }) =>
  fetchFromApi(`/monitoring/events/${type}/xlsx/preview`, optionsForPostRequest(value));

export default {
  getLegalEntities,
  postLegalEntity,
  deleteLegalEntity,
  updateLegalEntity,
  deleteAllLegalEntities,
  getIndividualEntities,
  getUserSettings,
  postUserSettings,
  postIndividualEntity,
  deleteIndividualEntity,
  updateIndividualEntity,
  deleteAllIndividuals,
  uploadFile,
  getCompanyMonitoringEvents,
  getPersonMonitoringEvents,
  getCompanyMonitoringSummary,
  getPersonMonitoringSummary,
  getMonitoringSubjectsExcel,
  getMonitoringEventsExcel,
};
