import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { resetSearchResults, setSelectedCompany } from 'store/entities/spiderWeb';
import { setSearchCompanyCode } from 'store/entities/companySearchResult';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import { Table, Typography, message } from 'antd';
import { getBasicData } from 'store/entities/spiderWeb/thunks';

const { Text } = Typography;

const SearchResultsTable = ({ setIsSpiderwebOpen, selectedCountry }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { searchResults, getSearchResultsPending, getSearchResultsMessage } = useSelector(state => state.spiderWeb);
  const { isAuthenticated } = useSelector(state => state.user);

  const companyCountryCheck = (country, companyCode) => {
    if (country === 'NL') {
      if (companyCode.length === 8) {
        return companyCode;
      } else return companyCode.slice(0, 8);
    } else return companyCode;
  };

  const companyColumns = [
    {
      title: t('companySearch.results.companyName'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <>
          <p onClick={setIsSpiderwebOpen}>
            <Text>{text}</Text>
          </p>
          {record.oldName && <p>({record.oldName})</p>}
        </>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
    },
    {
      render: code => companyCountryCheck(selectedCountry, code),
      title: t('companySearch.results.code'),
      dataIndex: 'registrationNumber',
      key: 'code',
    },
    {
      title: t('companySearch.results.address'),
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: t('companySearch.results.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => {
        if (record?.isAfterBankruptcy) {
          return `${status} ${t('companyBasicInformation.basicData.basicInformation.isAfterBankruptcy')}`;
        } else return status;
      },
    },
  ];

  const individualColumns = [
    {
      title: t('individualSearch.results.fullName'),
      dataIndex: 'fullName',
      key: 'fullName',
      render: text => (
        <p onClick={setIsSpiderwebOpen}>
          <Text>{text}</Text>
        </p>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('individualSearch.results.code'),
      dataIndex: 'id',
      render: personCode => {
        const modifiedPersonCode = personCode.substring(0, 7) + '****';
        return (
          <p onClick={setIsSpiderwebOpen}>
            <Text>{modifiedPersonCode}</Text>
          </p>
        );
      },
      key: 'id',
    },
  ];

  const handleUnregisteredRowClick = code => {
    dispatch(setSearchCompanyCode({ searchCompanyCode: code }));

    history.push(`/search/company/${code}/result`);
  };

  const getSpiderwebData = (record, company) => {
    const requestProps = {
      companyCode: company ? companyCountryCheck(selectedCountry, record.registrationNumber) : '',
      companyName: company ? record.name : '',
      personCode: company ? '' : record.id,
      personName: company ? '' : record.fullName,
      country: selectedCountry,
      relationTypes: [255],
    };
    return requestProps;
  };

  const handleRowClick = async (record, company = true) => {
    if (isAuthenticated) {
      await dispatch(setSelectedCompany(company ? record.registrationNumber : record.id));
      await dispatch(getBasicData(getSpiderwebData(record, company)));
      dispatch(resetSearchResults());
    } else {
      handleUnregisteredRowClick(record.registrationNumber);
    }
  };

  useEffect(() => {
    if (getSearchResultsMessage && !searchResults.length) {
      if (getSearchResultsMessage === 'Nėra rezultatų, atitinkančių nurodytus kriterijus') {
        message.error(t('companySearch.noResults'));
      } else {
        message.error(getSearchResultsMessage);
      }
    }
  }, [getSearchResultsPending]);

  return searchResults?.companies?.length > 0 ? (
    <div className="space-vertical-lg">
      <LoadingSpinner size="large" spinning={getSearchResultsPending} indicator={null}>
        <Table
          dataSource={searchResults?.companies}
          columns={companyColumns}
          onRow={record => ({
            onClick: () => handleRowClick(record, true),
          })}
          rowKey={record => record.registrationNumber}
          rowClassName={record => `ant-table-row-clickable ${record.isHistoricalManager ? 'ant-table-row-dark' : ''}`}
          scroll={{ x: 'max-content' }}
          pagination={{ defaultPageSize: 20 }}
          showSorterTooltip={false}
        />
      </LoadingSpinner>
    </div>
  ) : searchResults?.individuals?.length > 0 ? (
    <div className="space-vertical-lg">
      <LoadingSpinner size="large" spinning={getSearchResultsPending} indicator={null}>
        <Table
          dataSource={searchResults?.individuals}
          columns={individualColumns}
          onRow={record => ({
            onClick: () => handleRowClick(record, false),
          })}
          rowKey={record => record.id}
          rowClassName={record => `ant-table-row-clickable ${record.isHistoricalManager ? 'ant-table-row-dark' : ''}`}
          scroll={{ x: 'max-content' }}
          pagination={{ defaultPageSize: 20 }}
          showSorterTooltip={false}
        />
      </LoadingSpinner>
    </div>
  ) : null;
};

export default SearchResultsTable;
