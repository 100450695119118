import { fetchFromApi, optionsForGetRequest, optionsForPostRequest } from './';

const getCompanies = filters => fetchFromApi('/foreign-company/search', optionsForPostRequest(filters));

const getHistoricalCompanies = filters => fetchFromApi('/foreign-company/history', optionsForPostRequest(filters));

const getCompanyOrderId = companyData => fetchFromApi(`/foreign-company/order`, optionsForPostRequest(companyData));

const getCompanyPdfReport = orderId => {
  return fetchFromApi(`/foreign-company/order/${orderId}/report/pdf`, optionsForGetRequest());
};

const getCompanyOtherInformation = companyCode =>
  fetchFromApi(`/company/${companyCode}/other-information`, optionsForGetRequest());

export default {
  getCompanies,
  getHistoricalCompanies,
  getCompanyOrderId,
  getCompanyOtherInformation,
  getCompanyPdfReport,
};
