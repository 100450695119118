import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';

import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import { setActiveTab } from 'store/entities/professionalReport';
import { basicVehicles } from 'store/entities/professionalReport/selectors/basicDataSelectors';

import NumberOfVehiclesTable from 'components/reusable/report/tables/NumberOfVehiclesTable';
import VehicleFleet from './VehicleFleet';

import { Typography, Card, Collapse, Col, Row } from 'antd';
import Icon from '@ant-design/icons';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

import { ReactComponent as CarIcon } from 'assets/img/icons/car.svg';
import ListOfVehiclesTable from 'components/reusable/report/tables/ListOfVehiclesTable';
import ReportBlockHeader from '../../../../components/reusable/report/ReportBlockHeader';

const { Panel } = Collapse;
const { Paragraph } = Typography;

const Vehicles = ({ briefPdf }) => {
  const [customReport, setCustomReport] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { vehicles, vehiclesDetails, vehiclesFleetValues } = useSelector(basicVehicles);
  const isUiHidden = useIsUiHidden();

  const { customPdfValues } = useSelector(state => state.professionalReport);

  useEffect(() => {
    if (customPdfValues && 'CarPark' in customPdfValues) {
      setCustomReport(customPdfValues['CarPark']);
    }
  }, [customPdfValues]);

  const collapseProps = {};
  if (isUiHidden) collapseProps.activeKey = 1;

  return (
    <div id="vehicles">
      <div className="space-top-lg">
        <ReportBlockHeader
          icon={<Icon component={CarIcon} className="icon--gray" />}
          title={t('professionalReport.basicData.vehicles.title')}
          disclaimer={t('professionalReport.basicData.vehicles.numberOfVehicles.title')}
        />
        <VisibilitySensor
          offset={{ bottom: 50 }}
          scrollCheck
          intervalDelay={10}
          onChange={isVisible => isVisible && dispatch(setActiveTab('vehicles'))}
          partialVisibility
          minTopValue={200}
        >
          <NumberOfVehiclesTable vehicles={vehicles} vehiclesFleetValues={vehiclesFleetValues} />
        </VisibilitySensor>
      </div>
      <Card
        className="card-simple card-simple-padding-none has-shadow space-vertical-md"
        style={{ width: '100%' }}
        bodyStyle={{ padding: 12 }}
      >
        <Collapse
          ghost
          expandIcon={({ isActive }) =>
            isActive ? <MinusOutlined style={{ fontSize: '14px' }} /> : <PlusOutlined style={{ fontSize: '14px' }} />
          }
        >
          <Panel header={t('professionalReport.basicData.vehicles.ownershipDescription.title')} key="1">
            <div className="padding-x-lg padding-top-lg">
              <Paragraph>
                <Trans i18nKey="professionalReport.basicData.vehicles.ownershipDescription.managerOwner" />
              </Paragraph>
              <Paragraph>
                <Trans i18nKey="professionalReport.basicData.vehicles.ownershipDescription.manager" />
              </Paragraph>
              <Paragraph>
                <Trans i18nKey="professionalReport.basicData.vehicles.ownershipDescription.owner" />
              </Paragraph>
            </div>
          </Panel>
        </Collapse>
      </Card>
      {customPdfValues && !customReport ? (
        <Row>
          <Card className="card-transparent space-vertical-sm">
            {t('professionalReport.basicData.vehicles.customDisclaimer')}
          </Card>
        </Row>
      ) : briefPdf ? (
        <Row className="space-bottom-md">
          <Card className="card-transparent space-vertical-sm">
            {t('professionalReport.basicData.vehicles.brief.disclaimer')}
          </Card>
        </Row>
      ) : (
        <>
          {!isUiHidden || vehiclesDetails?.length ? (
            <div className="space-top-lg">
              <ReportBlockHeader
                icon={<Icon component={CarIcon} className="icon--gray" />}
                title={t('professionalReport.basicData.vehicles.listOfVehicles.title')}
              />
              <ListOfVehiclesTable vehicleTableData={vehiclesDetails} />
            </div>
          ) : null}
          <Card
            className="card-simple card-simple-padding-none has-shadow space-vertical-md space-bottom-lg"
            style={{ width: '100%' }}
          >
            <VehicleFleet reportBasicDataSelector={basicVehicles} showVehiclesValueSectionByDefault />
          </Card>
        </>
      )}
    </div>
  );
};

export default Vehicles;
