import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row, Button, Form, Input, Card, Divider, Select, Modal, message, DatePicker, Tooltip } from 'antd';
import Icon, { EuroOutlined } from '@ant-design/icons';
import { ReactComponent as AddDebtIcon } from 'assets/img/icons/addDebt.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  addDebtorCompany,
  getCompanyOrPersonCoveredDebtsList,
  getCompanyOrPersonUncoveredDebtsList,
  getDebtId,
  getDebtTypesCompany,
} from '../../store/entities/administration/thunks';
import { BlockHeader } from './BlockHeader';
import { ChooseDebtorModal } from '../../views/Administration/DebtManagement/modals/ChooseDebtorModal';

const NewDebt = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { Option } = Select;
  const dispatch = useDispatch();
  const { debtTypesCompany, debtTypesLoading, debtId, errorMsg, isSaveDebtsBtnLoading, debtorInformation } =
    useSelector(state => state.administration);
  const defaultInputRules = [{ required: true, message: '' }];

  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePrefillForm = () => {
    if (debtorInformation) {
      form.setFieldsValue({
        companyName: debtorInformation.companyName ?? '',
        companyCode: debtorInformation.companyCode ?? '',
        address: debtorInformation.address ?? '',
        email: '',
        manager: debtorInformation.manager ?? '',
        phoneNumber: debtorInformation.phoneNumber ?? '',
      });
      dispatch({ type: 'TOGGLE_CHOOSE_DEBTOR_MODAL', payload: false });
    }
  };

  const handleOnSubmit = async () => {
    setIsConfirmationModalVisible(true);
  };

  const handleConfirmSubmit = async () => {
    setLoading(true);
    await dispatch(addDebtorCompany(form.getFieldsValue()));
    await dispatch(getCompanyOrPersonCoveredDebtsList({ companyOrPerson: 'company' }));
    await dispatch(getCompanyOrPersonUncoveredDebtsList({ companyOrPerson: 'company' }));
  };

  useEffect(() => {
    if (loading && !isSaveDebtsBtnLoading) {
      if (errorMsg) {
        message.error(errorMsg);
      } else {
        dispatch(getDebtId());
        message.success(t('global.successMsg'));
        form.resetFields();
      }
      setIsConfirmationModalVisible(false);
      setLoading(false);
    }
  }, [errorMsg, isSaveDebtsBtnLoading]);

  useEffect(() => {
    !debtTypesCompany?.length && dispatch(getDebtTypesCompany());
  }, []);

  useEffect(() => {
    form.setFieldsValue({ debtId });
  }, [debtId]);

  return (
    <>
      <Row justify="start">
        <Col>
          <BlockHeader
            icon={<Icon component={AddDebtIcon} className="icon--gray" />}
            title={t('administration.debtManagement.company.newDebt.title')}
          />
        </Col>
      </Row>
      <Form
        form={form}
        labelCol={{ span: 24 }}
        initialValues={{ submissionDate: new Date().toLocaleDateString('en-CA'), debtId }}
        onFinish={handleOnSubmit}
        className="new-debt"
        requiredMark={false}
      >
        <Card className="card-simple space-vertical-lg has-shadow">
          <span>{t('administration.debtManagement.company.newDebt.information.title')}</span>
          <Divider />
          <Row
            gutter={[16, 16]}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Col xs={7} md={7} lg={7} xl={7} xxl={7}>
              <Form.Item name="debtId" label={t('administration.debtManagement.company.newDebt.information.debtId')}>
                <Input readOnly />
              </Form.Item>
              <Form.Item
                name="amount"
                label={t('administration.debtManagement.company.newDebt.information.amount')}
                extra={t('administration.debtManagement.company.newDebt.information.amount.subtitle')}
                rules={[
                  {
                    required: true,
                    message: t('administration.debtManagement.company.newDebt.information.amount.subtitle'),
                  },
                ]}
              >
                <Input addonAfter={<EuroOutlined />} />
              </Form.Item>
            </Col>
            <Col xs={7} md={7} lg={7} xl={7} xxl={7}>
              <Form.Item
                name="submissionDate"
                label={t('administration.debtManagement.company.newDebt.information.submission.date')}
                rules={defaultInputRules}
              >
                <Input readOnly />
              </Form.Item>
              <Form.Item
                name="debtType"
                label={t('administration.debtManagement.company.newDebt.information.type')}
                rules={defaultInputRules}
              >
                <Select
                  optionFilterProp="children"
                  showSearch
                  allowClear
                  placeholder={t('administration.debtManagement.company.newDebt.information.type.placeholder')}
                  loading={debtTypesLoading}
                >
                  {!!debtTypesCompany.length &&
                    debtTypesCompany.map(item => (
                      <Option key={item.id} value={item.id}>
                        {t(`administration.debtManagement.company.newDebt.information.type.${item.debtType}`)}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={7} md={7} lg={7} xl={7} xxl={7}>
              <Form.Item
                name="dateIncurrence"
                label={t('administration.debtManagement.company.newDebt.information.incurrence.date')}
                rules={defaultInputRules}
              >
                <DatePicker className="datepicker" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <span>{t('administration.debtManagement.company.newDebt.debtor.title')}</span>
          <Divider />
          <Row>
            <Col xs={20} md={20} lg={20} xl={20} xxl={20}>
              <Form.Item
                name="companyName"
                label={t('administration.debtManagement.company.newDebt.debtor')}
                rules={defaultInputRules}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={4} md={4} lg={4} xl={4} xxl={4} className="justify-content-end align-items-center">
              <Button
                className="choose"
                onClick={() => dispatch({ type: 'TOGGLE_CHOOSE_DEBTOR_MODAL', payload: true })}
              >
                {t('administration.debtManagement.company.newDebt.debtor.button')}
              </Button>
            </Col>
          </Row>
          <Row
            gutter={[16, 16]}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Col xs={7} md={7} lg={7} xl={7} xxl={7}>
              <Form.Item
                name="companyCode"
                label={t('administration.debtManagement.company.newDebt.debtor.code')}
                rules={defaultInputRules}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={7} md={7} lg={7} xl={7} xxl={7}>
              <Form.Item name="manager" label={t('administration.debtManagement.company.newDebt.debtor.manager')}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={7} md={7} lg={7} xl={7} xxl={7}></Col>
          </Row>
          <Row
            gutter={[16, 16]}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Col xs={7} md={7} lg={7} xl={7} xxl={7}>
              <Form.Item name="phoneNumber" label={t('administration.debtManagement.company.newDebt.debtor.phone')}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={7} md={7} lg={7} xl={7} xxl={7}>
              <Form.Item name="address" label={t('administration.debtManagement.company.newDebt.debtor.address')}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={7} md={7} lg={7} xl={7} xxl={7}>
              <Tooltip title={t('administration.debtManagement.company.newDebt.debtor.email.tooltip')}>
                <Form.Item
                  name="email"
                  label={t('administration.debtManagement.company.newDebt.debtor.email')}
                  extra={t('administration.debtManagement.company.newDebt.debtor.email.subtitle')}
                >
                  <Input />
                </Form.Item>
              </Tooltip>
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ float: 'right', marginTop: 20 }}>
            <Col>
              <Button className="cancel" htmlType="submit">
                {t('administration.debtManagement.company.newDebt.debtor.cancel.button')}
              </Button>
            </Col>
            <Col>
              <Button className="save" htmlType="submit">
                {t('administration.debtManagement.company.newDebt.debtor.save.button')}
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
      <Modal
        title={t('administration.debtManagement.newDebt.confirmationModal.title')}
        visible={isConfirmationModalVisible}
        onCancel={() => setIsConfirmationModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsConfirmationModalVisible(false)} loading={loading}>
            {t('global.cancel')}
          </Button>,
          <Button key="confirm" type="primary" onClick={handleConfirmSubmit} loading={loading}>
            {t('global.confirm')}
          </Button>,
        ]}
      >
        {t('administration.debtManagement.newDebt.confirmationModal.message')}
      </Modal>
      <ChooseDebtorModal handlePrefillForm={handlePrefillForm} />
    </>
  );
};

export default NewDebt;
