import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/reusable/Modal';

import { Typography } from 'antd';
import MessageImg from 'assets/img/message.png';
import { ReactComponent as Close } from 'assets/img/icons/close.svg';

const { Paragraph } = Typography;

const ResponseModal = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal className="response-modal" isOpen size="sm">
      <div className="justify-content-center">
        <img src={MessageImg} alt="message" />
      </div>
      <div className="response-modal-content">
        <Paragraph>{t('modal.consultation.responseModal.received')}</Paragraph>
        <Paragraph>{t('modal.consultation.responseModal.contact')}</Paragraph>
      </div>
      <Close className="close-btn" onClick={onClose} />
    </Modal>
  );
};

export default ResponseModal;
