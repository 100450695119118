import CompanyDataCard from '../../../components/reusable/CompanyDataCard';
import { Button, Col, Radio, Space } from 'antd';
import useFormatDate, { DATE_FORMAT_LONG } from '../../../hooks/format/useFormatDate';
import { getChildOrderIdFinancialStatement, getFinancialStatements } from '../../../store/entities/startReport/thunks';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DetailedFinancialStatements } from '../../../components/reusable/DetailedFinancialStatements';

export const OptionalStatements = () => {
  const { companyId } = useParams();
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const dispatch = useDispatch();
  const [selectedOptionalStatement, setSelectedOptionalStatement] = useState(null);
  const { selectableFinancialStatements, financialStatementChildOrderId, financialData } = useSelector(
    state => state.startReport
  );

  const findStatementById = id => {
    const statement = selectableFinancialStatements.find(stmt => stmt?.financialStatementId === id);

    if (statement) {
      return {
        year: statement?.financialYear,
        financialStatementId: statement?.financialStatementId,
      };
    }

    return null;
  };

  useEffect(() => {
    if (financialStatementChildOrderId) {
      // Trigger the second request when financialStatementChildOrderId is available
      dispatch(getFinancialStatements(financialStatementChildOrderId));
    }
  }, [financialStatementChildOrderId]);

  const groupedStatements = useMemo(() => {
    if (!financialData?.activePurchasedFinancialStatements) return [];

    return Object.values(
      financialData.activePurchasedFinancialStatements.reduce((acc, statement) => {
        const groupingId = statement.groupingId;

        if (!acc[groupingId]) {
          acc[groupingId] = {
            financialStatementId: statement.financialStatementId,
            statements: [statement],
          };
        } else {
          acc[groupingId].statements.push(statement);

          // Update financialStatementId based on largest financialYear
          if (statement.financialYear > acc[groupingId].statements[0].financialYear) {
            acc[groupingId].financialStatementId = statement.financialStatementId;
          }
        }

        return acc;
      }, {})
    );
  }, [financialData.activePurchasedFinancialStatements]);

  const filteredOptionalFinancialStatements = useMemo(() => {
    if (!selectableFinancialStatements) return null;

    return selectableFinancialStatements.filter(statement => {
      const targetFinancialStatementId = statement.financialStatementId;

      return groupedStatements
        ? groupedStatements.every(
            purchasedStatement => purchasedStatement.financialStatementId !== targetFinancialStatementId
          )
        : true;
    });
  }, [selectableFinancialStatements, groupedStatements]);

  return (
    <>
      {selectableFinancialStatements?.length && filteredOptionalFinancialStatements?.length ? (
        <CompanyDataCard title={t('startReport.financialStatements.optional.title')}>
          <Col>
            <Radio.Group
              onChange={event => setSelectedOptionalStatement(event.target.value)}
              value={selectedOptionalStatement}
            >
              <Space direction="vertical">
                {filteredOptionalFinancialStatements.map(statement => {
                  return (
                    <Radio
                      key={statement.financialStatementId}
                      value={statement?.financialStatementId}
                      disabled={!!statement?.isDeleted}
                    >
                      {t('startReport.financialStatements.optional.radio', {
                        year: statement?.financialYear,
                        months: statement?.financialMonth || '-',
                        reportType:
                          statement.reportType &&
                          statement.reportType !== 'Undefined' &&
                          statement.reportType !== 'Annual'
                            ? ` (${t(`financialStatements.reportType.${statement.reportType}`)})`
                            : '',
                        categoryType:
                          statement.categoryType && statement.categoryType !== 'Undefined'
                            ? ` (${t(`financialStatements.categoryType.${statement.categoryType}`)})`
                            : '',
                        dateFrom: formatDate(statement?.financialYearBegin, DATE_FORMAT_LONG),
                        dateTo: formatDate(statement?.financialYearEnd, DATE_FORMAT_LONG),
                        source: statement?.source,
                        deletionStatus: statement.isDeleted ? t('financialStatements.chooseReport.deletionStatus') : '',
                        submissionDate: statement.submissionDate
                          ? t('financialStatements.chooseReport.submissionDate', {
                              date: formatDate(statement.submissionDate),
                            })
                          : '',
                      })}
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
          </Col>
          <Button
            className="btn-secondary buy-report"
            onClick={() =>
              dispatch(
                getChildOrderIdFinancialStatement({
                  companyCode: companyId,
                  optionalObj: findStatementById(selectedOptionalStatement),
                })
              )
            }
            disabled={!selectedOptionalStatement}
          >
            {t('startReport.financialStatements.optional.buy.button')}
          </Button>
        </CompanyDataCard>
      ) : null}
      {financialData?.activePurchasedFinancialStatements?.length > 0 &&
        groupedStatements.map(groupstatement => {
          const targetFinancialStatementId = groupstatement.financialStatementId;
          const targetFinancialStatement = selectableFinancialStatements.find(
            statement => statement.financialStatementId === targetFinancialStatementId
          );

          return (
            <DetailedFinancialStatements
              financialStatement={groupstatement.statements}
              statement={targetFinancialStatement}
            />
          );
        })}
    </>
  );
};
