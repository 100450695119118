import { useCallback, useState, useEffect, useRef, useLayoutEffect } from 'react';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';

const useResponsiveContainer = (minHeight = 150) => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const { siderCollapsed } = useSelector(state => state.ui);
  const ref = useRef();
  const hasBeenReset = useRef(true);

  const setNode = useCallback(node => {
    ref.current = node;
    updateSize();
  }, []);

  const updateSize = () => {
    if (!ref.current) return;
    setSize({
      width: ref.current.getBoundingClientRect().width,
      height:
        ref.current.getBoundingClientRect().height > minHeight ? ref.current.getBoundingClientRect().height : minHeight,
    });
    hasBeenReset.current = false;
  };

  const updateSizeDebounced = useCallback(debounce(updateSize, 150), []);

  useEffect(() => {
    window.addEventListener('resize', updateSizeDebounced);

    return () => {
      updateSizeDebounced.cancel();
      window.removeEventListener('resize', updateSizeDebounced);
    };
  }, []);

  useLayoutEffect(() => {
    setTimeout(() => updateSize(), 300);
  }, [siderCollapsed]);

  return [size, setNode];
};

export default useResponsiveContainer;
