import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Collapse, Card } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { ReactComponent as Owner } from 'assets/img/spiderweb/owner.svg';
import { ReactComponent as Management } from 'assets/img/spiderweb/management.svg';
import { ReactComponent as Number } from 'assets/img/spiderweb/number.svg';
import { ReactComponent as Board } from 'assets/img/spiderweb/board.svg';
import { ReactComponent as Joint } from 'assets/img/spiderweb/joint.svg';
import { ReactComponent as Email } from 'assets/img/spiderweb/email.svg';
import { ReactComponent as Founder } from 'assets/img/spiderweb/founder.svg';
import { ReactComponent as Address } from 'assets/img/spiderweb/address.svg';
import { ReactComponent as Domain } from 'assets/img/spiderweb/domain.svg';
import { ReactComponent as InBankruptcy } from 'assets/img/spiderweb/in-bankruptcy.svg';
import { ReactComponent as Removed } from 'assets/img/spiderweb/removed.svg';
import { ReactComponent as UnderReorganization } from 'assets/img/spiderweb/under-reorganization.svg';
import { ReactComponent as UnderRestructurization } from 'assets/img/spiderweb/under-restructurization.svg';
import { ReactComponent as UnderLiquidation } from 'assets/img/spiderweb/under-liquidation.svg';
import { ReactComponent as UnderReformation } from 'assets/img/spiderweb/under-reformation.svg';
import { ReactComponent as UnpaidDepts } from 'assets/img/spiderweb/unpaid-depts.svg';
import { ReactComponent as ActiveArrests } from 'assets/img/spiderweb/active-arrests.svg';
import { ReactComponent as NoEmployees } from 'assets/img/spiderweb/no-employees.svg';
import { ReactComponent as DebtPaymentHistory } from 'assets/img/spiderweb/dept-payment-history.svg';
import { ReactComponent as ArrestsHistory } from 'assets/img/spiderweb/arrests-history.svg';
import { ReactComponent as DebtCollectionClaims } from 'assets/img/spiderweb/debt-collection-claims.svg';
import { ReactComponent as LegalProceedings } from 'assets/img/spiderweb/legal-proceedings.svg';
import { ReactComponent as FsNotSubmitted } from 'assets/img/spiderweb/FS-not-submitted.svg';
import { ReactComponent as BanckruptcyProceedings } from 'assets/img/spiderweb/bankruptcy-proceedings.svg';
import { ReactComponent as RestructuringProceedings } from 'assets/img/spiderweb/restructuring-proceedings.svg';
import { ReactComponent as PersonLimited } from 'assets/img/spiderweb/person-limited-right.svg';
import { ReactComponent as Foreigner } from 'assets/img/spiderweb/foreigner.svg';
import { ReactComponent as NewShareholders } from 'assets/img/spiderweb/new-shareholders-list.svg';
import { ReactComponent as CompanyAgeLess } from 'assets/img/spiderweb/company-age-less.svg';
import { ReactComponent as UnknownCode } from 'assets/img/spiderweb/unknown-code.svg';
import { NODE_RELATIONS } from '../../Spiderweb/SpiderwebMap/utils/constants';

const MarksExplanation = () => {
  const { t } = useTranslation();
  const { Panel } = Collapse;

  const explanationsContent = useMemo(
    () => (
      <>
        <Row className="marks-title">
          <Col xs={8} md={8} lg={8} xl={8}>
            {t('marks.explanation.relations.types.title')}
          </Col>
        </Row>
        <Row className="marks">
          <Col xs={8} md={8} lg={8} xl={8}>
            {NODE_RELATIONS['shareholdersRelations']?.icon ?? NODE_RELATIONS['rootCompany']?.icon}
            {t('marks.explanation.relations.types.owner')}
          </Col>
          <Col xs={8} md={8} lg={8} xl={8}>
            {NODE_RELATIONS['executiveRelations']?.icon ?? NODE_RELATIONS['rootCompany']?.icon}
            {t('marks.explanation.relations.types.management')}
          </Col>
          <Col xs={8} md={8} lg={8} xl={8}>
            {NODE_RELATIONS['samePhoneNumberRelations']?.icon ?? NODE_RELATIONS['rootCompany']?.icon}
            {t('marks.explanation.relations.types.phone')}
          </Col>
        </Row>
        <Row className="marks">
          <Col xs={8} md={8} lg={8} xl={8}>
            {NODE_RELATIONS['boardMemberRelations']?.icon ?? NODE_RELATIONS['rootCompany']?.icon}
            {t('marks.explanation.relations.types.board')}
          </Col>
          <Col xs={8} md={8} lg={8} xl={8}>
            {NODE_RELATIONS['sameCourtRelations']?.icon ?? NODE_RELATIONS['rootCompany']?.icon}
            {t('marks.explanation.relations.types.joint')}
          </Col>
          <Col xs={8} md={8} lg={8} xl={8}>
            {NODE_RELATIONS['sameEmailRelations']?.icon ?? NODE_RELATIONS['rootCompany']?.icon}
            {t('marks.explanation.relations.types.email')}
          </Col>
        </Row>
        <Row className="marks">
          <Col xs={8} md={8} lg={8} xl={8}>
            {NODE_RELATIONS['founderRelations']?.icon ?? NODE_RELATIONS['rootCompany']?.icon}
            {t('marks.explanation.relations.types.founder')}
          </Col>
          <Col xs={8} md={8} lg={8} xl={8}>
            {NODE_RELATIONS['sameRegistrationAddressRelations']?.icon ?? NODE_RELATIONS['rootCompany']?.icon}
            {t('marks.explanation.relations.types.address')}
          </Col>
          <Col xs={8} md={8} lg={8} xl={8}>
            {NODE_RELATIONS['sameWebRelations']?.icon ?? NODE_RELATIONS['rootCompany']?.icon}
            {t('marks.explanation.relations.types.domain')}
          </Col>
        </Row>

        <Row className="marks-title">
          <Col xs={8} md={8} lg={8} xl={8}>
            {t('marks.explanation.company.statuses.title')}
          </Col>
        </Row>
        <Row className="marks">
          <Col xs={8} md={8} lg={8} xl={8}>
            <Icon component={InBankruptcy} className="icon--lg " />
            {t('marks.explanation.company.statuses.in.bankruptcy')}
          </Col>
          <Col xs={8} md={8} lg={8} xl={8}>
            <Icon component={Removed} className="icon--lg " />
            {t('marks.explanation.company.statuses.removed')}
          </Col>
          <Col xs={8} md={8} lg={8} xl={8}>
            <Icon component={UnderReorganization} className="icon--lg " />
            {t('marks.explanation.company.statuses.under.reorganization')}
          </Col>
        </Row>
        <Row className="marks">
          <Col xs={8} md={8} lg={8} xl={8}>
            <Icon component={UnderRestructurization} className="icon--lg " />
            {t('marks.explanation.company.statuses.under.restructurization')}
          </Col>
          <Col xs={8} md={8} lg={8} xl={8}>
            <Icon component={UnderLiquidation} className="icon--lg " />
            {t('marks.explanation.company.statuses.under.liquidation')}
          </Col>
          <Col xs={8} md={8} lg={8} xl={8}>
            <Icon component={UnderReformation} className="icon--lg " />
            {t('marks.explanation.company.statuses.under.reformation')}
          </Col>
        </Row>

        <Row className="marks-title">
          <Col xs={8} md={8} lg={8} xl={8}>
            {t('marks.explanation.negative.facts.title')}
          </Col>
        </Row>
        <Row className="marks">
          <Col xs={8} md={8} lg={8} xl={8}>
            <Icon component={UnpaidDepts} className="icon--lg " />
            {t('marks.explanation.negative.facts.unpaid.depts')}
          </Col>
          <Col xs={8} md={8} lg={8} xl={8}>
            <Icon component={ActiveArrests} className="icon--lg " />
            {t('marks.explanation.negative.facts.active.arrests')}
          </Col>
          <Col xs={8} md={8} lg={8} xl={8}>
            <Icon component={NoEmployees} className="icon--lg " />
            {t('marks.explanation.negative.facts.no.employees')}
          </Col>
        </Row>
        <Row className="marks">
          <Col xs={8} md={8} lg={8} xl={8}>
            <Icon component={DebtPaymentHistory} className="icon--lg " />
            {t('marks.explanation.negative.facts.debt.payment.history')}
          </Col>
          <Col xs={8} md={8} lg={8} xl={8}>
            <Icon component={ArrestsHistory} className="icon--lg " />
            {t('marks.explanation.negative.facts.arrests.history')}
          </Col>
          <Col xs={8} md={8} lg={8} xl={8}>
            <Icon component={DebtCollectionClaims} className="icon--lg " />
            {t('marks.explanation.negative.facts.debt.collection.claims')}
          </Col>
        </Row>
        <Row className="marks">
          <Col xs={8} md={8} lg={8} xl={8}>
            <Icon component={LegalProceedings} className="icon--lg " />
            {t('marks.explanation.negative.facts.legal.proceedings')}
          </Col>
          <Col xs={8} md={8} lg={8} xl={8}>
            <Icon component={FsNotSubmitted} className="icon--lg " />
            {t('marks.explanation.negative.facts.fs.not.submitted')}
          </Col>
          <Col xs={8} md={8} lg={8} xl={8}>
            <Icon component={BanckruptcyProceedings} className="icon--lg " />
            {t('marks.explanation.negative.facts.bankruptcy.proceedings')}
          </Col>
        </Row>
        <Row className="marks">
          <Col xs={8} md={8} lg={8} xl={8}>
            <Icon component={RestructuringProceedings} className="icon--lg " />
            {t('marks.explanation.negative.facts.restructuring.proceedings')}
          </Col>
          <Col xs={8} md={8} lg={8} xl={8}>
            <Icon component={PersonLimited} className="icon--lg " />
            {t('marks.explanation.negative.facts.person.limited.right')}
          </Col>
          <Col xs={8} md={8} lg={8} xl={8}></Col>
        </Row>

        <Row className="marks-title">
          <Col xs={8} md={8} lg={8} xl={8}>
            {t('marks.explanation.other.facts.title')}
          </Col>
        </Row>
        <Row className="marks">
          <Col xs={8} md={8} lg={8} xl={8}>
            <Icon component={Foreigner} className="icon--lg " />
            {t('marks.explanation.other.facts.foreigner')}
          </Col>
          <Col xs={8} md={8} lg={8} xl={8}>
            <Icon component={NewShareholders} className="icon--lg " />
            {t('marks.explanation.other.facts.new.shareholders')}
          </Col>
          <Col xs={8} md={8} lg={8} xl={8} className="greyed-out">
            {t('marks.explanation.other.facts.historic.entry')}
          </Col>
        </Row>
        <Row className="marks">
          <Col xs={8} md={8} lg={8} xl={8}>
            <Icon component={CompanyAgeLess} className="icon--lg " />
            {t('marks.explanation.other.facts.company.age.less')}
          </Col>
          <Col xs={8} md={8} lg={8} xl={8}>
            <Icon component={UnknownCode} className="icon--lg " />
            {t('marks.explanation.other.facts.unknown.code')}
          </Col>
          <Col xs={8} md={8} lg={8} xl={8}></Col>
        </Row>
      </>
    ),
    [t]
  );

  return (
    <Card
      className="card-simple card-simple-padding-none space-vertical-sm has-shadow-spiderweb marks-explanation"
      style={{ width: '100%' }}
    >
      <Row gutter={24}>
        <Col xs={24} md={24} lg={24} xl={24}>
          <Collapse
            ghost
            expandIcon={({ isActive }) =>
              isActive ? <MinusOutlined style={{ fontSize: '20px' }} /> : <PlusOutlined />
            }
          >
            <Panel header={t('marks.explanation.title')} key="1">
              {' '}
              <Card title={t('marks.explanation.title')}>{explanationsContent}</Card>
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </Card>
  );
};

export default MarksExplanation;
