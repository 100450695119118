import React from 'react';
import { Row, Col, Typography, Space } from 'antd';

const { Text } = Typography;

const InformationBlock = ({ title, icon, data, hasDivider = false, extra }) => {
  const InfoRow = ({ label, value }) => (
    <Row>
      <Col xs={24} xl={6}>
        <strong>{label}</strong>
      </Col>
      <Col xs={24} xl={18}>
        {value}
      </Col>
    </Row>
  );
  return (
    <div className="space-top-xl">
      <Row className="statistic-block statistic-block--company" gutter={[16, 16]}>
        <Col md={24} lg={4}>
          <Space size="middle" className="space-vertical-none">
            {icon && icon}
            <Text>{title}</Text>
          </Space>
        </Col>
        <Col md={24} lg={extra ? 16 : 20}>
          {Object.keys(data).map(dataKey => (
            <InfoRow {...data[dataKey]} />
          ))}
          {hasDivider && <hr className="space-top-xl" />}
        </Col>
        {extra && (
          <Col md={24} lg={4}>
            {extra}
          </Col>
        )}
      </Row>
    </div>
  );
};

export default InformationBlock;
