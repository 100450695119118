export const flattenObject = obj => {
  const result = {};

  Object.values(obj).forEach(nestedObject => {
    Object.assign(result, nestedObject);
  });

  return result;
};

export const sumArrayByAttribute = (data, attribute) => data.reduce((total, obj) => obj[attribute] + total, 0);
