import React from 'react';

const Statistic = ({ value, description, isNegative = false }) => (
  <div className="ant-statistic ant-statistic-sm">
    <div className="ant-statistic-content">
      <span className="ant-statistic-content-value">
        <span className={`ant-statistic-content-value-int${isNegative ? ' color-error' : ''}`}>{value}</span>
      </span>
    </div>
    <div className="ant-statistic-title">{description}</div>
  </div>
);

export default Statistic;
