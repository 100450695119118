import { useCallback, useLayoutEffect, useRef, useState } from 'react';

const useYAxisWidth = (deps = []) => {
  const [yAxisWidth, setYAxisWidth] = useState(100);
  const ref = useRef();

  const setNode = useCallback(node => {
    ref.current = node;
    updateSize();
  }, []);

  const updateSize = () => {
    if (!ref.current) return;

    const yAxisTicks = [...ref.current.querySelectorAll('.recharts-yAxis .recharts-cartesian-axis-tick > text')];

    const widths = yAxisTicks.map(tick => tick.getBBox().width);

    setTimeout(() => setYAxisWidth(yAxisTicks.length > 0 ? Math.max(...widths) + 10 : 100), 50);
  };

  useLayoutEffect(updateSize, deps);

  return [yAxisWidth, setNode];
};

export default useYAxisWidth;
