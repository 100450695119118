import { fetchFromApi, optionsForGetRequest, optionsForPostRequest } from './';

const getBasicData = filters => fetchFromApi(`/spider-web/report/basic`, optionsForPostRequest(filters));

const getCompanies = filters => fetchFromApi(`/spider-web/search`, optionsForPostRequest(filters));
const getChildCompany = filters => fetchFromApi(`/spider-web/report/basic`, optionsForPostRequest(filters));

const getSpiderWebReportPdf = ({ companyCode }) =>
  fetchFromApi(`/company/spider-web/report/basic/pdf/preview?companyCode=${companyCode}`, optionsForGetRequest());

export default {
  getBasicData,
  getCompanies,
  getChildCompany,
  getSpiderWebReportPdf,
};
