import { useMemo } from 'react';

const useFilteredUtmParams = search => {
  return useMemo(() => {
    let params = new URLSearchParams(search);
    let utmParams = new URLSearchParams();
    params.forEach((value, key) => {
      if (key.startsWith('utm')) {
        utmParams.append(key, value);
      }
    });
    return utmParams.toString();
  }, [search]);
};

export default useFilteredUtmParams;
