import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Close } from 'assets/img/icons/close.svg';
import attention from 'assets/img/attention.png';

const Popup = ({ onClose, onContinue, financial }) => {
  const { t } = useTranslation();

  return (
    <div className="popup-container">
      <div className="popup-filler">
        <span className="icon-btn space-top-md popup-filler-close-btn" role="button" onClick={onClose}>
          <Close />
        </span>
        <h1>{t('financialStatements.popup.title')}</h1>
        {financial ? (
          <p className="financial">{t('financialStatements.popup.disclaimer')}</p>
        ) : (
          <p className="monitoring">{t('otherProducts.monitoring.attention')}</p>
        )}
        <img alt="attention" src={attention} />
        <div>
          <button className="popup-filler-continue-btn" onClick={onContinue}>
            {t('financialStatements.popup.button')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
