import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _isEmpty from 'lodash.isempty';
import VisibilitySensor from 'react-visibility-sensor';

import useNumberFormatter from 'hooks/format/useNumberFormatter';
import { setActiveTab } from 'store/entities/professionalReport';
import { basicShares } from 'store/entities/professionalReport/selectors/basicDataSelectors';

import CompanyDataCard, { CompanyDataRow } from 'components/reusable/CompanyDataCard';
import CollapsibleTable from 'components/reusable/CollapsibleTable';
import TableColumnDate from 'components/reusable/TableColumnDate';
import AuthorizedCapital from 'components/reusable/report/cards/AuthorizedCapital';
import { authorizedCapitalSelector } from 'store/entities/professionalReport/selectors/basicDataSelectors';

import { Col, Row, Typography, Table, Card } from 'antd';
import { PieChartOutlined } from '@ant-design/icons';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import { BlockHeader } from '../../../components/reusable/BlockHeader';
import { JadisInformationCard } from '../../../components/reusable/report/cards/JadisInformationCard';

const { Text } = Typography;

const Shares = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isUiHidden = useIsUiHidden();
  const { shares, shareholders, shareholdersHistory, jadisInformation, jadisInformationHistory } =
    useSelector(basicShares);
  const { authorizedCapital, authorizedCapitalHistory } = useSelector(authorizedCapitalSelector);
  const formatNumber = useNumberFormatter({ minimumFractionDigits: 2, maximumFractionDigits: 2 });
  const formatCurrency = useNumberFormatter();

  const shareholdersColumns = [
    {
      title: t('professionalReport.basicData.shares.shareOwner'),
      dataIndex: 'name',
      key: 'sharedholders_name',
      render: text => (
        <>
          <p>
            <Text type="info">{text}</Text>
          </p>
        </>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: `${t('professionalReport.basicData.shares.numberOfShares')} %`,
      dataIndex: 'percentage',
      key: 'sharedholders_percentage',
      sorter: (a, b) => a.percentage.localeCompare(b.percentage),
      sortDirections: ['ascend', 'descend'],
      render: value => (value ? formatNumber(value) : '-'),
    },
    {
      title: t('global.dateFrom'),
      dataIndex: 'dateFrom',
      key: 'sharedholders_dateFrom',
      render: TableColumnDate,
      sorter: (a, b) => {
        const dateA = new Date(a.dateFrom);
        const dateB = new Date(b.dateFrom);
        return dateB - dateA;
      },
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 80 : null,
    },
    {
      title: t('professionalReport.basicData.shares.recordDate'),
      dataIndex: 'recordDate',
      key: 'sharedholders_recordDate',
      render: TableColumnDate,
      sorter: (a, b) => {
        const dateA = new Date(a.recordDate);
        const dateB = new Date(b.recordDate);
        return dateB - dateA;
      },
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 80 : null,
    },
    {
      title: t('professionalReport.basicData.shares.shareholderRights'),
      dataIndex: 'rightType',
      key: 'sharedholders_rightType',
      sorter: (a, b) => a.rightType.localeCompare(b.rightType),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('professionalReport.basicData.shares.country'),
      dataIndex: 'country',
      key: 'sharedholders_country',
      sorter: (a, b) => a.country.localeCompare(b.country),
      sortDirections: ['ascend', 'descend'],
    },
  ];

  const historicalInformationColumns = [
    {
      title: t('global.dateFrom'),
      dataIndex: 'dateFrom',
      key: 'sharedholders_historic_dateFrom',
      render: TableColumnDate,
      sorter: (a, b) => {
        const dateA = new Date(a.dateFrom);
        const dateB = new Date(b.dateFrom);
        return dateB - dateA;
      },
      sortDirections: ['ascend', 'descend'],
      defaultSortOrder: 'ascend',
      width: isUiHidden ? 80 : null,
    },
    {
      title: t('global.dateUntil'),
      dataIndex: 'dateUntil',
      key: 'sharedholders_historic_dateUntil',
      render: TableColumnDate,
      sorter: (a, b) => {
        const dateA = new Date(a.dateUntil);
        const dateB = new Date(b.dateUntil);
        return dateB - dateA;
      },
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 80 : null,
    },
    {
      title: t('professionalReport.basicData.shares.recordDate'),
      dataIndex: 'recordDate',
      key: 'sharedholders_historic_recordDate',
      render: TableColumnDate,
      sorter: (a, b) => {
        const dateA = new Date(a.recordDate);
        const dateB = new Date(b.recordDate);
        return dateB - dateA;
      },
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('professionalReport.basicData.shares.shareOwner'),
      dataIndex: 'name',
      key: 'sharedholders_historic_name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: `${t('professionalReport.basicData.shares.numberOfShares')} %`,
      dataIndex: 'percentage',
      key: 'sharedholders_historic_percentage',
      sorter: (a, b) => {
        const aValue = a.percentage === '-' || !a?.percentage ? -1 : parseFloat(a.percentage);
        const bValue = b.percentage === '-' || !b?.percentage ? -1 : parseFloat(b.percentage);

        if (aValue === -1 && bValue === -1) return 0;
        if (aValue === -1) return 1;
        if (bValue === -1) return -1;

        return aValue - bValue;
      },
      sortDirections: ['ascend', 'descend'],
      render: value => (value ? formatNumber(value) : '-'),
    },
  ];

  return (
    <VisibilitySensor
      offset={{ bottom: 50 }}
      scrollCheck
      intervalDelay={10}
      onChange={isVisible => isVisible && dispatch(setActiveTab('shares'))}
    >
      <div>
        <AuthorizedCapital authorizedCapital={authorizedCapital} authorizedCapitalHistory={authorizedCapitalHistory} />
        <div id="shares">
          <CompanyDataCard
            icon={<PieChartOutlined className="icon--gray" />}
            title={t('professionalReport.basicData.shares.title')}
          >
            {_isEmpty(shares) && t('global.noInformation')}
            {shares.map((share, i) => (
              <React.Fragment key={`share-${i}`}>
                <Col>
                  <CompanyDataRow type={t('professionalReport.basicData.shares.type')} value={share?.type ?? '-'} />
                  <CompanyDataRow
                    type={t('professionalReport.basicData.shares.numberOfShares')}
                    value={share?.totalShare ?? '-'}
                  />
                  <CompanyDataRow
                    type={t('professionalReport.basicData.shares.valueOfShares')}
                    value={share?.shareValue ? formatCurrency(share?.shareValue) : '-'}
                  />
                </Col>
                {i < shares.length - 1 && <br />}
              </React.Fragment>
            ))}
          </CompanyDataCard>
          <JadisInformationCard jadisInformation={jadisInformation} jadisInformationHistory={jadisInformationHistory} />
        </div>
        {!isUiHidden || shareholders?.length ? (
          <Card className="card-simple card-simple-padding-none space-vertical-sm has-shadow" style={{ width: '100%' }}>
            <Row justify="start">
              <Col>
                <BlockHeader
                  icon={<PieChartOutlined />}
                  title={t('professionalReport.basicData.shares.shareholders.title')}
                />
              </Col>
            </Row>
            {shareholders?.length ? (
              <Table
                rowKey={record => record.companyName}
                bordered
                dataSource={shareholders}
                columns={shareholdersColumns}
                scroll={{ x: true }}
                pagination={{ defaultPageSize: 10, showSizeChanger: true }}
                showSorterTooltip={false}
              />
            ) : (
              <Row>
                <Card className="card-transparent space-bottom-sm">
                  {t('professionalReport.basicData.shares.historicalInformation.noInformation')}
                </Card>
              </Row>
            )}

            {shareholdersHistory?.length ? (
              <CollapsibleTable
                className="space-top-md"
                title={t('professionalReport.basicData.shares.historicalInformation.title')}
                data={shareholdersHistory}
                columns={historicalInformationColumns}
                bordered
              />
            ) : null}
          </Card>
        ) : null}
      </div>
    </VisibilitySensor>
  );
};

export default Shares;
