import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';

import Summary from 'views/ProfessionalReport/Summary';
import { getOrderId } from 'store/entities/professionalReport/thunks';
import { resetProfessionalReport } from 'store/entities/professionalReport';
import useRedirect from 'hooks/navigation/useRedirect';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import useProfReportState from 'hooks/report/professionalReport/useProfReportState';
import CompanyRatings from 'views/ProfessionalReport/CompanyRatings';
import NegativeData from 'views/ProfessionalReport/NegativeData';
import FinancialData from 'views/ProfessionalReport/FinancialData';
import BasicData from 'views/ProfessionalReport/BasicData';
import { setIsUiHidden } from 'store/entities/ui';
import PdfHeader from '../Pdfs/PdfHeader';
import useWindowSize from 'hooks/dom/useWindowSize';

const ProfessionalReportPdf = () => {
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const { t } = useTranslation();
  const { orderId, orderDetails, customPdfValues } = useSelector(state => state.professionalReport);
  const { isLoading, hasError } = useProfReportState();

  const orderReport = async () => {
    const orderIdResult = await dispatch(getOrderId({ code: companyId, isCompany: true }));

    if (!getOrderId.fulfilled.match(orderIdResult)) {
      redirect('/search/company');
    }
  };

  useEffect(() => {
    dispatch(setIsUiHidden(true));
    return () => {
      dispatch(setIsUiHidden(false));
    };
  }, []);

  useEffect(() => {
    orderReport();
    return () => dispatch(resetProfessionalReport());
  }, []);

  return (
    <LoadingSpinner size="large" className="loader-overlay" spinning={isLoading}>
      {hasError && <Redirect to="/search/company" exact />}
      <div className="pdf-wrapper">
        {orderId && (
          <>
            <PdfHeader title={orderDetails?.companyTitle} subtitle={t('professionalReport.title')} />
            <Summary />
            <BasicData briefPdf={!customPdfValues} />
            <CompanyRatings />
            <NegativeData briefPdf={!customPdfValues} />
            <FinancialData />
          </>
        )}
      </div>
    </LoadingSpinner>
  );
};

export default ProfessionalReportPdf;
