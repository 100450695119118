import React, { useState } from 'react';
import SpiderwebView from './OldSpiderwebMap/SpiderwebView';
import { SearchBlock } from './SearchBlock';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import LoadingSpinner from 'components/reusable/LoadingSpinner.js';

const Spiderweb = () => {
  const [isSpiderwebOpen, setIsSpiderwebOpen] = useState(false);
  const [filterTagList, setFilterTagList] = useState();
  const [searchRelations, setSearchRelations] = useState();
  const { basicDataPending } = useSelector(state => state.spiderWeb);

  return (
    <LoadingSpinner spinning={basicDataPending}>
      <Row className="container">
        <Col span={24}>
          <SearchBlock
            setIsSpiderwebOpenForm={() => setIsSpiderwebOpen(false)}
            setFilterTagList={setFilterTagList}
            setSearchRelations={setSearchRelations}
            setIsSpiderwebOpenResults={() => setIsSpiderwebOpen(true)}
            searchRelations={searchRelations}
            isSpiderwebOpen={isSpiderwebOpen}
          />
          {isSpiderwebOpen && <SpiderwebView filterTagList={filterTagList} setFilterTagList={setFilterTagList} />}
        </Col>
      </Row>
    </LoadingSpinner>
  );
};

export default Spiderweb;
