import React from 'react';
import { useSelector } from 'react-redux';

import IndividualSearchForm from 'views/Search/IndividualSearch/IndividualSearchForm';
import Basic from 'views/Search/IndividualSearch/Basic';

const IndividualSearch = () => {
  const { visiblePersonView } = useSelector(state => state.person);

  return (
    <>
      {(visiblePersonView === 'table' || visiblePersonView === 'details') && <IndividualSearchForm />}
      {visiblePersonView === 'details' && <Basic />}
    </>
  );
};

export default IndividualSearch;
