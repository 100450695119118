import { fetchFromApi, optionsForPostRequest } from './';

const getSession = () => fetchFromApi('/session');

const getFeatures = () => fetchFromApi('/features');

const changeLanguage = languageCode =>
  fetchFromApi(`/session/change-language?languageCode=${languageCode}`, optionsForPostRequest());

export default {
  getSession,
  changeLanguage,
  getFeatures,
};
