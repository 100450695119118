import React from 'react';
import { useTranslation } from 'react-i18next';

import CompanyDataCard, { CompanyDataRow } from 'components/reusable/CompanyDataCard';
import useFormatDate from 'hooks/format/useFormatDate';
import { Button, Card, Row } from 'antd';

import { ReactComponent as History } from 'assets/img/icons/history.svg';

const PropertyArrestsCard = ({
  propertyArrestsData,
  type = 'registeredOut',
  showNoEntries = false,
  buyReportButton = false,
  historyPurchesed,
  action = null,
  children,
}) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();

  return (
    <CompanyDataCard
      icon={<History />}
      title={t(`professionalReport.negativeData.propertyArrests${type === 'registered' ? '.registered' : ''}.title`)}
      extra={action}
    >
      {!propertyArrestsData?.length && showNoEntries ? (
        <span>{t('global.noRecords')}</span>
      ) : propertyArrestsData?.total ? (
        <>
          <CompanyDataRow
            type={t('professionalReport.negativeData.propertyArrests.numberOfEntries')}
            value={propertyArrestsData?.total || '-'}
          />
          <CompanyDataRow
            type={t('professionalReport.negativeData.propertyArrests.averageDuration')}
            value={propertyArrestsData?.averageDays ? `${propertyArrestsData.averageDays} ${t('global.days')}` : '-'}
          />
          <CompanyDataRow
            type={t('professionalReport.negativeData.propertyArrests.lastDate')}
            value={propertyArrestsData?.latestDate ? formatDate(propertyArrestsData?.latestDate) : '-'}
          />
          {buyReportButton ? (
            <Button className="buy-report" onClick={historyPurchesed}>
              {t('startReport.negativeData.buy.report.button')}
            </Button>
          ) : null}
        </>
      ) : (
        <Row className="space-bottom-md">
          <Card className="card-transparent space-vertical-sm">
            {t(
              `professionalReport.negativeData.propertyArrests.noInformation.${
                type === 'registered' ? 'registered' : 'registeredOut'
              }`
            )}
          </Card>
        </Row>
      )}
      {children}
    </CompanyDataCard>
  );
};

export default PropertyArrestsCard;
