import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Tabs, message } from 'antd';

import Companies from 'views/Administration/DebtManagement/Companies';
import Individuals from 'views/Administration/DebtManagement/Individuals';
import useRedirect from 'hooks/navigation/useRedirect';
import useLastPathname from 'hooks/navigation/useLastPathname';
import NewDebtLegal from './NewDebtLegal';
import NewDebtIndividuals from './NewDebtIndividuals';
import Instructions from './Instructions';
import { useDispatch, useSelector } from 'react-redux';
import { SelectedDebtorSection } from './sections';
import { getDebtsExcel, getDebtId } from '../../../store/entities/administration/thunks';
import { DownloadExcel } from '../../../components/reusable/report/buttons';
import LoadingSpinner from 'components/reusable/LoadingSpinner';

const tabsKeys = Object.freeze({
  companies: 'companies',
  individuals: 'individuals',
  newDebtLegal: 'newDebtLegal',
  newDebtIndividuals: 'newDebtIndividuals',
  instructions: 'instrctions',
});
const { TabPane } = Tabs;

const DebtManagement = () => {
  const { t } = useTranslation();
  const redirect = useRedirect();
  const lastPathname = useLastPathname();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(tabsKeys.companies);
  const { companyOrPersonDebtsLoading, debtsExcelLoading } = useSelector(state => state.administration);

  const getType = type => {
    switch (type) {
      case 'newDebtIndividuals':
      case 'individuals':
        return 'person';
      case 'newDebtLegal':
      case 'companies':
        return 'company';
    }
  };

  const handleTabChange = useCallback(
    activeKey => {
      dispatch({ type: 'REMOVE_SELECTED_DEBTOR' });
      setActiveTab(activeKey);

      if (activeKey === 'newDebtLegal' || activeKey === 'newDebtIndividuals') {
        dispatch(getDebtId());
      }
      if (activeKey !== lastPathname) {
        redirect(`/administration/debtors-administration/debt-management`);
      }
    },
    [redirect, dispatch]
  );

  useEffect(() => {
    return () => {
      dispatch({ type: 'REMOVE_SELECTED_DEBTOR' });
    };
  }, []);

  return (
    <LoadingSpinner size="large" spinning={debtsExcelLoading}>
      <div className="container">
        <Row>
          <Col span={24}>
            {activeTab !== 'instrctions' && (
              <DownloadExcel
                disabled={companyOrPersonDebtsLoading || debtsExcelLoading}
                fileName={
                  getType(activeTab) === 'person'
                    ? 'header.breadcrumb.administration.person.xlsx'
                    : 'header.breadcrumb.administration.company.xlsx'
                }
                className="claims-excel-download"
                fetcher={() => dispatch(getDebtsExcel({ type: getType(activeTab) }))}
              />
            )}
            <Tabs defaultActiveKey={activeTab} style={{ height: 'auto', width: '100%' }} onChange={handleTabChange}>
              <TabPane tab={t('administration.tabs.companies')} key={tabsKeys.companies}>
                {activeTab === tabsKeys.companies && <SelectedDebtorSection companyOrPerson="company" />}
                <Companies />
              </TabPane>
              <TabPane tab={t('administration.tabs.individuals')} key={tabsKeys.individuals}>
                {activeTab === tabsKeys.individuals && <SelectedDebtorSection companyOrPerson="person" />}
                <Individuals />
              </TabPane>
              <TabPane tab={t('administration.tabs.newDebtLegal')} key={tabsKeys.newDebtLegal}>
                <NewDebtLegal />
              </TabPane>
              <TabPane tab={t('administration.tabs.newDebtIndividuals')} key={tabsKeys.newDebtIndividuals}>
                <NewDebtIndividuals />
              </TabPane>
              <TabPane tab={t('administration.tabs.instructions')} key={tabsKeys.instructions}>
                <Instructions />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>
    </LoadingSpinner>
  );
};

export default DebtManagement;
