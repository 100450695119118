import React from 'react';
import { Col, Row } from 'antd';
import { BlockHeader } from '../BlockHeader';

const ReportBlockHeader = ({ title, id, icon = null, disclaimer = null }) => {
  return (
    <>
      {title && (
        <Row id={id} justify="start">
          <Col>
            <BlockHeader icon={icon} title={title} />
          </Col>
        </Row>
      )}
      {disclaimer && (
        <Row>
          <Col>
            <div className="space-bottom-sm">
              <span>{disclaimer}</span>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ReportBlockHeader;
