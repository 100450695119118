import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Col, Row, Typography } from 'antd';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import { setIsUiHidden } from 'store/entities/ui';
import SpiderwebView from './SpiderwebView';
import MarksExplanation from './MarksExplanation';

const { Title } = Typography;

const SpiderwebReportPdf = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { basicData, basicDataPending, basicDataError } = useSelector(state => state.spiderWeb);

  useEffect(() => {
    dispatch(setIsUiHidden(true));

    return () => {
      dispatch(setIsUiHidden(false));
    };
  }, []);

  return (
    <LoadingSpinner size="large" className="loader-overlay" spinning={basicDataPending}>
      {basicDataError || (basicData?.isError && <Redirect to="/spider-web/" exact />)}
      <div className="pdf-wrapper">
        <Row>
          <Col>
            <Title level={1} className="pdf-title">
              {basicData?.company?.fullName}
            </Title>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="align-items-end">
            <Title level={5}>{t('header.breadcrumb.other-products.spider-web-report')}</Title>
          </Col>
        </Row>
        <SpiderwebView filterTagList={[]} setFilterTagList={() => null} explanationsExpanded />
      </div>
    </LoadingSpinner>
  );
};

export default SpiderwebReportPdf;
