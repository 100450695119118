import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getBasicInfo, getOrderId } from 'store/entities/mainInformation/thunks';
import { setIsUiHidden } from 'store/entities/ui';
import BasicData from './BasicData';
import PdfHeader from '../Pdfs/PdfHeader';
import PdfFooterDisclaimer from '../Pdfs/PdfFooterDisclaimer';

const MainInformationPdf = () => {
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { orderId, basicInfo } = useSelector(state => state.mainInformation);

  useEffect(() => {
    dispatch(setIsUiHidden(true));
    return () => {
      dispatch(setIsUiHidden(false));
    };
  }, []);

  useEffect(() => {
    dispatch(getOrderId(companyId));
  }, []);

  useEffect(() => {
    if (orderId) {
      dispatch(getBasicInfo(orderId));
    }
  }, [orderId]);

  return (
    <div className="pdf-wrapper">
      <PdfHeader
        title={basicInfo?.basicInformation?.companyName}
        subtitle={t('header.breadcrumb.main-information-report')}
      />
      <BasicData />
      <PdfFooterDisclaimer />
    </div>
  );
};

export default MainInformationPdf;
