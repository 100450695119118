import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { setSelectedCompany } from 'store/entities/foreignCompany';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import { Table, Typography } from 'antd';
import useFormatDate from '../../../hooks/format/useFormatDate';
import CustomRequestTable from '../../../components/reusable/CustomRequestTable';
import { getCompanies, getHistoricalCompanies } from '../../../store/entities/foreignCompany/thunks';

const { Text } = Typography;

const SearchResultsTable = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const formatDate = useFormatDate();
  const { searchResults, getCompaniesPending, foreignCompanySearch } = useSelector(state => state.foreignCompany);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const currentLang = useMemo(() => i18n.language.split('-')[0], [i18n]);
  const sortOpts = useMemo(() => ({ sensitivity: 'base' }), []);

  const columns = [
    {
      title: t('foreignCompanySearch.results.companyName'),
      dataIndex: 'companyName',
      key: 'name',
      render: (text, record) => (
        <>
          <p>
            <Text type="info">{text}</Text>
          </p>
          {record.oldName && <p>({record.oldName})</p>}
        </>
      ),
      // sorter: (a, b) => (a.companyName || '').localeCompare(b.companyName || '', 'lt', sortOpts),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('foreignCompanySearch.results.code'),
      dataIndex: 'companyCode',
      key: 'code',
      // sorter: (a, b) => a.companyCode.localeCompare(b.companyCode),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('foreignCompanySearch.results.address'),
      dataIndex: 'address',
      key: 'address',
      render: address => {
        return <>{address.simpleValue && <p>{address.simpleValue}</p>}</>;
      },
      // sorter: (a, b) => {console.log(a, 'a'); console.log(b,'b'); return (a.address?.simpleValue || '').localeCompare(b.address?.simpleValue || '', 'lt', sortOpts)},
    },
    {
      title: t('foreignCompanySearch.results.other'),
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => {
        return (
          <>
            <p>
              <Text>
                {t('foreignCompanySearch.results.other.status')}: {record.status}
              </Text>
            </p>
            <p>
              <Text>
                {t('foreignCompanySearch.results.other.dateOfLatestAccounts')}:{' '}
                {record?.dateOfLatestAccounts ? formatDate(record.dateOfLatestAccounts) : '-'}
              </Text>
            </p>
            <p>
              <Text>
                {t('foreignCompanySearch.results.other.type')}: {record.type}
              </Text>
            </p>
          </>
        );
      },
      // sorter: (a, b) => (a.status || '').localeCompare(b.status || '', currentLang, sortOpts),
    },
  ];

  const handleRowClick = record => {
    dispatch(setSelectedCompany(record));
  };

  const sortedResults = useMemo(() => {
    if (!searchResults?.companies) return null;

    return [...searchResults.companies].sort((a, b) => {
      if (
        (a.status === 'Veikiantis subjektas' && b.status !== 'Veikiantis subjektas') ||
        (a.status === 'Active subject' && b.status !== 'Active subject')
      ) {
        return -1;
      }
      if (
        (a.status !== 'Veikiantis subjektas' && b.status === 'Veikiantis subjektas') ||
        (a.status !== 'Active subject' && b.status === 'Active subject')
      ) {
        return 1;
      }

      const statusComparison = (a.status || '').localeCompare(b.status || '', currentLang, sortOpts);
      if (statusComparison !== 0) {
        return statusComparison;
      }
      return (a.companyName || '').localeCompare(b.companyName || '', 'lt', sortOpts);
    });
  }, [searchResults, currentLang, sortOpts]);

  const getCleanedFieldsValue = values => {
    const cleanedValues = {};

    Object.keys(values).forEach(key => {
      cleanedValues[key] = values[key] !== undefined ? values[key] : '';
    });

    return cleanedValues;
  };

  const handleTableChange = ({ current, pageSize: size }, _, { order, field }) => {
    if (pageSize === size) {
      setPageSize(size);
      setPage(current);
      dispatch(
        getCompanies({
          ...getCleanedFieldsValue(foreignCompanySearch),
          page: { pageNumber: current, itemsPerPage: size },
        })
      );
    } else {
      setPageSize(size);
      setPage(1);
      dispatch(
        getCompanies({
          ...getCleanedFieldsValue(foreignCompanySearch),
          page: { pageNumber: 1, itemsPerPage: size },
        })
      );
    }
  };

  return (
    searchResults?.companies?.length > 0 && (
      <div className="space-vertical-lg">
        <LoadingSpinner size="large" spinning={getCompaniesPending} indicator={null}>
          <CustomRequestTable
            data={sortedResults}
            total={searchResults.totalSize}
            columns={columns}
            defaultPageSize={pageSize}
            current={page}
            handleTableChange={handleTableChange}
            pageSizeOptions={[10, 20, 50, 100]}
            rowClassName="ant-table-row-clickable"
            onRow={record => ({
              onClick: () => handleRowClick(record),
            })}
            rowKey="id"
          />
        </LoadingSpinner>
      </div>
    )
  );
};

export default SearchResultsTable;
