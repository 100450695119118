import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useFormatDate, { DATE_FORMAT_LONG, DATE_FORMAT_YEAR_ONLY } from 'hooks/format/useFormatDate';
import useNumberFormatter from 'hooks/format/useNumberFormatter';

import { CompanySmallBlock } from 'components/reusable/CompanyDataCard';
import OrderExpertReport from '../CompanyRatings/OrderExpertReport';

import { Col, Row } from 'antd';
import useIsUiHidden from '../../../hooks/dom/useIsUiHidden';

const BasicInformation = () => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const formatCurrency = useNumberFormatter();
  const { summary } = useSelector(state => state.professionalReport);
  const isUiHidden = useIsUiHidden();

  return (
    <>
      <div className="space-vertical-sm small-blocks">
        <Row gutter={[3]} className="ant-row-pdf-gap">
          <Col xs={12} sm={isUiHidden ? 8 : 12} md={8} lg={6} xl={4} xxl={4}>
            <CompanySmallBlock
              title={t('professionalReport.summary.basicInformation.creditLimit')}
              value={
                summary?.basicInformation?.automatedCreditLimitAmount
                  ? formatCurrency(summary?.basicInformation?.automatedCreditLimitAmount)
                  : '-'
              }
              explanation={
                !summary?.basicInformation?.automatedCreditLimitAmount &&
                t('professionalReport.summary.basicInformation.creditLimit.explanation')
              }
            />
          </Col>
          <Col xs={12} sm={isUiHidden ? 8 : 12} md={8} lg={6} xl={4} xxl={4}>
            <CompanySmallBlock
              value={summary?.activeDebts?.total || t('global.noEntries')}
              title={t('professionalReport.summary.openDebts.title')}
              explanation={
                summary?.activeDebts?.total
                  ? t('professionalReport.summary.openDebts.explanation', {
                      date: summary?.activeDebts?.updateDate ? formatDate(summary.activeDebts.updateDate) : '-',
                      lastDate: summary?.activeDebts?.latestDate ? formatDate(summary.activeDebts.latestDate) : '-',
                      amount: summary?.activeDebts?.amount ? formatCurrency(summary.activeDebts.amount) : '-',
                      days: summary?.activeDebts?.averageDays ? summary.activeDebts.averageDays : '-',
                      count: summary?.activeDebts?.total ? summary.activeDebts.total : '-',
                    })
                  : false
              }
            />
          </Col>
          <Col xs={12} sm={isUiHidden ? 8 : 12} md={8} lg={6} xl={4} xxl={4}>
            <CompanySmallBlock
              explanation={
                summary.paidDebts.total
                  ? t('professionalReport.summary.paymentHistory.explanation', {
                      amount: summary?.paidDebts?.total || '-',
                      date: formatDate(summary?.paidDebts?.latestDate),
                      days: summary?.paidDebts?.averageDays,
                    })
                  : false
              }
              title={t('professionalReport.summary.paymentHistory.title')}
              value={summary?.paidDebts?.total || t('global.noEntries')}
            />
          </Col>
          <Col xs={12} sm={isUiHidden ? 8 : 12} md={8} lg={6} xl={4} xxl={4}>
            <CompanySmallBlock
              title={t('professionalReport.summary.basicInformation.propertyArrests')}
              value={summary?.registeredArrests?.total || t('global.noEntries')}
            />
          </Col>
          <Col xs={12} sm={isUiHidden ? 8 : 12} md={8} lg={6} xl={4} xxl={4}>
            <CompanySmallBlock
              explanation={
                summary?.legalProceedings?.total
                  ? t('professionalReport.summary.numberOfLegalProceedings.explanation', {
                      totalDefendant: summary.legalProceedings?.totalDefendant || '0',
                      latestDate: formatDate(new Date(summary.legalProceedings?.latestDate), 'P'),
                    })
                  : false
              }
              title={t('professionalReport.summary.numberOfLegalProceedings.title')}
              value={summary?.legalProceedings?.total || t('global.noEntries')}
            />
          </Col>
          <Col xs={12} sm={isUiHidden ? 8 : 12} md={8} lg={6} xl={4} xxl={4}>
            <CompanySmallBlock
              title={t('professionalReport.summary.negativeInformation.title')}
              value={summary?.negativeInformation?.total || t('global.noEntries')}
            />
          </Col>
          <Col xs={12} sm={isUiHidden ? 8 : 12} md={8} lg={6} xl={4} xxl={4}>
            <CompanySmallBlock
              explanation={
                summary?.employees?.employeeNumber
                  ? t('professionalReport.summary.employees.numberOfEmployees.basic.explanation', {
                      date: formatDate(summary?.employees?.employeeNumberDate),
                    })
                  : false
              }
              title={t('professionalReport.summary.employees.numberOfEmployees.basic')}
              value={summary?.employees?.employeeNumber || t('global.noEntries')}
            />
          </Col>
          <Col xs={12} sm={isUiHidden ? 8 : 12} md={8} lg={6} xl={4} xxl={4}>
            <CompanySmallBlock
              title={
                <Trans
                  i18nKey="professionalReport.summary.finance.explanation"
                  values={{
                    date: summary?.finance?.turnoverModel?.year
                      ? formatDate(summary?.finance?.turnoverModel?.year, DATE_FORMAT_YEAR_ONLY)
                      : '-',
                  }}
                />
              }
              value={
                summary?.finance?.turnoverModel?.amount
                  ? formatCurrency(summary.finance.turnoverModel.amount)
                  : t('global.noEntries')
              }
            />
          </Col>
          <Col xs={12} sm={isUiHidden ? 8 : 12} md={8} lg={6} xl={4} xxl={4}>
            <CompanySmallBlock
              explanation={
                summary?.sodraDebts?.hasDebts
                  ? t('professionalReport.summary.sodraDebts.explanation', {
                      deferredAmount: formatCurrency(summary?.sodraDebts?.deffAmount),
                      date: summary?.sodraDebts?.latestDate
                        ? formatDate(summary?.sodraDebts?.latestDate, DATE_FORMAT_LONG)
                        : '-',
                    })
                  : false
              }
              title={t('professionalReport.summary.sodraDebts.title')}
              value={summary?.sodraDebts?.amount ? formatCurrency(summary.sodraDebts.amount) : t('global.noEntries')}
            />
          </Col>
          <Col xs={12} sm={isUiHidden ? 8 : 12} md={8} lg={6} xl={4} xxl={4}>
            <CompanySmallBlock
              title={t('professionalReport.summary.basicInformation.numberOfShareholders')}
              value={summary?.basicInformation?.numberOfShareholders || t('global.noEntries')}
            />
          </Col>
          <Col xs={12} sm={isUiHidden ? 8 : 12} md={8} lg={6} xl={4} xxl={4}>
            <CompanySmallBlock
              explanation={t('companyResult.summary.reportNumbers.value', {
                reports1: summary?.statistics?.lastMonth,
                reports2: summary?.statistics?.lastQuarter,
                reports3: summary?.statistics?.lastHalfYear,
              })}
              title={t('professionalReport.summary.searches.title')}
              value={summary?.statistics?.lastHalfYear || t('global.noEntries')}
            />
          </Col>
          <Col xs={12} sm={isUiHidden ? 8 : 12} md={8} lg={6} xl={4} xxl={4}>
            <CompanySmallBlock
              explanation={t('professionalReport.summary.employees.explanation', {
                averageSalaryDate: summary?.employees?.averageSalaryDate
                  ? formatDate(summary?.employees?.averageSalaryDate, DATE_FORMAT_LONG)
                  : '-',
                averageSalaryWomen: summary?.employees?.averageSalaryWomen
                  ? formatCurrency(summary?.employees?.averageSalaryWomen)
                  : t('global.noCurrency'),
                averageSalaryMen: summary?.employees?.averageSalaryMen
                  ? formatCurrency(summary?.employees?.averageSalaryMen)
                  : t('global.noCurrency'),
              })}
              title={t('professionalReport.summary.employees.title')}
              value={
                summary?.employees?.averageSalary
                  ? formatCurrency(summary.employees.averageSalary)
                  : t('global.noEntries')
              }
            />
          </Col>
        </Row>
      </div>
      <OrderExpertReport />
    </>
  );
};

export default BasicInformation;
