import { useSelector } from 'react-redux';

const useIsProfReportLoading = () => {
  const {
    orderIdPending,
    summaryPending,
    basicDataPending,
    companyRatingsPending,
    negativeDataPending,
    financialDataPending,
    professionalReportPdfPending,
    orderIdError,
    summaryError,
    basicDataError,
    companyRatingsError,
    negativeDataError,
    financialDataError,
  } = useSelector(state => state.professionalReport);

  return {
    isLoading:
      orderIdPending ||
      summaryPending ||
      basicDataPending ||
      companyRatingsPending ||
      negativeDataPending ||
      professionalReportPdfPending ||
      financialDataPending,
    hasError:
      orderIdError || summaryError || basicDataError || negativeDataError || financialDataError || companyRatingsError,
  };
};

export default useIsProfReportLoading;
