import React from 'react';

const CustomLabelList = props => {
  const { x, y, width, value, position } = props;

  return (
    <g>
      <text x={x + width / 2 || x + 15} y={position === 'bottom' ? y + 23 : y - 10} fill="#000" textAnchor="middle">
        {value}
      </text>
    </g>
  );
};

export default CustomLabelList;
