import LoadingSpinner from 'components/reusable/LoadingSpinner';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getOrderId, getOrderDetails } from 'store/entities/professionalReport/thunks';
import { setIsUiHidden } from 'store/entities/ui';
import ProfessionalReport from './ProfessionalReport';
import useFormatDate from '../../hooks/format/useFormatDate';
import PdfHeader from '../Pdfs/PdfHeader';

const IndividualProfessionalReportPdf = () => {
  const { individualId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orderId, orderDetails, summaryPending } = useSelector(state => state.professionalReport);

  useEffect(() => {
    dispatch(setIsUiHidden(true));
    return () => {
      dispatch(setIsUiHidden(false));
    };
  }, []);

  useEffect(() => {
    if (individualId) {
      dispatch(getOrderId({ code: individualId, isCompany: false }));
    }
  }, []);

  useEffect(() => {
    if (orderId) {
      dispatch(
        getOrderDetails({
          code: individualId,
          orderId: orderId,
          isCompany: false,
        })
      );
    }
  }, [orderId]);

  const fullTitle = () => {
    if (orderDetails?.firstName && orderDetails?.lastName) {
      return `${orderDetails?.firstName} ${orderDetails?.lastName}`;
    } else {
      return `${t('global.personalCode.short')} ${individualId}`;
    }
  };

  return (
    <LoadingSpinner size="large" className="loader-overlay" spinning={summaryPending}>
      <div className="pdf-wrapper">
        <PdfHeader title={fullTitle()} subtitle={t('professionalReport.individual.title')} />
        {orderDetails && <ProfessionalReport isProfReport={true} />}
      </div>
    </LoadingSpinner>
  );
};

export default IndividualProfessionalReportPdf;
