import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import CompanyDataCard from 'components/reusable/CompanyDataCard';
import Icon, { DownloadOutlined, FileOutlined } from '@ant-design/icons';
import { Select, Col, Button, Row, Space } from 'antd';
import useFormatDate from 'hooks/format/useFormatDate';

const { Option } = Select;

const ChooseReport = ({ setIsPopUpModalOpen, basicInformation = [], setReport, report }) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();

  const handleReportChange = (value, option) => {
    const { financialStatementId, financialYear } = option.data;
    setReport({ financialStatementId, financialYear });
  };

  return (
    <CompanyDataCard
      icon={<FileOutlined />}
      title={t('financialStatements.chooseReport.title')}
      className="financial-statement-choose-report"
    >
      <Row gutter={[16, 16]} align="middle" className="choose-report-row">
        <Col xs={24} md={24} lg={24} xl={4}>
          <Space className="select-option-title">{t('financialStatements.chooseReport.select.title')}</Space>
        </Col>
        <Col xs={24} md={24} lg={24} xl={14} xxl={16}>
          <Select
            style={{ width: '90%', height: 'auto', wordWrap: 'break-word' }}
            defaultValue="default"
            onChange={handleReportChange}
          >
            <Option key="default" value="default" disabled selected hidden>
              {t('financialStatements.chooseReport.placeholder')}
            </Option>
            {basicInformation.map(data => {
              return (
                <Option
                  key={data.financialStatementId}
                  value={data.financialStatementId}
                  data={data}
                  year={data.financialYear}
                >
                  <Trans
                    i18nKey="financialStatements.chooseReport.option"
                    values={{
                      year: data.financialYear,
                      month: data.financialMonth,
                      categoryType:
                        data.categoryType && data.categoryType !== 'Undefined'
                          ? ` (${t(`financialStatements.categoryType.${data.categoryType}`)})`
                          : '',
                      reportType:
                        data.reportType && data.reportType !== 'Undefined' && data.reportType !== 'Annual'
                          ? ` (${t(`financialStatements.reportType.${data.reportType}`)})`
                          : '',
                      begin: formatDate(data.financialYearBegin),
                      end: formatDate(data.financialYearEnd),
                      source: data.source,
                      deletionStatus: data.isDeleted ? t('financialStatements.chooseReport.deletionStatus') : '',
                      submissionDate: data.submissionDate
                        ? t('financialStatements.chooseReport.submissionDate', {
                            date: formatDate(data.submissionDate),
                          })
                        : '',
                    }}
                  />
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col xs={24} md={24} lg={24} xl={6} xxl={4}>
          <Button className="btn-secondary" onClick={setIsPopUpModalOpen} disabled={!report}>
            <Icon component={DownloadOutlined} className="icon--lg" />
            {t('financialStatements.chooseReport.button')}
          </Button>
        </Col>
      </Row>
    </CompanyDataCard>
  );
};

export default ChooseReport;
