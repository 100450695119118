import React from 'react';
import CompanyDataCard, { CompanyDataRowTwoColumn } from 'components/reusable/CompanyDataCard';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useFormatDate from 'hooks/format/useFormatDate';
import { Button, Col, Row } from 'antd';
import { Link, useParams } from 'react-router-dom';

const FinancialStatementCard = ({ financialStatement, profReport = false, children }) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const { companyId } = useParams();
  const year = financialStatement?.financialYear || financialStatement?.financialStatementYear || '-';
  const months = financialStatement?.financialMonth || financialStatement?.monthsIncluded || '-';
  const yearFrom = formatDate(financialStatement?.financialYearBegin) || formatDate(financialStatement?.periodFrom);
  const yearTo = formatDate(financialStatement?.financialYearEnd) || formatDate(financialStatement?.periodTo);
  const reportType =
    financialStatement?.reportType && financialStatement.reportType !== 'Undefined' && financialStatement.reportType;
  const isPdfReportPage = !!window.location.pathname.includes('pdf-print');
  return (
    <CompanyDataCard
      icon={<InfoCircleOutlined />}
      title={
        <Row justify="space-between">
          <Col>{t('professionalReport.financialData.periodOfStatement.title')}</Col>
          {financialStatement?.categoryType && financialStatement?.categoryType !== 'Undefined' && (
            <Col>{t(`financialStatements.categoryType.${financialStatement.categoryType}`)}</Col>
          )}
        </Row>
      }
    >
      <CompanyDataRowTwoColumn
        items={[
          { type: t('professionalReport.financialData.financialStatementYear'), value: year },
          { type: t('professionalReport.financialData.source'), value: financialStatement?.source || '-' },
        ]}
      />
      <CompanyDataRowTwoColumn
        items={[
          { type: t('professionalReport.financialData.monthsIncluded'), value: months },
          {
            type: t('professionalReport.financialData.type'),
            value: reportType
              ? `${t(`financialStatements.reportType.${financialStatement.reportType}`)} ${t(
                  'professionalReport.financialData.type.financialReports'
                )}`
              : '-',
          },
        ]}
      />
      <CompanyDataRowTwoColumn
        items={[
          {
            type: t('professionalReport.financialData.period'),
            value: yearFrom && yearTo ? `${yearFrom} - ${yearTo}` : '-',
          },
          financialStatement?.isDeleted
            ? {
                type: t('professionalReport.financialData.state'),
                value: t('professionalReport.financialData.state.isDeleted'),
              }
            : profReport &&
              !isPdfReportPage && {
                type: t('professionalReport.financialData.financialReports'),
                value: (
                  <Link to={`/search/company/${companyId}/financial-statements`} target="_blank">
                    <Button size="small">{t('global.view')}</Button>
                  </Link>
                ),
              },
        ]}
      />
      <CompanyDataRowTwoColumn
        items={[
          {
            type: t('professionalReport.financialData.submissionDate'),
            value: formatDate(financialStatement?.submissionDate) || '-',
          },
          financialStatement?.isDeleted &&
            profReport &&
            !isPdfReportPage && {
              type: t('professionalReport.financialData.financialReports'),
              value: (
                <a href={`/search/company/${companyId}/financial-statements`} target="_blank" rel="noopener noreferrer">
                  <Button size="small">{t('global.view')}</Button>
                </a>
              ),
            },
        ]}
      />
      {children}
    </CompanyDataCard>
  );
};

export default FinancialStatementCard;
