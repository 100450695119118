import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { Bar, BarChart, Cell, ResponsiveContainer, Text, XAxis, YAxis, LabelList } from 'recharts';

import colors from 'constants/colors';
import { economicStatus } from 'constants/prescore';

const getTickText = (props, data, enableSmallScreenSize, windowWidth) => {
  const {
    y,
    x,
    payload: { value, index },
  } = props;

  const economicObject = data[index];

  return !!enableSmallScreenSize ? (
    <>
      <foreignObject x={x + 8} y={y - 30} width={16} height={16}>
        {economicObject?.icon}
      </foreignObject>
      <Text
        x={x + 30}
        y={y - 18}
        textAnchor="start"
        verticalAnchor="end"
        style={{ fontWeight: 'normal', fontSize: 11 }}
      >
        {i18next.t(value)}
      </Text>
    </>
  ) : (
    <>
      <foreignObject
        x={windowWidth > 1300 ? windowWidth * (2 / 3) - 155 : windowWidth * 0.5 - 5}
        y={y - 13}
        width={25}
        height={25}
        style={{ fontSize: 20 }}
      >
        {economicObject?.icon}
      </foreignObject>
      <Text
        x={windowWidth > 1300 ? windowWidth * (2 / 3) + -118 : windowWidth * 0.5 + 32}
        y={y - 1}
        textAnchor="start"
        verticalAnchor="middle"
        style={{ fontWeight: 'normal', fontSize: 18 }}
      >
        {i18next.t(value)}
      </Text>
    </>
  );
};

const getBarColor = status =>
  ({
    [economicStatus.warning]: colors.COLOR_AVERAGE,
    [economicStatus.error]: colors.COLOR_SECONDARY,
    [economicStatus.ok]: colors.COLOR_SUCCESS,
    [economicStatus.none]: colors.COLOR_GRAY,
  }[status]);

const barSizeSmall = 8;

const PrescoreChartV2 = ({ data, pdfReport }) => {
  const [windowWidth, setWindowWidth] = useState(pdfReport ? 740 : 1920);

  useEffect(() => {
    if (!pdfReport) {
      setWindowWidth(window.innerWidth);
      window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
    }
  }, []);

  return (
    <ResponsiveContainer width="100%" height={250} debounce={50}>
      <BarChart height={130} data={data} layout="vertical" margin={{ top: 10, left: -60 }}>
        <YAxis
          type="category"
          dataKey="name"
          interval={0}
          minTickGap={0}
          axisLine={false}
          tickLine={false}
          tick={props => getTickText(props, data, true, windowWidth)}
        />
        <XAxis type="number" hide />
        <Bar dataKey="uv" stackId="bar" radius={36} barSize={barSizeSmall}>
          {data.map(entry => (
            <Cell key={entry?.name} fill={getBarColor(entry?.status)} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default PrescoreChartV2;
