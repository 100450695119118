import { createAsyncThunk } from '@reduxjs/toolkit';
import sessionService from 'api/sessionService';
import accountService from 'api/accountService';
import usersService from 'api/usersService';
import jwt_decode from 'jwt-decode';

export const getSession = createAsyncThunk('user/getSession', async (_, { rejectWithValue }) => {
  try {
    const response = await sessionService.getSession();
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const signIn = createAsyncThunk('user/signIn', async (credentials, { rejectWithValue }) => {
  try {
    const response = await accountService.signIn(credentials);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const signOut = createAsyncThunk('user/signOut', async (_, { rejectWithValue }) => {
  try {
    const response = await accountService.signOut();
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const changeLanguage = createAsyncThunk('user/changeLanguage', async (languageCode, { rejectWithValue }) => {
  try {
    await sessionService.changeLanguage(languageCode);
    const tokenResponse = await accountService.refreshToken();
    localStorage.setItem('token', tokenResponse.accessToken);
    localStorage.setItem('tokenExp', jwt_decode(tokenResponse.accessToken)?.exp);
    return tokenResponse;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const changePhoneNumber = createAsyncThunk('user/changePhoneNumber', async (data, { rejectWithValue }) => {
  try {
    const response = await usersService.changePhoneNumber(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const changePassword = createAsyncThunk('user/changePassword', async (data, { rejectWithValue }) => {
  try {
    const response = await usersService.changePassword(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const resetPassword = createAsyncThunk('user/resetPassword', async (data, { rejectWithValue }) => {
  try {
    const response = await usersService.resetPassword(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getFeatures = createAsyncThunk('user/getFeatures', async (_, { rejectWithValue }) => {
  try {
    const response = await sessionService.getFeatures();
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});
