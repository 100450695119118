import {
  averageEconomicStatus,
  lowEconomicStatus,
  noneEconomicStatus,
  highEconomicStatus,
  economicIndicator,
  MAX_AMT,
} from '../../../constants/prescore';

export const getChartBarColorAndStatus = indicator => {
  switch (indicator) {
    case -1:
      return noneEconomicStatus;
    case 0:
      return lowEconomicStatus;
    case 1:
      return lowEconomicStatus;
    case 2:
      return averageEconomicStatus;
    case 3:
      return averageEconomicStatus;
    case 4:
      return highEconomicStatus;
    default:
      return noneEconomicStatus;
  }
};

// Value of the colored chart bars
export const getChartBarUv = indicator => {
  switch (indicator) {
    case -1:
      return 0;
    case 0:
      return 2000;
    case 1:
      return 2000;
    case 2:
      return 4000;
    case 3:
      return 4000;
    case 4:
      return 6000;
    default:
      return 0;
  }
};

export const getEconomicStatus = (indicator, name) =>
  ({
    [economicIndicator.low1]: {
      value: indicator,
      ...getChartBarColorAndStatus(indicator),
      name,
      uv: getChartBarUv(indicator),

      // Value of the uncolored part of the chart bar
      amt: MAX_AMT - getChartBarUv(indicator),
    },
    [economicIndicator.low2]: {
      value: indicator,
      ...getChartBarColorAndStatus(indicator),
      name,
      uv: getChartBarUv(indicator),
      amt: MAX_AMT - getChartBarUv(indicator),
    },
    [economicIndicator.average1]: {
      value: indicator,
      ...averageEconomicStatus,
      name,
      uv: getChartBarUv(indicator),
      amt: MAX_AMT - getChartBarUv(indicator),
    },
    [economicIndicator.average2]: {
      value: indicator,
      ...getChartBarColorAndStatus(indicator),
      name,
      uv: getChartBarUv(indicator),
      amt: MAX_AMT - getChartBarUv(indicator),
    },
    [economicIndicator.high]: {
      value: indicator,
      ...getChartBarColorAndStatus(indicator),
      name,
      uv: getChartBarUv(indicator),
      amt: MAX_AMT - getChartBarUv(indicator),
    },
    value: indicator ?? -1,
    [economicIndicator.none]: {
      ...getChartBarColorAndStatus(indicator),
      name,
      uv: getChartBarUv(indicator),
      amt: MAX_AMT - getChartBarUv(indicator),
    },
  }[indicator]);
