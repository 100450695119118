const isDev = process.env.NODE_ENV === 'development';

const languageCodes = {
  'en-US': 'EN',
  'lt-LT': 'LT',
};

const reCaptchaKey = '6LdvWU4eAAAAADQ2X53pEKqt8iL2s_-9uDqREU3B';

export { isDev, languageCodes, reCaptchaKey };
