import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';

import { basicInfo } from 'store/entities/professionalReport/selectors/basicDataSelectors';
import { setActiveTab } from 'store/entities/professionalReport';

import CompanyBasicInformation from 'components/reusable/report/cards/CompanyBasicInformation';
import CompanyManager from 'components/reusable/report/cards/CompanyManager';
import Procurist from 'components/reusable/report/cards/Procurist';

const Basic = () => {
  const dispatch = useDispatch();
  const { basicInformation, manager, managersHistory, previousCompanyNames } = useSelector(basicInfo);

  return (
    <div id="basic">
      <VisibilitySensor
        offset={{ bottom: 50 }}
        scrollCheck
        intervalDelay={10}
        onChange={isVisible => isVisible && dispatch(setActiveTab('basic'))}
      >
        <CompanyBasicInformation companyData={{ ...basicInformation, previousCompanyNames }} />
      </VisibilitySensor>
      <CompanyManager managerData={manager} managersHistory={managersHistory} />
      {basicInformation?.prokurist && <Procurist prokurist={basicInformation?.prokurist} />}
    </div>
  );
};

export default Basic;
