import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';

import Modal from 'components/reusable/Modal';
import { signOut } from 'store/entities/user/thunks';

import { Button, Typography } from 'antd';
import { ReactComponent as LightBulb } from 'assets/img/icons/LightBulb.svg';

const { Paragraph } = Typography;

const SESSION_TIMEOUT = 90;

const InactiveSessionModal = ({ onClose, initialTime }) => {
  const [remainingTime, setRemainingTime] = useState(SESSION_TIMEOUT);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSignOut = async () => {
    await dispatch(signOut());
    onClose();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const minutes = Math.abs((initialTime - new Date()) / 1000 / 60);
      if (minutes > 1.5) {
        clearInterval(interval);
        handleSignOut();
      }
      setRemainingTime(prevState => {
        return prevState - 1;
      });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [onClose]);

  return (
    <Modal isOpen size="sm">
      <div className="justify-content-center">
        <LightBulb />
      </div>
      <div className="inactive-session-content">
        <Paragraph>
          <Trans
            i18nKey="modal.inactiveSession.session"
            components={{ span: <span /> }}
            values={{ seconds: remainingTime }}
          />
        </Paragraph>
        <Paragraph>{t('modal.inactiveSession.continue')}</Paragraph>
      </div>
      <div className="inactive-session-actions">
        <Button onClick={handleSignOut}>{t('global.no')}</Button>
        <Button className="btn-secondary" onClick={onClose}>
          {t('global.yes')}
        </Button>
      </div>
    </Modal>
  );
};

export default InactiveSessionModal;
