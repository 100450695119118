import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'components/reusable/Modal';

import magnifierImage from 'assets/img/magnifier.png';
import { ReactComponent as Close } from 'assets/img/icons/close.svg';

import { Button, Row, Col, Typography, Space } from 'antd';

const { Title, Paragraph } = Typography;

const AddToMonitoring = ({ modalOpen, setModalOpen, setAddedToMonitoring }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={modalOpen} setIsOpen={setModalOpen}>
      <div className="container">
        <Row>
          <Col span={20}>
            <Title className="space-vertical-xl">
              {t('companySearch.results.basic.companyReport.addToMonitoring')}
            </Title>
          </Col>
          <Col span={4} className="justify-content-end">
            <span className="icon-btn space-top-md" role="button" onClick={() => setModalOpen(false)}>
              <Close />
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={24} lg={12}>
            <Paragraph className="space-top-md">
              {t('companySearch.results.basic.companyReport.addToMonitoring.description')}
            </Paragraph>
            <Space>
              <Button htmlType="button" onClick={() => setModalOpen(false)}>
                {t('companySearch.results.basic.companyReport.addToMonitoring.button.cancel')}
              </Button>
              <Button
                className="btn-secondary"
                htmlType="button"
                onClick={() => {
                  setAddedToMonitoring();
                  setModalOpen(false);
                }}
              >
                {t('companySearch.results.basic.companyReport.addToMonitoring.button.save')}
              </Button>
            </Space>
          </Col>
          <Col md={24} lg={12}>
            <Row justify="center">
              <img src={magnifierImage} alt="magnifier"></img>
            </Row>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default AddToMonitoring;
