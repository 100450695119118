import React from 'react';
import { Col, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';

const CompanyOrderForm = () => {
  const { t } = useTranslation();

  return (
    <>
      <Row gutter={[32]}>
        <Col xs={24} className="space-top-md">
          <Form.Item
            label={t('companyResult.buyReport.companyInformationForm.company')}
            name="company"
            rules={[
              {
                required: true,
                message: t('companyResult.buyReport.companyInformationForm.company.placeholder'),
              },
            ]}
            required
          >
            <Input placeholder={t('companyResult.buyReport.companyInformationForm.company.placeholder')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[32]}>
        <Col xs={24}>
          <Form.Item
            label={t('companyResult.buyReport.companyInformationForm.companyCode')}
            name="companyCode"
            rules={[
              {
                required: true,
                message: t('companyResult.buyReport.companyInformationForm.companyCode.required'),
              },
            ]}
            required
          >
            <Input placeholder={t('companyResult.buyReport.companyInformationForm.companyCode.placeholder')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[32]}>
        <Col xs={24}>
          <Form.Item
            label={t('companyResult.buyReport.companyInformationForm.payCode')}
            name="payCode"
            rules={[
              {
                required: true,
                message: t('companyResult.buyReport.companyInformationForm.payCode.required'),
              },
            ]}
            required
          >
            <Input placeholder={t('companyResult.buyReport.companyInformationForm.payCode.placeholder')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[32]}>
        <Col xs={24}>
          <Form.Item
            label={t('companyResult.buyReport.companyInformationForm.address')}
            name="address"
            rules={[
              {
                required: true,
                message: t('companyResult.buyReport.companyInformationForm.address.required'),
              },
            ]}
            required
          >
            <Input placeholder={t('companyResult.buyReport.companyInformationForm.address.placeholder')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[32]}>
        <Col xs={24}>
          <Form.Item
            label={t('companyResult.buyReport.companyInformationForm.contactPerson')}
            name="contactPerson"
            rules={[
              {
                required: true,
                message: t('companyResult.buyReport.companyInformationForm.contactPerson.required'),
              },
            ]}
            required
          >
            <Input placeholder={t('companyResult.buyReport.companyInformationForm.contactPerson.placeholder')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[32]}>
        <Col xs={24}>
          <Form.Item
            label={t('companyResult.buyReport.companyInformationForm.email')}
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: t('companyResult.buyReport.companyInformationForm.email.required'),
              },
            ]}
            required
          >
            <Input placeholder={t('companyResult.buyReport.companyInformationForm.email.placeholder')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[32]}>
        <Col xs={24}>
          <Form.Item
            label={t('companyResult.buyReport.companyInformationForm.phone')}
            name="phone"
            rules={[{ required: false, type: 'string' }]}
          >
            <Input placeholder={t('companyResult.buyReport.companyInformationForm.phone.placeholder')} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default CompanyOrderForm;
