import { createAsyncThunk } from '@reduxjs/toolkit';
import spiderwebService from 'api/spiderwebService';
import professionalReportService from '../../../api/professionalReportService';

export const getBasicData = createAsyncThunk('spiderWeb/getBasicData', async (filters, { rejectWithValue }) => {
  try {
    const response = await spiderwebService.getBasicData(filters);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getCompanies = createAsyncThunk('spiderWeb/getCompanies', async (filters, { rejectWithValue }) => {
  try {
    const response = await spiderwebService.getCompanies(filters);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getChildCompany = createAsyncThunk('spiderWeb/getChildCompany', async (filters, { rejectWithValue }) => {
  try {
    const response = await spiderwebService.getChildCompany(filters);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getSelectedCompanyData = createAsyncThunk(
  'spiderWeb/getSelectedCompanyData',
  async (companyCode, { rejectWithValue }) => {
    try {
      const response = await spiderwebService.getBasicData(companyCode);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getSpiderWebReportPdf = createAsyncThunk(
  'spiderWeb/report/pdf',
  async ({ companyCode }, { rejectWithValue }) => {
    try {
      return await spiderwebService.getSpiderWebReportPdf({ companyCode });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
