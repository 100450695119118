import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, Col, Row, Table } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { ReactComponent as ComposedChart } from 'assets/img/icons/composedChart.svg';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import CompanyDataCard from '../../../components/reusable/CompanyDataCard';

const ReportsCount = props => {
  const { t } = useTranslation();
  const { statistics, statisticsBySector } = props;
  const isStasticsNotPresent = !statistics || statisticsBySector.length === 0;
  const isUiHidden = useIsUiHidden();

  const reportsTableColumns = useMemo(
    () => [
      {
        title: '',
        dataIndex: 'sectorTitle',
        width: 300,
        render: (text, row) => (row.nested ? <strong>{text}</strong> : <span style={{ paddingLeft: 10 }}>{text}</span>),
      },
      {
        title: t('individualReport.reportsCount.lastMonth'),
        dataIndex: 'lastMonth',
        align: 'center',
        render: (text, row) => (row.nested ? <strong>{text}</strong> : text),
      },
      {
        title: t('individualReport.reportsCount.lastQuarter'),
        dataIndex: 'lastQuarter',
        align: 'center',
        render: (text, row) => (row.nested ? <strong>{text}</strong> : text),
      },
      {
        title: t('individualReport.reportsCount.lastHalfYear'),
        dataIndex: 'lastHalfYear',
        align: 'center',
        render: (text, row) => (row.nested ? <strong>{text}</strong> : text),
      },
    ],
    [t]
  );

  const stasticsData = useMemo(() => {
    if (isStasticsNotPresent) {
      return {};
    }

    return [
      {
        sectorTitle: t('individualReport.reportsCount.totalReports'),
        lastMonth: statistics.lastMonth,
        lastQuarter: statistics.lastQuarter,
        lastHalfYear: statistics.lastHalfYear,
        nested: statisticsBySector.map(({ sectorTitle, lastMonth, lastQuarter, lastHalfYear }) => ({
          sectorTitle,
          lastMonth,
          lastQuarter,
          lastHalfYear,
        })),
      },
    ];
  }, [t, isStasticsNotPresent, statisticsBySector]);

  if (isStasticsNotPresent) {
    return <></>;
  }

  if (!stasticsData[0]?.lastQuarter && !stasticsData[0]?.lastMonth && !stasticsData[0]?.lastHalfYear) {
    return (
      <CompanyDataCard icon={<ComposedChart className="icon--gray" />} title={t('reportCounts.title')}>
        <Card className="card-transparent space-vertical-sm">{t('openDebts.negativeData.reportCounts.noEntries')}</Card>
      </CompanyDataCard>
    );
  }

  if (stasticsData[0]?.nested[0]) {
    // Find the index of the element with 'sectorTitle' equal to "Debt Collection" in the 'nested' array.
    const indexOfDebtCollection = stasticsData[0].nested.findIndex(
      item => item.sectorTitle === 'Debt Collection' || item.sectorTitle === 'Skolų išieškojimo įmonės'
    );

    // Remove the element at the found index from the 'nested' array and get it as a separate item.
    const removedDebtCollection = stasticsData[0].nested.splice(indexOfDebtCollection, 1)[0];

    // Insert the removed "Debt Collection" element back into the 'nested' array at index 3.
    stasticsData[0].nested.splice(3, 0, removedDebtCollection);
  }

  return (
    <CompanyDataCard icon={<ComposedChart className="icon--gray" />} title={t('reportCounts.title')}>
      <Row>
        <Col span={24}>
          <Table
            dataSource={stasticsData}
            columns={reportsTableColumns}
            pagination={false}
            scroll={false}
            className="ant-table--dark"
            rowKey={record => record.rowKey}
            expandable={{
              expandedRowRender: record => (
                <Table
                  dataSource={record?.nested}
                  columns={reportsTableColumns}
                  scroll={false}
                  pagination={false}
                  rowKey={record => record.rowKey}
                  className="ant-table--nested"
                />
              ),
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <MinusOutlined onClick={e => onExpand(record, e)} />
                ) : (
                  <PlusOutlined onClick={e => onExpand(record, e)} />
                ),
              defaultExpandAllRows: isUiHidden,
            }}
          />
        </Col>
      </Row>
    </CompanyDataCard>
  );
};

export default ReportsCount;
