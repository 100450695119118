import React from 'react';

export const BlockHeader = ({ icon = null, title }) => {
  return (
    <div className="block-header">
      {icon}
      <span className={icon ? 'block-header-span-icon' : 'block-header-span-no-icon'}>{title}</span>
    </div>
  );
};
