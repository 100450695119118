import { createSelector } from 'reselect';

export const registeredLegalEntities = createSelector(
  state => state.otherProducts?.legalEntities,
  legalEntities => legalEntities
);

export const registeredIndividualEntities = createSelector(
  state => state.otherProducts?.individualEntities,
  individualEntities => individualEntities
);

export const userSettings = createSelector(
  state => state.otherProducts?.userSettings,
  userSettings => userSettings
);
