import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import useCurrentLanguage from 'hooks/user/useCurrentLanguage';
import { getSummary } from 'store/entities/professionalReport/thunks';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';

import { Row, Col, Skeleton } from 'antd';

import BasicInformation from './BasicInformation';
import Ratings from './Ratings';
import Statistics from './Statistics';
import StrongestCompanyCard from 'components/reusable/StrongestCompanyCard';

const Summary = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const lang = useCurrentLanguage();
  const isUiHidden = useIsUiHidden();

  const { orderId, summary, summaryPending } = useSelector(state => state.professionalReport);

  useEffect(() => {
    if ((orderId && summary && summary?.lang && lang !== summary?.lang) || !summary) {
      dispatch(getSummary({ code: companyId, isCompany: true, orderId }));
    }
  }, [lang]);

  return (
    <Row>
      <Col span={24}>
        {isUiHidden && (
          <Col span={24}>
            <h2 className="space-bottom-none align-items-end">{t('professionalReport.tab.summary')}</h2>
          </Col>
        )}
        {summaryPending && <Skeleton />}
        {summary && !summaryPending && (
          <>
            {summary?.strongest?.hasStrongestCertificate && (
              <StrongestCompanyCard
                type={summary?.strongest?.certificateType}
                year={summary?.strongest?.yearOfCertificate}
              />
            )}
            <Ratings />
            <BasicInformation />
            {/*<Statistics />*/}
          </>
        )}
      </Col>
    </Row>
  );
};

export default Summary;
