import React from 'react';
import { useTranslation } from 'react-i18next';

import useFormatDate from 'hooks/format/useFormatDate';

import CompanyDataCard, { CompanyDataRow } from 'components/reusable/CompanyDataCard';
import CollapsibleTable from 'components/reusable/CollapsibleTable';
import TableColumnDate from 'components/reusable/TableColumnDate';

import { ClockCircleOutlined } from '@ant-design/icons';

const UnreliableCompanies = ({
  unreliableCompanies,
  unreliableCompaniesDetails,
  icon = <ClockCircleOutlined className="icon--gray" />,
}) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();

  const detailedInfoColumns = [
    {
      title: t('professionalReport.negativeData.unreliableCompanies.detailedInformation.recordDate'),
      dataIndex: 'recordDate',
      render: TableColumnDate,
      key: 'recordDate',
      width: 120,
      sorter: (a, b) => {
        const dateA = new Date(a.recordDate);
        const dateB = new Date(b.recordDate);
        return dateB - dateA;
      },
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('professionalReport.negativeData.unreliableCompanies.detailedInformation.comments'),
      dataIndex: 'comments',
      render: (comment, data) => {
        const url = data?.url;
        const formattedUrl = url && !url.startsWith('http://') && !url.startsWith('https://') ? `https://${url}` : url;

        return (
          <div>
            <p>{comment}</p>
            {data?.url && (
              <p>
                {t('professionalReport.negativeData.unreliableCompanies.detailedInformation.url')}:
                <a href={formattedUrl} target="_blank" rel="noopener noreferrer" style={{ marginLeft: 5 }}>
                  {data?.url}
                </a>
              </p>
            )}
          </div>
        );
      },
      key: 'comments',
    },
  ];

  return (
    <>
      <div id="unreliableCompanies">
        <CompanyDataCard icon={icon} title={t('professionalReport.negativeData.unreliableCompanies.title')}>
          {!unreliableCompanies?.totals && !unreliableCompanies?.latestDate ? (
            <span>{t('startReport.negativeData.noEntries')}</span>
          ) : (
            <>
              <CompanyDataRow
                type={t('professionalReport.negativeData.unreliableCompanies.numberOfEntries')}
                value={unreliableCompanies?.total || '-'}
              />
              <CompanyDataRow
                type={t('professionalReport.negativeData.unreliableCompanies.lastDate')}
                value={unreliableCompanies?.latestDate ? formatDate(unreliableCompanies?.latestDate) : '-'}
              />
            </>
          )}
          {unreliableCompaniesDetails?.length ? (
            <CollapsibleTable
              className="space-top-md"
              title={t('professionalReport.negativeData.unreliableCompanies.detailedInformation.title')}
              data={unreliableCompaniesDetails}
              columns={detailedInfoColumns}
              bordered
            />
          ) : null}
        </CompanyDataCard>
      </div>
    </>
  );
};

export default UnreliableCompanies;
