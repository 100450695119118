import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';

import { setActiveTab } from 'store/entities/professionalReport';

import { Col, Row, Space, Typography, Card } from 'antd';
import { BankOutlined } from '@ant-design/icons';
import { BlockHeader } from '../../../components/reusable/BlockHeader';

const { Text } = Typography;

const Procurement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <VisibilitySensor
      offset={{ bottom: 50 }}
      scrollCheck
      intervalDelay={10}
      onChange={isVisible => isVisible && dispatch(setActiveTab('procurement'))}
    >
      <div>
        <Row id="procurement" justify="start" className="space-bottom-md">
          <Col>
            <BlockHeader icon={<BankOutlined />} title={t('professionalReport.basicData.procurement.title')} />
          </Col>
        </Row>
        <Row className="space-bottom-md">
          <Col>
            <Text level={4}>{t('professionalReport.basicData.procurement.asSeller')}</Text>
          </Col>
        </Row>
        <Row className="space-bottom-md">
          <Card className="card-transparent space-vertical-sm">
            {t('professionalReport.basicData.procurement.infoUnavailable')}
          </Card>
        </Row>
        <Row className="space-bottom-md">
          <Col>
            <Text level={4}>{t('professionalReport.basicData.procurement.asBuyer')}</Text>
          </Col>
        </Row>
        <Row className="space-bottom-md">
          <Card className="card-transparent space-vertical-sm">
            {t('professionalReport.basicData.procurement.infoUnavailable')}
          </Card>
        </Row>
      </div>
    </VisibilitySensor>
  );
};

export default Procurement;
