import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { Layout as AntLayout } from 'antd';

import App from './App';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import store from './store';
import reportWebVitals from './reportWebVitals';
import CookieBot from 'components/CookieBot';

import './assets/scss/main.scss';

window.store = store;

ReactDOM.render(
  <>
    <CookieBot />
    <Provider store={store}>
      <Suspense
        fallback={
          <LoadingSpinner size="large" spinning={true}>
            <AntLayout style={{ height: '100vh' }} />
          </LoadingSpinner>
        }
      >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Suspense>
    </Provider>
  </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
