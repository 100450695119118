import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Divider } from 'antd';
import CompanyBasicInformation from 'components/reusable/report/cards/CompanyBasicInformation';
import CompanyContactInformation from 'components/reusable/report/cards/CompanyContactInformation';
import UpdateContactInfo from 'views/PrescoreReport/Data/UpdateContactInfo';

import { basicInfo } from 'store/entities/prescoreReport/selectors/basicDataSelectors';
import { basicContact } from 'store/entities/prescoreReport/selectors/basicDataSelectors';

const Basic = () => {
  const { basicInformation } = useSelector(basicInfo);
  const { contacts } = useSelector(basicContact);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);

  return (
    <>
      <div id="basic">
        <CompanyBasicInformation companyData={basicInformation} />
      </div>
      <div id="contact">
        <CompanyContactInformation setUpdateModalOpen={setUpdateModalOpen} contactData={contacts} />
      </div>
      <UpdateContactInfo modalOpen={updateModalOpen} setModalOpen={setUpdateModalOpen} initialValues={contacts} />
      <Divider />
    </>
  );
};

export default Basic;
