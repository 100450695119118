import React from 'react';
import { useTranslation } from 'react-i18next';

import FinancialRatioTable from 'components/reusable/report/tables/FinancialRatioTable';
import ReportBlockHeader from 'components/reusable/report/ReportBlockHeader';

import { ReactComponent as ComposedChart } from 'assets/img/icons/composedChart.svg';
import { financialRatiosTable } from '../../../store/entities/startReport/selectors/financialDataSelectors';
import { useSelector } from 'react-redux';

const ExpertData = () => {
  const { t } = useTranslation();
  const { financialRatiosTableData, ratioTimeline } = useSelector(financialRatiosTable);

  return (
    <div className="space-top-lg">
      <div className="space-vertical-sm">
        <ReportBlockHeader
          icon={<ComposedChart className="icon--gray" />}
          title={t('startReport.financialData.expertData.title')}
        />
        <FinancialRatioTable
          financialRatiosTableData={financialRatiosTableData}
          ratioTimeline={ratioTimeline.slice(0, 3)}
        />
      </div>
    </div>
  );
};

export default ExpertData;
