import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SearchForm from './SearchForm';
import SearchResultsTable from './SearchResultsTable';

export const SearchBlock = ({ isSpiderwebOpen, setIsSpiderwebOpenForm, setIsSpiderwebOpenResults }) => {
  const { visibleCompanyView } = useSelector(state => state.company);
  const [selectedCountry, setSelectedCountry] = useState('LT');
  const [showMoreFilters, setShowMoreFilters] = useState(false);

  useEffect(() => {
    isSpiderwebOpen && setShowMoreFilters(false);
  }, [isSpiderwebOpen]);

  const handleFilterChange = () => {
    setShowMoreFilters(!showMoreFilters);
  };

  return (
    <>
      {(visibleCompanyView === 'table' || visibleCompanyView === 'details') && (
        <SearchForm
          setIsSpiderwebOpen={setIsSpiderwebOpenForm}
          setSelectedCountry={setSelectedCountry}
          showMoreFilters={showMoreFilters}
          handleFilterChange={handleFilterChange}
          selectedCountry={selectedCountry}
        />
      )}
      {visibleCompanyView === 'table' && (
        <SearchResultsTable setIsSpiderwebOpen={setIsSpiderwebOpenResults} selectedCountry={selectedCountry} />
      )}
    </>
  );
};
