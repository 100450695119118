import React from 'react';
import { StockOutlined, WarningFilled, CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Row, Col, Card, Space } from 'antd';
import { PieChart, Pie, Cell } from 'recharts';
import useResponsiveContainer from 'hooks/graph/useResponsiveContainer';
import colors from 'constants/colors';
import { useIsMdOrLess } from 'hooks/dom/useMedia';
import { BlockHeader } from '../../../components/reusable/BlockHeader';

const data = [
  { name: 'score', value: 50 },
  { name: 'emptyness', value: 50 },
];

const CreditScore = () => {
  const [{ width, height }, setContainerNode] = useResponsiveContainer();
  const callbackRef = React.useCallback(node => {
    setContainerNode(node);
  }, []);

  const screenIsMdOrLess = useIsMdOrLess();

  return (
    <Card className="card-simple card-simple-padding-none space-vertical-md has-shadow" style={{ width: '100%' }}>
      <Row justify="start" align="middle">
        <Col>
          <BlockHeader icon={<StockOutlined />} title={<span>Crediti score</span>} />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col
          lg={24}
          xl={12}
          style={screenIsMdOrLess ? { border: 'none' } : { borderRight: `1px solid ${colors.COLOR_GRAY}` }}
        >
          <div className="chart" ref={callbackRef}>
            <PieChart width={width} height={height}>
              <text x={width / 2} y={height / 2} textAnchor="middle" dominantBaseline="middle">
                C2
              </text>
              <Pie
                cy="60%"
                data={data}
                startAngle={180}
                endAngle={0}
                innerRadius={60}
                outerRadius={70}
                fill="#8884d8"
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={entry.name === 'score' ? colors.COLOR_WARNING : colors.COLOR_GRAY}
                  />
                ))}
              </Pie>
              <text x={width / 2} y={height / 1.3} textAnchor="middle" dominantBaseline="middle">
                Average credit risk
              </text>
            </PieChart>
          </div>
        </Col>
        <Col lg={24} xl={12} className="text-align-center">
          <ul className="list-center">
            <li key="economy">
              <Space size="middle" className="space-vertical-none">
                <WarningFilled style={{ color: colors.COLOR_WARNING }} />
                <span>Economic status</span>
              </Space>
            </li>
            <li key="credit-history">
              <Space size="middle">
                <WarningFilled style={{ color: colors.COLOR_WARNING }} />
                <span>Credit history</span>
              </Space>
            </li>
            <li key="debts">
              <Space size="middle" className="space-vertical-none">
                <CheckCircleFilled style={{ color: colors.COLOR_SUCCESS }} />
                <span>Debts for SoDra</span>
              </Space>
            </li>
            <li key="salary">
              <Space size="middle">
                <CloseCircleFilled style={{ color: colors.COLOR_ERROR }} />
                <span>Salary average</span>
              </Space>
            </li>
          </ul>
        </Col>
      </Row>
    </Card>
  );
};

export default CreditScore;
