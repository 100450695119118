import { useSelector } from 'react-redux';

import CompanySearchForm from './CompanySearchForm';
import SearchResultsTable from './SearchResultsTable';

const UnauthenticatedSearch = () => {
  const { visibleCompanyView } = useSelector(state => state.company);

  return (
    <>
      {(visibleCompanyView === 'table' || visibleCompanyView === 'details') && <CompanySearchForm />}
      {visibleCompanyView === 'table' && <SearchResultsTable />}
    </>
  );
};

export default UnauthenticatedSearch;
