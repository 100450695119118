import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18n';

import {
  getBasicData,
  getCompanies,
  getChildCompany,
  getSelectedCompanyData,
  getSpiderWebReportPdf,
} from 'store/entities/spiderWeb/thunks';

const initialState = {
  basicData: null,
  basicDataPending: false,
  basicDataError: false,
  searchResults: [],
  getSearchResultsPending: false,
  getSearchResultsError: false,
  getSearchResultsMessage: false,
  selectedCompany: null,
  selectedCompanyData: null,
  selectedCompanyDataPending: false,
  spiderWebPdfLoading: false,
  childCompanies: null,
  childCompaniesPending: false,
  childCompaniesError: false,
};

const slice = createSlice({
  name: 'spiderWeb',
  initialState: initialState,
  reducers: {
    resetSpiderWeb: () => initialState,
    resetSearchResults: state => {
      state.searchResults = [];
    },
    setActiveFullReportTab: (state, { payload }) => {
      state.activeFullReportTab = payload;
    },
    setMenuAffixed: (state, { payload }) => {
      state.menuAffixed = payload;
    },
    setSearchResults: (state, { payload }) => {
      state.searchResults = payload;
    },
    setSelectedCompany: (state, { payload }) => {
      state.selectedCompany = payload;
    },
  },
  extraReducers: {
    [getBasicData.fulfilled]: (state, { payload }) => {
      state.basicData = { ...payload, lang: i18n.language };
      state.basicDataPending = false;
      state.basicDataError = false;
    },
    [getBasicData.pending]: state => {
      state.basicDataPending = true;
      state.basicDataError = false;
    },
    [getBasicData.rejected]: state => {
      state.basicDataPending = false;
      state.basicDataError = true;
    },
    [getCompanies.fulfilled]: (state, { payload }) => {
      state.searchResults = payload;
      state.getSearchResultsPending = false;
      if (payload?.resultMessage) {
        state.getSearchResultsMessage = payload.resultMessage;
      } else state.getSearchResultsMessage = false;
      state.getSearchResultsError = false;
    },
    [getCompanies.pending]: state => {
      state.getSearchResultsPending = true;
      state.getSearchResultsError = false;
    },
    [getCompanies.rejected]: state => {
      state.getSearchResultsPending = false;
      state.getSearchResultsError = true;
    },
    [getChildCompany.fulfilled]: (state, { payload }) => {
      state.childCompanies = payload;
      state.childCompaniesPending = false;
      state.childCompaniesError = false;
    },
    [getChildCompany.pending]: state => {
      state.childCompaniesPending = true;
      state.childCompaniesError = true;
    },
    [getChildCompany.rejected]: state => {
      state.childCompaniesPending = false;
      state.childCompaniesError = false;
    },
    [getSelectedCompanyData.fulfilled]: (state, { payload }) => {
      state.selectedCompanyData = payload;
      state.selectedCompanyDataPending = true;
    },
    [getSpiderWebReportPdf.pending]: state => {
      state.spiderWebPdfLoading = true;
    },
    [getSpiderWebReportPdf.rejected]: state => {
      state.spiderWebPdfLoading = false;
    },
    [getSpiderWebReportPdf.fulfilled]: (state, { payload }) => {
      state.spiderWebPdfLoading = false;
    },
  },
});

export const {
  resetSpiderWeb,
  setActiveFullReportTab,
  setMenuAffixed,
  setSearchResults,
  resetSearchResults,
  setSelectedCompany,
} = slice.actions;

export default slice.reducer;
