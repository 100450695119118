import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Typography, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { ReactComponent as Company } from 'assets/img/icons/company.svg';
import { ReactComponent as Residents } from 'assets/img/icons/residents.svg';
import { ReactComponent as Media } from 'assets/img/icons/media.svg';
import { ReactComponent as FaceBook } from 'assets/img/icons/fb.svg';
import { ReactComponent as LinkedIn } from 'assets/img/icons/linkedin.svg';

const contactData = [
  {
    label: 'login.contactUs.companies',
    phone: '+370 5 239 4131',
    email: 'ltu.info@creditinfo.com',
    component: Company,
  },
  {
    label: 'login.contactUs.residents',
    phone: '+370 5 239 4149',
    email: 'info@manocreditinfo.lt',
    component: Residents,
  },
  {
    label: 'login.contactUs.media',
    phone: '+370 686 49154',
    email: 'ausrine.motiejuniene@creditinfo.com',
    component: Media,
  },
];

const socials = [
  {
    link: 'https://www.linkedin.com/company/creditinfo-lietuva/',
    icon: LinkedIn,
  },
  {
    link: 'https://www.facebook.com/StipriausiLietuvoje',
    icon: FaceBook,
  },
];

const ContactUs = React.forwardRef(({ ref, onClose = null }) => {
  const { t } = useTranslation();
  const { Paragraph } = Typography;

  return (
    <div ref={ref} id="contact" className="container login-contact">
      <Row gutter={[16, 16]}>
        <Col xs={24} md={24} lg={6} className="login-contact-basic">
          {onClose && (
            <Button
              style={{ position: 'absolute', top: -50, right: -30 }}
              type="text"
              icon={<CloseOutlined />}
              onClick={onClose}
            />
          )}
          <strong>{t('login.contactUs.bureau')}</strong>
          <Paragraph>Lvivo g. 21A, </Paragraph>
          <Paragraph>LT-09309 Vilnius, LITHUANIA</Paragraph>
          <Paragraph className="last">I-IV 8-17, V 8-15:45</Paragraph>
          <div className="login-contact-social">
            {socials.map(item => (
              <a href={item.link} target="_blank" rel="noopener noreferrer" key={item.link}>
                <item.icon style={{ width: 15 }} />
              </a>
            ))}
          </div>
        </Col>
        {contactData.map(item => (
          <Col xs={24} md={8} lg={6} className="login-contact-basic" key={item.label}>
            <strong>{t(item.label)}</strong>
            <Paragraph>{item.phone}</Paragraph>
            <Paragraph>{item.email}</Paragraph>
          </Col>
        ))}
      </Row>
    </div>
  );
});

export default ContactUs;
