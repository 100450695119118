import { createSlice } from '@reduxjs/toolkit';
import { getPerson, getIndividualProfessionalReportPdf } from 'store/entities/person/thunks';

const initialState = {
  person: null,
  visiblePersonView: 'table',
  getPersonPending: false,
  getPersonError: false,
  individualProfessionalReportPdfPending: false,
};

const slice = createSlice({
  name: 'person',
  initialState,
  reducers: {
    resetPerson: () => initialState,
    setVisiblePersonView: (state, { payload }) => {
      state.visiblePersonView = payload;
    },
  },
  extraReducers: {
    [getPerson.fulfilled]: (state, { payload }) => {
      state.person = payload;
      state.getPersonPending = false;
      state.getPersonError = false;
    },
    [getPerson.pending]: state => {
      state.getPersonPending = true;
      state.getPersonError = false;
    },
    [getPerson.rejected]: state => {
      state.getPersonPending = false;
      state.getPersonError = true;
    },
    [getIndividualProfessionalReportPdf.pending]: state => {
      state.individualProfessionalReportPdfPending = true;
    },
    [getIndividualProfessionalReportPdf.rejected]: state => {
      state.individualProfessionalReportPdfPending = false;
    },
    [getIndividualProfessionalReportPdf.fulfilled]: state => {
      state.individualProfessionalReportPdfPending = false;
    },
  },
});

export const { resetPerson, setVisiblePersonView } = slice.actions;

export default slice.reducer;
