import React from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumb } from 'antd';

const CumulativeBreadcrumb = ({ location }) => {
  const { t } = useTranslation();
  const breadcrumbsArr = location.pathname.split('/').slice(1);
  const translationPaths = breadcrumbsArr.map((item, i) => breadcrumbsArr.slice(0, i + 1).join('.'));

  return breadcrumbsArr.map((urlPart, i) => (
    <Breadcrumb.Item key={`breadcrumbItem-${urlPart}`} href={`/${breadcrumbsArr.slice(0, i + 1).join('/')}`}>
      {t(`header.breadcrumb.${translationPaths[i]}`)}
    </Breadcrumb.Item>
  ));
};

export default CumulativeBreadcrumb;
