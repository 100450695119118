import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Route } from 'react-router-dom';

const GoogleAnalytics = ({ location }) => {
  useEffect(() => {
    const logPageChange = (pathname, search = '') => {
      const page = pathname + search;
      const { location } = window;

      ReactGA.set({
        page,
        location: `${location.origin}${page}`,
      });
      ReactGA.send({ hitType: 'pageview', page });
    };

    logPageChange(location.pathname, location.search);
  }, [location]);

  return null;
};

export const RouteTracker = () => <Route component={GoogleAnalytics} />;

export const init = async (options = {}) => {
  const trackingCode = process.env.REACT_APP_TRACKING_CODE;
  const isGAEnabled = !!trackingCode;

  if (isGAEnabled) {
    ReactGA.initialize(trackingCode, {
      ...options,
    });
  }

  return isGAEnabled;
};

export default GoogleAnalytics;
