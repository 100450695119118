import { createAsyncThunk } from '@reduxjs/toolkit';
import prescoreReportService from 'api/prescoreReportService';

export const getOrderId = createAsyncThunk('prescoreReport/getOrderId', async (companyCode, { rejectWithValue }) => {
  try {
    return await prescoreReportService.getOrderId(companyCode);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getBasicData = createAsyncThunk('prescoreReport/getBasicData', async (orderId, { rejectWithValue }) => {
  try {
    const response = await prescoreReportService.getBasicData(orderId);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateContacts = createAsyncThunk(
  'prescoreReport/updateContacts',
  async ({ orderId, newContacts }, { rejectWithValue }) => {
    try {
      const response = await prescoreReportService.updateContacts(orderId, newContacts);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPrescoreReportPdf = createAsyncThunk(
  'prescoreReport/getPrescoreReportPdf',
  async ({ orderId }, { rejectWithValue }) => {
    try {
      return await prescoreReportService.getPrescoreReportPdf(orderId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
