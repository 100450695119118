import React, { useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import useRedirect from 'hooks/navigation/useRedirect';
import useProfReportState from 'hooks/report/professionalReport/useProfReportState';
import { getOrderId } from 'store/entities/professionalReport/thunks';
import { resetProfessionalReport } from 'store/entities/professionalReport';
import FullReport from './FullReport';
import LoadingSpinner from 'components/reusable/LoadingSpinner';

import { Col, BackTop } from 'antd';
import ReportHeader from '../../components/reusable/report/ReportHeader';
import CustomReportModal from './CustomReportModal';

const ProfessionalReport = () => {
  const { companyId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { orderId, orderDetails } = useSelector(state => state.professionalReport);
  const isUiHidden = useIsUiHidden();
  const redirect = useRedirect();
  const { isLoading, hasError } = useProfReportState();

  const orderReport = async () => {
    const orderIdResult = await dispatch(getOrderId({ code: companyId, isCompany: true }));

    if (!getOrderId.fulfilled.match(orderIdResult)) {
      redirect('/search/company');
    }
  };

  useEffect(() => {
    orderReport();

    return () => dispatch(resetProfessionalReport());
  }, []);

  return (
    <LoadingSpinner size="large" spinning={isLoading}>
      {hasError && <Redirect to="/search/company" exact />}
      <CustomReportModal />
      <div className="container">
        <Col span={24}>
          <ReportHeader title={orderDetails?.companyTitle} subTitle={t('professionalReport.title')} />
          {orderId && <FullReport />}
        </Col>
        {!isUiHidden && <BackTop />}
      </div>
    </LoadingSpinner>
  );
};

export default ProfessionalReport;
