import { createPortal } from 'react-dom';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const TooltipPortal = ({ text, x, y }) => {
  const tooltipStyle = {
    left: x,
    top: y,
  };

  return createPortal(
    <div className="hierarchy-tree__tooltip" style={tooltipStyle}>
      {text}
    </div>,
    document.body
  );
};

export const Tooltip = ({ children, text, type }) => {
  const { t } = useTranslation();
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = e => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = left + width / 2;
    const y = top - 30;

    setTooltipPosition({ x, y });
    setIsOpen(true);
  };

  const handleMouseLeave = () => setIsOpen(false);

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="hierarchy-tree__tooltip-icon">
      {children}
      {isOpen && (
        <TooltipPortal
          text={text ? text : t(`spiderweb.relations.tooltip.${type}`)}
          x={tooltipPosition.x}
          y={tooltipPosition.y}
        />
      )}
    </div>
  );
};
