import { fetchFromApi, optionsForGetRequest, optionsForPostRequest } from './';

const objectToQueryString = obj => {
  const keyValuePairs = [];

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
    }
  }

  return keyValuePairs.join('&');
};

const getOrderId = (code, isCompany) =>
  fetchFromApi(`/${isCompany ? 'company' : 'person'}/${code}/report/professional/order`);

const getOrderDetails = (code, orderId, isCompany) =>
  fetchFromApi(`/${isCompany ? 'company' : 'person'}/${code}/report/professional/order/details/${orderId}`);

const getSummary = (orderId, isCompany) =>
  fetchFromApi(`/${isCompany ? 'company' : 'person'}/${orderId}/report/professional/summary`);

const getBasicData = orderId => fetchFromApi(`/company/${orderId}/report/professional/basic`);

const getCompanyRatings = orderId => fetchFromApi(`/company/${orderId}/report/professional/rating`);

const getNegativeData = orderId => fetchFromApi(`/company/${orderId}/report/professional/negative-data`);

const getFinancialData = orderId => fetchFromApi(`/company/${orderId}/report/professional/financial-data`);

const updateContacts = (orderId, newContacts) =>
  fetchFromApi(`/company/${orderId}/report/professional/update-contacts`, optionsForPostRequest(newContacts));

const orderExpertEvaluation = (orderId, orderDetails) =>
  fetchFromApi(`/company/${orderId}/report/professional/order-expert-evaluation`, optionsForPostRequest(orderDetails));

const getProfessionalReportPdf = (companyCode, orderId, customPdfValues) => {
  if (customPdfValues) {
    const query = objectToQueryString(customPdfValues);
    return fetchFromApi(
      `/company/${companyCode}/report/professional/pdf/${orderId}/preview?${query}`,
      optionsForGetRequest()
    );
  } else {
    return fetchFromApi(`/company/${companyCode}/report/professional/pdf/${orderId}/preview`, optionsForGetRequest());
  }
};

export default {
  getOrderId,
  getOrderDetails,
  getSummary,
  getBasicData,
  getCompanyRatings,
  getNegativeData,
  getFinancialData,
  updateContacts,
  orderExpertEvaluation,
  getProfessionalReportPdf,
};
