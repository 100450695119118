import { Tooltip } from './Tooltip';
import { GROUPS_ICONS } from '../../utils/constants';
import { useTranslation } from 'react-i18next';

export const HoverableIcons = ({ data, id }) => {
  const { t } = useTranslation();

  return (
    <div className="node__group-item__negative-icons">
      {Object.entries(data)
        .filter(([_key, value]) => value)
        .map(([key]) => (
          <Tooltip text={t(`spiderweb.marks.explanation.${key}`)} key={key + id}>
            <div key={key} className="node__group-item__negative-icons__icon-name">
              {GROUPS_ICONS[key]}
            </div>
          </Tooltip>
        ))}
    </div>
  );
};
