import { createAsyncThunk } from '@reduxjs/toolkit';
import dataAvailabilityService from 'api/dataAvailabilityService';
import consultationService from 'api/consultationService';

export const getCompanyData = createAsyncThunk(
  'companySearchResult/getCompanyData',
  async ({ companyCode, languageCode }, { rejectWithValue }) => {
    try {
      const orderDetailsResponse = await dataAvailabilityService.getCompanyData(companyCode, languageCode);

      return orderDetailsResponse;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const registerConsultation = createAsyncThunk(
  'companySearchResult/registerConsultation',
  async (values, { rejectWithValue }) => {
    try {
      const response = await consultationService.sendConsultationRegistration(values);

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOrderIdFromCompanyBuyReport = createAsyncThunk(
  'company/getOrderIdFromCompanyBuyReport',
  async (company, { rejectWithValue }) => {
    try {
      const response = await dataAvailabilityService.getOrderIdFromCompanyBuyReport(company);

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOrderIdFromPersonBuyReport = createAsyncThunk(
  'company/getOrderIdFromPersonBuyReport',
  async (person, { rejectWithValue }) => {
    try {
      const response = await dataAvailabilityService.getOrderIdFromPersonBuyReport(person);

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOpayUriEncoded = createAsyncThunk(
  'company/getOpayUriEncoded',
  async ({ orderId, languageCode }, { rejectWithValue }) => {
    try {
      const response = await dataAvailabilityService.getOpayUriEncoded(orderId, languageCode);

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
