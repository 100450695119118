import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import useCurrentLanguage from 'hooks/user/useCurrentLanguage';
import OpenDebtsCard from 'components/reusable/report/cards/OpenDebtsCard';
import PaymentHistoryCard from 'components/reusable/report/cards/PaymentHistoryCard';
import PropertyArrestsCard from 'components/reusable/report/cards/PropertyArrestsCard';
import LegalProceedingsCard, {
  LegalProceedingsOtherCard,
  LegalProceedingsTable,
} from 'components/reusable/report/cards/LegalProceedingsCard';
import { getNegativeData } from 'store/entities/startReport/thunks';
import {
  negativeDataUnreliableCompanies,
  negativeDataBailiffsInformation,
  negativeDataPaidDepts,
  negativeDataOutPropertyArrests,
  negativeDataPropertyArrests,
  negativeDataOpenDebts,
} from 'store/entities/startReport/selectors/negativeDataSelectors';

import { WarningOutlined } from '@ant-design/icons';
import { Card, Row, Skeleton } from 'antd';
import UnreliableCompanies from 'components/reusable/report/cards/UnreliableCompanies';
import { negativeDataLegalProceedings } from 'store/entities/startReport/selectors/negativeDataSelectors';
import SodraDebtInformation from 'views/StartReport/NegativeData/SodraDebtInformation';
import VMIDebtInformation from 'views/StartReport/NegativeData/VMIDebtInformation';
import BailiffsInformation from './BailiffsInformation';
import PaymentHistoryDetails from './PaymentHistoryDetails';
import PropertyArrestsDetails from './PropertyArrestsDetails';
import OpenDebtsDetails from './OpenDebtsDetails';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import { setProductCode } from '../../../store/entities/startReport';

const NegativeData = () => {
  const { t } = useTranslation();
  const lang = useCurrentLanguage();
  const dispatch = useDispatch();
  const {
    legalProceeding,
    legalProceedingOther,
    legalProceedingsDetailedDefendant,
    legalProceedingsDetailedPlaintiff,
  } = useSelector(negativeDataLegalProceedings);
  const { orderId, negativeData, negativeDataPending, negativeDataError, pdfReport } = useSelector(
    state => state.startReport
  );

  useEffect(() => {
    if ((orderId && negativeData && lang !== negativeData?.lang) || (!negativeData && !negativeDataError))
      dispatch(getNegativeData(orderId));
  }, [lang]);
  const { unreliableCompanies, unreliableCompaniesDetails } = useSelector(negativeDataUnreliableCompanies);
  const { bailiffsRemainders, bailiffsRemaindersDetails } = useSelector(negativeDataBailiffsInformation);
  const { paidDebts, paidDebtsDetails } = useSelector(negativeDataPaidDepts);
  const { unregisteredArrests, unregisteredArrestDetails } = useSelector(negativeDataOutPropertyArrests);
  const { registeredArrests, registeredArrestDetails } = useSelector(negativeDataPropertyArrests);
  const { activeDebts, activeDebtsDetails } = useSelector(negativeDataOpenDebts);

  const { claims } = useSelector(state => state.user);
  const availableClaims = claims.map(claim => {
    if (claim.value) {
      return claim.key;
    }
  });
  const detectClaim = value => availableClaims.includes(value);

  return (
    <LoadingSpinner spinning={negativeDataPending}>
      {negativeData ? (
        <>
          <OpenDebtsCard
            openDebtsData={activeDebts}
            buyReportButton={
              detectClaim('/product/opendebts/') && !activeDebtsDetails?.length > 0 && activeDebts?.total > 0
            }
            historyPurchesed={() => dispatch(setProductCode('OpenDebts'))}
          >
            <OpenDebtsDetails activeDebtsDetails={activeDebtsDetails} pdfReport={pdfReport && activeDebts?.total > 0} />
          </OpenDebtsCard>
          <PaymentHistoryCard
            paymentHistoryData={paidDebts}
            buyReportButton={
              detectClaim('/product/paymenthistory/') && !paidDebtsDetails?.length > 0 && paidDebts?.total > 0
            }
            historyPurchesed={() => dispatch(setProductCode('PaymentHistory'))}
          >
            <PaymentHistoryDetails
              paymentHistoryDetailsData={paidDebtsDetails}
              pdfReport={pdfReport && paidDebts?.total > 0}
            />
          </PaymentHistoryCard>
          <PropertyArrestsCard
            type="registered"
            propertyArrestsData={registeredArrests}
            buyReportButton={
              detectClaim('/product/registeredarrests/') &&
              !registeredArrestDetails?.length > 0 &&
              registeredArrests?.total > 0
            }
            historyPurchesed={() => dispatch(setProductCode('RegisteredArrests'))}
          >
            <PropertyArrestsDetails
              propertyArrestsDetailsData={registeredArrestDetails}
              pdfReport={pdfReport && registeredArrests?.total > 0}
            />
          </PropertyArrestsCard>
          <PropertyArrestsCard
            propertyArrestsData={unregisteredArrests}
            showNoEntries={false}
            buyReportButton={
              detectClaim('/product/registeredoutrealestatearrests/') &&
              !unregisteredArrestDetails?.length > 0 &&
              unregisteredArrests?.total
            }
            historyPurchesed={() => dispatch(setProductCode('RegisteredOutRealEstateArrests'))}
          >
            <PropertyArrestsDetails
              propertyArrestsDetailsData={unregisteredArrestDetails}
              unregisteredData
              pdfReport={pdfReport && unregisteredArrests?.total > 0}
            />
          </PropertyArrestsCard>
          <LegalProceedingsCard legalProceedingsData={legalProceeding}>
            <LegalProceedingsTable report="start" type="asDefendant" tableData={legalProceedingsDetailedDefendant} />
            <LegalProceedingsTable report="start" type="asPlaintiff" tableData={legalProceedingsDetailedPlaintiff} />
          </LegalProceedingsCard>
          <LegalProceedingsOtherCard legalProceedingsData={legalProceedingOther} />
          <BailiffsInformation
            bailiffsReminder={bailiffsRemainders}
            bailiffsRemaindersDetails={bailiffsRemaindersDetails}
          />
          <UnreliableCompanies
            icon={<WarningOutlined className="icon--gray" />}
            unreliableCompanies={unreliableCompanies}
            unreliableCompaniesDetails={unreliableCompaniesDetails}
          />
          <SodraDebtInformation />
          <VMIDebtInformation />
        </>
      ) : (
        <Skeleton />
      )}
    </LoadingSpinner>
  );
};

export default NegativeData;
