import React from 'react';
import { useTranslation } from 'react-i18next';
import ComposedChart from 'components/reusable/graphs/ComposedChart';
import useFormatDate from 'hooks/format/useFormatDate';

import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import { getFirstQuarterData } from 'utils/report/professionalReport';

const AverageEmployeesChart = ({ tableData }) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const isUiHidden = useIsUiHidden();

  const latestData = tableData[tableData.length - 1];
  // Based on KND-2044 the decision was to leave not the average count, but the value as in a table
  const avgYearlyEmployeeData = getFirstQuarterData(tableData);

  return (
    <>
      <ComposedChart
        id="averageNumberOfEmployees"
        series={[
          {
            type: 'area',
            dataKey: 'total',
            stroke: '#C40F11',
            key: 'total',
          },
        ]}
        monotone
        activeDefs
        data={isUiHidden ? avgYearlyEmployeeData : tableData}
        xAxisFormat={isUiHidden ? 'yyyy' : undefined}
        showLegend={false}
        lazyLoaded
        allowDecimalsY={false}
      />
      <div className="ant-statistic align-items-center fd-column text-align-center">
        <div className="ant-statistic-content ">
          <span className="ant-statistic-content-value">
            <span className="ant-statistic-content-value-int" style={{ color: '#C40F11' }}>
              {latestData?.total ?? '-'}
            </span>
          </span>
        </div>
        <div className="ant-statistic-title">
          <p style={{ fontWeight: 600, marginBottom: 0 }}>
            {t('professionalReport.basicData.employees.averageNumberOfEmployees.number')}
          </p>
          <p>{latestData?.date ? `(${formatDate(latestData?.date)})` : ''}</p>
        </div>
      </div>
    </>
  );
};

export default AverageEmployeesChart;
