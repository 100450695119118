import React, { useCallback, useEffect } from 'react';
import { Row, Col, Tabs } from 'antd';
import useLastPathname from 'hooks/navigation/useLastPathname';
import useRedirect from 'hooks/navigation/useRedirect';
import { useTranslation } from 'react-i18next';
import Monitoring from 'views/OtherProducts/WebMonitoring/Monitoring';
import { useDispatch } from 'react-redux';
import { getUserSettings } from 'store/entities/otherProducts/thunks';

const { TabPane } = Tabs;

const tabsKeys = Object.freeze({
  legalEntities: 'legal-entities',
  individualPerson: 'individual-person',
});

const WebMonitoring = () => {
  const lastPathname = useLastPathname();
  const redirect = useRedirect();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleTabChange = useCallback(
    activeKey => {
      if (activeKey !== lastPathname) {
        redirect(`/monitorings/web-monitoring/${activeKey}`);
      }
    },
    [redirect]
  );

  useEffect(() => {
    dispatch(getUserSettings());
  }, []);

  return (
    <Row className="container">
      <Col span={24}>
        <Tabs defaultActiveKey={lastPathname} style={{ height: 'auto', width: '100%' }} onChange={handleTabChange}>
          <TabPane tab={t('otherProducts.tabs.legalEntities')} key={tabsKeys.legalEntities}>
            <Monitoring legalEntities={true} />
          </TabPane>
          <TabPane tab={t('otherProducts.tabs.individualPerson')} key={tabsKeys.individualPerson}>
            <Monitoring legalEntities={false} />
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};

export default WebMonitoring;
