import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  companyProfitAndIncomeChart,
  revenueTable,
} from 'store/entities/professionalReport/selectors/financialDataSelectors';

import { Card, Col, Row } from 'antd';
import Icon from '@ant-design/icons';

import { ReactComponent as ComposedChartIcon } from 'assets/img/icons/composedChart.svg';
import RevenueTable from 'components/reusable/report/tables/RevenueTable';
import RevenueChart from 'components/reusable/report/graphs/RevenueChart';
import { BlockHeader } from '../../../components/reusable/BlockHeader';

const Revenue = () => {
  const { t } = useTranslation();
  const companyProfitAndIncome = useSelector(companyProfitAndIncomeChart);
  const { revenueTableData, profitsTableData, incomes } = useSelector(revenueTable);

  return (
    <>
      <Card className="card-simple card-simple-padding-none space-vertical-sm has-shadow" style={{ width: '100%' }}>
        <Row justify="start" align="middle">
          <Col>
            <BlockHeader
              icon={<Icon component={ComposedChartIcon} className="icon--gray" />}
              title={t('professionalReport.financialData.income.title')}
            />
          </Col>
        </Row>
        <Row className="space-vertical-md">
          <Col md={24} lg={24}>
            <RevenueChart chartData={companyProfitAndIncome} income />
            <RevenueTable tableData={revenueTableData} incomes={incomes.slice(-5)} />
          </Col>
        </Row>
      </Card>
      <Card className="card-simple card-simple-padding-none space-vertical-sm has-shadow" style={{ width: '100%' }}>
        <Row justify="start" align="middle">
          <Col>
            <BlockHeader
              icon={<Icon component={ComposedChartIcon} className="icon--gray" />}
              title={t('professionalReport.financialData.profit.title')}
            />
          </Col>
        </Row>
        <Row className="space-vertical-md">
          <Col md={24} lg={24}>
            <RevenueChart chartData={companyProfitAndIncome} profit />
            <RevenueTable tableData={profitsTableData} incomes={incomes.slice(-5)} type="income" />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Revenue;
