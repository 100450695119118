import React from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Col, Card, Space } from 'antd';
import Statistic from './Statistic';

const StatisticsCard = ({ title, icon, items = [] }) => {
  const { t } = useTranslation();
  const hasEntries = items.length > 0;
  return (
    <Card
      className="fill-rating-card card-simple card-simple-padding-none card-statistics has-shadow"
      style={{ width: '100%', height: '100%' }}
    >
      <Row justify="start">
        <Col span={24}>
          <span className="space-right-sm">{icon}</span>
          <strong className="w-100">{title}</strong>
        </Col>
      </Row>
      <Row gutter={[16, 16]} justify={hasEntries ? 'space-around' : 'start'} align="middle">
        {hasEntries ? (
          items.map(({ number, text, negative = false, graph, content, alignSelf = 'center' }, i) =>
            content ? (
              <div key={'statistic' + i}>
                <Space>{content}</Space>
              </div>
            ) : graph ? (
              <Col sm={24} md={12} lg={24} xl={15} key={'statistic' + i}>
                {graph}
              </Col>
            ) : (
              <Col key={'statistic' + i} style={{ alignSelf }}>
                <Statistic sm={24} md={6} lg={24} xl={4} value={number} description={text} isNegative={negative} />
              </Col>
            )
          )
        ) : (
          <div>{t('global.noEntries')}</div>
        )}
      </Row>
    </Card>
  );
};

export default StatisticsCard;
