import { useSelector } from 'react-redux';

const useAllowedFeature = (key, type = 'features') => {
  const { features, claims } = useSelector(state => state.user);

  return type === 'features'
    ? features?.includes(key)
    : claims?.filter(item => item.key.includes(key.toLowerCase()) && item.value).length > 0;
};

export default useAllowedFeature;
