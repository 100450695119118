import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { setVisibleCompanyView } from 'store/entities/company';

import CompanyDataBlock from './CompanyDataBlock';
import StatisticsCard from 'components/reusable/StatisticsCard';
import CreditScore from './CreditScore';
import InformationBlock from 'components/reusable/InformationBlock';
import UpdateContactInfo from './UpdateContactInfo';

import basicInformation from 'mockData/basicInformation.json';
import contactInformation from 'mockData/contactInformation.json';

import { BarChartOutlined, FileTextOutlined, MessageOutlined, EditOutlined } from '@ant-design/icons';
import { Row, Col, Button } from 'antd';

const PrescoreReport = () => {
  const dispatch = useDispatch();
  const [updateModalOpen, setUpdateModalOpen] = useState(false);

  return (
    <div>
      <CompanyDataBlock hasBackBtn={true} onBackBtnClick={() => dispatch(setVisibleCompanyView('details'))} />
      <Row gutter={[16, 16]}>
        <Col md={24} lg={12}>
          <CreditScore />
        </Col>
        <Col md={24} lg={12}>
          <StatisticsCard
            title="Number of interest in the subject's prescore"
            icon={<BarChartOutlined />}
            items={[
              { number: 3, text: 'Within last month' },
              { number: 8, text: 'Within last 3 months' },
              { number: 11, text: 'Within last 6 months' },
            ]}
          />
        </Col>
      </Row>
      <InformationBlock
        title="Basic information"
        data={basicInformation}
        icon={<FileTextOutlined />}
        hasDivider={true}
      />
      <InformationBlock
        title="Contact information"
        data={contactInformation}
        icon={<MessageOutlined />}
        extra={
          <Button icon={<EditOutlined />} onClick={() => setUpdateModalOpen(true)}>
            Update
          </Button>
        }
      />
      <UpdateContactInfo modalOpen={updateModalOpen} setModalOpen={setUpdateModalOpen} />
    </div>
  );
};

export default PrescoreReport;
