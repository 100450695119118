import React from 'react';
import { Col, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';

const IndividualOrderForm = () => {
  const { t } = useTranslation();

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} className="space-top-md">
          <Form.Item
            label={t('companyResult.buyReport.individualInformationForm.person')}
            name="person"
            rules={[
              {
                required: true,
                message: t('companyResult.buyReport.individualInformationForm.person.required'),
              },
            ]}
            required
          >
            <Input placeholder={t('companyResult.buyReport.individualInformationForm.person.placeholder')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Form.Item label={t('companyResult.buyReport.individualInformationForm.address')} name="address">
            <Input placeholder={t('companyResult.buyReport.individualInformationForm.address.placeholder')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Form.Item
            label={t('companyResult.buyReport.individualInformationForm.email')}
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: t('companyResult.buyReport.individualInformationForm.email.required'),
              },
            ]}
            required
          >
            <Input placeholder={t('companyResult.buyReport.individualInformationForm.email.placeholder')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Form.Item label={t('companyResult.buyReport.individualInformationForm.phone')} name="phone">
            <Input placeholder={t('companyResult.buyReport.individualInformationForm.phone.placeholder')} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default IndividualOrderForm;
