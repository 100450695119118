import React from 'react';
import { Col, Row, Space, Table } from 'antd';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';

const FinancialReportTable = ({ title, tableData, columns }) => {
  const isUiHidden = useIsUiHidden();

  return (
    <div>
      <Row>
        <Col>
          <Space size="large">
            <span>
              <strong>{title}</strong>
            </span>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            rowKey={({ index, number, categoryName }) => `${categoryName}-${number}-${index}`}
            dataSource={tableData}
            columns={columns}
            scroll={isUiHidden ? false : { x: 'max-content' }}
            pagination={false}
            bordered
          />
        </Col>
      </Row>
    </div>
  );
};

export default FinancialReportTable;
