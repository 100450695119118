import React, { useState } from 'react';
import settingsCompany from 'mockData/settingsListCompany.json';
import settingsPerson from 'mockData/settingsListPerson.json';
import { useTranslation } from 'react-i18next';
import { Row, Col, Space, Table, Form } from 'antd';
import { useSelector } from 'react-redux';

const MonitoringAlerts = ({ legalEntities, setEnabledEntities }) => {
  const { t } = useTranslation();
  const { eventListCompany, eventListPerson } = useSelector(state => state.otherProducts.userSettings);

  const halfEntities = Math.ceil(legalEntities ? eventListCompany.length / 2 : eventListPerson.length / 2);
  const firstHalfEntities = legalEntities
    ? eventListCompany.slice(0, halfEntities)
    : eventListPerson.slice(0, halfEntities);
  const secondHalfEntities = legalEntities ? eventListCompany.slice(halfEntities) : eventListPerson.slice(halfEntities);

  const [enabledFirstHalf, setEnabledFirstHalf] = useState(
    firstHalfEntities.filter(item => item.enabled).map(item => item.id)
  );
  const [enabledSecondHalf, setEnabledSecondHalf] = useState(
    secondHalfEntities.filter(item => item.enabled).map(item => item.id)
  );

  const alertsFirstColumns = [
    {
      title: t('webMonitoring.settings.name'),
      dataIndex: 'id',
      key: 'id',
      render: record =>
        legalEntities
          ? settingsCompany.map(setting => {
              if (setting.id === record) {
                return t(setting.name);
              }
            })
          : settingsPerson.map(setting => {
              if (setting.id === record) {
                return t(setting.name);
              }
            }),
    },
  ];

  const alertsSecondColumns = [
    {
      title: t('webMonitoring.settings.name'),
      dataIndex: 'id',
      key: 'id',
      render: record =>
        legalEntities
          ? settingsCompany.map(setting => {
              if (setting.id === record) {
                return t(setting.name);
              }
            })
          : settingsPerson.map(setting => {
              if (setting.id === record) {
                return t(setting.name);
              }
            }),
    },
  ];

  const rowSelection = {
    onSelect: (record, selected) => {
      setEnabledEntities(entity => [
        ...entity,
        {
          id: record.id,
          name: record.name,
          enabled: selected,
          priority: record.priority,
        },
      ]);
    },
  };

  return (
    <div className="settings-list">
      <Row>
        <Col>
          <Space size="large">
            <span>{t('webMonitoring.settings.subtitle')}</span>
          </Space>
        </Col>
      </Row>
      <Form>
        <Row>
          <Col xs={24} md={24} lg={12} xxl={12}>
            <Table
              className="settings-table"
              rowKey={record => record.id}
              dataSource={firstHalfEntities}
              columns={alertsFirstColumns}
              scroll={false}
              pagination={false}
              showSorterTooltip={false}
              rowSelection={{
                columnTitle: t('webMonitoring.settings.receive'),
                columnWidth: 150,
                fixed: false,
                selectedRowKeys: enabledFirstHalf,
                onChange: selectedRowKeys => {
                  setEnabledFirstHalf(selectedRowKeys);
                },
                ...rowSelection,
              }}
            />
          </Col>
          <Col xs={24} md={24} lg={12} xxl={12}>
            <Table
              className="settings-table"
              rowKey={record => record.id}
              dataSource={secondHalfEntities}
              columns={alertsSecondColumns}
              scroll={false}
              pagination={false}
              showSorterTooltip={false}
              rowSelection={{
                columnTitle: t('webMonitoring.settings.receive'),
                columnWidth: 150,
                fixed: false,
                selectedRowKeys: enabledSecondHalf,
                onChange: selectedRowKeys => {
                  setEnabledSecondHalf(selectedRowKeys);
                },
                ...rowSelection,
              }}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default MonitoringAlerts;
