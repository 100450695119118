import { createSlice } from '@reduxjs/toolkit';
import {
  addDebtorCompany,
  addDebtorPerson,
  getDebtId,
  getCompanyOrPersonCoveredDebtsList,
  getCompanyOrPersonUncoveredDebtsList,
  getDebtorInformation,
  getDebtTypesPerson,
  getDebtTypesCompany,
  updateDebtAmount,
  updateDebtPayment,
  getDebtsExcel,
} from './thunks';
import { format } from 'date-fns';

const initialState = {
  isSaveDebtsBtnLoading: false,
  companyDebts: { coveredDebts: [], uncoveredDebts: [] },
  personDebts: { coveredDebts: [], uncoveredDebts: [] },
  companyOrPersonDebtsLoading: false,
  errorMsg: null,
  debtTypesPerson: [],
  debtTypesCompany: [],
  debtTypesLoading: false,
  debtorInformation: null,
  debtorInformationErrorMsg: null,
  debtorInformationLoading: false,
  selectedDebtor: null,
  debtManagementModals: {
    isCoveredPartiallyCoveredOpen: false,
    isIncreaseDebtOpen: false,
    isInformAboutIncorrectDebtOpen: false,
  },
  chooseDebtorModalOpen: false,
  updateDebtPaymentState: {
    errorMsg: null,
    error: false,
    loading: false,
  },
  updateDebtAmountState: {
    errorMsg: null,
    error: false,
    loading: false,
  },
  debtId: null,
  debtsExcelLoading: false,
};

const slice = createSlice({
  name: 'administration',
  initialState,
  reducers: {
    resetPerson: () => null,
  },
  extraReducers: {
    [addDebtorCompany.pending]: state => {
      state.isSaveDebtsBtnLoading = true;
      state.errorMsg = false;
    },
    [addDebtorCompany.rejected]: (state, { payload }) => {
      state.errorMsg = payload.response?.data?.messages[0];
      state.isSaveDebtsBtnLoading = false;
    },
    [addDebtorCompany.fulfilled]: state => {
      state.debtTypesLoading = false;
      state.errorMsg = false;
      state.isSaveDebtsBtnLoading = false;
    },
    [addDebtorPerson.pending]: state => {
      state.isSaveDebtsBtnLoading = true;
      state.errorMsg = false;
    },
    [addDebtorPerson.rejected]: (state, { payload }) => {
      state.errorMsg = payload.response?.data?.messages[0];
      state.isSaveDebtsBtnLoading = false;
    },
    [addDebtorPerson.fulfilled]: state => {
      state.debtTypesLoading = false;
      state.errorMsg = false;
      state.isSaveDebtsBtnLoading = false;
    },
    [getDebtTypesPerson.pending]: state => {
      state.debtTypesLoading = true;
    },
    [getDebtTypesPerson.rejected]: state => {
      state.debtTypesLoading = false;
    },
    [getDebtTypesPerson.fulfilled]: (state, { payload }) => {
      state.debtTypesPerson = payload;
      state.debtTypesLoading = false;
    },
    [getDebtTypesCompany.pending]: state => {
      state.debtTypesLoading = true;
    },
    [getDebtTypesCompany.rejected]: state => {
      state.debtTypesLoading = false;
    },
    [getDebtTypesCompany.fulfilled]: (state, { payload }) => {
      state.debtTypesCompany = payload;
      state.debtTypesLoading = false;
    },
    [getDebtId.pending]: state => {
      state.debtId = null;
      state.debtIdLoading = true;
    },
    [getDebtId.rejected]: state => {
      state.debtIdLoading = false;
    },
    [getDebtId.fulfilled]: (state, { payload }) => {
      state.debtId = payload;
      state.debtIdLoading = false;
    },
    [getCompanyOrPersonCoveredDebtsList.pending]: state => {
      state.companyOrPersonDebtsLoading = true;
    },
    [getCompanyOrPersonCoveredDebtsList.rejected]: state => {
      state.companyOrPersonDebtsLoading = false;
    },
    [getCompanyOrPersonCoveredDebtsList.fulfilled]: (
      state,
      {
        payload,
        meta: {
          arg: { companyOrPerson },
        },
      }
    ) => {
      if (companyOrPerson === 'company') {
        state.companyCoveredDebts = payload;
      } else {
        state.personCoveredDebts = payload;
      }
      state.companyOrPersonDebtsLoading = false;

      //TODO: Need add increase debt modal
      //TODO: Need add inform modal
      //TODO: Need to do the same for individuals tab
    },
    [getCompanyOrPersonUncoveredDebtsList.pending]: state => {
      state.companyOrPersonDebtsLoading = true;
    },
    [getCompanyOrPersonUncoveredDebtsList.rejected]: state => {
      state.companyOrPersonDebtsLoading = false;
    },
    [getCompanyOrPersonUncoveredDebtsList.fulfilled]: (
      state,
      {
        payload,
        meta: {
          arg: { companyOrPerson },
        },
      }
    ) => {
      if (companyOrPerson === 'company') {
        state.companyUncoveredDebts = payload;
      } else {
        state.personUncoveredDebts = payload;
      }
      state.companyOrPersonDebtsLoading = false;
    },

    [getDebtorInformation.pending]: state => {
      state.debtorInformation = null;
      state.debtorInformationLoading = true;
      state.debtorInformationErrorMsg = null;
    },
    [getDebtorInformation.rejected]: (state, { payload }) => {
      state.debtorInformationLoading = false;
      state.debtorInformationErrorMsg = payload.response?.data?.messages[0];
    },
    [getDebtorInformation.fulfilled]: (state, { payload }) => {
      state.debtorInformation = payload;
      state.debtorInformationLoading = false;
      state.debtorInformationErrorMsg = null;
    },
    [updateDebtPayment.fulfilled]: state => {
      state.updateDebtPaymentState.errorMsg = null;
      state.updateDebtPaymentState.error = false;
      state.updateDebtPaymentState.loading = false;
      state.debtManagementModals.isCoveredPartiallyCoveredOpen = false;
      state.selectedDebtor = null;
    },
    [updateDebtPayment.pending]: state => {
      state.updateDebtPaymentState.loading = true;
    },
    [updateDebtPayment.rejected]: (state, { payload }) => {
      state.updateDebtPaymentState.errorMsg = payload.response?.data?.messages[0];
      state.updateDebtPaymentState.error = true;
      state.updateDebtPaymentState.loading = false;
    },
    [updateDebtAmount.fulfilled]: state => {
      state.updateDebtAmountState.errorMsg = null;
      state.updateDebtAmountState.error = false;
      state.updateDebtAmountState.loading = false;
      state.debtManagementModals.isIncreaseDebtOpen = false;
      state.selectedDebtor = null;
    },
    [updateDebtAmount.pending]: state => {
      state.updateDebtAmountState.loading = true;
    },
    [updateDebtAmount.rejected]: (state, { payload }) => {
      state.updateDebtAmountState.errorMsg = payload.response?.data?.messages[0];
      state.updateDebtAmountState.error = true;
      state.updateDebtAmountState.loading = false;
    },
    [getDebtsExcel.pending]: state => {
      state.debtsExcelLoading = true;
    },
    [getDebtsExcel.rejected]: state => {
      state.debtsExcelLoading = false;
    },
    [getDebtsExcel.fulfilled]: (state, { payload }) => {
      state.debtsExcelLoading = false;
    },
    SELECT_DEBTOR_COMPANY: (state, { payload }) => {
      state.selectedDebtor = {
        ...payload,
        companyName: payload.companyName,
        debtorCode: payload.debtorCode,
        companyCode: payload.companyCode,
        dateIncurrence: format(new Date(payload.dateIncurrence), 'yyyy-MM-dd'),
        submissionDate: format(new Date(payload.submissionDate), 'yyyy-MM-dd'),
        debtType: payload.debtType,
      };
    },
    SELECT_DEBTOR_INDIVIDUAL: (state, { payload }) => {
      state.selectedDebtor = {
        ...payload,
        debtorName: payload.personFullName,
        companyName: payload.personFullName,
        companyCode: payload.personCode,
        manager: payload.manager,
        dateIncurrence: format(new Date(payload.dateIncurrence), 'yyyy-MM-dd'),
        submissionDate: format(new Date(payload.submissionDate), 'yyyy-MM-dd'),
        debtType: payload.debtType,
      };
    },
    REMOVE_SELECTED_DEBTOR: state => {
      state.selectedDebtor = null;
    },
    TOGGLE_COVERED_PARTIALLY_COVERED_MODAL: (state, { payload }) => {
      state.debtManagementModals = {
        ...state.debtManagementModals,
        isCoveredPartiallyCoveredOpen: payload,
      };
    },
    TOGGLE_CHOOSE_DEBTOR_MODAL: (state, { payload }) => {
      state.chooseDebtorModalOpen = payload;
      if (!payload) {
        state.debtorInformation = null;
      }
    },
    TOGGLE_INCREASE_DEBT_MODAL: (state, { payload }) => {
      state.debtManagementModals = {
        ...state.debtManagementModals,
        isIncreaseDebtOpen: payload,
      };
    },
    TOGGLE_INFORM_ABOUT_INCORRECT_DEBT_MODAL: (state, { payload }) => {
      state.debtManagementModals = {
        ...state.debtManagementModals,
        isInformAboutIncorrectDebtOpen: payload,
      };
    },
  },
});

export const { resetPerson, setVisiblePersonView } = slice.actions;

export default slice.reducer;
