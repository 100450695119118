import React from 'react';
import { useSelector } from 'react-redux';

import useWindowSize from 'hooks/dom/useWindowSize';

import { Modal as AntModal, Row, Spin } from 'antd';
import LoadingSpinner from './LoadingSpinner';

const Modal = ({
  isOpen,
  children,
  setIsOpen = () => {},
  closable = true,
  size = 'lg',
  className = '',
  title = '',
  secondaryTitle,
  onCancel,
  isLoading = false,
  ...rest
}) => {
  const { width: windowWidth } = useWindowSize();
  const { siderCollapsed } = useSelector(state => state.ui);
  const SMALL_MODAL_WIDTH = size === 'xs' ? 460 : 720;

  return (
    <AntModal
      className={`${size === 'lg' ? 'fullpage-modal' : ''} ${className}`}
      visible={isOpen}
      footer={null}
      closable={closable}
      maskClosable={closable}
      onCancel={onCancel ?? (() => setIsOpen(false))}
      title={title}
      width={size === 'lg' ? windowWidth - (siderCollapsed ? 100 : 222) : SMALL_MODAL_WIDTH}
      confirmLoading={true}
      {...rest}
    >
      <LoadingSpinner size="small" spinning={isLoading}>
        <div>
          {secondaryTitle && (
            <Row>
              <h3 style={{ marginBottom: '1.8rem', fontWeight: 700 }}>{secondaryTitle}</h3>
            </Row>
          )}
          {children}
        </div>
      </LoadingSpinner>
    </AntModal>
  );
};

export default Modal;
