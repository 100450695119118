export const generateId = () => Math.random().toString(36).substring(2, 10);

export const deepClone = obj => {
  if (Array.isArray(obj)) {
    return obj.map(item => deepClone(item));
  } else if (typeof obj === 'object' && obj !== null) {
    const clone = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        clone[key] = deepClone(obj[key]);
      }
    }
    return clone;
  } else {
    return obj;
  }
};

export const insertChildNodes = (data, parentId, newNode) => {
  if (data.id === parentId) {
    if (!Array.isArray(data.children)) {
      data.children = [];
    }
    data.children.push(newNode);

    return true;
  }

  const childrenNodes = Array.isArray(data) ? data : data.children;

  if (Array.isArray(childrenNodes)) {
    // this is the logic to remove the already active data set of nodes in the same depth level
    // const foundObject = childrenNodes?.find(obj => obj.id === parentId);

    // if (foundObject) {
    //   const sameLevelNodes = childrenNodes.filter(obj => obj.id !== parentId);
    //   removeAllChildren(data, sameLevelNodes, parentId);
    // }

    for (const node of childrenNodes) {
      if (node.id === parentId) {
        if (!node.children) {
          node.children = [];
        }
        node.children.push(newNode);
        return true;
      }

      if (node.children && node.children.length) {
        const found = insertChildNodes(node.children, parentId, newNode);
        if (found) return true;
      }
    }
  }

  return false;
};

export const removeAllChildren = (data, sameLevelNodes) => {
  sameLevelNodes.forEach(depthNode => {
    const sameLevelNode =
      data.type === 'rootItem'
        ? data.children.filter(node => node.id === depthNode.id)[0]
        : data.filter(node => node.id === depthNode.id)[0];

    //If expanded group is found on same Level, un-expand it
    const expandedGroup = sameLevelNode.group.find(group => group.expanded);
    if (expandedGroup) {
      if (sameLevelNode.group) {
        const groupIndex = sameLevelNode.group.findIndex(group => group.id === expandedGroup.id);

        if (groupIndex !== -1) {
          sameLevelNode.group[groupIndex] = { ...sameLevelNode.group[groupIndex], ...{ expanded: false } };
        }
      }
    }

    // Delete all other nodes children on same level
    if (sameLevelNode.id === depthNode.id) {
      sameLevelNode.expanded = false;
      sameLevelNode.children = [];

      return true;
    }
  });
};

export const removeChildNodes = (data, parentId) => {
  if (data.id === parentId) {
    data.expanded = false;
    data.children = [];

    return true;
  }

  if (data.children && data.children.length) {
    for (let i = 0; i < data.children.length; i++) {
      if (data.children[i].id === parentId) {
        data.children[i].expanded = false;
        data.children[i].children = [];

        return true;
      } else {
        const found = removeChildNodes(data.children[i], parentId);
        if (found) return true;
      }
    }
  }

  return false;
};

export const findAllNodes = (node, nodes = []) => {
  if (node.id) {
    nodes.push(node);
  }
  if (node.children) {
    node.children.forEach(child => findAllNodes(child, nodes));
  }
  return nodes;
};

// Traverse recursively to get all groups with parent nodes
export const findAllGroupsInNodes = (node, groups = []) => {
  if (node.group) {
    node.group.forEach(item =>
      groups.push({
        ...item,
        parent: node,
      })
    );
  }
  if (node.children) {
    node.children.forEach(child => findAllGroupsInNodes(child, groups));
  }
  return groups;
};

// Checks for highest nodes on all depths / levels
export const calculateDepthHeights = (data, basicHeight, groupHeight, depth = 0, depthHeights = []) => {
  if (depth >= depthHeights.length) {
    depthHeights.push({
      depth,
      height: 0,
    });
  }
  const currentHeight = depthHeights[depth].height;
  let currentMaxHeight = data.group ? 20 + basicHeight + data.group.length * groupHeight : 20 + basicHeight;
  depthHeights[depth].height = Math.max(currentHeight, currentMaxHeight);

  if (data.children) {
    data.children.forEach(child => {
      calculateDepthHeights(child, basicHeight, groupHeight, depth + 1, depthHeights);
    });
  }
  return depthHeights;
};

export const calculateSingleNodeHeight = (data, basicHeight, groupHeight) => {
  if (data.type === 'rootItem') {
    return basicHeight + 60;
  }

  if (data.group && data.group.length > 0) {
    let additionalHeight =
      (data.moreGroup && data.moreGroup.length) || (data.historicalExpanded && data.group.length === 20) ? 20 : 0;
    if (
      (data.group.length === data.historicalGroup.length && data.historicalExpanded) ||
      (data.group.length % 20 === 0 && data.historicalExpanded && data.historicalGroup.length > 15)
    ) {
      additionalHeight = additionalHeight + 20;
    }
    return basicHeight + data.group.length * groupHeight + additionalHeight;
  }

  if (data.group && data.group.length === 0) {
    return basicHeight + 40;
  }

  return basicHeight + 20;
};

// Receiving the array of all the nodes heights
export const calculateIndividualNodesHeights = (node, basicHeight, groupHeight) => {
  const result = [];
  if (node.group) {
    result.push({
      id: node.id,
      length: calculateSingleNodeHeight(node, basicHeight, groupHeight),
    });
  }
  if (node.children) {
    node.children.forEach(child => {
      result.push(...calculateIndividualNodesHeights(child, basicHeight, groupHeight));
    });
  }
  return result;
};

export const getHeightByNodeId = (result, targetId, basicHeight) => {
  const foundNode = result.find(item => item.id === targetId);
  return foundNode ? foundNode.length : basicHeight;
};

export const checkLinkLength = (node, basicHeight, groupHeight, depthHeights) => {
  const depthObject = depthHeights.find(item => item.depth === node.depth - 1);
  return depthObject ? depthObject.height : basicHeight;
};

export const adjustNodePositions = (node, yOffset) => {
  node.y += yOffset;
  if (node.children) {
    node.children.forEach(child => adjustNodePositions(child, yOffset));
  }
};

export const updateGroupById = (data, groupId, updates) => {
  if (data.group) {
    const groupIndex = data.group.findIndex(group => group.id === groupId);

    if (groupIndex !== -1) {
      data.group[groupIndex] = { ...data.group[groupIndex], ...updates };

      return;
    }
  }

  if (data.children) {
    data.children.forEach(child => updateGroupById(child, groupId, updates));
  }
};
