import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { pickBy, identity } from 'lodash';

import { ReactComponent as Card } from 'assets/img/icons/Card.svg';

import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { registerConsultation } from 'store/entities/companySearchResult/thunks';
import { useHistory } from 'react-router-dom';
import ResponseModal from 'views/ConsultationRegistration/ResponseModal';

const { Paragraph } = Typography;

const ConsultationRegistration = () => {
  const [showResponseModal, setShowResponseModal] = useState(false);

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCloseModal = () => {
    setShowResponseModal(false);
    history.goBack();
  };

  const handleSubmit = values => {
    const postObject = pickBy(values, identity);
    setShowResponseModal(true);
    // TODO: Uncomment when backend working again
    // dispatch(registerConsultation(postObject));
  };

  return (
    <div className="container cregister">
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Form
                form={form}
                name="consultationRegisterForm"
                onFinish={handleSubmit}
                className="cregister-form"
                rules={[
                  {
                    required: true,
                    message: t('consultation.form.name.required'),
                  },
                ]}
                labelCol={{ span: 24 }}
              >
                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <Paragraph className="align-items-center">
                      <Card className="space-right-sm" />
                      {t('consultation.form.title')}
                    </Paragraph>
                  </Col>
                </Row>
                <Row gutter={[16]}>
                  <Col xs={24} lg={11}>
                    <Form.Item
                      label={t('consultation.form.name')}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: t('consultation.form.name.required'),
                        },
                      ]}
                      required
                    >
                      <Input placeholder={t('placeholder.firstName')} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={{ span: 11, offset: 2 }}>
                    <Form.Item
                      label={t('consultation.form.surName')}
                      name="surName"
                      rules={[
                        {
                          required: true,
                          message: t('consultation.form.surName.required'),
                        },
                      ]}
                      required
                    >
                      <Input placeholder={t('placeholder.lastName')} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16]}>
                  <Col xs={24} lg={11}>
                    <Form.Item
                      label={t('consultation.form.email')}
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: t('consultation.form.email.required'),
                        },
                      ]}
                      required
                    >
                      <Input placeholder={t('placeholder.email')} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={{ span: 11, offset: 2 }}>
                    <Form.Item
                      label={t('consultation.form.phone')}
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: t('consultation.form.phone.required'),
                        },
                      ]}
                      required
                    >
                      <Input placeholder={t('placeholder.phone')} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16]}>
                  <Col span={24}>
                    <Form.Item label={t('consultation.form.message')} name="message">
                      <Input.TextArea placeholder={t('placeholder.message')} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[32]}>
                  <Col xs={24} className="cregister-actions">
                    <Button onClick={() => history.goBack()}>{t('global.cancel')}</Button>
                    <Button className="btn-secondary" htmlType="submit">
                      {t('global.register')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      {showResponseModal && <ResponseModal onClose={handleCloseModal} />}
    </div>
  );
};

export default ConsultationRegistration;
