import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, Typography } from 'antd';

import XLS from './../../assets/pdf/XLS.pdf';
import XML from './../../assets/pdf/XML.pdf';
import WEB from './../../assets/pdf/WEB.pdf';
import ReportRow from 'views/Search/components/ReportRow';
import useRedirect from 'hooks/navigation/useRedirect';
import { ReactComponent as BasicDataIcon } from 'assets/img/icons/basic.svg';
import RestrictedAccess from '../../components/RestrictedAccess';
const { Paragraph } = Typography;

const DebtorsAdministration = () => {
  const { t } = useTranslation();
  const redirect = useRedirect();

  return (
    <Card
      className="card-simple card-simple-padding-none space-vertical-sm has-shadow"
      title={t('administration.selectProduct.title')}
      style={{ width: '100%' }}
    >
      <Row gutter={[32, 16]}>
        <Col xs={24} md={24} lg={5} xl={8}>
          <Paragraph></Paragraph>
        </Col>
        <Col xs={24} md={24} lg={19} xl={16}>
          <RestrictedAccess permission="/product/persondebtsadmin/">
            <ReportRow
              key="debtManagement"
              btnText="global.selectClaims"
              reportName={t('administration.selectProduct.debtManagement')}
              onBtnClick={() => {
                redirect('/administration/debtors-administration/debt-management');
              }}
            />
          </RestrictedAccess>
          <ReportRow
            key="upload"
            btnText="global.selectClaims"
            reportName={t('administration.selectProduct.upload')}
            onBtnClick={() => {
              window.open(
                'https://new.infobankas.lt/creditinfogroup_lt/sais/uploadclaims.aspx?UserID=2133242298320231912253172253310631412653109280320431533150&PasswID=311031153247113196249325331222643209323831453168311232553200',
                '_self'
              );
            }}
          />
          <BasicDataIcon className="card-icon" />
        </Col>
      </Row>
    </Card>
  );
};

export default DebtorsAdministration;
