import { useSelector } from 'react-redux';

const UserPermissions = value => {
  const { claims } = useSelector(state => state.user);
  const availableClaims = claims.map(claim => {
    if (claim.value) {
      return claim.key;
    }
  });
  return availableClaims.includes(value);
};

export default UserPermissions;
