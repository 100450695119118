import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { Card, Collapse, Typography } from 'antd';

import Icon from '@ant-design/icons';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

import ReportBlockHeader from 'components/reusable/report/ReportBlockHeader';
import NumberOfVehiclesTable from 'components/reusable/report/tables/NumberOfVehiclesTable';
import { ReactComponent as CarIcon } from 'assets/img/icons/car.svg';
import ListOfVehiclesTable from 'components/reusable/report/tables/ListOfVehiclesTable';
import { basicInfo, basicVehicles } from 'store/entities/startReport/selectors/basicDataSelectors';
import UserPermissions from 'helpers/userPermissions';
import VehicleFleet from '../../ProfessionalReport/BasicData/Vehicles/VehicleFleet';

const { Panel } = Collapse;
const { Paragraph } = Typography;

const Vehicles = () => {
  const { t } = useTranslation();
  const { vehiclesDetails, vehiclesFleetValues } = useSelector(basicInfo);
  const { vehicles } = useSelector(basicVehicles);

  return (
    <>
      <div className="no-break" id="vehicles">
        <div className="space-vertical-md">
          <ReportBlockHeader
            icon={<Icon component={CarIcon} className="icon--gray" />}
            title={t('startReport.basic.vehicles.title')}
            disclaimer={t('professionalReport.basicData.vehicles.numberOfVehicles.title')}
          />
          <NumberOfVehiclesTable vehicles={vehicles} />
        </div>
        <Card
          className="card-simple card-simple-padding-none has-shadow space-top-md"
          style={{ width: '100%' }}
          bodyStyle={{ padding: 12 }}
        >
          <Collapse
            ghost
            expandIcon={({ isActive }) =>
              isActive ? <MinusOutlined style={{ fontSize: '14px' }} /> : <PlusOutlined style={{ fontSize: '14px' }} />
            }
          >
            <Panel header={t('startReport.basic.vehicles.ownershipDescription.title')} key="1">
              <div className="padding-x-lg padding-top-lg">
                <Paragraph>
                  <Trans i18nKey="startReport.basic.vehicles.ownershipDescription.managerOwner" />
                </Paragraph>
                <Paragraph>
                  <Trans i18nKey="startReport.basic.vehicles.ownershipDescription.manager" />
                </Paragraph>
                <Paragraph>
                  <Trans i18nKey="startReport.basic.vehicles.ownershipDescription.owner" />
                </Paragraph>
              </div>
            </Panel>
          </Collapse>
        </Card>
      </div>

      <div className="space-vertical-md">
        <ReportBlockHeader
          icon={<Icon component={CarIcon} className="icon--gray" />}
          title={t('professionalReport.basicData.vehicles.listOfVehicles.title')}
          disclaimer={
            vehiclesDetails.length > 50
              ? t('startReport.basic.vehicles.last')
              : t('professionalReport.basicData.vehicles.numberOfVehicles.title')
          }
        />
        <ListOfVehiclesTable vehicleTableData={vehiclesDetails.slice(0, 50)} />
      </div>
      {UserPermissions('/product/valueofvehicles/') && (
        <Card className="card-simple card-simple-padding-none space-vertical-sm has-shadow" style={{ width: '100%' }}>
          <VehicleFleet
            reportBasicDataSelector={basicVehicles}
            showVehiclesValueSectionByDefault={!!vehiclesFleetValues}
          />
        </Card>
      )}
    </>
  );
};

export default Vehicles;
