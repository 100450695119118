import React from 'react';

import { Col, Row, Typography } from 'antd';

const { Title } = Typography;

const ReportHeader = ({ title, actions }) => {
  return (
    <Row gutter={[16, 16]} justify="space-between">
      <Col span={24}>
        <Title
          level={2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <span>{title}</span>
          <span style={{ marginLeft: '1rem', display: 'flex' }}>{actions}</span>
        </Title>
      </Col>
    </Row>
  );
};

export default ReportHeader;
