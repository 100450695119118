import React from 'react';
import { useTranslation } from 'react-i18next';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import { Card, Col, Row, Table, Typography } from 'antd';
import useNumberFormatter from '../../../../hooks/format/useNumberFormatter';

const { Text } = Typography;

const VehicleFleetTable = ({ dataKey, table, columns }) => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();
  const formatNumber = useNumberFormatter({ minimumFractionDigits: 2, maximumFractionDigits: 2 });

  const formatCurrencyValues = dataSource => {
    return dataSource.map(item => {
      const formattedItem = { ...item };

      for (const key in formattedItem) {
        if (key.includes('date')) {
          formattedItem[key] = formatNumber(formattedItem[key]);
        }
      }

      return formattedItem;
    });
  };

  const formattedTable = formatCurrencyValues(table);

  return (
    <div>
      <Row className="space-bottom-md">
        <Col>
          <Text level={4} className="fw-600">
            {t(`professionalReport.basicData.vehicles.valueOfVehicles.${dataKey}.title`)}
          </Text>
        </Col>
      </Row>
      <Row className="space-bottom-lg">
        <Col xs={24} md={24}>
          {formattedTable[0]?.valueRange !== '0' ? (
            <Table
              rowKey={range => range.valueRange}
              bordered
              dataSource={formattedTable}
              columns={isUiHidden ? columns.filter((item, idx, arr) => idx === 0 || idx > arr.length - 7) : columns}
              scroll={isUiHidden ? false : { x: 'max-content' }}
              pagination={false}
              showSorterTooltip={false}
            />
          ) : (
            <Card className="card-transparent">
              {t('professionalReport.basicData.vehicles.valueOfVehicles.noInformation')}
            </Card>
          )}
        </Col>
      </Row>
      <Row className="space-bottom-lg">
        <Col>
          <Text level={4}>{t(`professionalReport.basicData.vehicles.valueOfVehicles.${dataKey}.description`)}</Text>
        </Col>
      </Row>
    </div>
  );
};

export default VehicleFleetTable;
