import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ReportBlockHeader from 'components/reusable/report/ReportBlockHeader';
import useNumberFormatter from 'hooks/format/useNumberFormatter';

import { Card, Col, Button, Row } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import { setProductCode } from '../../../store/entities/startReport';
import { basicInfo } from '../../../store/entities/startReport/selectors/basicDataSelectors';

const CreditLimit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { claims } = useSelector(state => state.user);
  const formatCurrency = useNumberFormatter();
  const { childAutomatedCreditLimitData, childAutomatedCreditLimitPending, pdfReport } = useSelector(
    state => state.startReport
  );
  const { isAvailableCreditLimitCountedAmount } = useSelector(basicInfo);
  const availableClaims = claims.map(claim => {
    if (claim.value) {
      return claim.key;
    }
  });
  const detectClaim = value => availableClaims.includes(value);

  return (
    <LoadingSpinner spinning={childAutomatedCreditLimitPending}>
      <Row id="company-credit" className="company-credit-limit no-break">
        <div className="space-top-lg">
          <ReportBlockHeader icon={<InfoCircleOutlined />} title={t('startReport.basic.companyCredit.title')} />
        </div>
        <Col span={24}>
          {detectClaim('/product/automatedcreditlimit/') && (
            <>
              {childAutomatedCreditLimitData === null && isAvailableCreditLimitCountedAmount ? (
                pdfReport ? (
                  <Row>
                    <Card span={24} className="card-transparent space-vertical-sm">
                      {t('startReport.basic.vehicles.pdf.notBought')}
                    </Card>
                  </Row>
                ) : (
                  <Card className="card-transparent space-vertical-sm" style={{ background: '#fff', fontSize: '110%' }}>
                    <Button
                      className="credit-limit-button"
                      onClick={() => dispatch(setProductCode('AutomatedCreditLimit'))}
                    >
                      {t('startReport.basic.credit.limit.buy.button')}
                    </Button>
                    <div className="disclaimer">{t('startReport.basic.credit.limit.paragraph')}</div>
                  </Card>
                )
              ) : childAutomatedCreditLimitData?.amount || childAutomatedCreditLimitData?.amount === 0 ? (
                <Card className="card-transparent space-vertical-sm" style={{ background: '#fff', fontSize: '110%' }}>
                  <Trans
                    i18nKey="companyCreditLimit.amount.value"
                    values={{
                      value: formatCurrency(childAutomatedCreditLimitData?.amount),
                    }}
                    components={{ bold: <strong className="bigger-font" /> }}
                  />
                  <div className="disclaimer">{t('startReport.basic.credit.limit.paragraph')}</div>
                </Card>
              ) : (
                <Card className="card-transparent space-vertical-sm" style={{ background: '#fff', fontSize: '110%' }}>
                  <Trans
                    i18nKey="companyCreditLimit.not.available"
                    components={{ bold: <strong className="bigger-font" /> }}
                  />
                  <div className="disclaimer">{t('startReport.basic.credit.limit.paragraph')}</div>
                </Card>
              )}
            </>
          )}
        </Col>
      </Row>
    </LoadingSpinner>
  );
};

export default CreditLimit;
