import { useEffect, useState } from 'react';

const useMedia = (queries, values, defaultValue) => {
  const mediaQueryLists = queries.map(q => window.matchMedia(q));
  const getValue = () => {
    const index = mediaQueryLists.findIndex(mql => mql.matches);
    return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
  };
  const [value, setValue] = useState(getValue);

  useEffect(() => {
    const handler = () => setValue(getValue);
    mediaQueryLists.forEach(mql => mql.addListener(handler));
    return () => mediaQueryLists.forEach(mql => mql.removeListener(handler));
  }, []);

  return value;
};

export const useIsSmOrLess = () => {
  const isSmOrLess = useMedia(['(max-width: 767px)', '(min-width: 768px)'], [true, false], false);

  return isSmOrLess;
};

export const useIsMdOrLess = () => {
  const isMdOrLess = useMedia(['(max-width: 991px)', '(min-width: 992px)'], [true, false], false);

  return isMdOrLess;
};

export default useMedia;
