import { useCallback } from 'react';
import useResponsiveContainer from 'hooks/graph/useResponsiveContainer';
import useYAxisWidth from 'hooks/graph/useYAxisWidth';

const ChartWrapper = props => {
  const { data = [], minHeight = 400, children } = props;
  const [{ width, height }, setContainerNode] = useResponsiveContainer(minHeight);
  const [yAxisWidth, setYAxisNode] = useYAxisWidth([height, data, width]);

  const callbackRef = useCallback(
    node => {
      setYAxisNode(node);
      setContainerNode(node);
    },
    [setContainerNode, setYAxisNode]
  );

  const Chart = children({
    ...props,
    yAxisWidth,
    width,
    height,
    callbackRef,
  });

  return Chart;
};

export default ChartWrapper;
