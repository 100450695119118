const riskClasses = Object.freeze({
  noRiskIdentified: 'Undefined',
  lowRisk: 'Low',
  mediumRisk: 'Average',
  highRisk: 'High',
  veryHighRisk: 'VeryHigh',
  vipClients: 'VipClients',
});

const riskClassesMap = {
  Undefined: 0,
  Low: 1,
  Average: 2,
  High: 3,
  VeryHigh: 4,
  VipClients: 5,
};

const riskStyleClass = new Map([
  [riskClasses.noRiskIdentified, { color: '#333333', fontWeight: 600 }],
  [riskClasses.lowRisk, { color: '#0A7A00', fontWeight: 600 }],
  [riskClasses.mediumRisk, { color: '#0C9CB2', fontWeight: 600 }],
  [riskClasses.highRisk, { color: '#0D6BFE', fontWeight: 600 }],
  [riskClasses.veryHighRisk, { color: '#FF7900', fontWeight: 600 }],
  [riskClasses.vipClients, { color: '#C40F11', fontWeight: 600 }],
]);

export { riskClasses, riskClassesMap, riskStyleClass };
