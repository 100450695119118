import { fetchFromApi, optionsForGetRequest, optionsForPostRequest } from './';

const getOrderId = companyCode => fetchFromApi(`/company/${companyCode}/report/prescore/order`, optionsForGetRequest());

const getBasicData = orderId => fetchFromApi(`/company/${orderId}/report/prescore/basic`, optionsForGetRequest());

const updateContacts = (orderId, newContacts) =>
  fetchFromApi(`/company/${orderId}/report/prescore/update-contacts`, optionsForPostRequest(newContacts));

const getPrescoreReportPdf = orderId =>
  fetchFromApi(`/company/${orderId}/report/prescore/pdf/preview`, optionsForGetRequest());

export default {
  getOrderId,
  getBasicData,
  updateContacts,
  getPrescoreReportPdf,
};
