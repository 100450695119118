export const financialRatiosValues = [
  'currentSolvencyRatio',
  'criticalCoverageFactor',
  'returnEquity',
  'profitBeforeTax',
  'inventoryTurnover',
  'turnoverReceivables',
  'turnoverAmountsDue',
  'equityAssetsRatio',
];
