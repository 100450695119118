import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent';
import colors from 'constants/colors';

const CustomTooltip = props => {
  if (props && props.payload && props.payload[0] != null) {
    const newPayload = [
      ...props.payload,
      ...(props.hiddenSeries &&
        props.hiddenSeries
          .map(({ dataKey, index, color }) => {
            const value = props.payload[index].payload[dataKey];
            return value
              ? {
                  name: dataKey,
                  value,
                  color,
                }
              : null;
          })
          .filter(Boolean)),
    ];

    return <DefaultTooltipContent {...props} payload={newPayload} />;
  }

  return <DefaultTooltipContent {...props} />;
};

export default CustomTooltip;
