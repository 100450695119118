import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Skeleton } from 'antd';

import { useParams } from 'react-router-dom';
import { getBasicData } from '../../../../store/entities/spiderWeb/thunks';
import HierarchyTree from '../SpiderwebMap/components/HierarchyTree';
import MarksExplanation from './MarksExplanation';

const SpiderwebView = () => {
  const { basicData } = useSelector(state => state.spiderWeb);
  const { companyOrPersonId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (companyOrPersonId) {
      dispatch(
        getBasicData({
          searchPhraseCompany: companyOrPersonId,
          searchPhrasePerson: 'string',
          country: 'LT',
          relationTypes: [255],
        })
      );
    }
  }, []);

  return basicData?.company || basicData?.person ? (
    <Layout height={640} className="spider-container">
      <MarksExplanation />
      {basicData && <HierarchyTree data={basicData} />}
    </Layout>
  ) : (
    <Skeleton />
  );
};

export default SpiderwebView;
