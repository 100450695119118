import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Form, Button, Table, Row, Col, Upload, message } from 'antd';
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';

import CompanyDataCard from 'components/reusable/CompanyDataCard';
import { riskClasses } from 'constants/otherProducts';
import { uploadFile } from 'store/entities/otherProducts/thunks';

const FileUpload = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);
  const [errorList, setErrorList] = useState([]);

  const companyDataSource = useMemo(() => {
    return [
      {
        companyCode: 111111111,
        riskClass: 5,
      },
      {
        companyCode: 111111112,
        riskClass: 3,
      },
      {
        companyCode: 111111113,
        riskClass: '',
      },
    ];
  }, []);
  const riskDataSource = useMemo(() => {
    return [
      {
        riskGroupNumber: 5,
        riskClass: t('otherProducts.monitorings.riskClasses.vipClients'),
      },
      {
        riskGroupNumber: 4,
        riskClass: t('otherProducts.monitorings.riskClasses.veryHighRisk'),
      },
      {
        riskGroupNumber: 3,
        riskClass: t('otherProducts.monitorings.riskClasses.highRisk'),
      },
      {
        riskGroupNumber: 2,
        riskClass: t('otherProducts.monitorings.riskClasses.mediumRisk'),
      },
      {
        riskGroupNumber: 1,
        riskClass: t('otherProducts.monitorings.riskClasses.lowRisk'),
      },
      {
        riskGroupNumber: t('otherProducts.monitorings.riskClasses.noSpecify'),
        riskClass: t('otherProducts.monitorings.riskClasses.noRiskIdentified'),
      },
    ];
  }, [t, riskClasses]);
  const companyTableColumns = useMemo(() => {
    return [
      {
        dataIndex: 'companyCode',
        key: 'companyCode',
        title: t('otherProducts.monitorings.creditMonitoring.fileUpload.companyCode'),
        align: 'center',
      },
      {
        dataIndex: 'riskClass',
        key: 'riskClass',
        title: t('otherProducts.monitorings.creditMonitoring.fileUpload.riskClass'),
        align: 'center',
      },
    ];
  }, [t]);
  const riskTableColumns = useMemo(() => {
    return [
      {
        dataIndex: 'riskClass',
        key: 'riskClass',
        title: t('otherProducts.monitorings.creditMonitoring.fileUpload.riskClass'),
        align: 'center',
      },
      {
        dataIndex: 'riskGroupNumber',
        key: 'riskGroupNumber',
        title: t('otherProducts.monitorings.creditMonitoring.fileUpload.riskGroupNumber'),
        align: 'center',
      },
    ];
  }, [t]);

  const handleFileUpload = () => {
    const formData = new FormData();
    fileList.forEach(file => {
      formData.append('FileDetails', file);
    });
    dispatch(uploadFile(formData)).then(response => {
      setFileList([]);
      if (response?.payload?.uploadStatusType === 'TooManyRows') {
        message.error(t('otherProducts.monitorings.creditMonitoring.fileUpload.TooManyRows'), 10);
      }
      if (response?.payload?.uploadStatusType === 'PartialUpload') {
        message.warning(t('otherProducts.monitorings.creditMonitoring.fileUpload.PartialUpload'), 10);
      }
      if (response?.payload?.uploadStatusType === 'Failed') {
        message.error(t('otherProducts.monitorings.creditMonitoring.fileUpload.Failed'), 10);
      }
      if (response?.payload?.uploadStatusType === 'Success') {
        message.success(t('otherProducts.monitorings.creditMonitoring.fileUpload.Success'), 10);
      }
      if (response?.payload?.errorList?.length) {
        setErrorList(response.payload.errorList);
      } else setErrorList([]);
    });
  };

  const props = {
    fileList,
    onChange: info => {
      if (info.file.status === 'removed') {
        const updatedFileList = fileList.filter(file => file.uid !== info.file.uid);
        setFileList(updatedFileList);
      }
    },
    beforeUpload: file => {
      const isXLS = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (!isXLS) {
        message.error(`${file.name} ${t('otherProducts.monitorings.creditMonitoring.fileUpload.incorrect')}`);
      } else {
        setFileList(prevList => [...prevList, file]);
        setErrorList([]);
      }
      return false;
    },
  };

  const errorTypes = {
    InvalidSubjectCode: t('otherProducts.monitorings.creditMonitoring.fileUpload.InvalidSubjectCode'),
    InvalidRiskClasss: t('otherProducts.monitorings.creditMonitoring.fileUpload.InvalidRiskClasss'),
    EmptySubjectCode: t('otherProducts.monitorings.creditMonitoring.fileUpload.EmptySubjectCode'),
    Exception: t('otherProducts.monitorings.creditMonitoring.fileUpload.Exception'),
    AlreadyMonitored: t('otherProducts.monitorings.creditMonitoring.fileUpload.AlreadyMonitored'),
  };

  return (
    <Form form={form} labelCol={{ span: 24 }}>
      <CompanyDataCard
        icon={<InfoCircleOutlined />}
        title={`${t('otherProducts.monitorings.creditMonitoring.fileUpload.title')} ${t(
          'otherProducts.monitorings.creditMonitoring.fileUpload.disclaimer'
        )}`}
      >
        <Row style={{ paddingTop: 24 }} justify="space-around">
          <Col xs={24} md={24} lg={24} xl={10} xxl={10} style={{ paddingBottom: 50 }}>
            <Table
              rowKey={companyDataSource.companyCode}
              dataSource={companyDataSource}
              columns={companyTableColumns}
              pagination={false}
              size="small"
              bordered
            />
          </Col>
          <Col xs={24} md={24} lg={24} xl={10} xxl={10} style={{ paddingBottom: 50 }}>
            <Table
              rowKey={riskDataSource.riskGroupNumber}
              dataSource={riskDataSource}
              columns={riskTableColumns}
              pagination={false}
              size="small"
              bordered
            />
          </Col>
        </Row>
        <Row justify="space-around">
          <Col xs={24} md={8} lg={6} xl={6} xxl={4}>
            <Upload name="file" {...props}>
              <Button className="btn-secondary" icon={<UploadOutlined />} style={{ marginTop: '1rem' }}>
                {t('otherProducts.monitorings.creditMonitoring.fileUpload.btn.selectFile')}
              </Button>
            </Upload>
          </Col>
          <Col xs={24} md={12} lg={16} xl={16} xxl={18}>
            <Button
              style={{ marginTop: '1rem' }}
              type="primary"
              onClick={handleFileUpload}
              disabled={fileList.length === 0}
            >
              {t('global.register')}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            {errorList.length > 0 && (
              <div style={{ marginTop: '1rem' }}>
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                  {errorList.map((error, index) => (
                    <li className="color-secondary" key={index}>{`${error?.subjectCode || '-'} : ${
                      errorTypes[error?.errorType] || error?.errorType
                    }`}</li>
                  ))}
                </ul>
              </div>
            )}
          </Col>
        </Row>
      </CompanyDataCard>
    </Form>
  );
};

export default FileUpload;
