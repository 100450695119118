import { CaretRightOutlined } from '@ant-design/icons';

const ExpandableButton = ({ children, isActive, onClick }) => {
  return (
    <>
      <button className="btn-expand" onClick={onClick}>
        <CaretRightOutlined style={{ marginLeft: -3, marginRight: 2 }} rotate={isActive ? 90 : undefined} />
        {children}
      </button>
    </>
  );
};

export default ExpandableButton;
