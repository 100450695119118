import { useEffect } from 'react';
import Revenue from 'views/StartReport/FinancialData/Revenue';
import ExpertData from 'views/StartReport/FinancialData/ExpertData';
import { useDispatch, useSelector } from 'react-redux';
import { getFinancialData, getOptionalFinancialStatements } from 'store/entities/startReport/thunks';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import { Skeleton } from 'antd';
import useCurrentLanguage from '../../../hooks/user/useCurrentLanguage';
import { OptionalStatements } from './OptionalStatements';
import { useParams } from 'react-router-dom';
import SimilarCompanies from '../../../components/reusable/SimilarCompanies';

const FinancialData = () => {
  const dispatch = useDispatch();
  const lang = useCurrentLanguage();

  const { companyId } = useParams();
  const { orderId, financialData, financialDataPending, basicData } = useSelector(state => state.startReport);

  useEffect(() => {
    if (orderId) {
      dispatch(getFinancialData(orderId));
      dispatch(getOptionalFinancialStatements(companyId));
    }
  }, [orderId, lang]);

  return (
    <div>
      <LoadingSpinner spinning={financialDataPending}>
        {financialData ? (
          <>
            <Revenue />
            <ExpertData />
            <OptionalStatements />
            <SimilarCompanies similarCompanies={basicData?.similarCompany} />
          </>
        ) : (
          <Skeleton />
        )}
      </LoadingSpinner>
    </div>
  );
};

export default FinancialData;
