import React, { memo, useCallback, useMemo } from 'react';
import { Alert, Button, Col, Form, Input, Row } from 'antd';
import { EuroOutlined } from '@ant-design/icons';
import Modal from '../../../../components/reusable/Modal';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCompanyOrPersonCoveredDebtsList,
  getCompanyOrPersonUncoveredDebtsList,
  updateDebtAmount,
  updateDebtPayment,
} from '../../../../store/entities/administration/thunks';
import { checkDateValidity } from '../../../../helpers/common';

/**
 * Shows one of three available modal versions when user clicks on
 */
export const IncreaseDecreaseDebtModal = memo(({ companyOrPerson }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { selectedDebtor, debtManagementModals, updateDebtPaymentState, updateDebtAmountState } = useSelector(
    state => state.administration
  );
  const currentModalKey = Object.keys(debtManagementModals).find(key => debtManagementModals[key]);

  const refreshTables = useCallback(async () => {
    await dispatch(
      getCompanyOrPersonCoveredDebtsList({
        companyOrPerson: companyOrPerson,
      })
    );
    await dispatch(
      getCompanyOrPersonUncoveredDebtsList({
        companyOrPerson: companyOrPerson,
      })
    );
    form.resetFields();
  }, [companyOrPerson, dispatch, form, selectedDebtor.id]);

  const modalContent = useMemo(() => {
    switch (currentModalKey) {
      case 'isCoveredPartiallyCoveredOpen':
        return {
          content: (
            <>
              <Form.Item name="amount" label={t('administration.debtManagement.uncoveredDebtAmount')}>
                <Input addonAfter={<EuroOutlined />} />
              </Form.Item>

              <Form.Item
                name="paymentDate"
                label={t('professionalReport.negativeData.paymentHistory.detailedInformation.paymentDate')}
                extra={t('administration.debtManagement.company.newDebt.information.incurrence.subtitle')}
              >
                <Input />
              </Form.Item>
            </>
          ),
          modalTitle: t('common.coveredOrPartially'),
          onCloseActionType: 'TOGGLE_COVERED_PARTIALLY_COVERED_MODAL',
          onSubmit: async formVal => {
            const isDateValid = checkDateValidity(formVal.paymentDate);

            if (!isDateValid) return;

            const updateDebtReq = await dispatch(
              updateDebtPayment({
                ...formVal,
                caseNr: selectedDebtor?.debtsId,
                id: selectedDebtor?.id,
                companyOrPerson,
              })
            );

            if (!updateDebtReq.error) {
              await refreshTables();
            }
          },
          error: updateDebtPaymentState.errorMsg && (
            <Alert
              message={updateDebtPaymentState.errorMsg}
              companyOrPerson="error"
              showIcon
              style={{ marginTop: '2rem', marginBottom: '1rem' }}
            />
          ),
          isLoading: updateDebtPaymentState.loading,
        };
      case 'isIncreaseDebtOpen':
        return {
          content: (
            <>
              <Form.Item name="amount" label={t('common.debtAmountAfterIncrease')}>
                <Input addonAfter={<EuroOutlined />} />
              </Form.Item>
            </>
          ),
          modalTitle: t('common.increaseDebt'),
          onCloseActionType: 'TOGGLE_INCREASE_DEBT_MODAL',
          onSubmit: async formVal => {
            const updateDebtReq = await dispatch(
              updateDebtAmount({ ...formVal, caseNr: selectedDebtor?.debtsId, id: selectedDebtor?.id, companyOrPerson })
            );

            if (!updateDebtReq.error) {
              await refreshTables();
            }
          },
          error: updateDebtAmountState.errorMsg && (
            <Alert
              message={updateDebtAmountState.errorMsg}
              companyOrPerson="error"
              showIcon
              style={{ marginTop: '2rem', marginBottom: '1rem' }}
            />
          ),
          isLoading: updateDebtAmountState.loading,
        };
      case 'isInformAboutIncorrectDebtOpen':
        return {
          content: (
            <>
              <p>{t('common.areYouSureInformIncorrectDebt')}</p>
              <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', paddingTop: '1rem' }}>
                <Col xs={24} md={11}>
                  <Button className="btn-secondary" htmlType="submit" block>
                    {t('global.yes')}
                  </Button>
                </Col>
                <Col xs={24} md={11}>
                  <Button
                    onClick={() => dispatch({ type: modalContent.onCloseActionType, payload: false })}
                    className="btn-secondary"
                    htmlType="submit"
                    block
                  >
                    {t('global.no')}
                  </Button>
                </Col>
              </Row>
            </>
          ),
          modalTitle: t('common.informAboutIncorrectDebt'),
          onCloseActionType: 'TOGGLE_INFORM_ABOUT_INCORRECT_DEBT_MODAL',
        };
    }
  }, [debtManagementModals, updateDebtPaymentState, updateDebtAmountState, t, companyOrPerson]);

  return (
    <Modal
      isOpen={debtManagementModals[currentModalKey]}
      onCancel={() => dispatch({ type: modalContent.onCloseActionType, payload: false })}
      isLoading={modalContent?.isLoading}
      secondaryTitle={modalContent?.modalTitle}
      size="xs"
      destroyOnClose
    >
      <Form
        form={form}
        onFinish={modalContent?.onSubmit}
        layout="vertical"
        autoComplete="off"
        requiredMark={false}
        name="debtManagement"
      >
        {modalContent?.content}
        {modalContent?.error}
        {modalContent?.onSubmit && (
          <Form.Item wrapperCol={{ span: 12 }} style={{ alignItems: 'center', margin: 0, paddingTop: '0.5em' }}>
            <Button className="btn-secondary" htmlType="submit" block>
              {t('common.updatePayment')}
            </Button>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
});
