import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Tabs } from 'antd';

import DebtorsAdministration from './DebtorsAdministration';
import useRedirect from 'hooks/navigation/useRedirect';
import useLastPathname from 'hooks/navigation/useLastPathname';
const { TabPane } = Tabs;

const Administration = () => {
  const { t } = useTranslation();
  const redirect = useRedirect();
  const lastPathname = useLastPathname();

  const handleTabChange = activeKey => {
    if (activeKey !== lastPathname) {
      redirect(`/administration/${activeKey}`);
    }
  };

  return (
    <Row className="container">
      <Col span={24}>
        <Tabs defaultActiveKey={lastPathname} style={{ height: 'auto', width: '100%' }} onChange={handleTabChange}>
          <TabPane tab={t('administration.tabs.debtorsAdministration')} key="debtorsAdministration">
            <DebtorsAdministration />
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};

export default Administration;
