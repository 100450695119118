import { useTranslation } from 'react-i18next';

import CollapsibleTable from 'components/reusable/CollapsibleTable';
import TableColumnDate from 'components/reusable/TableColumnDate';
import PossibilityIndicator from 'components/reusable/PossibilityIndicator';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import { useIsMdOrLess } from '../../../hooks/dom/useMedia';
import useNumberFormatter from '../../../hooks/format/useNumberFormatter';
import { Card, Row } from 'antd';

const OpenDebtsDetails = ({ activeDebtsDetails, pdfReport }) => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();
  const screenIsMdOrLess = useIsMdOrLess();
  const formatNumber = useNumberFormatter({ minimumFractionDigits: 2, maximumFractionDigits: 2 });

  const detailedInfoColumns = [
    {
      title: t('professionalReport.negativeData.openDebts.detailedInformation.date'),
      dataIndex: 'claimDate',
      key: 'claimDate',
      render: TableColumnDate,
      sorter: (a, b) => new Date(b.claimDate) - new Date(a.claimDate),
      sortDirections: ['ascend', 'descend'],
      width: screenIsMdOrLess ? 80 : null,
    },
    {
      title: t('professionalReport.negativeData.openDebts.detailedInformation.amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: amount => {
        return formatNumber(amount);
      },
      sorter: (a, b) => a.amount - b.amount,
      sortDirections: ['ascend', 'descend'],
      width: screenIsMdOrLess ? 60 : null,
    },
    {
      title: t('professionalReport.negativeData.openDebts.detailedInformation.creditor'),
      dataIndex: 'creditor',
      key: 'creditor',
      sorter: (a, b) => a.creditor.localeCompare(b.creditor),
      sortDirections: ['ascend', 'descend'],
      width: screenIsMdOrLess ? 60 : null,
    },
    {
      title: t('professionalReport.negativeData.openDebts.detailedInformation.claimType'),
      dataIndex: 'claimType',
      key: 'claimType',
      sorter: (a, b) => a.claimType.localeCompare(b.claimType),
      sortDirections: ['ascend', 'descend'],
      width: screenIsMdOrLess ? 60 : null,
    },
    {
      title: t('professionalReport.negativeData.openDebts.detailedInformation.claimId'),
      dataIndex: 'claimId',
      key: 'claimId',
      sorter: (a, b) => a.claimId.localeCompare(b.claimId),
      sortDirections: ['ascend', 'descend'],
      width: screenIsMdOrLess ? 60 : null,
    },
  ];

  return activeDebtsDetails?.length ? (
    <CollapsibleTable
      className="space-top-md"
      title={t('professionalReport.negativeData.openDebts.detailedInformation.title')}
      rowKey={record => record.claimId}
      data={activeDebtsDetails}
      columns={detailedInfoColumns}
      pagination={isUiHidden ? false : { defaultPageSize: 10, showSizeChanger: true }}
      isCardCollapsed
      bordered
    />
  ) : pdfReport ? (
    <Row>
      <Card className="card-transparent space-vertical-sm">{t('startReport.comapnyRating.pdf.notBought')}</Card>
    </Row>
  ) : null;
};

export default OpenDebtsDetails;
