import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

const AMPLITUDE_API_KEY = '46f3f1eb07ffdb2835026ad6b205779c';
const instanceName = 'dev';

let isAmplitudeInitialized = false;

export const initializeAmplitude = () => {
  if (!AMPLITUDE_API_KEY) {
    return;
  }

  const sessionReplayTracking = sessionReplayPlugin({ sampleRate: 1 });
  amplitude.add(sessionReplayTracking);

  amplitude.init(AMPLITUDE_API_KEY, {
    serverZone: 'EU',
    instanceName,
    autocapture: {
      pageViews: false,
    },
  });

  isAmplitudeInitialized = true; // Mark Amplitude as initialized
};

export const logEvent = (eventName, eventProperties = {}) => {
  if (!isAmplitudeInitialized) return;
  amplitude.track(eventName, eventProperties);
};

export const logPageView = (path, url, queryParams, language, authentification) => {
  if (!isAmplitudeInitialized) return;
  logEvent('Page Viewed', {
    'page path': path,
    'page url': url,
    'query params': queryParams,
    language,
    'logged in': authentification,
  });
};

export const logSelectedProduct = value => {
  if (!isAmplitudeInitialized) return;
  logEvent('Product Select', { ...value });
};

export const logSearchResults = value => {
  if (!isAmplitudeInitialized) return;
  logEvent('Search Results', { ...value });
};

export const logError = (errorType, value) => {
  if (!isAmplitudeInitialized) return;
  logEvent('Error', {
    typeOfError: errorType,
    ...value,
  });
};
