import { createSelector } from 'reselect';
import _pick from 'lodash.pick';

import { flattenObject } from 'utils/data';

import { financialRatiosValues } from 'constants/report';

export const revenueTable = createSelector(
  state => state.startReport?.financialData,
  financialData => {
    const { financeDetails, lang } = financialData || {};
    const { incomes = [], profits = [] } = financeDetails || {};
    const { format } = new Intl.NumberFormat(lang);

    const incomeTableData = { revenue: '0 - ...' };
    incomes.forEach(
      ({ amount, financialYear }) =>
        (incomeTableData[`${new Date(financialYear).getFullYear()}`] =
          amount < 0 ? `-${format(Math.abs(amount))}` : format(amount))
    );

    const profitsTableData = { income: '0 - ...' };
    profits.forEach(
      ({ amount, financialYear }) =>
        (profitsTableData[`${new Date(financialYear).getFullYear()}`] =
          amount < 0 ? `-${format(Math.abs(amount))}` : format(amount))
    );

    return { revenueTableData: [incomeTableData], incomes, profitsTableData: [profitsTableData] };
  }
);

export const profitMargin = createSelector(
  state => state.startReport?.financialData,
  financialData => {
    const { financialProfitMargin = [] } = financialData || {};
    const tableData = { profitMargin: '%' };

    financialProfitMargin.forEach(
      ({ financialYear, profitMargin }) => (tableData[financialYear] = profitMargin?.companies)
    );

    return {
      profitMarginChart: financialProfitMargin
        .map(({ financialYear, profitMargin }) => ({
          date: new Date(`${financialYear}-01-01`),
          profitMargin: profitMargin?.companies,
        }))
        .reverse(),
      profitMarginTable: [tableData],
      financialProfitMargin,
    };
  }
);

export const companyProfitAndIncomeChart = createSelector(
  state => state.startReport?.financialData,
  financialData => {
    const { financeDetails } = financialData || {};
    const { profits = [], incomes = [] } = financeDetails || {};

    return incomes.map(({ financialYear, amount: income }) => {
      const profit = profits.find(({ financialYear: matchingYear }) => financialYear === matchingYear)?.amount ?? null;
      return { date: financialYear, income, profit };
    });
  }
);

export const financialDataStatement = createSelector(
  state => state.startReport?.financialData,
  financialData => {
    const { financialStatementDetails = [], financialStatement = {}, lang } = financialData || {};
    const { format } = new Intl.NumberFormat(lang);

    return {
      financialStatement,
      financialStatementTable: financialStatementDetails.length
        ? financialStatementDetails[0].financeStatementDetails.map(({ number, index, categoryName }) => ({
            number,
            index,
            categoryName,
            ...flattenObject(
              financialStatementDetails.map(({ financialYear, financeStatementDetails }) => {
                const { result } = financeStatementDetails.find(({ index: matchingIndex }) => index === matchingIndex);

                return {
                  [`${financialYear}`]: result !== undefined ? format(result) : '-',
                };
              })
            ),
          }))
        : [],
      statementTimeline: financialStatementDetails.map(({ financialYear, accountPeriodLength }) => ({
        financialYear,
        accountPeriodLength,
      })),
    };
  }
);

export const financialRatiosTable = createSelector(
  state => state.startReport?.financialData,
  financialData => {
    const { financialRatios = [] } = financialData || {};

    return {
      financialRatiosTableData: financialRatiosValues.map(ratio => ({
        ratio,
        ...flattenObject(
          financialRatios.map(dataOfYear => {
            const { financialYear } = dataOfYear;
            const ratioData = dataOfYear[ratio];

            return {
              [`companies${financialYear}`]: ratioData?.companies,
              [`sector${financialYear}`]: ratioData?.sector,
              [`sectorForecasted${financialYear}`]: ratioData?.sectorForecasted,
            };
          })
        ),
      })),
      ratioTimeline: financialRatios.map(dataOfYear =>
        _pick(dataOfYear, ['financialYear', 'financialMonth', 'hasRatiosForecasted'])
      ),
    };
  }
);
