import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Breadcrumb } from 'antd';
import { breadcrumbData } from 'store/entities/companySearchResult/selectors/unregisteredReportSelector';

const CompanySearchResultBreadcrumb = props => {
  const { match, location } = props;
  const { t } = useTranslation();

  const { page, companyCode, companyName } = useSelector(breadcrumbData);
  const { selectedCompanyData } = useSelector(state => state.company);

  const companyIdOrName = selectedCompanyData?.name ?? match.params.companyId;
  const breadcrumbsArr = location.pathname.split('/').slice(1);

  if (!selectedCompanyData?.name) return null;

  return location.pathname
    .split('/')
    .slice(1)
    .map((urlPart, i, arr) => (
      <Breadcrumb.Item key={`breadcrumbItem-${i}`} href={`/${breadcrumbsArr.slice(0, i + 1).join('/')}`}>
        {urlPart === match.params.companyId
          ? parseInt(urlPart) === companyCode
            ? companyName
            : companyIdOrName
          : t(`header.breadcrumb.${urlPart}${i === arr.length - 1 ? `.${page}` : ''}`)}
      </Breadcrumb.Item>
    ));
};

export default CompanySearchResultBreadcrumb;
