import { useSelector } from 'react-redux';

const useIsFinancialReportLoading = () => {
  const { orderIdPending } = useSelector(state => state.companyFinancialReport);
  const { basicDataPending, financialStatementPdfPending, companyYearFinReportPending } = useSelector(
    state => state.companyFinancialReport
  );
  const { financialDataPending } = useSelector(state => state.professionalReport);

  return {
    isLoading:
      orderIdPending ||
      basicDataPending ||
      financialDataPending ||
      financialStatementPdfPending ||
      companyYearFinReportPending,
  };
};

export default useIsFinancialReportLoading;
