import { createSelector } from 'reselect';
import _pick from 'lodash.pick';

export const negativeDataOpenDebts = createSelector(
  state => state.professionalReport?.negativeData,
  negativeData => _pick(negativeData, ['activeDebts', 'activeDebtsDetails'])
);

export const negativeDataPaymentHistory = createSelector(
  state => state.professionalReport?.negativeData,
  negativeData => _pick(negativeData, ['paidDebts', 'paidDebtsDetails'])
);

export const negativeDataDebtCollections = createSelector(
  state => state.professionalReport?.negativeData,
  negativeData => _pick(negativeData, ['debtCollection', 'debtCollectionDetail'])
);

export const negativeDataPropertyArrests = createSelector(
  state => state.professionalReport?.negativeData,
  negativeData =>
    _pick(negativeData, [
      'registeredArrests',
      'registeredArrestsDetails',
      'unregisteredArrests',
      'unregisteredArrestsDetails',
      'arrestAndMortageStatements',
    ])
);

export const negativeDataLegalProceedings = createSelector(
  state => state.professionalReport?.negativeData,
  negativeData =>
    _pick(negativeData, [
      'legalProceeding',
      'legalProceedingsDetailedDefendant',
      'legalProceedingsDetailedPlaintiff',
      'legalProceedingsDetailedThirdParty',
      'legalProceedingOther',
      'legalProceedingsDetailedOther',
    ])
);

export const negativeDataBailiffsInformation = createSelector(
  state => state.professionalReport?.negativeData,
  negativeData => _pick(negativeData, ['bailiffsRemainders', 'bailiffsRemaindersDetails'])
);

export const negativeDataUnreliableCompanies = createSelector(
  state => state.professionalReport?.negativeData,
  negativeData => _pick(negativeData, ['unreliableCompanies', 'unreliableCompaniesDetails'])
);

export const negativeDataSodra = createSelector(
  state => state.professionalReport?.negativeData,
  negativeData => _pick(negativeData, ['sodraDebts', 'sodraDebtsHistory'])
);

export const negativeDataVmi = createSelector(
  state => state.professionalReport?.negativeData,
  negativeData => _pick(negativeData, ['vmiDebt', 'vmiDebtsHistory'])
);
