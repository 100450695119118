import LoadingSpinner from 'components/reusable/LoadingSpinner';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getFinancialOrderId } from 'store/entities/companyFinancialReport/thunks';
import { setIsUiHidden } from 'store/entities/ui';
import FinancialStatementDetailedData from 'views/FinancialStatements/FinancialStatementDetailedData';
import { getSelectedCompanyData } from 'store/entities/company/thunks';
import useCurrentLanguage from 'hooks/user/useCurrentLanguage';
import useIsFinancialReportLoading from '../../hooks/report/useFinancialReportState';
import PdfHeader from '../Pdfs/PdfHeader';

const FinancialStatementsPdf = () => {
  const { companyId, statementId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lang = useCurrentLanguage();
  const { selectedCompanyData } = useSelector(state => state.company);
  const { orderId } = useSelector(state => state.companyFinancialReport);
  const { isLoading } = useIsFinancialReportLoading();

  useEffect(() => {
    dispatch(setIsUiHidden(true));
    return () => {
      dispatch(setIsUiHidden(false));
    };
  }, []);

  const request = {
    year: 0,
    financialStatementId: statementId,
  };

  useEffect(() => {
    dispatch(getFinancialOrderId({ companyCode: companyId, request }));
  }, []);

  useEffect(() => {
    companyId && dispatch(getSelectedCompanyData(companyId));
  }, [companyId, lang]);

  return (
    <LoadingSpinner size="large" className="loader-overlay" spinning={isLoading}>
      <div className="pdf-wrapper">
        <PdfHeader
          title={t(selectedCompanyData?.name)}
          subtitle={t('professionalReport.financialData.periodOfStatement.title')}
        />
        {orderId && <FinancialStatementDetailedData pdf />}
      </div>
    </LoadingSpinner>
  );
};

export default FinancialStatementsPdf;
