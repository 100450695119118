import { Col, Row, Typography } from 'antd';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getBasicData, getOrderId } from 'store/entities/companyCreditLimitReport/thunks';
import { setIsUiHidden } from 'store/entities/ui';
import CompanyCreditLimitReport from './CompanyCreditLimitReport';
import PdfHeader from '../Pdfs/PdfHeader';

const CompanyCreditLimitPdf = () => {
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { orderId, basicData, basicDataPending } = useSelector(state => state.companyCreditLimitReport);

  useEffect(() => {
    dispatch(setIsUiHidden(true));
    return () => {
      dispatch(setIsUiHidden(false));
    };
  }, []);

  useEffect(() => {
    dispatch(getOrderId(companyId));
  }, []);

  useEffect(() => {
    if (orderId) {
      dispatch(getBasicData(orderId));
    }
  }, [orderId]);

  return (
    <LoadingSpinner size="large" className="loader-overlay" spinning={basicDataPending}>
      <div className="pdf-wrapper">
        <PdfHeader title={basicData?.basicInformation?.companyName} subtitle={t('companyCreditLimit.title')} />
        {orderId && <CompanyCreditLimitReport pdfReport={true} />}
      </div>
    </LoadingSpinner>
  );
};

export default CompanyCreditLimitPdf;
