import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Typography, Divider } from 'antd';
import useWindowSize from '../../../hooks/dom/useWindowSize';
import { PhoneOutlined, MailOutlined, ClockCircleOutlined, EnvironmentOutlined } from '@ant-design/icons';

const contactData = [
  {
    label: 'login.contactUs.companies',
    phone: '+370 5 239 4131',
    email: 'ltu.info@creditinfo.com',
  },
  {
    label: 'login.contactUs.residents',
    phone: '+370 5 239 4149',
    email: 'info@manocreditinfo.lt',
  },
  {
    label: 'login.contactUs.media',
    phone: '+370 686 49154',
    email: 'ausrine.motiejuniene@creditinfo.com',
  },
];

const ContactUsDiv = () => {
  const { t } = useTranslation();
  const { Paragraph } = Typography;
  const { width: windowWidth } = useWindowSize();

  const IconParagraph = ({ icon, text, type }) => {
    const handleClick = () => {
      switch (type) {
        case 'phoneNumber':
          handlePhoneClick(text);
          break;
        case 'emailAddress':
          handleEmailClick(text);
          break;
        case 'address':
          handleAddressClick(text);
          break;
        default:
          break;
      }
    };

    const handlePhoneClick = phoneNumber => {
      window.location.href = `tel:${phoneNumber.replace(/\s/g, '')}`;
    };

    const handleEmailClick = emailAddress => {
      window.location.href = `mailto:${emailAddress}`;
    };

    const handleAddressClick = address => {
      const encodedAddress = encodeURIComponent(address);
      const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
      window.open(mapsUrl, '_blank');
    };

    const renderLink = (linkType, linkText) => {
      return (
        <a
          href={linkType === 'phoneNumber' ? `tel:${linkText.replace(/\s/g, '')}` : ''}
          style={{ color: 'inherit', textDecoration: 'none' }}
        >
          {linkText}
        </a>
      );
    };

    return (
      <Paragraph className="unauthenticated-contact-us__icon-paragraph" onClick={handleClick}>
        {icon}
        {type === 'phoneNumber' || type === 'emailAddress' ? renderLink(type, text) : text}
      </Paragraph>
    );
  };

  return (
    <div className="unauthenticated-contact-us">
      <Row>
        <Col span={24}>
          <Paragraph>
            <strong>{t('login.contactUs.bureau')}</strong>
          </Paragraph>
        </Col>
        <Col span={24}>
          <IconParagraph
            icon={<EnvironmentOutlined />}
            type="address"
            text="Lvivo g. 21A, LT-09309 Vilnius, LITHUANIA"
          ></IconParagraph>
        </Col>
        <Col span={24}>
          <IconParagraph icon={<ClockCircleOutlined />} text="I-IV 8-17, V 8-15:45"></IconParagraph>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={22}>
          <Divider />
        </Col>
      </Row>
      <Row>
        {contactData.map(item => (
          <>
            <Col xs={24} md={windowWidth > 767 ? 24 : 8}>
              <Col xs={24} className="justify-content-start">
                <Paragraph>
                  <strong>{t(item.label)}</strong>
                </Paragraph>
              </Col>
              <Col xs={24} className="justify-content-start">
                <IconParagraph type="phoneNumber" icon={<PhoneOutlined />} text={item.phone}></IconParagraph>
              </Col>
              <Col xs={24} className="justify-content-start">
                <IconParagraph type="emailAddress" icon={<MailOutlined />} text={item.email}></IconParagraph>
              </Col>
            </Col>
          </>
        ))}
      </Row>
    </div>
  );
};

export default ContactUsDiv;
