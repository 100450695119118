import React from 'react';
import { Spin } from 'antd';
import { ReactComponent as CiIcon } from 'assets/img/ci-symbol-i.svg';

const LoadingSpinner = ({ children, ...props }) => (
  <Spin {...props} indicator={<Loader />}>
    {children}
  </Spin>
);

const Loader = () => (
  <div className="loading-spinning-bubbles">
    <CiIcon
      style={{
        height: '55px',
        width: '50px',
        position: 'fixed',
        top: 'calc(50% - 32px)',
        left: 'calc(50% - 27px)',
        opacity: '1',
        zIndex: 999,
      }}
    />
    <div className="bubble-container" style={{ visibility: 'hidden' }}>
      <div className="bubble"></div>
    </div>
    <div className="bubble-container">
      <div className="bubble"></div>
    </div>
    <div className="bubble-container">
      <div className="bubble"></div>
    </div>
    <div className="bubble-container">
      <div className="bubble"></div>
    </div>
    <div className="bubble-container">
      <div className="bubble"></div>
    </div>
    <div className="bubble-container">
      <div className="bubble"></div>
    </div>
    <div className="bubble-container">
      <div className="bubble"></div>
    </div>
    <div className="bubble-container">
      <div className="bubble"></div>
    </div>
  </div>
);

export default LoadingSpinner;
