import React from 'react';
import { useTranslation } from 'react-i18next';

import useIsUiHidden from 'hooks/dom/useIsUiHidden';

import { Table } from 'antd';
import CustomExpandIcon from './CustomExpandIcon';

const ReportsTable = ({ reports }) => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();

  const numberOfReportsColumns = [
    {
      title: '',
      dataIndex: 'sectorTitle',
      render: (text, row) => (row.nested ? <strong>{text}</strong> : <span style={{ paddingLeft: 10 }}>{text}</span>),
      key: 'sectorTitle',
      width: 250,
    },
    {
      title: t('global.inTheLastMonth'),
      dataIndex: 'lastMonth',
      align: 'center',
      key: 'lastMonth',
      render: (text, row) =>
        row.nested ? <strong>{text}</strong> : <span style={{ paddingLeft: isUiHidden && 12 }}>{text}</span>,
    },
    {
      title: t('global.inLastMonths', { months: 3 }),
      dataIndex: 'lastQuarter',
      align: 'center',
      key: 'lastQuarter',
      render: (text, row) =>
        row.nested ? <strong>{text}</strong> : <span style={{ paddingLeft: isUiHidden && 2 }}>{text}</span>,
    },
    {
      title: t('global.inLastMonths', { months: 6 }),
      dataIndex: 'lastHalfYear',
      align: 'center',
      key: 'lastHalfYear',
      render: (text, row) =>
        row.nested ? <strong>{text}</strong> : <span style={{ paddingRight: isUiHidden && 10 }}>{text}</span>,
    },
  ];

  return (
    <Table
      rowKey={({ sectorTitle }) => `report-${sectorTitle}`}
      dataSource={reports}
      columns={numberOfReportsColumns}
      pagination={false}
      className="ant-table--dark"
      expandIcon={props => CustomExpandIcon(props)}
      expandable={{
        expandedRowRender: record => (
          <Table
            rowKey={record => record?.nested?.sectorTitle}
            dataSource={record?.nested}
            columns={numberOfReportsColumns}
            scroll={{ x: 'max-content' }}
            pagination={false}
            className="ant-table--nested"
          />
        ),
        rowExpandable: record => record.nested,
        defaultExpandAllRows: isUiHidden,
      }}
    />
  );
};

export default ReportsTable;
