import CompanyDataCard, { CompanyDataRow } from '../../CompanyDataCard';
import { PieChartOutlined } from '@ant-design/icons';
import { Col } from 'antd';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useNumberFormatter from '../../../../hooks/format/useNumberFormatter';

export const ProcurementInformation = ({ wonPublicProcurementTenders, announcedPublicProcurementTenders }) => {
  const { t } = useTranslation();
  const formatCurrency = useNumberFormatter();

  return (
    <CompanyDataCard
      icon={<PieChartOutlined className="icon--gray" />}
      title={t('startReport.basic.public.procurementInformation.title')}
    >
      <Col>
        <CompanyDataRow
          value={t('startReport.basic.procurementInformation.wonPublicProcurementTenders.title')}
          extraClass="fw-600"
        />
        <CompanyDataRow
          type={t('startReport.basic.procurementInformation.wonPublicProcurementTenders.contractsWorth')}
          value={
            wonPublicProcurementTenders?.contractsWorth
              ? t('startReport.basic.procurementInformation.wonPublicProcurementTenders.contractsWorth.value', {
                  contractsWorth: formatCurrency(wonPublicProcurementTenders?.contractsWorth),
                })
              : t('startReport.basic.procurementInformation.noInformation')
          }
        />
        <CompanyDataRow
          type={t('startReport.basic.procurementInformation.wonPublicProcurementTenders.numberOfTenders')}
          value={
            wonPublicProcurementTenders?.numberOfTenders
              ? t('startReport.basic.procurementInformation.wonPublicProcurementTenders.numberOfTenders.value', {
                  numberOfTenders: wonPublicProcurementTenders?.numberOfTenders,
                })
              : t('startReport.basic.procurementInformation.noInformation')
          }
        />
        <p></p>

        <CompanyDataRow
          value={t('startReport.basic.procurementInformation.announcedPublicProcurementTenders.title')}
          extraClass="fw-600"
        />
        <CompanyDataRow
          type={t('startReport.basic.procurementInformation.announcedPublicProcurementTenders.contractsWorth')}
          value={
            announcedPublicProcurementTenders?.contractsWorth
              ? t('startReport.basic.procurementInformation.announcedPublicProcurementTenders.contractsWorth.value', {
                  contractsWorth: formatCurrency(announcedPublicProcurementTenders?.contractsWorth),
                })
              : t('startReport.basic.procurementInformation.noInformation')
          }
        />
        <CompanyDataRow
          type={t('startReport.basic.procurementInformation.announcedPublicProcurementTenders.numberOfTenders')}
          value={
            announcedPublicProcurementTenders?.numberOfTenders
              ? t('startReport.basic.procurementInformation.announcedPublicProcurementTenders.numberOfTenders.value', {
                  numberOfTenders: announcedPublicProcurementTenders?.numberOfTenders,
                })
              : t('startReport.basic.procurementInformation.noInformation')
          }
        />
        <p></p>
        <CompanyDataRow disclaimer={t('startReport.basic.procurementInformation.disclaimer')} />
        <CompanyDataRow disclaimer={t('startReport.basic.procurementInformation.dataSource')} />
      </Col>
    </CompanyDataCard>
  );
};
