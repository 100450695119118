import React from 'react';
import { useSelector } from 'react-redux';

import Prescore from 'views/CompanySearchResult/components/Report/Prescore';
import StrongestCompanyCard from 'components/reusable/StrongestCompanyCard';
import Summary from 'views/CompanySearchResult/components/Report/Summary';
import Contact from 'views/CompanySearchResult/components/Report/Contact';
import BuyReportLink from './BuyReportLink';

import { Col, Row, Typography } from 'antd';
import RegisterConsultation from './RegisterConsultation';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

const Report = ({ handleBuyReport }) => {
  const { t } = useTranslation();
  const { report } = useSelector(state => state.companySearchResult);

  if (report)
    return (
      <>
        <Summary />
        <Row>
          <Contact />
          <BuyReportLink handleBuyReport={handleBuyReport} />
        </Row>
        {report?.strongest?.hasStrongestCertificate && (
          <StrongestCompanyCard type={report?.strongest?.certificateType} year={report?.strongest?.yearOfCertificate} />
        )}
        <Row>
          <BuyReportLink removeOnLargeScreen handleBuyReport={handleBuyReport} />
          <Prescore />
        </Row>
        <Row>
          <RegisterConsultation />
          <BuyReportLink handleBuyReport={handleBuyReport} />
        </Row>
      </>
    );
  return (
    <Row gutter={[16, 16]}>
      <Col span={24} className="company-title">
        <Title level={2}>{t('companyResult.report.noReport')}</Title>
      </Col>
    </Row>
  );
};

export default Report;
