import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';

const LazyLoader = ({ children, defaultValue = false }) => {
  const [isVisible, setIsVisible] = useState(defaultValue);

  const waypoint = <Waypoint onEnter={() => setIsVisible(true)} />;

  return children(waypoint, isVisible);
};

export default LazyLoader;
