import React, { useMemo } from 'react';
import i18next from 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import FinancialReportTable from '../../views/Pdfs/FinancialReportPdf/FinancialReportTable';
import FinancialStatementCard from './report/cards/FinancialStatementCard';
import useCurrentLanguage from '../../hooks/user/useCurrentLanguage';
import helpers from '../../helpers';
import { flattenObject } from '../../utils/data';

const getStatementColumns = ([detailsOneYear, detailsTwoYears]) => {
  const columns = [
    {
      title: i18next.t('professionalReport.financialData.no'),
      dataIndex: 'number',
      width: '5%',
      align: 'center',
    },
    {
      title: i18next.t('professionalReport.financialData.index'),
      dataIndex: 'index',
      width: '38%',
    },
  ];

  if (detailsOneYear) {
    columns.push({
      title: (
        <Trans
          i18nKey="global.valueEur"
          values={{
            value: `${detailsOneYear.financialYear ?? ''} / ${detailsOneYear.accountPeriodLength ?? ''}`,
          }}
        />
      ),
      dataIndex: detailsOneYear.financialYear ?? '',
      key: `detailsOneYear-${detailsOneYear.financialYear ?? ''}`, // Use a unique key based on the index
      width: '19%',
      align: 'right',
    });
  }

  if (detailsTwoYears) {
    columns.push({
      title: (
        <Trans
          i18nKey="global.valueEur"
          values={{
            value: `${detailsTwoYears.financialYear ?? '-'} / ${detailsTwoYears.accountPeriodLength ?? '-'}`,
          }}
        />
      ),
      dataIndex: detailsTwoYears.financialYear ? detailsTwoYears.financialYear : '-',
      key: `detailsTwoYears-${detailsTwoYears.financialYear ?? ''}`, // Use a unique key based on the index
      width: '14%',
      align: 'right',
    });
  }

  return columns;
};

export const DetailedFinancialStatements = ({ financialStatement, statement, profReport = false }) => {
  const { t } = useTranslation();
  const lang = useCurrentLanguage();
  const { format } = new Intl.NumberFormat(lang);

  // Convert financialStatement to an array if it's not
  const financialStatementArray = Array.isArray(financialStatement) ? financialStatement : [financialStatement];

  const financialStatementTablesData = useMemo(() => {
    if (
      !financialStatementArray ||
      !financialStatementArray?.length ||
      !financialStatementArray[0]?.financeStatementDetails
    ) {
      return [];
    }

    return financialStatementArray[0]?.financeStatementDetails.map(({ number, index, standardName, rowId }) => ({
      number,
      index,
      standardName,
      rowId,
      ...flattenObject(
        financialStatementArray.map(({ financialYear, financeStatementDetails }) => {
          const matchingItem = financeStatementDetails?.find(
            ({ index: matchingIndex, rowId: matchingRowId }) => index === matchingIndex && rowId === matchingRowId
          );
          const formattedResult = matchingItem?.result !== undefined ? format(matchingItem.result) : '-';

          return {
            [`${financialYear}`]: formattedResult,
          };
        })
      ),
    }));
  }, [financialStatementArray, format]);

  if (!financialStatementTablesData?.length) return <></>;

  const [tableOne, tableTwo, tablethree] = helpers.groupBy(financialStatementTablesData, 'standardName');

  const statementTimeline = financialStatementArray?.length
    ? financialStatementArray
        .filter(({ financialYear, accountPeriodLength }) => financialYear && accountPeriodLength)
        .map(({ financialYear, accountPeriodLength }) => ({
          financialYear,
          accountPeriodLength,
        }))
    : null;

  const financialReportTableData = [
    { data: tableOne, title: tableOne?.length && tableOne[0]?.standardName },
    { data: tableTwo, title: tableTwo?.length && tableTwo[0]?.standardName },
    { data: tablethree, title: tablethree?.length && tablethree[0]?.standardName },
  ];

  return financialStatementArray ? (
    <div key={`DetailedFinancialStatements ${financialStatementArray[0]?.financialStatementId}`}>
      <FinancialStatementCard
        financialStatement={statement}
        profReport={profReport}
        children={
          <>
            {financialReportTableData.map(report =>
              report?.title ? (
                <FinancialReportTable
                  columns={getStatementColumns(statementTimeline)}
                  tableData={report.data}
                  title={report.title}
                />
              ) : null
            )}
          </>
        }
      />
    </div>
  ) : null;
};
