import { fetchFromApi, optionsForGetRequest, optionsForPostRequest } from '.';

const getOrderId = (companyCode, request) =>
  fetchFromApi(`/company/${companyCode}/report/financial/order`, optionsForPostRequest(request));

const getBasicData = companyCode =>
  fetchFromApi(`/company/${companyCode}/report/financial/basic`, optionsForGetRequest());

const getFinancialStatementPdf = orderId =>
  fetchFromApi(`/company/${orderId}/report/financial/pdf/preview`, optionsForGetRequest());

const getCompanyFinReportPerYear = orderId =>
  fetchFromApi(`/company/${orderId}/report/financial/per-year`, optionsForGetRequest());

export default {
  getOrderId,
  getBasicData,
  getFinancialStatementPdf,
  getCompanyFinReportPerYear,
};
