import React, { memo, useCallback, useMemo } from 'react';
import { Alert, Button, Col, Form, Input, Row } from 'antd';
import Modal from '../../../../components/reusable/Modal';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getDebtorInformation } from '../../../../store/entities/administration/thunks';

export const ChooseDebtorModal = ({ handlePrefillForm }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { chooseDebtorModalOpen, debtorInformation, debtorInformationLoading, debtorInformationErrorMsg } = useSelector(
    state => state.administration
  );

  return (
    <Modal
      isOpen={chooseDebtorModalOpen}
      onCancel={() => dispatch({ type: 'TOGGLE_CHOOSE_DEBTOR_MODAL', payload: false })}
      secondaryTitle={t('common.search')}
      size="xs"
      destroyOnClose
    >
      <Form
        form={form}
        onFinish={formVal => dispatch(getDebtorInformation(formVal))}
        layout="vertical"
        autoComplete="off"
        requiredMark={false}
        name="debtManagement"
      >
        <Form.Item
          name="companyCode"
          label={t('common.code')}
          rules={[{ required: true, message: t('common.enterCompanyCode') }]}
          validateStatus={debtorInformationErrorMsg ? 'error' : ''}
          help={debtorInformationErrorMsg && t('common.incorrectCompanyCode')}
        >
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 12 }} style={{ alignItems: 'end', margin: 0, paddingTop: '0.5em' }}>
          <Button className="btn-secondary" htmlType="submit" loading={debtorInformationLoading} block>
            {debtorInformation ? t('common.newSearch') : t('common.search')}
          </Button>
        </Form.Item>
        {debtorInformation?.companyName && (
          <Form.Item name="company" label={t('global.title')}>
            <Input defaultValue={debtorInformation.companyName} />
          </Form.Item>
        )}
        {debtorInformation?.companyCode && (
          <Form.Item name="company" label={t('global.code')}>
            <Input defaultValue={debtorInformation.companyCode} />
          </Form.Item>
        )}
      </Form>
      {debtorInformation?.companyCode && (
        <Row style={{ flexDirection: 'column', margin: 0, paddingTop: '0.5em' }}>
          <Col span={12} style={{ marginLeft: 'auto' }}>
            <Button
              onClick={handlePrefillForm}
              className="btn-secondary"
              htmlType="submit"
              loading={debtorInformationLoading}
              block
            >
              {t('common.add')}
            </Button>
          </Col>
        </Row>
      )}
    </Modal>
  );
};
