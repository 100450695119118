import { createPortal } from 'react-dom';
import React, { useState } from 'react';
import { deepClone, updateGroupById, insertChildNodes, removeChildNodes } from '../../utils/helpers';
import { HoverableIcons } from './HoverableIcons';
import { ExpandCollapseBtn } from './ExpandCollapseBtn';
import { initTreeData } from '../../utils/data-helpers';
import { useDispatch } from 'react-redux';
import { getChildCompany } from '../../../../../../store/entities/spiderWeb/thunks';
import { ReactComponent as ProductSearch } from '../../assets/groupIcons/product-search.svg.svg';
import useFormatDate from '../../../../../../hooks/format/useFormatDate';
import { Trans } from 'react-i18next';

export const HierarchyGroup = ({ item, setGraphData }) => {
  const formatDate = useFormatDate();
  const sameCourtTitle =
    item.parent.type === 'sameCourtRelations' ? (
      <Trans
        i18nKey="spiderweb.group.courtTitle"
        values={{
          date: formatDate(item.groupTitle),
        }}
      />
    ) : null;
  const [loading, setLoading] = useState(false);
  const container = document.getElementById(item.id);
  const dispatch = useDispatch();
  const isSelectedGroupExpanded = item.expanded;
  const isRootNode = item.parent.type === 'rootItem';
  const hrefLink = item.isPerson
    ? `/search/individual/${item.apiID ?? item.groupTitle}`
    : `/search/company/${item.apiID ?? item.groupTitle}`;
  if (!container) return null;

  const companyCountryCheck = (country, companyCode) => {
    if (country === 'NL') {
      if (companyCode.length === 8) {
        return companyCode;
      } else return companyCode.slice(0, 8);
    } else return companyCode;
  };

  const getNestedData = async () => {
    if (isSelectedGroupExpanded) {
      setGraphData(prev => {
        const dataClone = deepClone(prev);

        removeChildNodes(dataClone, item.parent.id);
        updateGroupById(dataClone, item.id, { expanded: false });

        return dataClone;
      });
      return false;
    }

    try {
      setLoading(true);
      const checkForGroupSubTitle =
        item.parent.type === 'executiveRelations' ||
        item.parent.type === 'boardMemberRelations' ||
        item.parent.type === 'sameRegistrationAddressRelations' ||
        item.parent.type === 'sameWebRelations' ||
        item.parent.type === 'sameCourtRelations';
      const searchRequest = {
        companyCode: item.isPerson ? '' : companyCountryCheck(item?.country ? item.country : 'LT', item.apiID),
        companyName: item.isPerson ? '' : checkForGroupSubTitle ? item.groupSubtitle : item.groupTitle,
        personCode: item.isPerson ? item.apiID : '',
        personName: item.isPerson ? (checkForGroupSubTitle ? item.groupSubtitle : item.groupTitle) : '',
        country: item?.country ? item.country : 'LT',
        relationTypes: [255],
      };
      const res = await dispatch(getChildCompany(searchRequest));
      if (getChildCompany.fulfilled.match(res)) {
        const formatted = initTreeData(res.payload);

        setGraphData(prev => {
          const dataClone = deepClone(prev);

          // If one of the groups in the same node is expanded, un-expand it
          const expandedGroup = item.parent.group.find(group => group.expanded);
          if (expandedGroup) {
            removeChildNodes(dataClone, item.parent.id);
            updateGroupById(dataClone, expandedGroup.id, { expanded: false });
          }
          insertChildNodes(dataClone, item.parent.id, formatted);
          updateGroupById(dataClone, item.id, { expanded: true });

          return dataClone;
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return createPortal(
    <div className="node__group-item__content">
      <div className="node__group-item__content__product-search">
        <a href={hrefLink} target="_blank" rel="noopener noreferrer">
          <ProductSearch />
        </a>
      </div>
      <div className="node__group-item__heading">
        {sameCourtTitle ? sameCourtTitle : item.groupTitle && item.groupTitle}
      </div>
      <div className="node__group-item__content--subtitle">
        {/* TODO - After BE alvays return share value, fix this part and remove additional operators */}
        {item.groupSubtitle && item.groupTitle !== item.groupSubtitle
          ? item.groupSubtitle
          : item.parent.type !== 'founderRelations' && item.parent.type !== 'rootItem' && '? %'}
      </div>
      <div>{item.isHistorical && `(${formatDate(item.fromDate)} - ${formatDate(item.toDate)})`}</div>
      <HoverableIcons data={item.hoverableIcons} id={item.id} />
      {!(item.hoverableIcons?.foreigner || item.hoverableIcons?.unknownCode || isRootNode) && (
        <div className="node__group-item__btn-position">
          <ExpandCollapseBtn
            onClick={() => getNestedData(item.id)}
            iconSign={!isSelectedGroupExpanded ? 'plus' : 'minus'}
          />
        </div>
      )}
      {loading && (
        <div className="loading-spinner-container">
          <div className="loading-spinner" />
        </div>
      )}
    </div>,
    container
  );
};
