import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useRedirect from 'hooks/navigation/useRedirect';
import usePrescoreReportState from 'hooks/report/usePrescoreReportState';
import CompanyDataBlock from 'views/Search/CompanySearch/CompanyDataBlock';
import { getSelectedCompanyData } from 'store/entities/company/thunks';
import useCurrentLanguage from 'hooks/user/useCurrentLanguage';

import ReportHeader from 'components/reusable/report/ReportHeader';
import { getOrderId, getPrescoreReportPdf } from 'store/entities/prescoreReport/thunks';
import { resetPrescoreReport } from 'store/entities/prescoreReport';
import LoadingSpinner from 'components/reusable/LoadingSpinner';

import Data from './Data';

import { Col } from 'antd';
import { DownloadPdfBtn } from '../../components/reusable/report/buttons';
import PdfFooterDisclaimer from '../Pdfs/PdfFooterDisclaimer';

const PrescoreReport = () => {
  const { companyId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const lang = useCurrentLanguage();
  const { isLoading } = usePrescoreReportState();

  const { orderId, prescoreReportPdfLoading } = useSelector(state => state.prescoreReport);
  const { selectedCompanyData } = useSelector(state => state.company);

  useEffect(() => {
    companyId && dispatch(getSelectedCompanyData(companyId));
  }, [companyId, lang]);

  const orderReport = useCallback(async () => {
    const orderIdResult = await dispatch(getOrderId(companyId));
    if (!getOrderId.fulfilled.match(orderIdResult)) {
      redirect('/search/company');
    }
  }, [dispatch, redirect, getOrderId]);

  useEffect(() => {
    orderReport();

    return () => dispatch(resetPrescoreReport());
  }, []);

  return (
    <LoadingSpinner size="large" spinning={isLoading || prescoreReportPdfLoading}>
      <div className="prescore-report container">
        <Col span={24}>
          <ReportHeader
            title={selectedCompanyData?.name}
            actions={
              <DownloadPdfBtn fetcher={() => dispatch(getPrescoreReportPdf({ orderId }))} reportType="prescore" />
            }
          />
          {selectedCompanyData && <CompanyDataBlock companyData={selectedCompanyData} />}
        </Col>
        {orderId && <Data />}
        <PdfFooterDisclaimer />
      </div>
    </LoadingSpinner>
  );
};

export default PrescoreReport;
