import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Row, Col, Form, Select, Space, Button, Input, Card } from 'antd';
import { SearchOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';

import useCurrentLanguage from 'hooks/user/useCurrentLanguage';
import useQueryParams from 'hooks/navigation/useQueryParams';
import { getCompanies, getCompaniesUnregistered, getDistricts } from 'store/entities/company/thunks';
import { setSelectedCompany } from 'store/entities/company';

import LoadingSpinner from 'components/reusable/LoadingSpinner';

const { Option } = Select;

const CompanySearchForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lang = useCurrentLanguage();
  const queryParams = useQueryParams();
  const [form] = Form.useForm();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { isAuthenticated } = useSelector(state => state.user);
  const { districts, searchResults, getCompaniesPending, getDistrictsPending } = useSelector(state => state.company);
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const managerSearch = queryParams.get('manager');
  const managerCreditinfoIdSearch = queryParams.get('managerCreditinfoId');
  const companySearch = queryParams.get('search');

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getDistricts());
    }

    if (searchResults.length > 0) {
      dispatch(getCompanies(form.getFieldsValue(true)));
    }
  }, [lang]);

  const handleRecaptchaVerification = useCallback(
    ({ name }) => {
      executeRecaptcha('search')
        .then(token => {
          dispatch(getCompaniesUnregistered({ name, token }));
        })
        .catch(error => {
          throw new Error(error);
        });
    },
    [executeRecaptcha, dispatch]
  );

  useEffect(() => {
    if (managerSearch) {
      const searchPayload = {
        ...(managerSearch && { manager: managerSearch }),
        ...(managerCreditinfoIdSearch && { managerCreditinfoId: managerCreditinfoIdSearch }),
      };
      setShowMoreFilters(true);
      form.setFieldsValue(searchPayload);
      dispatch(getCompanies(searchPayload));
    }
  }, [managerSearch, managerCreditinfoIdSearch]);

  useEffect(() => {
    if (!isAuthenticated && companySearch) {
      const searchPayload = { name: companySearch };

      form.setFieldsValue(searchPayload);
      handleRecaptchaVerification(searchPayload);
    }
  }, [isAuthenticated]);

  const handleSubmit = values => {
    if (isAuthenticated) {
      dispatch(setSelectedCompany(null));
      dispatch(getCompanies(values));
    } else {
      handleRecaptchaVerification(values);
    }
  };

  return (
    <Form form={form} hideRequiredMark={true} layout="vertical" name="companySearchFilters" onFinish={handleSubmit}>
      <LoadingSpinner size="large" spinning={getCompaniesPending}>
        <Card className="card-simple space-vertical-lg has-shadow">
          <Row
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
            gutter={!showMoreFilters && [16, 16]}
          >
            <Col xs={24} md={24} lg={24} xl={12} xxl={15}>
              <Form.Item name="name">
                <Input placeholder={t('companySearch.companyName')} />
              </Form.Item>
              {showMoreFilters && (
                <Row gutter={[16, 16]} style={{ rowGap: 0 }}>
                  <Col xs={24} md={24} lg={24} xl={24}>
                    <Form.Item name="manager">
                      <Input placeholder={t('companySearch.manager')} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={12} xl={24}>
                    <Form.Item name="address">
                      <Input placeholder={t('companySearch.address')} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={12}>
                    <Form.Item name="municipality">
                      <Select
                        optionFilterProp="children"
                        showSearch
                        allowClear
                        loading={getDistrictsPending}
                        placeholder={t('companySearch.municipality')}
                      >
                        {districts.map(({ name }) => (
                          <Option key={name} value={name}>
                            {name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={12}>
                    <Form.Item name="telephone">
                      <Input placeholder={t('companySearch.phone')} />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Col>
            <Col
              style={{
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              <Row style={{ alignItems: 'baseline' }} align="bottom">
                {isAuthenticated && (
                  <Form.Item
                    style={{
                      marginTop: '0px',
                      marginBottom: showMoreFilters ? '24px' : 0,
                    }}
                  >
                    <Button
                      type="link"
                      onClick={() => setShowMoreFilters(!showMoreFilters)}
                      icon={showMoreFilters ? <MinusOutlined /> : <PlusOutlined />}
                    >
                      {showMoreFilters ? t('companySearch.lessOptions') : t('companySearch.moreOptions')}
                    </Button>
                  </Form.Item>
                )}
                <Form.Item>
                  <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                    {t('companySearch.search')}
                  </Button>
                </Form.Item>
              </Row>
            </Col>
          </Row>
        </Card>
      </LoadingSpinner>
    </Form>
  );
};

export default CompanySearchForm;
