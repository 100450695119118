import {
  fetchFromApi,
  optionsForDeleteRequest,
  optionsForPostRequest,
  optionsForFilePostRequest,
  optionsForPutRequest,
} from 'api';

const postFeedback = params => {
  return fetchFromApi(`/feedback`, optionsForFilePostRequest(params));
};

export default { postFeedback };
