import React from 'react';
import colors from 'constants/colors';

const YAxisTick = ({ x, y, payload, textAnchor = 'end', riskClasses }) => {
  const { value } = payload;

  const getClassCount = ratingClass => {
    const item = riskClasses.find(item => item.ratingClass === ratingClass);
    return item ? item.bankruptcyCount || item.defaultCount : null;
  };

  const classCount = getClassCount(value);
  const offset = classCount < 10 || !classCount ? 68 : classCount < 100 ? 64 : classCount < 1000 ? 62 : 57;

  return (
    <text
      x={x}
      y={y + 3}
      textAnchor={textAnchor}
      stroke="none"
      className="recharts-text recharts-cartesian-axis-tick-value"
    >
      <tspan x={x - (value >= 10 ? 4 : 7)} fill={value > 9 ? colors.COLOR_WHITE : colors.COLOR_BLACK}>
        {value === 11 ? 99 : value}
      </tspan>
      <tspan x={x - offset} fill={colors.COLOR_BLACK}>
        {value === 11 ? '' : classCount || 0}
      </tspan>
    </text>
  );
};

export default YAxisTick;
