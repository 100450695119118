import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enUS from 'antd/lib/locale/en_US';
import ltLT from 'antd/lib/locale/lt_LT';

import translationsEn from './translations/en/translation';
import translationsLt from './translations/lt/translation';

export const languages = [
  { codeShort: 'lt-LT', codeLong: 'LTU', antdLocale: ltLT },
  { codeShort: 'en-US', codeLong: 'ENG', antdLocale: enUS },
];

const whitelist = languages.map(({ codeShort }) => codeShort);

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: languages[0].codeShort,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
    whitelist,
    detection: {
      order: ['localStorage'],
      checkWhitelist: true,
      possibleLanguages: whitelist,
      caches: ['localStorage'],
    },
    resources: {
      'en-US': { translation: translationsEn },
      'lt-LT': { translation: translationsLt },
    },
  });

export default i18n;
