import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { orderExpertEvaluation } from 'store/entities/professionalReport/thunks';
import { setExpertReportModalOpen } from 'store/entities/professionalReport';
import { showSuccessNotification } from 'utils/notification';

import { Button, Row, Col, Typography, Space, Radio, Input } from 'antd';
import Modal from 'components/reusable/Modal';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import { ReactComponent as Close } from 'assets/img/icons/close.svg';

const { Title, Paragraph } = Typography;
const { TextArea } = Input;

const OrderExpertReport = () => {
  const { companyId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { session } = useSelector(state => state.user);
  const {
    expertReportModalOpen,
    orderId: profReportOrderId,
    orderDetails: profReportDetails,
    orderExpertEvaluationPending,
  } = useSelector(state => state.professionalReport);
  const { basicData, orderId: startReportOrderId } = useSelector(state => state.startReport);

  const [mentionCustomerCompany, setMentionCustomerCompany] = useState(true);
  const [orderDetails, setOrderDetails] = useState('');

  const orderReport = async () => {
    const order = {
      mentionCustomerCompany,
      orderDetails,
      customerId: 0,
      companyCode: 0,
      creditinfoId: 0,
    };

    const submitResult = await dispatch(
      orderExpertEvaluation({ orderId: profReportOrderId ?? startReportOrderId, orderDetails: order })
    );

    if (orderExpertEvaluation.fulfilled.match(submitResult)) {
      showSuccessNotification('Order request sent!');
      dispatch(setExpertReportModalOpen(false));
    }
  };

  return (
    <Modal
      isOpen={expertReportModalOpen}
      setIsOpen={isOpen => dispatch(setExpertReportModalOpen(isOpen))}
      closable={orderExpertEvaluationPending}
    >
      <LoadingSpinner size="large" spinning={orderExpertEvaluationPending}>
        <div className="container">
          <Row>
            <Col span={20}>
              <Title className="space-vertical-lg">
                {t('professionalReport.companyRatings.orderExpertReport.title')}
              </Title>
            </Col>
            <Col span={4} className="justify-content-end">
              <span
                className="icon-btn space-top-md"
                role="button"
                onClick={() => dispatch(setExpertReportModalOpen(false))}
              >
                <Close />
              </span>
            </Col>
          </Row>
          <ReportRow
            items={[
              t('professionalReport.companyRatings.orderExpertReport.label.client'),
              `${session?.companyName}, ${session?.fullName}`,
            ]}
          />
          <ReportRow
            items={[t('professionalReport.companyRatings.orderExpertReport.label.clientCode'), session?.companyCode]}
          />
          <ReportRow
            items={[t('professionalReport.companyRatings.orderExpertReport.label.clientEmail'), session?.email]}
          />
          <ReportRow
            items={[
              t('professionalReport.companyRatings.orderExpertReport.label.order'),
              `${profReportDetails?.companyTitle ?? basicData?.basicInformation?.companyName} 
              (${t('professionalReport.companyRatings.orderExpertReport.label.c.c')} 
              ${companyId}) ${t('professionalReport.companyRatings.orderExpertReport.label.expert.evaluation')}`,
            ]}
          />
          <ReportRow
            items={[
              t('professionalReport.companyRatings.orderExpertReport.label.orderPrice'),
              t('professionalReport.companyRatings.orderExpertReport.order.price'),
            ]}
          />
          <ReportRow
            items={[
              t('professionalReport.companyRatings.orderExpertReport.label.orderExecutionTime'),
              t('professionalReport.companyRatings.orderExpertReport.label.execution.time'),
            ]}
          />
          <ReportRow
            items={[
              t('professionalReport.companyRatings.orderExpertReport.label.mention'),
              <div>
                <Paragraph>
                  <span>
                    {t('global.true')}{' '}
                    <Radio checked={mentionCustomerCompany} onClick={e => setMentionCustomerCompany(true)} />
                  </span>
                  <span>
                    {t('global.false')}{' '}
                    <Radio checked={!mentionCustomerCompany} onClick={e => setMentionCustomerCompany(false)} />
                  </span>
                </Paragraph>
                <Paragraph>{t('professionalReport.companyRatings.orderExpertReport.description.mention')}</Paragraph>
              </div>,
            ]}
          />
          <ReportRow
            items={[
              t('professionalReport.companyRatings.orderExpertReport.label.comments'),
              <TextArea
                key="comments"
                value={orderDetails}
                onChange={e => setOrderDetails(e.target.value)}
                rows={5}
                placeholder={t('professionalReport.companyRatings.orderExpertReport.placeholder.comments')}
              />,
            ]}
          />
          <Row>
            <Col>
              <Space size="large">
                <Button htmlType="button" onClick={() => dispatch(setExpertReportModalOpen(false))}>
                  {t('global.cancel')}
                </Button>
                <Button className="btn-secondary" htmlType="button" onClick={orderReport}>
                  {t('global.order')}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
      </LoadingSpinner>
    </Modal>
  );
};

const ReportRow = ({ items }) => (
  <Row gutter={[32]} className="space-bottom-xs">
    <Col sm={24} xl={8} xxl={6}>
      <strong>{items[0]}</strong>
    </Col>
    <Col sm={24} xl={16} xxl={18}>
      {items[1]}
    </Col>
  </Row>
);

export default OrderExpertReport;
