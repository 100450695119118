import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  companyBasicInfo,
  companyContactInfo,
} from 'store/entities/companyCreditLimitReport/selectors/basicDataSelectors';
import CompanyBasicInformation from 'components/reusable/report/cards/CompanyBasicInformation';
import CompanyContactInformation from 'components/reusable/report/cards/CompanyContactInformation';
import UpdateContactInfo from 'views/ProfessionalReport/BasicData/Contact/UpdateContactInfo';

const Basic = () => {
  const { basicInformation } = useSelector(companyBasicInfo);
  const { companyContact } = useSelector(companyContactInfo);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);

  return (
    <>
      <CompanyBasicInformation companyData={basicInformation} />
      <CompanyContactInformation contactData={companyContact} setUpdateModalOpen={setUpdateModalOpen} />
      <UpdateContactInfo modalOpen={updateModalOpen} setModalOpen={setUpdateModalOpen} initialValues={companyContact} />
    </>
  );
};

export default Basic;
