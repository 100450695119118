import React from 'react';
import { EllipsisOutlined, CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const Step = ({ text, step, activeStep, setStep }) => {
  const { t } = useTranslation();
  return (
    <div className="step">
      <span
        className={`step-indicator ${step === activeStep ? 'step-indicator-active' : ''} ${
          step < activeStep ? 'step-indicator-complete' : ''
        }`}
        onClick={setStep}
      >
        <span>{step === activeStep ? <EllipsisOutlined /> : step < activeStep && <CheckOutlined />}</span>
      </span>
      <span
        className={`step-number  ${step < activeStep ? 'step-number--complete' : ''} ${
          step === activeStep ? 'step-number--active' : ''
        }`}
      >
        {`${t('companyResult.buyReport.step.number')} ${step}`}
      </span>
      <span
        className={`step-text  ${step < activeStep ? 'step-text--complete' : ''} ${
          step === activeStep ? 'step-text--active' : ''
        }`}
      >
        {text}
      </span>
    </div>
  );
};

const Steps = ({ steps, activeStep, setStep }) => {
  return (
    <div className="steps">
      {steps.map((step, idx, arr) => (
        <>
          <Step
            text={step.text}
            step={step.orderNr}
            activeStep={activeStep}
            setStep={() => setStep(step.orderNr)}
            key={`${step.orderNr}${step.text}`}
          />
          {idx !== arr.length - 1 && <div className="steps-decorator"></div>}
        </>
      ))}
    </div>
  );
};

export default Steps;
