import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row, Space, Card } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { BlockHeader } from '../../../components/reusable/BlockHeader';

const OtherInformation = () => {
  const { t } = useTranslation();
  return (
    <>
      <BlockHeader
        icon={<InfoCircleOutlined className="icon--gray" />}
        title={t('professionalReport.negativeData.otherInformation.title')}
      />
      <Row>
        <Card className="card-transparent">{t('professionalReport.negativeData.otherInformation.noInformation')}</Card>
      </Row>
    </>
  );
};

export default OtherInformation;
