import useUserClaims from '../hooks/user/useUserClaims';

// Controls children components availability based on provided permission
const RestrictedAccess = ({ children, permission }) => {
  const permissions = useUserClaims();

  if (!permissions) return null;

  const permissionGranted = permissions.find(item => item.key === permission && item.value);

  return permissionGranted ? <>{children}</> : null;
};

export default RestrictedAccess;
