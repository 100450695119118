import { Card, Select, Row, Col, Form, Input, Divider, DatePicker, Button } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import 'moment/locale/lt';
import { getCompanyMonitoringEvents, getPersonMonitoringEvents } from '../../../store/entities/otherProducts/thunks';
import { useDispatch, useSelector } from 'react-redux';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const disabledDate = current => {
  const afterToday = current > dayjs().endOf('day');
  const before30Days = current < dayjs().add(-30, 'd');
  return afterToday || before30Days;
};

const SearchForResults = ({ legalEntities, setModalOpen }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { monitoringEventsDataLoading, userSettings } = useSelector(state => state.otherProducts);

  const allType = { value: -1, label: t('webMonitoring.dropdown.all') };

  const getMonitoringEventTypes = () => {
    if (legalEntities) {
      const options = userSettings?.eventListCompany.map(item => ({
        value: item.id,
        label: item.name,
      }));

      if (!options?.length) return [];

      return [allType, ...options];
    }

    const options = userSettings?.eventListPerson.map(item => ({
      value: item.id,
      label: item.name,
    }));

    if (!options?.length) return [];

    return [allType, ...options];
  };

  const handleOnSubmit = v => {
    const selectedMonitoringEventTypesIds = v.selectedMonitoringEventTypesIds?.length
      ? v.selectedMonitoringEventTypesIds
      : [-1];

    dispatch(
      legalEntities
        ? getCompanyMonitoringEvents({ ...v, selectedMonitoringEventTypesIds })
        : getPersonMonitoringEvents({ ...v, selectedMonitoringEventTypesIds })
    );
  };

  const fetchInitialData = () => {
    const initialValues = {
      dateFrom: dayjs().startOf('day'),
      dateTo: dayjs().endOf('day'),
      name: '',
      code: '',
    };

    handleOnSubmit(initialValues);
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  // Currently no validation is required
  const inputRules = [];

  return (
    <Card className="card-simple space-vertical-sm web-monitoring-card" style={{ width: '100%' }}>
      <Form
        form={form}
        initialValues={{ dateFrom: dayjs().startOf('day'), dateTo: dayjs().endOf('day'), name: '', code: '' }}
        labelCol={{ span: 24 }}
        onFinish={handleOnSubmit}
      >
        <Row>
          <Col xs={24} md={24} lg={24} xl={12} xxl={12} className="column left-section">
            <Form.Item name="dateFrom" label={t('webMonitoring.from')} rules={inputRules}>
              <DatePicker disabledDate={disabledDate} className="datepicker" />
            </Form.Item>
            <Form.Item name="dateTo" label={t('webMonitoring.to')} rules={inputRules}>
              <DatePicker disabledDate={disabledDate} className="datepicker" />
            </Form.Item>
            <Divider />
            <Form.Item name="name" label={t(`webMonitoring.search.${legalEntities ? 'company' : 'person'}.name`)}>
              <Input className="search" />
            </Form.Item>
            <Form.Item name="code" label={t(`webMonitoring.search.${legalEntities ? 'company' : 'person'}.code`)}>
              <Input className="search" />
            </Form.Item>
            {legalEntities && <p className="disclaimer">{t('webMonitoring.otherProducts.disclaimer')}</p>}
            <Row xs={12} md={12} lg={12} xl={3} xxl={3} className="buttons">
              <Button className="btn-secondary search-button" htmlType="submit" loading={monitoringEventsDataLoading}>
                {t('global.search')}
              </Button>
              <Button className="btn-secondary settings-button" onClick={() => setModalOpen(true)}>
                {t('global.settings')}
              </Button>
            </Row>
          </Col>
          <Col xs={24} md={24} lg={24} xl={12} xxl={12} className="column right-section">
            <Form.Item
              name="selectedMonitoringEventTypesIds"
              rules={inputRules}
              label={t('webMonitoring.otherProducts.monitoring_event_types')}
            >
              <Select
                showArrow
                popupclassname="dropdown-select"
                mode="multiple"
                options={getMonitoringEventTypes()}
                placeholder={t('webMonitoring.otherProducts.monitoring.choose.events')}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default SearchForResults;
