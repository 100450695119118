import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Col, Row, Card } from 'antd';
import Icon, { InfoCircleOutlined } from '@ant-design/icons';
import { companyCreditLimit } from 'store/entities/companyCreditLimitReport/selectors/basicDataSelectors';
import useNumberFormatter from 'hooks/format/useNumberFormatter';
import { BlockHeader } from '../../../components/reusable/BlockHeader';

const CompanyCredit = () => {
  const { t } = useTranslation();
  const formatCurrency = useNumberFormatter();
  const { countedAmount } = useSelector(companyCreditLimit);

  return (
    <Row id="company-credit" className="company-credit-limit">
      <Col>
        <BlockHeader
          icon={<Icon component={InfoCircleOutlined} className="icon--gray" />}
          title={t('companyCreditLimit.title')}
        />
      </Col>
      {countedAmount?.amount || countedAmount?.amount === 0 ? (
        <Card className="card-transparent space-vertical-sm" style={{ background: '#fff', fontSize: '110%' }}>
          <Trans
            i18nKey="companyCreditLimit.amount.value"
            values={{
              value: formatCurrency(countedAmount?.amount),
            }}
            components={{ bold: <strong className="bigger-font" /> }}
          />
          <div className="disclaimer">
            <Trans i18nKey="companyCreditLimit.disclaimer" />
          </div>
        </Card>
      ) : (
        <Card className="card-transparent space-vertical-sm" style={{ background: '#fff', fontSize: '110%' }}>
          <Trans i18nKey="companyCreditLimit.not.available" components={{ bold: <strong className="bigger-font" /> }} />
        </Card>
      )}
    </Row>
  );
};

export default CompanyCredit;
