import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';

import personHooks from 'store/entities/person/selectors';
import useLastPathname from 'hooks/navigation/useLastPathname';
import useRedirect from 'hooks/navigation/useRedirect';
import PaymentHistory from 'views/IndividualReport/PaymentHistory';
import OpenDebts from 'views/IndividualReport/OpenDebts';
import PersonsRegister from 'views/IndividualReport/components/PersonsRegister';
import ProfessionalReport from 'views/IndividualReport/ProfessionalReport';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import ReportHeader from 'components/reusable/report/ReportHeader';
import { useParams } from 'react-router-dom';
import { b64toBlob, downloadAsFile } from 'utils/download';
import { useIndividualPdfFilename } from 'hooks/report/professionalReport/usePdfFilename';
import { resetProfessionalReport } from 'store/entities/professionalReport';
import { getOrderId } from 'store/entities/professionalReport/thunks';
import { getIndividualProfessionalReportPdf } from 'store/entities/person/thunks';

const IndividualReport = () => {
  const { t } = useTranslation();
  const { individualId } = useParams();
  const lastPathname = useLastPathname();
  const redirect = useRedirect();
  const dispatch = useDispatch();
  const pdfFileName = useIndividualPdfFilename();
  const person = useSelector(personHooks.selectPersonInformation);
  const { orderId, orderIdPending, summaryPending } = useSelector(state => state.professionalReport);
  const { individualProfessionalReportPdfPending } = useSelector(state => state.person);

  const orderReport = async () => {
    const orderIdResult = await dispatch(getOrderId({ code: individualId, isCompany: false }));

    if (!getOrderId.fulfilled.match(orderIdResult)) {
      redirect('/search/company');
    }
  };

  useEffect(() => {
    orderReport();

    return () => dispatch(resetProfessionalReport());
  }, []);

  useEffect(() => {
    if (person === null) {
      redirect('/search/individual');
    }
  }, [person]);

  if (person === null) {
    return <></>;
  }

  const { firstName, lastName, personCode, getPersonPending } = person;

  const fullTitle = () => {
    if (firstName && lastName) {
      return `${firstName} ${lastName} ${t('global.personalCode.short')} ${personCode}`;
    } else {
      return `${t('global.personalCode.short')} ${personCode}`;
    }
  };

  const handlePdfDownload = async () => {
    const response = await dispatch(getIndividualProfessionalReportPdf(orderId));

    const data = b64toBlob(response.payload.data);
    downloadAsFile(data, pdfFileName);
  };

  return (
    <LoadingSpinner
      size="large"
      spinning={getPersonPending || orderIdPending || summaryPending || individualProfessionalReportPdfPending}
    >
      <div className="container individual-report">
        <ReportHeader
          title={fullTitle()}
          actions={
            <Button onClick={handlePdfDownload}>
              <PrinterOutlined />
              {t('global.print')}
            </Button>
          }
        />
        <>
          {lastPathname === 'payment-history' && <PaymentHistory />}
          {lastPathname === 'open-debts' && <OpenDebts />}
          {lastPathname === 'persons-register' && <PersonsRegister />}
          {lastPathname === 'professional-report' && <ProfessionalReport />}
        </>
      </div>
    </LoadingSpinner>
  );
};

export default IndividualReport;
