import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setActiveTab } from 'store/entities/professionalReport';
import { basicContact } from 'store/entities/professionalReport/selectors/basicDataSelectors';

import VisibilitySensor from 'react-visibility-sensor';
import { Col, Row, Divider } from 'antd';
import CollapsibleTable from 'components/reusable/CollapsibleTable';
import TableColumnDate from 'components/reusable/TableColumnDate';
import UpdateContactInfo from './UpdateContactInfo';
import CompanyContactInformation from 'components/reusable/report/cards/CompanyContactInformation';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';

const Contact = () => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();
  const dispatch = useDispatch();
  const { companyContact, companyAddressesHistory, rekvizitaiContacts } = useSelector(basicContact);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);

  const companyAddressesHistoryColumns = [
    {
      title: t('global.dateFrom'),
      dataIndex: 'dateFrom',
      render: TableColumnDate,
      key: 'dateFrom',
      sorter: (a, b) => new Date(b.dateFrom) - new Date(a.dateFrom),
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 80 : 120,
    },
    {
      title: t('global.dateUntil'),
      dataIndex: 'dateUntil',
      render: TableColumnDate,
      key: 'dateUntil',
      sorter: (a, b) => new Date(b.dateUntil) - new Date(a.dateUntil),
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 80 : 120,
    },
    {
      title: t('common.address'),
      dataIndex: 'address',
      key: 'address',
      sorter: (a, b) => a.address.localeCompare(b.address),
      sortDirections: ['ascend', 'descend'],
    },
  ];

  return (
    <div>
      <div id="contact">
        <VisibilitySensor
          offset={{ bottom: 50 }}
          scrollCheck
          intervalDelay={10}
          onChange={isVisible => isVisible && dispatch(setActiveTab('contact'))}
        >
          <CompanyContactInformation contactData={companyContact} setUpdateModalOpen={setUpdateModalOpen}>
            {companyAddressesHistory?.length ? (
              <CollapsibleTable
                className="space-top-md"
                title={t('professionalReport.basicData.legalAddressHistoricalInformation.title')}
                data={companyAddressesHistory}
                columns={companyAddressesHistoryColumns}
                bordered
                showSorterTooltip={false}
              />
            ) : null}
          </CompanyContactInformation>
        </VisibilitySensor>
      </div>
      <div id="details">
        <CompanyContactInformation contactData={rekvizitaiContacts} type="rekvizitai" />
      </div>
      <UpdateContactInfo modalOpen={updateModalOpen} setModalOpen={setUpdateModalOpen} initialValues={companyContact} />
    </div>
  );
};

export default Contact;
