import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18n';
import {
  getBasicData,
  getOrderId,
  getPrescoreReportPdf,
  updateContacts,
} from 'store/entities/prescoreReport/thunks.js';

const initialState = {
  orderId: null,
  orderIdPending: false,
  orderIdError: false,
  orderDetails: null,
  basicData: null,
  basicDataPending: false,
  basicDataError: false,
  updateContactsPending: false,
  prescoreReportPdfLoading: false,
};

const slice = createSlice({
  name: 'prescoreReport',
  initialState: initialState,
  reducers: {
    resetPrescoreReport: state => initialState,
  },
  extraReducers: {
    [getOrderId.fulfilled]: (state, { payload }) => {
      state.orderId = payload.id;
      state.orderIdPending = false;
      state.orderIdError = false;
    },
    [getOrderId.pending]: state => {
      state.orderIdPending = true;
      state.orderIdError = false;
    },
    [getOrderId.rejected]: state => {
      state.orderIdPending = false;
      state.orderIdError = true;
    },
    [getBasicData.fulfilled]: (state, { payload }) => {
      state.basicData = { ...payload, lang: i18n.language };
      state.basicDataPending = false;
      state.basicDataError = false;
    },
    [getBasicData.pending]: state => {
      state.basicDataPending = true;
      state.basicDataError = false;
    },
    [getBasicData.rejected]: state => {
      state.basicDataPending = false;
      state.basicDataError = true;
    },
    [updateContacts.fulfilled]: state => {
      state.updateContactsPending = false;
    },
    [updateContacts.pending]: state => {
      state.updateContactsPending = true;
    },
    [updateContacts.rejected]: state => {
      state.updateContactsPending = false;
    },
    [getPrescoreReportPdf.pending]: state => {
      state.prescoreReportPdfLoading = true;
    },
    [getPrescoreReportPdf.rejected]: state => {
      state.prescoreReportPdfLoading = false;
    },
    [getPrescoreReportPdf.fulfilled]: state => {
      state.prescoreReportPdfLoading = false;
    },
  },
});

export const { resetPrescoreReport } = slice.actions;

export default slice.reducer;
