import { createAsyncThunk } from '@reduxjs/toolkit';
import foreignCompanyService from 'api/foreignCompanyService';
import countriesService from 'api/countriesService';

export const getCountries = createAsyncThunk('foreignCompany/getCountries', async (_, { rejectWithValue }) => {
  try {
    const response = await countriesService.getCountries();
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getCompanies = createAsyncThunk('foreign-company/search', async (filters, { rejectWithValue }) => {
  try {
    const response = await foreignCompanyService.getCompanies(filters);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getHistoricalCompanies = createAsyncThunk(
  'foreign-company/history',
  async (filters, { rejectWithValue }) => {
    try {
      const response = await foreignCompanyService.getHistoricalCompanies(filters);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCompanyOrderId = createAsyncThunk('foreign-company/order', async (companyData, { rejectWithValue }) => {
  try {
    const response = await foreignCompanyService.getCompanyOrderId(companyData);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getCompanyPdfReport = createAsyncThunk(
  'company/getCompanyPdfReport',
  async (orderId, { rejectWithValue }) => {
    try {
      const response = await foreignCompanyService.getCompanyPdfReport(orderId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
