import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row, Typography } from 'antd';

import { individualProducts } from 'constants/product';
import useRedirect from 'hooks/navigation/useRedirect';
import personHooks from 'store/entities/person/selectors';
import IndividualDataBlock from 'views/Search/IndividualSearch/IndividualDataBlock';
import AddToMonitoringSwitch from 'views/Search/components/AddToMonitoringSwitch';
import AddToMonitoring from 'views/Search/components/AddToMonitoring';
import ReportRow from 'views/Search/components/ReportRow';
import { ReactComponent as BasicDataIcon } from 'assets/img/icons/basic.svg';
import RestrictedAccess from '../../../components/RestrictedAccess';
import { addIndividualEntity } from '../../../store/entities/otherProducts/thunks';

const { Paragraph } = Typography;

const Basic = () => {
  const person = useSelector(personHooks.selectPersonInformation);

  const [monitoringModalOpen, setMonitoringModalOpen] = useState(false);
  const [addedToMonitoring, setAddedToMonitoring] = useState(false);
  const redirect = useRedirect();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (person === null) {
    return <></>;
  }

  const { personCode, firstName, lastName, isMonitored } = person;

  const setPersonMonitoring = () => {
    dispatch(
      addIndividualEntity({
        personCode,
        personRequest: {
          personCode: personCode,
        },
      })
    );
    setAddedToMonitoring(true);
  };

  return (
    <>
      <IndividualDataBlock data={{ name: `${firstName} ${lastName}`, personalCode: personCode }} />
      <AddToMonitoring
        modalOpen={monitoringModalOpen}
        setModalOpen={setMonitoringModalOpen}
        setAddedToMonitoring={() => setPersonMonitoring()}
      />
      <Card
        className="card-simple card-simple-padding-none space-vertical-sm has-shadow"
        title={t('individualSearch.result.basic.individualReport.title')}
        extra={
          <AddToMonitoringSwitch
            addedToMonitoring={addedToMonitoring || isMonitored}
            setMonitoringModalOpen={setMonitoringModalOpen}
          />
        }
      >
        <Row gutter={[32, 16]}>
          <Col xs={24} md={24} lg={24} xl={10}>
            <Paragraph>
              <Trans i18nKey={'individualSearch.result.basic.professionalReport.intro'} components={{ under: <u /> }} />
            </Paragraph>
          </Col>
          <Col xs={24} md={24} lg={24} xl={14}>
            {individualProducts
              .filter(product => !product.hidden)
              .map(({ id, url, permission }) => (
                <RestrictedAccess permission={permission}>
                  <ReportRow
                    btnText="global.selectReport"
                    reportName={t(`${id === 'professionalReport' ? `${id}.individual` : id}.title`)}
                    onBtnClick={() => redirect(`/search/individual/${personCode}${url}`)}
                  />
                </RestrictedAccess>
              ))}
            <BasicDataIcon className="card-icon" width={73} height={73} />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Basic;
