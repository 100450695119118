import { ReactComponent as BoardRelation } from '../assets/nodeIcons/board-relations.svg';
import { ReactComponent as FounderRelation } from '../assets/nodeIcons/founder-relations.svg';
import { ReactComponent as DefendantRelation } from '../assets/nodeIcons/defendant-relations.svg';
import { ReactComponent as AddressRelation } from '../assets/nodeIcons/legal-address-relations.svg';
import { ReactComponent as EmailRelation } from '../assets/nodeIcons/email-relations.svg';
import { ReactComponent as ManagementRelation } from '../assets/nodeIcons/management.svg';
import { ReactComponent as OwnershipRelation } from '../assets/nodeIcons/ownership.svg';
import { ReactComponent as PhoneRelation } from '../assets/nodeIcons/phone-number-relations.svg';
import { ReactComponent as WebRelation } from '../assets/nodeIcons/web-relations.svg';
import { ReactComponent as RootCompany } from '../assets/nodeIcons/root-company.svg';
import { ReactComponent as RootPerson } from '../assets/nodeIcons/root-person.svg';
import { ReactComponent as Arrests } from '../assets/groupIcons/active-arrests.svg';
import { ReactComponent as ArrestsHistory } from '../assets/groupIcons/arrests-history.svg';
import { ReactComponent as Claims } from '../assets/groupIcons/debt-collection-claims.svg';
import { ReactComponent as Lawsuits } from '../assets/groupIcons/legal-proceedings.svg';
import { ReactComponent as PendingVmiOrders } from '../assets/groupIcons/person-limited-right.svg';
import { ReactComponent as NoFinancialReports } from '../assets/groupIcons/FS-not-submitted.svg';
import { ReactComponent as NoEmployees } from '../assets/groupIcons/no-employees.svg';
import { ReactComponent as BankruptcyCase } from '../assets/groupIcons/bankruptcy-proceedings.svg';
import { ReactComponent as RestrCase } from '../assets/groupIcons/restructuring-proceedings.svg';
import { ReactComponent as UnpaidDebts } from '../assets/groupIcons/unpaid-depts.svg';
import { ReactComponent as DeptPaymentHistory } from '../assets/groupIcons/dept-payment-history.svg';
import { ReactComponent as Foreigner } from '../assets/groupIcons/foreigner.svg';
import { ReactComponent as UnknownCode } from '../assets/groupIcons/unknown-code.svg';
import { ReactComponent as IsNewCompany } from '../assets/groupIcons/company-age-less.svg';
import { ReactComponent as HasShareholderList } from '../assets/groupIcons/new-shareholders-list.svg';
import { ReactComponent as Removed } from '../assets/groupIcons/removed.svg';
import { ReactComponent as InBankruptcy } from '../assets/groupIcons/in-bankruptcy.svg';
import { ReactComponent as UnderReorganization } from '../assets/groupIcons/under-reorganization.svg';
import { ReactComponent as UnderReformation } from '../assets/groupIcons/under-reformation.svg';
import { ReactComponent as UnderRestructurization } from '../assets/groupIcons/under-restructurization.svg';
import { ReactComponent as UnderLiquidation } from '../assets/groupIcons/under-liquidation.svg';
import { ReactComponent as BE } from '../assets/countryIcons/BE.svg';
import { ReactComponent as CZ } from '../assets/countryIcons/CZ.svg';
import { ReactComponent as DE } from '../assets/countryIcons/DE.svg';
import { ReactComponent as DK } from '../assets/countryIcons/DK.svg';
import { ReactComponent as EE } from '../assets/countryIcons/EE.svg';
import { ReactComponent as ES } from '../assets/countryIcons/ES.svg';
import { ReactComponent as FI } from '../assets/countryIcons/FI.svg';
import { ReactComponent as FR } from '../assets/countryIcons/FR.svg';
import { ReactComponent as GB } from '../assets/countryIcons/GB.svg';
import { ReactComponent as IE } from '../assets/countryIcons/DE.svg';
import { ReactComponent as IT } from '../assets/countryIcons/IT.svg';
import { ReactComponent as LT } from '../assets/countryIcons/LT.svg';
import { ReactComponent as LU } from '../assets/countryIcons/LU.svg';
import { ReactComponent as LV } from '../assets/countryIcons/LV.svg';
import { ReactComponent as NL } from '../assets/countryIcons/NL.svg';
import { ReactComponent as NO } from '../assets/countryIcons/NO.svg';
import { ReactComponent as PL } from '../assets/countryIcons/PL.svg';
import { ReactComponent as SE } from '../assets/countryIcons/SE.svg';
import { Tooltip } from '../components/graph-components/Tooltip';

export const GROUPS_ICONS = {
  arrests: <Arrests />,
  hasArrests: <Arrests />,
  hasIsregistruotiArrests: <ArrestsHistory />,
  isregistruotiArrests: <ArrestsHistory />,
  hasClaims: <Claims />,
  claims: <Claims />,
  hasLawsuits: <Lawsuits />,
  lawsuits: <Lawsuits />,
  hasPendingVmiOrders: <PendingVmiOrders />,
  pendingVmiOrders: <PendingVmiOrders />,
  isNotProvidingFinancialReports: <NoFinancialReports />,
  notProvidingFinancialReports: <NoFinancialReports />,
  hasEmployees: <NoEmployees />,
  employees: <NoEmployees />,
  hasBankruptcyCase: <BankruptcyCase />,
  bankruptcyCase: <BankruptcyCase />,
  hasRestrCase: <RestrCase />,
  restrCase: <RestrCase />,
  unpaidDebtsCount: <UnpaidDebts />,
  paidDebtsCount: <DeptPaymentHistory />,
  foreigner: <Foreigner />,
  unknownCode: <UnknownCode />,
  isNewCompany: <IsNewCompany />,
  hasShareholderList: <HasShareholderList />,
  status4: <Removed />,
  status18: <InBankruptcy />,
  status19: <InBankruptcy />,
  status20: <UnderReorganization />,
  status21: <UnderReorganization />,
  status22: <UnderReformation />,
  status23: <UnderRestructurization />,
  status25: <UnderLiquidation />,
  status26: <UnderRestructurization />,
  status34: <UnderReorganization />,
  status35: <UnderReorganization />,
  status36: <UnderLiquidation />,
  status37: <UnderLiquidation />,
  country_LT: <LT />,
  country_SE: <SE />,
  country_DE: <DE />,
  country_FR: <FR />,
  country_GB: <GB />,
  country_IE: <IE />,
  country_ES: <ES />,
  country_DK: <DK />,
  country_CH: <CZ />,
  country_NL: <NL />,
  country_BE: <BE />,
  country_NO: <NO />,
  country_FI: <FI />,
  country_LU: <LU />,
  country_EE: <EE />,
  country_LV: <LV />,
  country_IT: <IT />,
  country_PL: <PL />,
  // country_EEB: ,
};

export const NODE_RELATIONS = {
  shareholdersRelations: {
    icon: <OwnershipRelation className="node__icon" />,
    tooltipIcon: (
      <Tooltip type="shareholdersRelations">
        <OwnershipRelation className="node__icon" />
      </Tooltip>
    ),
  },
  executiveRelations: {
    icon: <ManagementRelation className="node__icon" />,
    tooltipIcon: (
      <Tooltip type="executiveRelations">
        <ManagementRelation className="node__icon" />
      </Tooltip>
    ),
  },
  founderRelations: {
    icon: <FounderRelation className="node__icon" />,
    tooltipIcon: (
      <Tooltip type="founderRelations">
        <FounderRelation className="node__icon" />
      </Tooltip>
    ),
  },
  boardMemberRelations: {
    icon: <BoardRelation className="node__icon" />,
    tooltipIcon: (
      <Tooltip type="boardMemberRelations">
        <BoardRelation className="node__icon" />
      </Tooltip>
    ),
  },
  samePhoneNumberRelations: {
    icon: <PhoneRelation className="node__icon" />,
    tooltipIcon: (
      <Tooltip type="samePhoneNumberRelations">
        <PhoneRelation className="node__icon" />
      </Tooltip>
    ),
  },
  sameRegistrationAddressRelations: {
    icon: <AddressRelation className="node__icon" />,
    tooltipIcon: (
      <Tooltip type="sameRegistrationAddressRelations">
        <AddressRelation className="node__icon" />
      </Tooltip>
    ),
  },
  sameCourtRelations: {
    icon: <DefendantRelation className="node__icon" />,
    tooltipIcon: (
      <Tooltip type="sameCourtRelations">
        <DefendantRelation className="node__icon" />
      </Tooltip>
    ),
  },
  sameEmailRelations: {
    icon: <EmailRelation className="node__icon" />,
    tooltipIcon: (
      <Tooltip type="sameEmailRelations">
        <EmailRelation className="node__icon" />
      </Tooltip>
    ),
  },
  sameWebRelations: {
    icon: <WebRelation className="node__icon" />,
    tooltipIcon: (
      <Tooltip type="sameWebRelations">
        <WebRelation className="node__icon" />
      </Tooltip>
    ),
  },
  rootCompany: {
    icon: <RootCompany className="node__icon" />,
    tooltipIcon: (
      <Tooltip type="rootCompany">
        <RootCompany className="node__icon" />
      </Tooltip>
    ),
  },
  rootPerson: {
    icon: <RootPerson className="node__icon" />,
    tooltipIcon: (
      <Tooltip type="rootPerson">
        <RootPerson className="node__icon" />
      </Tooltip>
    ),
  },
};

export const RELATION_TYPE_MAP = {
  company: {
    relationType: 'rootItem',
    icon: <RootCompany className="node__icon" />,
    property: 'company',
    lineClassName: 'node__line--company',
  },
  person: {
    relationType: 'rootItem',
    icon: <RootPerson className="node__icon" />,
    property: 'person',
    lineClassName: 'node__line--person',
    person: true,
  },
  boardMemberRelations: {
    relationType: 'boardMemberRelations',
    icon: <BoardRelation className="node__icon" />,
    property: 'boardMemberRelations',
    lineClassName: 'node__line--board-members',
  },
  executiveRelations: {
    relationType: 'executiveRelations',
    icon: <ManagementRelation className="node__icon" />,
    property: 'executiveRelations',
    lineClassName: 'node__line--executive-relations',
  },
  sameCourtRelations: {
    relationType: 'sameCourtRelations',
    icon: <DefendantRelation className="node__icon" />,
    property: 'sameCourtRelations',
    lineClassName: 'node__line--same-court-relations',
  },
  sameEmailRelations: {
    relationType: 'sameEmailRelations',
    icon: <BoardRelation className="node__icon" />,
    property: 'sameEmailRelations',
    lineClassName: 'node__line--same-email-relations',
  },
  samePhoneNumberRelations: {
    relationType: 'samePhoneNumberRelations',
    icon: <PhoneRelation className="node__icon" />,
    property: 'samePhoneNumberRelations',
    lineClassName: 'node__line--same-phone-number',
  },
  sameRegistrationAddressRelations: {
    relationType: 'sameRegistrationAddressRelations',
    icon: <AddressRelation className="node__icon" />,
    property: 'sameRegistrationAddressRelations',
    lineClassName: 'node__line--same-registration-address',
  },
  sameWebRelations: {
    relationType: 'sameWebRelations',
    icon: <BoardRelation className="node__icon" />,
    property: 'sameWebRelations',
    lineClassName: 'node__line--same-web-relations',
  },
  spiderShareholdersIn: {
    relationType: 'shareholdersRelations',
    icon: <OwnershipRelation className="node__icon" />,
    property: 'spiderShareholdersIn',
    lineClassName: 'node__line--share-holder',
  },
  spiderShareholdersOut: {
    relationType: 'shareholdersRelations',
    icon: <OwnershipRelation className="node__icon" />,
    property: 'spiderShareholdersOut',
    lineClassName: 'node__line--share-holder',
  },
  founderRelationsIn: {
    relationType: 'founderRelations',
    icon: <OwnershipRelation className="node__icon" />,
    property: 'founderRelationsIn',
    lineClassName: 'node__line--founder-relations',
  },
  founderRelationsOut: {
    relationType: 'founderRelations',
    icon: <OwnershipRelation className="node__icon" />,
    property: 'founderRelationsOut',
    lineClassName: 'node__line--founder-relations',
  },
};
