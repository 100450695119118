import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Select, Button, Input, Card } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import useCurrentLanguage from 'hooks/user/useCurrentLanguage';
import { getCountries, getCompanies } from 'store/entities/foreignCompany/thunks';
import { setSelectedCompany, setForeignCompanySearch, setChargeModal } from 'store/entities/foreignCompany';

import LoadingSpinner from 'components/reusable/LoadingSpinner';
import AdditionalChargeModal from './AdditionalChargeModal';

const { Option } = Select;

const CompanySearchForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lang = useCurrentLanguage();
  const [form] = Form.useForm();

  const { isAuthenticated } = useSelector(state => state.user);
  const { countries, searchResults, getCompaniesPending, getCountriesPending } = useSelector(
    state => state.foreignCompany
  );

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getCountries());
    }
    // TODO: need to read form values instead of just providing values to the getCleanedFieldsValue functiuon
    // if (searchResults.length > 0) {
    //   dispatch(
    //     getCompanies({
    //       ...getCleanedFieldsValue(values),
    //       page: {
    //         pageNumber: 1,
    //         itemsPerPage: 10,
    //       },
    //     })
    //   );
    // }
  }, [lang]);

  const handleCountrySelect = countryCode => {
    const selectedCountry = countries.find(country => country.countryCode === countryCode);

    if (selectedCountry.isAdditionalCharged) {
      dispatch(setChargeModal(selectedCountry));
      form.setFieldsValue({ countryCode: null });
    } else {
      form.setFieldsValue({ countryCode });
    }
  };

  const handleModalConfirm = countryCode => {
    form.setFieldsValue({ countryCode });
    dispatch(setChargeModal(false));
  };

  const getCleanedFieldsValue = values => {
    const cleanedValues = {};

    Object.keys(values).forEach(key => {
      cleanedValues[key] = values[key] !== undefined ? values[key] : '';
    });

    return cleanedValues;
  };

  const handleSubmit = values => {
    dispatch(setSelectedCompany(null));
    dispatch(setForeignCompanySearch(values));

    //TODO - investigate if this is a good request for page paras
    dispatch(
      getCompanies({
        ...getCleanedFieldsValue(values),
        page: {
          pageNumber: 1,
          itemsPerPage: 10,
        },
      })
    );
  };

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues.companyCode && allValues.companyName) {
      form.setFieldsValue({ companyName: '' }); // Clear companyName if companyCode is changed
    } else if (changedValues.companyName && allValues.companyCode) {
      form.setFieldsValue({ companyCode: '' }); // Clear companyCode if companyName is changed
    }
  };

  const validateOneFieldFilled = () => {
    const companyCode = form.getFieldValue('companyCode');
    const companyName = form.getFieldValue('companyName');
    if (!companyCode && !companyName) {
      return Promise.reject(new Error(t('foreignCompany.search.singleInsertRequired')));
    }
    return Promise.resolve();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="foreignCompanySearch"
      onFinish={handleSubmit}
      onValuesChange={onValuesChange}
    >
      <LoadingSpinner size="large" spinning={getCompaniesPending}>
        <AdditionalChargeModal onContinue={handleModalConfirm} />
        <Card className="card-simple space-vertical-lg has-shadow">
          <Row gutter={[16, 16]} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Col xs={24} md={24} lg={24} xl={12} xxl={15}>
              <Row gutter={[16, 16]} style={{ rowGap: 0 }}>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item name="companyCode" rules={[{ validator: validateOneFieldFilled }]}>
                    <Input placeholder={t('foreignCompany.search.companyCode')} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item name="companyName" rules={[{ validator: validateOneFieldFilled }]}>
                    <Input placeholder={t('foreignCompany.search.companyName')} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24} lg={24}>
                  <Form.Item
                    name="countryCode"
                    rules={[{ required: true, message: t('foreignCompany.search.countryRequired') }]}
                  >
                    <Select
                      optionFilterProp="children"
                      showSearch
                      allowClear
                      loading={getCountriesPending}
                      placeholder={t('foreignCompany.search.country')}
                      onSelect={handleCountrySelect}
                    >
                      {countries
                        ?.filter(({ isAdditionalCharged }) => !isAdditionalCharged)
                        .map(({ countryCode }) => (
                          <Option key={countryCode} value={countryCode}>
                            {t(`search.country.${countryCode}`)}
                          </Option>
                        ))}
                      <Option disabled key="separator">
                        {t('foreignCompany.search.country.additionalCost')}
                      </Option>
                      {countries
                        ?.filter(({ isAdditionalCharged }) => isAdditionalCharged)
                        .map(({ countryCode }) => (
                          <Option key={countryCode} value={countryCode}>
                            {t(`search.country.${countryCode}`)}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Row style={{ alignItems: 'baseline' }} align="bottom">
                <Form.Item style={{ marginTop: '0px', marginBottom: 0 }}></Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                    {t('companySearch.search')}
                  </Button>
                </Form.Item>
              </Row>
            </Col>
          </Row>
        </Card>
      </LoadingSpinner>
    </Form>
  );
};

export default CompanySearchForm;
