import { createSlice } from '@reduxjs/toolkit';
import { getCompaniesUnregistered } from '../company/thunks';
import {
  getCompanyData,
  getOrderIdFromCompanyBuyReport,
  getOrderIdFromPersonBuyReport,
  registerConsultation,
} from './thunks';

const initialSharedBuyerInformation = {
  address: '',
  email: '',
  phone: '',
  token: '',
  isTaxInvoiceRequired: false,
  rulesAccepted: false,
  agreedConsultation: false,
  isPrivacyPolicyAccepted: false,
  createdDate: null,
};

const initialCompanyBuyerInformation = {
  ...initialSharedBuyerInformation,
  company: '',
  companyCode: undefined,
  payCode: '',
  contactPerson: '',
};
const initialPersonBuyerInformation = {
  ...initialSharedBuyerInformation,
  person: '',
};

const initialState = {
  companyId: null,
  page: 'report',
  report: null,
  buyReport: {
    step: 1,
    stepperIsLoading: false,
    completedSteps: [],
    selectedReportType: 'professionalReport',
    selectedReport: null,
    productType: 6016,
    productPrice: 72.6,
    buyerType: 'company',
    companyBuyerInformation: initialCompanyBuyerInformation,
    personBuyerInformation: initialPersonBuyerInformation,
    paymentType: null,
    getCompanyDataPending: true,
    encoded: '',
    uri: '',
  },
};

const slice = createSlice({
  name: 'companySearchResult',
  initialState,
  reducers: {
    setCompany: (state, { payload }) => {
      state.companyId = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setBuyReportStep: (state, { payload }) => {
      state.buyReport.step = payload;
    },
    setBuyReportDetails: (state, { payload }) => {
      const { productType, reportType, productPrice } = payload;
      state.buyReport.selectedReportType = reportType;
      state.buyReport.productType = productType;
      state.buyReport.productPrice = productPrice;
    },
    setBuyReportSelectedReport: (state, { payload }) => {
      state.buyReport.selectedReport = payload;
    },
    setBuyReportBuyerType: (state, { payload }) => {
      state.buyReport.buyerType = payload;
    },
    setCompanyBuyerInformation: (state, { payload }) => {
      state.buyReport.companyBuyerInformation = payload;
    },
    setPersonBuyerInformation: (state, { payload }) => {
      state.buyReport.personBuyerInformation = payload;
    },
    setBuyReportPaymentType: (state, { payload }) => {
      state.buyReport.paymentType = payload;
    },
    completeStep: (state, { payload }) => {
      const { completedSteps } = state.buyReport;

      if (!completedSteps.includes(payload)) {
        completedSteps.push(payload);
      }
    },
    unCompleteStep: (state, { payload }) => {
      const { completedSteps } = state.buyReport;

      if (completedSteps.includes(payload)) {
        const i = completedSteps.indexOf(payload);
        completedSteps.splice(i, 1);
      }
    },
    setOpayData: (state, { payload }) => {
      const { encoded, uri } = payload;

      state.buyReport.encoded = encoded;
      state.buyReport.uri = uri;
    },
    setSearchCompanyCode: (state, { payload }) => {
      const { searchCompanyCode } = payload;

      state.buyReport.companyBuyerInformation.searchCompanyCode = searchCompanyCode;
      state.buyReport.personBuyerInformation.searchCompanyCode = searchCompanyCode;
    },
    resetCompanySearchResult: () => initialState,
  },
  extraReducers: {
    [getCompanyData.fulfilled]: (state, { payload }) => {
      state.report = payload;
      state.getCompanyDataPending = false;
    },
    [getCompanyData.pending]: state => {
      state.getCompanyDataPending = true;
    },
    [getCompanyData.rejected]: state => {
      state.getCompanyDataPending = false;
    },
    [registerConsultation.fulfilled]: state => {
      state.registerConsultationSuccess = true;
      state.registerConsultationPending = false;
    },
    [registerConsultation.pending]: state => {
      state.registerConsultationSuccess = false;
      state.registerConsultationPending = true;
    },
    [registerConsultation.rejected]: state => {
      state.registerConsultationPending = false;
    },
    [getCompaniesUnregistered.fulfilled]: (state, action) => {
      const { token } = action.meta.arg;

      state.buyReport.companyBuyerInformation.token = token;
      state.buyReport.personBuyerInformation.token = token;
    },
    [getOrderIdFromCompanyBuyReport.pending]: state => {
      state.buyReport.stepperIsLoading = true;
    },
    [getOrderIdFromCompanyBuyReport.rejected]: state => {
      state.buyReport.stepperIsLoading = false;
    },
    [getOrderIdFromCompanyBuyReport.fulfilled]: state => {
      state.buyReport.stepperIsLoading = false;
    },
    [getOrderIdFromPersonBuyReport.pending]: state => {
      state.buyReport.stepperIsLoading = true;
    },
    [getOrderIdFromPersonBuyReport.rejected]: state => {
      state.buyReport.stepperIsLoading = false;
    },
    [getOrderIdFromPersonBuyReport.fulfilled]: state => {
      state.buyReport.stepperIsLoading = false;
    },
  },
});

export const {
  setCompany,
  setPage,
  setBuyReportStep,
  setBuyReportBuyerType,
  setBuyReportDetails,
  setBuyReportSelectedReport,
  setCompanyBuyerInformation,
  setPersonBuyerInformation,
  setBuyReportPaymentType,
  setSearchCompanyCode,
  completeStep,
  unCompleteStep,
  setOpayData,
  resetCompanySearchResult,
} = slice.actions;

export default slice.reducer;
