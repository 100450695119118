import React from 'react';
import { useTranslation } from 'react-i18next';

import colors from 'constants/colors';

import { Tooltip } from 'antd';
import { Sector } from 'recharts';
import classColors from 'components/reusable/graphs/PersonalScoreGraph/classColors';

const CustomLabel = props => {
  const { t } = useTranslation();
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    innerRadius,
    payload,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    graphId,
    isLabel,
    personalScoreClass,
    isPersonalScore = false,
  } = props;

  const angleMapping = isPersonalScore
    ? {
        1: 62,
        2: 24,
        3: -12,
        4: -45,
        5: -84,
      }
    : {
        10: -80,
        9: -64,
        8: -46,
        7: -30,
        6: -12,
        5: 10,
        4: 28,
        3: 46,
        2: 65,
        1: 80,
      };

  const offset = isPersonalScore ? innerRadius : innerRadius + 7;

  const x =
    cx +
    (payload.score < Object.keys(angleMapping).length / 2 + 1 ? (isPersonalScore ? 0 : 7) : isPersonalScore ? 10 : 0) +
    offset * Math.sin(-RADIAN * angleMapping[payload.score]);
  const y = cy - offset * Math.cos(-RADIAN * angleMapping[payload.score]);

  const tooltipProps = {
    overlayClassName: 'graph-tooltip',
    overlayStyle: {
      border: `2px solid ${payload?.active ? payload?.tooltipBorder : '#6d7179'}`,
    },
    ...(!payload.active ? { visible: true } : {}),
    title: (
      <p className="graph-tooltip__content">
        {t(payload?.text)}
        <strong>{` ${payload?.percentages[0].toLocaleString()}% - ${payload?.percentages[1].toLocaleString()}%`}</strong>
      </p>
    ),
    placement: payload.score < 5 ? 'topLeft' : 'topRight',
  };

  const renderShape = () => (
    <Tooltip {...tooltipProps}>
      <g id={`${graphId}${isLabel ? '_label' : ''}`}>
        {!isLabel && (
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
          />
        )}

        <circle
          cx={x - 5}
          cy={y}
          r={14}
          fill="white"
          stroke={
            payload.active
              ? isPersonalScore
                ? classColors[personalScoreClass]
                : `url(#${graphId}_${payload.gradientId})`
              : colors.COLOR_LIGHTER_GRAY
          }
          strokeWidth={2}
        />

        <text
          x={isPersonalScore ? x : payload.score === 10 ? x + 3 : x - 1}
          y={y}
          fill={payload.active ? colors.COLOR_BLACK : colors.COLOR_LIGHTER_GRAY}
          textAnchor={'end'}
          dominantBaseline="central"
        >
          {isPersonalScore ? payload.className : payload.score}
        </text>
      </g>
    </Tooltip>
  );

  return isLabel ? (payload.active ? renderShape() : null) : payload.active ? renderShape() : renderShape();
};

export default CustomLabel;
