import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ReactComponent as GaugeNeedle } from 'assets/img/icons/gauge-needle.svg';
import colors from 'constants/colors';
import useWindowSize from '../../../../hooks/dom/useWindowSize';

const RADIAN = Math.PI / 180;

const cx = '50%';
const cy = '70%';

const getLabelXPositionByIndex = (i, windowWidth) => {
  switch (i) {
    case 0:
      return windowWidth < 450 ? 10 : 16;
    case 1:
      return windowWidth < 450 ? 18 : 30;
    case 2:
      return windowWidth < 450 ? 12 : 20;
  }
};

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, data, index, windowWidth }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN) - getLabelXPositionByIndex(index, windowWidth);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor="start" dominantBaseline="central">
      {data[index].label.toLowerCase()}
    </text>
  );
};

const getChartValue = value => {
  switch (value) {
    case 0:
      return { degree: -65, name: 'low' };
    case 1:
      return { degree: 0, name: 'average' };
    case 2:
      return { degree: 65, name: 'high' };
    case 3:
      return { degree: 0, name: 'noInformation' };
    case 4:
      return { degree: 0, name: 'inactive' };
  }
};

const CreditRisk = ({ value = 3, isTheSalaryGraphAvailable }) => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowSize();

  const data = [
    {
      name: 'low',
      value: 50,
      color: value < 3 ? colors.COLOR_SUCCESS : colors.COLOR_MUTED,
      label: t('prescoreReport.prescore.graph.low'),
    },
    {
      name: 'average',
      value: 50,
      color: value < 3 ? colors.COLOR_AVERAGE : colors.COLOR_MUTED,
      label: t('prescoreReport.prescore.graph.average'),
    },
    {
      name: 'high',
      value: 50,
      color: value < 3 ? colors.COLOR_SECONDARY : colors.COLOR_MUTED,
      label: t('prescoreReport.prescore.graph.high'),
    },
    {
      name: 'noInformation',
      value: 3,
      color: '#EDEDED',
      label: t('prescoreReport.prescore.graph.no_information'),
    },
    {
      name: 'inactive',
      value: 4,
      color: '#EDEDED',
      label: t('prescoreReport.prescore.graph.inactive'),
    },
  ];

  const activeRiskLevelLabel = data.find(item => item.name === getChartValue(value).name).label;

  return (
    <div
      style={{
        height: 300,
      }}
      className="credit-risk-graph-container__wrapper"
    >
      {value < 3 && (
        <GaugeNeedle
          className="credit-risk-graph__gauge-needle"
          style={{
            transform: `translateX(-50%) rotate(${getChartValue(value).degree}deg)`,
          }}
        />
      )}
      <ResponsiveContainer>
        <PieChart>
          <Pie
            dataKey="value"
            startAngle={180}
            endAngle={0}
            data={data.slice(0, data.length - 2)}
            cx={cx}
            cy={cy}
            labelLine={false}
            innerRadius={windowWidth < 600 ? '55%' : '65%'}
            outerRadius={windowWidth < 600 ? '100%' : '120%'}
            label={props => renderCustomizedLabel({ ...props, data, windowWidth })}
            paddingAngle={3}
            fill="#8884d8"
            stroke="none"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <div className="credit-risk-graph-title">
        <span className={`credit-risk-graph-title__heading`}>{t('prescoreReport.prescore.graph.creditrisk')}</span>
        <span className={`credit-risk-graph-title__risk-level credit-risk-graph-title--${getChartValue(value).name}`}>
          {activeRiskLevelLabel}
        </span>
      </div>
    </div>
  );
};

export default CreditRisk;
