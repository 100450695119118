import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ProtectIdentity } from 'assets/img/icons/protectIdentityIcon.svg';

import CompanyDataCard from 'components/reusable/CompanyDataCard';

import { LockOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';

const IdentityProtection = ({ isIdentityProtected, shortened = false }) => {
  const { t } = useTranslation();

  const className = isIdentityProtected ? 'color-secondary' : '';
  const status = isIdentityProtected ? 'on' : 'off';

  return (
    <CompanyDataCard
      icon={<LockOutlined className="icon--gray" style={{ fontSize: 20 }} />}
      title={t('protectIdentity.title')}
      className="fw-600"
    >
      {shortened ? (
        <>
          <Col className={className}> {t(`protectIdentity.status.${status}`)}</Col>
          {isIdentityProtected && <Col className="protect-identity__subtitle">{t('protectIdentity.subtitle')}</Col>}
        </>
      ) : (
        <>
          <Row className="protect-identity">
            <Col span={20}>
              <Col className={className}> {t(`protectIdentity.status.${status}`)}</Col>
              <Col className="protect-identity__subtitle">{t('protectIdentity.subtitle')}</Col>
              <Col className="protect-identity__disclaimer">{t('protectIdentity.disclaimer')}</Col>
            </Col>
            <Col span={4}>
              <ProtectIdentity />
            </Col>
          </Row>
        </>
      )}
    </CompanyDataCard>
  );
};

export default IdentityProtection;
