import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { getOrderId } from 'store/entities/startReport/thunks';
import useRedirect from 'hooks/navigation/useRedirect';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import { setIsUiHidden } from 'store/entities/ui';
import useStartReportState from '../../hooks/report/useStartReportState';
import { resetStartReport } from '../../store/entities/startReport';
import FullReport from './FullReport';
import PdfHeader from '../Pdfs/PdfHeader';
import { setPdfReport } from 'store/entities/startReport';

const StartReportPdf = () => {
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const { t } = useTranslation();
  const { orderId, orderDetails } = useSelector(state => state.startReport);
  const { isLoading, hasError } = useStartReportState();

  const orderReport = useCallback(async () => {
    const orderIdResult = await dispatch(getOrderId(companyId));

    if (!getOrderId.fulfilled.match(orderIdResult)) {
      redirect('/search/company');
    }
  }, [dispatch, redirect, getOrderId]);

  useEffect(() => {
    dispatch(setIsUiHidden(true));
    return () => {
      dispatch(setIsUiHidden(false));
    };
  }, []);

  useEffect(() => {
    dispatch(setPdfReport(true));
    orderReport();
    return () => dispatch(resetStartReport());
  }, []);

  return (
    <LoadingSpinner size="large" className="loader-overlay" spinning={isLoading}>
      {hasError && <Redirect to="/search/company" exact />}
      <div className="pdf-wrapper">
        {orderId && (
          <>
            <PdfHeader title={orderDetails?.companyTitle} subtitle={t('header.breadcrumb.start-report')} />
            <FullReport hideNavigationTabs />
          </>
        )}
      </div>
    </LoadingSpinner>
  );
};

export default StartReportPdf;
