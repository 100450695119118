import LoadingSpinner from '../../../components/reusable/LoadingSpinner';
import { getHistoricalCompanies } from 'store/entities/foreignCompany/thunks';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Typography, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import useFormatDate from '../../../hooks/format/useFormatDate';
import CustomRequestTable from '../../../components/reusable/CustomRequestTable';
import { setSelectedCompany } from '../../../store/entities/foreignCompany';
import { ReactComponent as ArchiveIcon } from 'assets/img/icons/archive.svg';
import CompanyDataCard from 'components/reusable/CompanyDataCard';

const { Text } = Typography;

const HistoricalSearchTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formatDate = useFormatDate();
  const { historicalCompanies, historicalCompaniesPending } = useSelector(state => state.foreignCompany);
  const [pageSize, setPageSize] = useState(3);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(getHistoricalCompanies({ page: { pageNumber: 1, itemsPerPage: 10 } }));
  }, [dispatch]);

  const dataSource = useMemo(() => {
    if (historicalCompaniesPending) {
      return [];
    }
    return historicalCompanies?.results?.length
      ? historicalCompanies.results.map(result => ({
          ...result.company,
          orderId: result.orderId,
        }))
      : [];
  }, [historicalCompanies, historicalCompaniesPending]);

  const handleRowClick = record => {
    dispatch(setSelectedCompany(record));
  };

  const columns = [
    {
      title: t('foreignCompanySearch.results.companyName'),
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text, record) => (
        <>
          <p>
            <Text type="info">{text}</Text>
          </p>
          {record?.oldName && <p>({record.oldName})</p>}
        </>
      ),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('foreignCompanySearch.results.code'),
      dataIndex: 'companyCode',
      key: 'companyCode',
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('foreignCompanySearch.results.address'),
      dataIndex: 'address',
      key: 'address',
      render: address => {
        return <>{address?.simpleValue && <p>{address.simpleValue}</p>}</>;
      },
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('foreignCompanySearch.results.other'),
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <>
          <p>
            <Text>
              {t('foreignCompanySearch.results.other.status')}: {status}
            </Text>
          </p>
          <p>
            <Text>
              {t('foreignCompanySearch.results.other.dateOfLatestAccounts')}:{' '}
              {record?.dateOfLatestAccounts ? formatDate(record.dateOfLatestAccounts) : '-'}
            </Text>
          </p>
          <p>
            <Text>
              {t('foreignCompanySearch.results.other.type')}: {record.type}
            </Text>
          </p>
        </>
      ),
      sortDirections: ['ascend', 'descend'],
    },
  ];

  const handleTableChange = ({ current, pageSize: size }, _, { order, field }) => {
    if (pageSize === size) {
      setPageSize(size);
      setPage(current);
      dispatch(getHistoricalCompanies({ page: { pageNumber: current, itemsPerPage: size } }));
    } else {
      setPageSize(size);
      setPage(1);
      dispatch(getHistoricalCompanies({ page: { pageNumber: 1, itemsPerPage: size } }));
    }
  };

  return (
    <LoadingSpinner size="large" spinning={historicalCompaniesPending}>
      {!historicalCompanies?.totalItemsCount ? null : (
        <CompanyDataCard
          icon={<ArchiveIcon className="icon--gray" />}
          title={t('foreignCompany.historical.companies.title')}
        >
          <CustomRequestTable
            data={dataSource}
            total={historicalCompanies.totalItemsCount}
            columns={columns}
            defaultPageSize={pageSize}
            current={page}
            handleTableChange={handleTableChange}
            pageSizeOptions={[3, 5, 10, 20, 50, 100]}
            rowClassName="ant-table-row-clickable"
            onRow={record => ({
              onClick: () => handleRowClick(record),
            })}
          />
        </CompanyDataCard>
      )}
    </LoadingSpinner>
  );
};

export default HistoricalSearchTable;
