import React from 'react';
import ChooseReport from 'views/FinancialStatements/FinancialStatementData/ChooseReport';
import DeliveredStatements from 'views/FinancialStatements/FinancialStatementData/DeliveredStatements';

import { Col, Row } from 'antd';

const FinancialStatementData = ({ setIsPopUpModalOpen, basicInformation, setReport, report }) => {
  return (
    <Row>
      <Col span={24}>
        <ChooseReport
          setIsPopUpModalOpen={setIsPopUpModalOpen}
          basicInformation={basicInformation}
          setReport={setReport}
          report={report}
        />
        <DeliveredStatements />
      </Col>
    </Row>
  );
};

export default FinancialStatementData;
