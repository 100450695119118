import React, { useState } from 'react';
import AlertsTable from './AlertsTable';
import MonitoringSettings from './MonitoringSettings';
import SearchForResults from './SearchResults';

const Monitoring = ({ legalEntities }) => {
  const [updateModalOpen, setSettingsModalOpen] = useState(false);

  return (
    <>
      <SearchForResults legalEntities={legalEntities} setModalOpen={setSettingsModalOpen} />
      <AlertsTable legalEntities={legalEntities} />
      <MonitoringSettings
        legalEntities={legalEntities}
        modalOpen={updateModalOpen}
        setModalOpen={setSettingsModalOpen}
      />
    </>
  );
};

export default Monitoring;
