import React from 'react';
import { Typography } from 'antd';
import useFormatDate from 'hooks/format/useFormatDate';

const { Text } = Typography;

const TableColumnDate = text => {
  const formatDate = useFormatDate();

  return <Text>{text && formatDate(text)}</Text>;
};

export const TableColumnDateMulti = dates => {
  const formatDate = useFormatDate();

  return (
    <Text>
      {Object.keys(dates).map((key, idx, arr) => `${formatDate(dates[key])} ${idx !== arr.length - 1 ? '/' : ''} `)}
    </Text>
  );
};

export default TableColumnDate;
