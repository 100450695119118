import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Col, Row } from 'antd';

import { summaryInfo } from 'store/entities/professionalReport/selectors/summaryDataSelectors';
import CompanyDataCard from 'components/reusable/CompanyDataCard';
import Statistic from 'components/reusable/Statistic';
import PersonalScore from 'components/reusable/graphs/PersonalScoreGraph';
import colors from 'constants/colors';
import { ReactComponent as Rating } from 'assets/img/icons/rating.svg';
import useWindowSize from '../../../hooks/dom/useWindowSize';

const descriptions = [
  {
    className: 'A',
    color: colors.COLOR_SUCCESS_DARK,
  },
  {
    className: 'B',
    color: colors.COLOR_SUCCESS,
  },
  {
    className: 'C',
    color: colors.COLOR_WARNING_LIGHT,
  },
  {
    className: 'D',
    color: colors.COLOR_WARNING,
  },
  {
    className: 'E',
    color: colors.COLOR_SECONDARY,
  },
];

const PersonalScoreCardDescriptionRow = ({ className, color }) => (
  <Row className="flex justify-content-center">
    <Col span={12} className="align-items-end">
      <span
        style={{
          display: 'inline-flex',
          borderRadius: '5px',
          backgroundColor: color,
          width: '1.125rem',
          height: '1rem',
          marginRight: '2rem',
          marginTop: '0.6rem',
        }}
      />
      <span>{i18next.t(`openDebts.personalScore.card.desc.class${className}.title`)}</span>
    </Col>
    <Col span={12} className="align-items-end">
      <span>{i18next.t(`openDebts.personalScore.card.desc.class${className}`)}</span>
    </Col>
  </Row>
);

const PersonalScoreCard = () => {
  const { width: windowWidth } = useWindowSize();
  const { t } = useTranslation();
  const { rating } = useSelector(summaryInfo);
  const ratingClass = rating.riskGrade.slice(0, 1);

  return (
    <CompanyDataCard icon={<Rating className="icon--gray" />} title={t('openDebts.personalScore.title')}>
      {windowWidth > 1150 ? (
        <>
          <Row gutter={[32, 0]}>
            <Col span={10}>
              <PersonalScore scoreClass={rating.riskGrade} percentage={rating.predictedProbability} />
            </Col>
            <Col span={14}>
              {descriptions.map(({ className, color }) => {
                return <PersonalScoreCardDescriptionRow className={className} color={color} />;
              })}
            </Col>
          </Row>
          <Row className="flex justify-content-start" style={{ marginBottom: 8 }}>
            <Col span={10} align="middle" className="justify-content-center align-items-center">
              <Statistic
                value={`${t('global.class')} ${rating.riskGrade}`}
                description={t(`openDebts.personalScore.card.desc.class${ratingClass}`)}
              />
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12} lg={8} align="middle" className="justify-content-center align-items-center">
              <Statistic
                value={`${t('global.class')} ${rating.riskGrade}`}
                description={t(`openDebts.personalScore.card.desc.class${ratingClass}`)}
              />
            </Col>
            <Col xs={24} md={12} lg={8}>
              <PersonalScore scoreClass={rating.riskGrade} percentage={rating.predictedProbability} />
            </Col>
          </Row>
          <Row className="flex justify-content-center">
            <Col lg={14} xl={12} style={{ maxWidth: 650 }}>
              {descriptions.map(({ className, color }) => {
                return <PersonalScoreCardDescriptionRow className={className} color={color} />;
              })}
            </Col>
          </Row>
        </>
      )}
    </CompanyDataCard>
  );
};

export default PersonalScoreCard;
